<template>
  <v-form
    v-model="legalUrlForm"
    @submit.prevent="handleSubmit()"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1 class="mb-4">{{ legalObject?.headline }}</h1>
        <p class="mb-8">
          {{ legalObject?.copy }}
        </p>
        <v-text-field
          v-model="legalUrl"
          :label="legalObject?.inputLabel"
          :rules="tenantLegalUrlRules"
          required
          variant="outlined"
        >
          <template #append-inner>
            <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
          </template>
        </v-text-field>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          :disabled="!legalUrlForm"
        >
          {{ t('buttons.save') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, PropType, ref } from 'vue'
import { isNotEmpty, isMaxLength } from '@/validators'
import { Icons } from '@/models/enums/IconTypes'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const { t } = useI18n()

const legalUrlForm = ref(false)

interface LegalObject {
  headline: string
  copy: string
  inputLabel: string
  inputValue: string
}

const componentProps = defineProps({
  legalObject: {
    type: Object as PropType<LegalObject>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
const tenantLegalUrlRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 240)])

const legalUrl = ref(componentProps.legalObject?.inputValue || '')

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', legalUrl.value)
}
</script>
