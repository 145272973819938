import { defineStore, getActivePinia, Pinia, Store, storeToRefs } from 'pinia'
import { OrganizationCreateRequest, OrganizationFullResponse } from '@/models/Organization'
import useAxios from '@/plugins/axios'
import { useActiveUserStore } from './activeUser'
import { useLoadingStore } from './loading'
import { UserOrganization } from '@/models/User'
import { useLocalStorage } from '@/composables/useLocalStorage'
import { StoreName } from '@/models/enums/StoreName'
import { useTenantStore } from './tenants'

export interface MyOrganizationStateProperties {
  myOrganizations: UserOrganization[]
  activeOrganization?: OrganizationFullResponse
}

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export const useMyOrganizationStore = defineStore(StoreName.MY_ORGANIZATIONS, {
  state: (): MyOrganizationStateProperties => ({
    myOrganizations: [],
    activeOrganization: undefined,
  }),
  actions: {
    async GET_BY_ID(organizationId: OrganizationFullResponse['id']): Promise<OrganizationFullResponse | undefined> {
      try {
        const { data }: { data: OrganizationFullResponse } = await useAxios.get(`/api/organizations/${organizationId}`, {
          headers: {
            'organization-id': organizationId,
          },
        })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async CREATE_AND_SELECT(organizationObject: OrganizationCreateRequest) {
      try {
        const activeUserStore = useActiveUserStore()
        const { data }: { data: OrganizationFullResponse } = await useAxios.post('/api/organizations', organizationObject)
        await Promise.all([activeUserStore.GET(), this.setActiveOrganization(data.id)]) // refetch active User to update myOrganization-Array
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(organizationObject: OrganizationCreateRequest): Promise<OrganizationFullResponse> {
      try {
        const activeUserStore = useActiveUserStore()
        const { data }: { data: OrganizationFullResponse } = await useAxios.post('/api/organizations', organizationObject)
        await activeUserStore.GET() // refetch active User to update myOrganization-Array
        return data
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(organizationObject: Partial<OrganizationCreateRequest>) {
      try {
        const activeUserStore = useActiveUserStore()
        const { data }: { data: OrganizationFullResponse } = await useAxios.patch(`/api/organizations/${this.activeOrganization?.id}`, organizationObject)
        this.activeOrganization = data
        await activeUserStore.GET() // refetch active User to update myOrganization-Array
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(organization?: UserOrganization) {
      const activeUserStore = useActiveUserStore()
      const loadingStore = useLoadingStore()
      const { activeUser } = storeToRefs(activeUserStore)
      if (activeUser?.value) {
        try {
          await useAxios.delete(`/api/users/${activeUser.value.id}`, {
            headers: {
              'organization-id': organization?.organizationId,
            },
          })
          this.myOrganizations = this.myOrganizations.filter((org) => org.organizationId !== organization?.organizationId)
          if (this.activeOrganization?.id === organization?.organizationId) {
            this.removeActiveOrganization()
            loadingStore.setAppLoading(false)
          }
        } catch {
          return Promise.reject()
        }
      }
    },
    async setActiveOrganization(organizationId: OrganizationFullResponse['id']) {
      try {
        const activeUserStore = useActiveUserStore()
        const tenantStore = useTenantStore()
        const { activeUser } = storeToRefs(activeUserStore)
        const { setItem } = useLocalStorage()

        tenantStore.removeActiveTenant()
        this.removeActiveOrganization()

        this.activeOrganization = await this.GET_BY_ID(organizationId)
        setItem('activeOrganizationId', organizationId || '')
        setItem('activeUserId', activeUser?.value?.id || '')
      } catch (err) {
        return Promise.reject()
      }
    },
    removeActiveOrganization() {
      const pinia = getActivePinia() as ExtendedPinia
      // clear all stores except organization store
      pinia._s.forEach((store) => {
        if (
          store.$id !== StoreName.MY_ORGANIZATIONS &&
          store.$id !== StoreName.ACTIVE_USER &&
          store.$id !== StoreName.TENANTS &&
          store.$id !== StoreName.LOADING
        ) {
          store.$reset()
        }
      })
      const { removeItem } = useLocalStorage()
      removeItem('activeOrganizationId')
      removeItem('activeUserId')
      this.activeOrganization = undefined
    },
    setOrganizations(userOrganizations?: UserOrganization[]) {
      this.myOrganizations = userOrganizations || []
    },
  },
  getters: {
    myOrganizationById(state) {
      return (id: UserOrganization['organizationId']): UserOrganization | undefined => state.myOrganizations.find((org) => org.organizationId === id)
    },
  },
})
