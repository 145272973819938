import { AccessResponse } from '@/models/Access'
import { AuthenticatorRequest } from '@/models/Authenticator'
import { BundleResponse } from '@/models/Bundle'
import { StoreName } from '@/models/enums/StoreName'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'

export const useAccessesStore = defineStore(StoreName.ACCESSES, {
  state: () => ({}),
  actions: {
    async CREATE(authenticator: AuthenticatorRequest, bundleId: BundleResponse['id']): Promise<AccessResponse> {
      try {
        const { data }: { data: AccessResponse } = await useAxios.post(`/api/accesses`, { authenticator, bundleId })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async ACTIVATE(accessId: AccessResponse['id'], note: string): Promise<AccessResponse> {
      try {
        const { data }: { data: AccessResponse } = await useAxios.patch(`/api/accesses/${accessId}/activate`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async DEACTIVATE(accessId: AccessResponse['id'], note: string): Promise<AccessResponse> {
      try {
        const { data }: { data: AccessResponse } = await useAxios.patch(`/api/accesses/${accessId}/deactivate`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async DECLINE(accessId: AccessResponse['id'], note: string): Promise<AccessResponse> {
      try {
        const { data }: { data: AccessResponse } = await useAxios.patch(`/api/accesses/${accessId}/decline`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async LOCK_REQUEST(accessId: AccessResponse['id'], note: string): Promise<AccessResponse> {
      try {
        const { data }: { data: AccessResponse } = await useAxios.patch(`/api/accesses/${accessId}/lock-request`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async RENEW(accessId: AccessResponse['id'], authenticator: AuthenticatorRequest) {
      try {
        const { data }: { data: AccessResponse } = await useAxios.post(`/api/accesses/${accessId}/renew`, { authenticator })
        return data
      } catch {
        return Promise.reject()
      }
    },
  },
})
