import { SearchbarValues } from './../models/SearchbarValues'
import { defineStore } from 'pinia'

interface searchbarStateProperties {
  searchbarProperties: SearchbarValues[]
}

export const useSearchbarStore = defineStore('searchbar', {
  state: (): searchbarStateProperties => ({
    searchbarProperties: [],
  }),
  actions: {
    setSearchbarValues(updatedSearchBarValues: SearchbarValues) {
      const matchedSearchbarValueObject = this.searchbarProperties.find(
        (searchbarValues) => searchbarValues.searchbarRoute === updatedSearchBarValues.searchbarRoute
      )

      if (matchedSearchbarValueObject) {
        matchedSearchbarValueObject.searchQuery = updatedSearchBarValues.searchQuery
        matchedSearchbarValueObject.selectedSortOption = updatedSearchBarValues.selectedSortOption
        matchedSearchbarValueObject.selectedFilterOptions = updatedSearchBarValues.selectedFilterOptions
      } else {
        this.searchbarProperties.push(updatedSearchBarValues)
      }
    },
    clearSearchbarValues(searchbarRoute: SearchbarValues['searchbarRoute']) {
      const searchbarPropertyMatchedIndex = this.searchbarProperties.findIndex((searchbarValues) => searchbarValues.searchbarRoute === searchbarRoute)
      if (typeof searchbarPropertyMatchedIndex === 'number') {
        this.searchbarProperties.splice(searchbarPropertyMatchedIndex, 1)
      }
    },
  },
  getters: {
    getMatchedSearchbarValues(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues | undefined =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)
    },
    getSearchbarSortOption(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues['selectedSortOption'] =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)?.selectedSortOption
    },
    getSearchbarFilterOptions(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues['selectedFilterOptions'] =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)?.selectedFilterOptions
    },
    getSearchbarSearchQuery(state) {
      return (searchbarRoute: SearchbarValues['searchbarRoute']): SearchbarValues['searchQuery'] =>
        state.searchbarProperties.find((searchbarValues) => searchbarRoute === searchbarValues.searchbarRoute)?.searchQuery
    },
  },
})
