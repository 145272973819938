import { defineStore, getActivePinia, Pinia, Store, storeToRefs } from 'pinia'
import { ResolveTenantResponse, TenantDetailResponse, TenantRequest } from '@/models/Tenant'
import { StoreName } from '@/models/enums/StoreName'
import useAxios from '@/plugins/axios'
import { useActiveUserStore } from './activeUser'
import { useLocalStorage } from '@/composables/useLocalStorage'
import { useMyOrganizationStore } from './myOrganizations'

interface TenantStateProperties {
  resolvedTenant?: ResolveTenantResponse
  activeTenant?: TenantDetailResponse
}

interface ExtendedPinia extends Pinia {
  _s: Map<string, Store>
}

export const useTenantStore = defineStore(StoreName.TENANTS, {
  state: (): TenantStateProperties => ({
    resolvedTenant: undefined,
    activeTenant: undefined,
  }),
  actions: {
    async GET_BY_ID(tenantOrganizationId: TenantDetailResponse['id']) {
      try {
        const { data }: { data: TenantDetailResponse } = await useAxios.get(`/api/tenants/${tenantOrganizationId}`)
        return data
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(tenantOrganizationId: TenantDetailResponse['id'], tenantRequestObject: Partial<TenantRequest>) {
      try {
        const { data }: { data: TenantDetailResponse } = await useAxios.patch(`/api/tenants/${tenantOrganizationId}`, tenantRequestObject)
        this.activeTenant = data
      } catch {
        return Promise.reject()
      }
    },
    setTenant(tenant: ResolveTenantResponse) {
      this.resolvedTenant = tenant
    },
    async setActiveTenant(tenantOrganizationId: TenantDetailResponse['id']) {
      try {
        const activeUserStore = useActiveUserStore()
        const myOrganizationStore = useMyOrganizationStore()
        const { activeUser } = storeToRefs(activeUserStore)
        const { setItem } = useLocalStorage()

        myOrganizationStore.removeActiveOrganization()
        this.removeActiveTenant()

        this.activeTenant = await this.GET_BY_ID(tenantOrganizationId)

        setItem('activeTenantOrganizationId', tenantOrganizationId || '')
        setItem('activeUserId', activeUser?.value?.id || '')
      } catch (err) {
        return Promise.reject()
      }
    },
    removeActiveTenant() {
      const pinia = getActivePinia() as ExtendedPinia
      // clear all stores except organization store
      pinia._s.forEach((store) => {
        if (
          store.$id !== StoreName.MY_ORGANIZATIONS &&
          store.$id !== StoreName.ACTIVE_USER &&
          store.$id !== StoreName.TENANTS &&
          store.$id !== StoreName.LOADING
        ) {
          store.$reset()
        }
      })
      const { removeItem } = useLocalStorage()
      removeItem('activeTenantOrganizationId')
      removeItem('activeUserId')
      this.activeTenant = undefined
    },
  },
})
