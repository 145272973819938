<template>
  <BusinessCaseList
    v-if="bundle?.businessCases.length"
    :bundle="bundle"
    is-provider
  />
  <div v-else>
    <p>{{ t('providerBundleDetailBusinessCases.empty') }}</p>
  </div>
</template>

<script lang="ts" setup>
import BusinessCaseList from '@/components/bundles/BusinessCaseList.vue'
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const bundleStore = useBundleStore()

const { bundle } = storeToRefs(bundleStore)
</script>

<i18n lang="yaml">
de:
  providerBundleDetailBusinessCases:
    empty: Es wurden noch keine Business Cases hinterlegt.
</i18n>
