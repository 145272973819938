<template>
  <DefaultList>
    <ListElement
      v-for="organizationRelation in organizationRelations"
      :key="organizationRelation[organizationContext].id"
      clickable
      @click="emit('handleClick', organizationRelation[organizationContext].id)"
    >
      <template #prepend>
        <DefaultAvatar
          color="grey-lighten-4"
          size="48"
        >
          <v-icon>{{ Icons.ORGANIZATIONS }}</v-icon>
        </DefaultAvatar>
      </template>
      <template #title>{{ organizationRelation[organizationContext].name }} </template>
      <template
        v-if="
          organizationRelation.status === StatusTypes.INACTIVE ||
          organizationRelation.status === StatusTypes.INITIAL ||
          organizationRelation.status === StatusTypes.IN_TERMINATION
        "
        #append
      >
        <StatusChip
          class="mx-4"
          :color="getStatusSetFromStatusKey(organizationRelation.status)?.color"
          :icon="getStatusSetFromStatusKey(organizationRelation.status)?.icon"
        >
          {{ t(`organizationRelationStatus.${organizationRelation.status}`) }}
        </StatusChip>
      </template>
    </ListElement>
  </DefaultList>
</template>
<script lang="ts" setup>
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { OrganizationRelationResponse } from '@/models/Organization'
import { useStatusHelper } from '@/composables/useStatusHelper'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { Icons } from '@/models/enums/IconTypes'
import StatusChip from '@/components/StatusChip.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'

const { t } = useI18n()
const emit = defineEmits(['handleClick'])
const { getStatusSetFromStatusKey } = useStatusHelper()

defineProps({
  organizationRelations: { type: Array<OrganizationRelationResponse>, required: true },
  organizationContext: { type: String as PropType<OrganizationTypes>, required: true },
})
</script>
