import { NavigationGroup } from '@/models/NavigationGroup'
import { Icons } from '@/models/enums/IconTypes'
import { Routes } from '@/models/enums/Routes'
import { i18n } from '@/plugins/i18n'

interface NavigationHelper {
  getProviderGroups: () => NavigationGroup[]
  getConsumerGroups: () => NavigationGroup[]
  getTenantGroups: () => NavigationGroup[]
  getNoOrganizationGroups: () => NavigationGroup[]
  getPublicGroups: () => NavigationGroup[]
}

/**
 * @return {NavigationHelper}
 */
export function useNavigationHelper(): NavigationHelper {
  const providerGroup = {
    items: [
      {
        icon: Icons.HOME,
        title: i18n.t('groups.consumer.dashboard'),
        to: { name: Routes.PROVIDER_DASHBOARD },
        exact: true,
        admin: true,
      },
      {
        icon: Icons.APIS,
        title: i18n.t('groups.provider.apis'),
        to: { name: Routes.PROVIDER_PRODUCTS },
        exact: false,
        admin: false,
      },
      {
        icon: Icons.USERS,
        title: i18n.t('groups.provider.customers'),
        to: { name: Routes.PROVIDER_CONSUMERS },
        exact: false,
        admin: false,
      },
    ],
  }
  const consumerGroup = {
    items: [
      {
        icon: Icons.HOME,
        title: i18n.t('groups.consumer.dashboard'),
        to: { name: Routes.CONSUMER_DASHBOARD },
        exact: true,
        admin: true,
      },
      {
        icon: Icons.APPS,
        title: i18n.t('groups.consumer.apps'),
        to: { name: Routes.CONSUMER_BUNDLES },
        exact: false,
        admin: true,
      },
      {
        icon: Icons.PROVIDER,
        title: i18n.t('groups.consumer.provider'),
        to: { name: Routes.CONSUMER_PROVIDERS },
        exact: false,
        admin: false,
      },
    ],
  }

  const tenantGroup = [
    {
      items: [
        {
          icon: Icons.HOME,
          title: i18n.t('groups.tenant.home'),
          to: { name: Routes.TENANT_DASHBOARD },
          exact: true,
          admin: true,
        },
      ],
    },
    {
      items: [
        {
          icon: Icons.SETTINGS,
          title: i18n.t('groups.tenant.settings'),
          to: { name: Routes.TENANT_SETTINGS },
          exact: false,
          admin: true,
        },
        {
          icon: Icons.PROVIDER,
          title: i18n.t('groups.tenant.provider'),
          to: { name: Routes.TENANT_PROVIDER },
          exact: true,
          admin: true,
        },
      ],
    },
  ]

  const settingsGroup = {
    items: [
      {
        icon: Icons.SETTINGS,
        title: i18n.t('groups.settings.organization'),
        to: { name: Routes.ORGANIZATION },
        exact: true,
        admin: true,
      },
    ],
  }
  const homeGroup = {
    items: [
      {
        icon: Icons.HOME,
        title: i18n.t('groups.consumer.dashboard'),
        to: { name: Routes.HOME },
        exact: false,
        admin: false,
      },
    ],
  }
  const marketplaceGroup = {
    items: [
      {
        icon: Icons.MARKETPLACE,
        title: i18n.t('groups.consumer.marketplace'),
        to: { name: Routes.CONSUMER_PRODUCTS },
        exact: false,
        admin: false,
      },
    ],
  }

  /**
   * getProviderGroups
   * @return {NavigationGroup[]}
   */
  function getProviderGroups(): NavigationGroup[] {
    return [providerGroup, settingsGroup]
  }

  /**
   * getConsumerGroups
   * @return {NavigationGroup[]}
   */
  function getConsumerGroups(): NavigationGroup[] {
    return [marketplaceGroup, consumerGroup, settingsGroup]
  }

  /**
   * getTenantGroups
   * @return {NavigationGroup[]}
   */
  function getTenantGroups(): NavigationGroup[] {
    return tenantGroup
  }

  /**
   * getNoOrganizationGroups
   * @return {NavigationGroup[]}
   */
  function getNoOrganizationGroups(): NavigationGroup[] {
    return [marketplaceGroup, homeGroup]
  }

  /**
   * getNoOrganizationGroups
   * @return {NavigationGroup[]}
   */
  function getPublicGroups(): NavigationGroup[] {
    return [marketplaceGroup]
  }

  return {
    getProviderGroups,
    getConsumerGroups,
    getTenantGroups,
    getNoOrganizationGroups,
    getPublicGroups,
  }
}
