<template>
  <div>
    <DialogSplitLayout>
      <template #content-left>
        <div class="d-flex justify-center">
          <ProductTile
            :product="productObject"
            class="ma-0"
          />
        </div>
      </template>
      <template #content-right>
        <h1>{{ t('publishProduct.headline') }}</h1>
        <p v-if="productObject?.publiclyAvailable">{{ t('publishProduct.copy.public') }}</p>
        <p v-else>{{ t('publishProduct.copy.private') }}</p>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="emit('submit', productObject)"
        >
          {{ t('buttons.publish') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
          >{{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogSplitLayout>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ProductResponse } from '@/models/Product'
import { PropType, onMounted, onUnmounted } from 'vue'
import DialogSplitLayout from './layout/DialogSplitLayout.vue'
import ProductTile from '../products/ProductTile.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const props = defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

/**
 * handleKeyPressed
 * @param {event} event
 */
function handleKeyPressed(event: KeyboardEvent): void {
  if (event.key == 'Enter') {
    emit('submit', props.productObject)
  }
}

onMounted(() => {
  document.addEventListener('keyup', handleKeyPressed)
})

onUnmounted(() => {
  document.removeEventListener('keyup', handleKeyPressed)
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  publishProduct:
    headline: API veröffentlichen
    copy:
      public: Deine API wird veröffentlicht und ist auf dem Markplatz öffentlich zugänglich. Du kannst jederzeit Änderungen vornehmen.
      private: Deine API wird veröffentlicht und ist auf dem Markplatz für freigegebene Organisationen verfügbar. Du kannst jederzeit Änderungen vornehmen.
</i18n>
