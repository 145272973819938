<template>
  <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
    <template #content>
      <h1>{{ t('deactivateCredential.headline') }}</h1>
      <p>{{ t('deactivateCredential.copy', { name: selectedAccess.name }) }}</p>
    </template>

    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="handleSubmit()"
      >
        {{ t('buttons.confirm') }}</DefaultButton
      >

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { AccessResponse } from '@/models/Access'
import { PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const props = defineProps({
  selectedAccess: { type: Object as PropType<AccessResponse>, default: () => {} },
})

const { t } = useI18n()
const loading = ref(false)

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', props.selectedAccess)
  loading.value = true
}

const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  deactivateCredential:
    headline: Deaktivierungsanfrage
    copy: Dein Kunde möchte das Authentifizierungsmittel {name} deaktivieren.
</i18n>
