<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit')">
    <template #content>
      <h1>{{ t('bundleChanges.delete.headline') }}</h1>
      <p>{{ t('bundleChanges.delete.copy') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        @click="emit('submit')"
      >
        {{ t('buttons.delete') }}</DefaultButton
      >

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
        >{{ t('buttons.cancel') }}</DefaultButton
      >
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  bundleChanges:
    delete:
      headline: Änderungen verwerfen?
      copy: Sollen die Änderungen wirklich verworfen werden? Dies kann nicht rückgängig gemacht werden.
</i18n>
