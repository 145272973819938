<template>
  <v-form
    v-model='organizationForm'
    @submit.prevent="emit('submit', organization)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1 class='mb-8'>{{ headline }}</h1>
        <p
          v-if='!organizationObject'
          class='mb-8'
        >
          <span class='font-weight-bold'>{{ t('organizationForm.copy.bold') }}</span>
          {{ t('organizationForm.copy.standard') }}
        </p>

        <v-card
          outlined
          class='mb-8 logo-upload'>
          <v-card-item>
            <template #prepend>
              <DefaultAvatar
                size='48'
                :color='PlattformColors.SECONDARY'
                class='mr-4'
              >
                <v-icon
                  v-if="!organization?.logo"
                  size="24">
                  {{ Icons.ORGANIZATIONS }}
                </v-icon>
                <v-img
                  v-if='organization.logo'
                  :src='organization.logo'
                />
              </DefaultAvatar>
            </template>

            <div>
              <v-card-subtitle>
                {{ t('organizationForm.copy.logo') }}
              </v-card-subtitle>
              <v-card-actions class="pl-0 pr-0">

                <DefaultButton
                  class='select-file'
                  :size='ButtonSizes.SMALL'
                  :variant='ButtonVariants.OUTLINED'
                  @click='triggerShowLogoInput'
                >
                  {{ t('organizationForm.form.selectLogo') }}
                </DefaultButton>

                <v-btn
                  v-if='organization.logo'
                  size='small'
                  @click='onClearLogoUpload'
                >
                  <v-icon dark>{{ Icons.DELETE }}</v-icon>
                </v-btn>

                <input
                  ref='uploader'
                  class='d-none'
                  type='file'
                  accept='image/svg+xml'
                  @change='onLogoSelected'
                >
              </v-card-actions>
            </div>
          </v-card-item>
        </v-card>

        <v-text-field
          v-model='organization.name'
          autofocus
          :label="t('organizationForm.form.name')"
          variant='outlined'
          required
          :rules='organizationNameRules'
        />
        <v-text-field
          v-model='organization.email'
          :label="t('organizationForm.form.email')"
          variant='outlined'
          required
          :rules='organizationEmailRules'
        />
        <div class='d-flex'>
          <v-text-field
            v-model='organization.address.street'
            :label="t('organizationForm.form.street')"
            variant='outlined'
            required
            :rules='organizationCityStreetRules'
            class='w-66 mr-6'
          />
          <v-text-field
            v-model='organization.address.number'
            :label="t('organizationForm.form.number')"
            variant='outlined'
            required
            :rules='organizationNumberRules'
            class='w-33'
          />
        </div>
        <div class='d-flex'>
          <v-text-field
            v-model='organization.address.zipCode'
            :label="t('organizationForm.form.zipCode')"
            variant='outlined'
            required
            :rules='organizationZipCodeRules'
            class='w-33 mr-6'
          />
          <v-text-field
            v-model='organization.address.city'
            :label="t('organizationForm.form.city')"
            variant='outlined'
            required
            :rules='organizationCityStreetRules'
            class='w-66'
          />
        </div>
        <v-text-field
          v-model='organization.registrationNumber'
          :label="t('organizationForm.form.registrationNumber')"
          variant='outlined'
        />
      </template>
      <template #buttons>
        <DefaultButton
          type='submit'
          :color='PlattformColors.PRIMARY'
          :loading='loading'
          :disabled='!organizationForm'
        >
          {{ organizationObject ? t('buttons.save') : t('buttons.create') }}
        </DefaultButton>

        <DefaultButton
          :color='PlattformColors.SECONDARY'
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang='ts' setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { computed, PropType, ref } from 'vue'
import { OrganizationFullResponse } from '@/models/Organization'
import { isMaxLength, isMinLength, isNotEmpty } from '@/validators'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { ButtonSizes, ButtonVariants } from '@/models/enums/components/Buttons'
import { Icons } from '@/models/enums/IconTypes'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel', 'logo-upload'])

const props = defineProps({
  organizationObject: {
    type: Object as PropType<OrganizationFullResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const selectedFile = ref<File | null>(null)
const uploader = ref<HTMLTextAreaElement | null>(null)
const alertStore = useAlertStore()

const onClearLogoUpload = async (): Promise<void> => {
  selectedFile.value = null
  organization.value.logo = null
}

const triggerShowLogoInput = async (): Promise<void> => {
  uploader.value?.click()
}

const onLogoSelected = async (evt: Event): Promise<void> => {
  const elLogoInput = evt.target as HTMLInputElement
  if (elLogoInput && elLogoInput?.files) {
    selectedFile.value = elLogoInput.files[0]

    try {
      const reader = new FileReader()
      reader.onloadend = (): void => {
        organization.value.logo = reader.result as string
        emit('logo-upload', selectedFile.value)
      }
      reader.readAsDataURL(selectedFile.value)
    } catch (error) {
      alertStore.add({
        text: t('organizationForm.errors.readLogo', { filename: selectedFile.value.name }),
        type: AlertTypes.ERROR,
      })
    }
  }
}

const organizationForm = ref(false)
const organization = ref({
  name: props.organizationObject?.name || '',
  email: props.organizationObject?.email || '',
  logo: props.organizationObject?.logo,
  address: {
    street: props.organizationObject?.address?.street,
    number: props.organizationObject?.address?.number,
    city: props.organizationObject?.address?.city,
    zipCode: props.organizationObject?.address?.zipCode,
  },
  registrationNumber: props.organizationObject?.registrationNumber,
})

const organizationNameRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 50),
])

const organizationEmailRules = computed(() => [
  (v: string): boolean | string => isNotEmpty(v),
  (v: string): boolean | string => isMinLength(v, 3),
  (v: string): boolean | string => isMaxLength(v, 64),
])

const organizationCityStreetRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 90)])
const organizationNumberRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 10)])
const organizationZipCodeRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 15)])

const headline = computed(() => (props.organizationObject ? t('organizationForm.headline.edit') : t('organizationForm.headline.create')))
</script>
<i18n lang='yaml'>
de:
  organizationForm:
    errors:
      readLogo: 'Die Datei: {filename} konnte nicht gelesen werden.'
    headline:
      create: Organisation erstellen
      edit: Organisationsdaten
    copy:
      bold: Deine Organisation ist bereits vorhanden?
      standard: Lass Dich von Deinem Admin zur Organisation hinzufügen.
      logo: 'Dein Logo ist für API-Anbieter und dich sichtbar. Optimale Größe: 96x96 px; Datei-Format: .svg'
    form:
      name: Organisationsname*
      email: Email*
      street: Straße*
      number: Hausnummer*
      city: Stadt*
      zipCode: Postleitzahl*
      registrationNumber: Handelsregisternummer
      selectLogo: Logo auswählen
</i18n>

<style lang='scss' scoped>
.logo-upload {
  .v-card-subtitle {
    white-space: normal;
  }
}
</style>
