<template>
  <DialogDefaultLayout>
    <template #content>
      <h1>{{ t('productInviteOrganization.headline') }}</h1>
      <p>{{ t('productInviteOrganization.copy') }}</p>

      <p class="highlight">{{ t('productInviteOrganization.subline.first') }}</p>
      <v-autocomplete
        v-model="selectedOrganization"
        :label="t('productInviteOrganization.label')"
        :items="invitableConsumerOrgas"
        item-title="name"
        return-object
        variant="outlined"
        @update:model-value="(organization: OrganizationPublicResponse | null) => addOrganizationToSelected(organization)"
      />
      <template v-if="!selectedOrganizations.length">
        <p class="highlight">{{ t('productInviteOrganization.subline.second') }}</p>
        <v-list v-if="onlyInvitedOrgas.length || consumers.length">
          <v-list-item
            v-for="invited in onlyInvitedOrgas"
            :key="invited.productOfferId"
            class="bg-grey-lighten-4 px-6 py-4 mb-4"
          >
            <template #prepend>
              <v-avatar
                color="grey"
                tile
                rounded
              >
                {{ getFirstLetterUpperCase(invited.consumerOrganisation.name) }}
              </v-avatar>
            </template>
            <v-list-item-title>{{ invited.consumerOrganisation.name }}</v-list-item-title>
            <template #append>
              <IconButton
                :icon="Icons.DELETE"
                :loading="loadingDeleteButtons[invited.productOfferId]"
                @click="deleteInvitation(invited)"
              />
            </template>
          </v-list-item>
          <v-list-item
            v-for="consumer in consumers"
            :key="consumer.id"
            class="bg-grey-lighten-4 px-6 py-4 mb-4"
          >
            <template #prepend>
              <v-avatar
                color="grey-lighten-4"
                tile
                rounded
              >
                {{ getFirstLetterUpperCase(consumer.name) }}
              </v-avatar>
            </template>
            <v-list-item-title>{{ consumer.name }}</v-list-item-title>
            <template #append>
              <p class="highlight mb-0">{{ t('productInviteOrganization.inUse') }}</p>
            </template>
          </v-list-item>
        </v-list>
        <Placeholder
          v-else
          :headline="t('productInviteOrganization.info.headline')"
          :copy="t('productInviteOrganization.info.copy')"
        />
      </template>
      <template v-else>
        <p class="highlight">{{ t('productInviteOrganization.subline.third') }}</p>
        <v-list>
          <v-list-item
            v-for="organization in selectedOrganizations"
            :key="organization.id"
            class="bg-grey-lighten-4 px-6 py-4 mb-4"
          >
            <template #prepend>
              <v-avatar
                color="grey-lighten-4"
                tile
                rounded
              >
                {{ getFirstLetterUpperCase(organization.name) }}
              </v-avatar>
            </template>
            <v-list-item-title>{{ organization.name }}</v-list-item-title>
            <template #append>
              <IconButton
                :icon="Icons.DELETE"
                @click="removeFromSelected(organization)"
              />
            </template>
          </v-list-item>
        </v-list>
      </template>
    </template>
    <template
      v-if="!selectedOrganizations.length"
      #buttons
    >
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        @click="emit('done')"
      >
        {{ t('buttons.done') }}
      </DefaultButton>
    </template>
    <template
      v-else
      #buttons
    >
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('invite', selectedOrganizations)"
      >
        {{ t('buttons.send') }}
      </DefaultButton>

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="clearSelected()"
      >
        {{ t('buttons.back') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ProductResponse } from '@/models/Product'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { PropType, computed, ref } from 'vue'
import { OrganizationPublicResponse } from '@/models/Organization'
import { ProductOfferResponse } from '@/models/ProductOffer'
import { Icons } from '@/models/enums/IconTypes'
import Placeholder from '../Placeholder.vue'
import { useFormatHelper } from '@/composables/useFormatHelper'
import IconButton from '../baseComponents/buttons/IconButton.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const { getFirstLetterUpperCase } = useFormatHelper()

const props = defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  organizations: {
    type: Array<OrganizationPublicResponse>,
    default: [],
  },
  consumers: {
    type: Array<OrganizationPublicResponse>,
    default: [],
  },
  productOffers: {
    type: Array<ProductOfferResponse>,
    default: [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['done', 'invite', 'remove'])

const selectedOrganization = ref<OrganizationPublicResponse>()
const selectedOrganizations = ref<OrganizationPublicResponse[]>([])
const loadingDeleteButtons = ref<{ [key: string]: boolean }>({})

// invited organizations that are not currently consuming the product (have not accepted the productOffer)
const onlyInvitedOrgas = computed(() =>
  props.productOffers.filter((offer) => !props.consumers.find((consumer) => consumer.id === offer.consumerOrganisation.id))
)

// consumer organizations that are not invited yet
const invitableConsumerOrgas = computed(() =>
  props.organizations.filter((organization) => !props.productOffers.find((offer) => offer.consumerOrganisation?.id === organization.id))
)

/**
 * addOrganizationToSelected
 * @param {organization | null} organization | null
 */
function addOrganizationToSelected(organization: OrganizationPublicResponse | null): void {
  if (organization !== null && !selectedOrganizations.value.find((org) => org.id === organization.id)) {
    selectedOrganizations.value.push(organization)
    selectedOrganization.value = undefined
  }
}

/**
 * removeFromSelected
 * @param {organization} organization
 */
function removeFromSelected(organization: OrganizationPublicResponse): void {
  selectedOrganizations.value = selectedOrganizations.value.filter((org) => org.id !== organization.id)
}

/**
 * clearSelected
 */
function clearSelected(): void {
  selectedOrganizations.value = []
}

/**
 * deleteInvitation
 * @param {productOffer} productOffer
 */
function deleteInvitation(productOffer: ProductOfferResponse): void {
  loadingDeleteButtons.value[productOffer.productOfferId] = true
  emit('remove', productOffer)
}

/**
 * clearDeleteLoading
 * @param {productOfferId} productOfferId
 */
function clearDeleteLoading(productOfferId: ProductOfferResponse['productOfferId']): void {
  loadingDeleteButtons.value[productOfferId] = false
}

defineExpose({
  clearSelected,
  clearDeleteLoading,
})
</script>

<i18n lang="yaml">
de:
  productInviteOrganization:
    headline: Freigaben verwalten
    copy: Wir informieren die Organisationen per Mail über die Freigabe.
    subline:
      first: API freigeben
      second: Organisationen mit Zugriff
      third: Ausgewählte Organisationen
    label: Nach Organisation suchen
    inUse: API in Verwendung
    info:
      headline: Keine Freigabe erteilt
      copy: Füge Organisationen hinzu, um deine API sichtbar zu machen.
</i18n>
