<template>
  <template v-if="bundle">
    <BusinessCaseList
      v-if="bundle.businessCases.length"
      :bundle="bundle"
      @open-edit-dialog="businessCaseDialogRef?.open()"
    />
    <CreateTeaser
      v-else
      :icon="Icons.BAR_CHART_ALT"
      :headline="t('consumerBundleDetailBusinessCases.createContent.headline')"
      :copy="t('consumerBundleDetailBusinessCases.createContent.copy')"
      :btn-text="t('buttons.add')"
      @submit="businessCaseDialogRef?.open()"
    />
    <Dialog
      ref="businessCaseDialogRef"
      @on-close="businessCaseDialogRef?.close()"
    >
      <DialogBundleBusinessCase
        :loading="businessCaseLoading"
        :bundle="bundle"
        :bundle-change="getBundleChangeById(bundle.id)"
        @cancel="businessCaseDialogRef?.close()"
        @submit="(businessCases: Array<BundleBusinessCaseResponse>) => updateBusinessCase(businessCases)"
      />
    </Dialog>
  </template>
</template>

<script lang="ts" setup>
import DialogBundleBusinessCase from '@/components/dialogs/DialogBundleBusinessCase.vue'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import BusinessCaseList from '@/components/bundles/BusinessCaseList.vue'
import Dialog from '@/components/layout/Dialog.vue'
import { BundleBusinessCaseResponse } from '@/models/Bundle'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useAlertStore } from '@/store/alerts'
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const bundleStore = useBundleStore()
const alertStore = useAlertStore()

const { bundle, getBundleChangeById } = storeToRefs(bundleStore)

const businessCaseDialogRef = ref<InstanceType<typeof Dialog>>()
const businessCaseLoading = ref(false)

/**
 * updateBusinessCase
 * @param {businessCases} businessCases
 */
async function updateBusinessCase(businessCases: Array<BundleBusinessCaseResponse>): Promise<void> {
  if (bundle?.value) {
    if (bundle?.value?.contractStatus === StatusTypes.DRAFT) {
      try {
        businessCaseLoading.value = true
        await bundleStore.UPDATE(bundle?.value?.id, { businessCases })
        alertStore.add({
          text: t('consumerBundleDetailBusinessCases.updated'),
          type: AlertTypes.SUCCESS,
        })
      } catch {
        Promise.resolve()
      } finally {
        businessCaseLoading.value = false
        businessCaseDialogRef.value?.close()
      }
    } else {
      bundleStore.pushBusinessCaseToBundleChanges(bundle?.value?.id, businessCases)
      businessCaseDialogRef.value?.close()
    }
  }
}
</script>

<i18n lang="yaml">
de:
  consumerBundleDetailBusinessCases:
    updated: Der Business Case wurde aktualisiert!
    createContent:
      headline: Es wurde noch kein Business Case hinterlegt.
      copy: Füge einen Business Case hinzu, um deiner Provider-Organisation mehr Informationen zu deiner App zu bieten.
</i18n>
