<template>
  <v-footer
    class="footer bg-secondary"
    padless
  >
    <v-list
      class="footer-navigation"
      flat
    >
      <v-list-item
        variant="plain"
        :href="resolvedTenant?.legalInformation.imprintUrl"
        target="_blank"
        :data-cy="FooterBarTest.IMPRINT_LINK"
      >
        {{ t('footer.imprint') }}
      </v-list-item>
      <v-list-item
        variant="plain"
        :href="resolvedTenant?.legalInformation.privacyPolicyUrl"
        target="_blank"
        :data-cy="FooterBarTest.PRIVACY_LINK"
      >
        {{ t('footer.privacy') }}
      </v-list-item>
      <v-list-item
        variant="plain"
        target="_blank"
        :href="resolvedTenant?.legalInformation.termsOfUseUrl"
        :data-cy="FooterBarTest.TERMS_LINK"
      >
        {{ t('footer.termsOfUse') }}
      </v-list-item>
    </v-list>
    <div class="footer__logo-wrapper">
      <v-img
        class="footer__logo"
        :src="logoSparkasse"
      ></v-img>
    </div>
  </v-footer>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import logoSparkasse from '@/assets/logos/logo-sparkasse.svg'
import { useTenantStore } from '@/store/tenants'
import { storeToRefs } from 'pinia'
import { FooterBarTest } from '@/models/enums/test/FooterBarTest'

const { t } = useI18n()

const tenantStore = useTenantStore()

const { resolvedTenant } = storeToRefs(tenantStore)
</script>
<style lang="scss" scoped>
@use 'sass:map';

.footer {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 0;
  right: 0;

  &.v-footer {
    padding-left: 16rem;
    align-items: end;
    margin-top: 3rem;

    @media only screen and (max-width: #{map.get($container-max-widths, 'sm')}) {
      padding-left: 0;
    }
  }

  &__logo {
    float: right;
    margin: 1rem 2rem 0 0;
    width: 1.2rem;
    height: 1.55rem;

    &-wrapper {
      position: absolute;
      right: 1rem;
      bottom: 2rem;
    }
  }

  &-navigation.v-list {
    background-color: transparent;
    display: flex;
    justify-content: center;
    width: 100%;

    @media only screen and (max-width: #{map.get($container-max-widths, 'xs')}) {
      display: block;
      text-align: center;
    }

    .v-list-item {
      background-color: transparent;
      flex: none;
      font-size: $font-size-s;
      opacity: 0.6;
      position: relative;

      &--active {
        opacity: 1;
      }

      &__content {
        @media only screen and (max-width: #{map.get($container-max-widths, 'xs')}) {
          display: inline;
          text-align: center;
        }
      }
    }
  }
}
</style>

<i18n lang="yaml">
de:
  footer:
    privacy: Datenschutz
    imprint: Impressum
    termsOfUse: Nutzungsbedingungen
</i18n>
