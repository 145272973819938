<template>
  <div>
    <DialogDefaultLayoutEnter :on-submit="() => emit('submit', bundle.id)">
      <template #content>
        <h1>{{ t('bundle.delete.headline') }}</h1>
        <p>{{ t('bundle.delete.copy') }}</p>
        <v-list-item class="py-4 border-grey">
          <template #prepend>
            <BundleAvatar :size="48" />
          </template>
          <v-list-item-title>
            <p class="highlight mb-0">{{ bundle.name }}</p>
          </v-list-item-title>
          <v-list-item-subtitle v-if="bundle.providerOrganization?.name">
            <p class="mb-0 xs">{{ bundle.providerOrganization.name }}</p>
          </v-list-item-subtitle>
        </v-list-item>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="emit('submit', bundle.id)"
        >
          {{ t('buttons.delete') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import { BundleResponse } from '@/models/Bundle'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'

const { t } = useI18n()

defineProps({
  bundle: {
    type: Object as PropType<BundleResponse>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>

<i18n lang="yaml">
de:
  bundle:
    delete:
      headline: App löschen
      copy: Bist du dir sicher, dass du folgende App unwiderruflich löschen möchtest?
</i18n>
