<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit')">
    <template #content>
      <h1>{{ t('invitedUser.headline') }}</h1>
      <p>{{ t('invitedUser.copy') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        @click="emit('submit')"
      >
        {{ t('buttons.done') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  invitedUser:
    headline: Die Einladung wurde verschickt!
    copy: Es wurde eine Benachrichtung an die angegebene E-Mail-Adresse versandt. Der Nutzer kann mit einem bereits bestehenden Account beitreten oder
      sich mit der angegebenen E-Mail-Adresse neu registrieren.
</i18n>
