<template>
  <div class="assignments">
    <div class="row">
      <div class="col d-flex justify-center">
        <LottieAnimation
          v-if="animationVisible"
          class="waiting-animation"
          :loop="true"
          :autoplay="true"
          :animation-data="animationData"
        />
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-center">
        <p>{{ t('assignments.wait') }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useInvitationStore } from '@/store/invitations'
import { useMyOrganizationStore } from '@/store/myOrganizations'

import { LottieAnimation } from 'lottie-web-vue'
import animationData from '@/assets/animation/wallis_logo-animation_lottie.json'
import { Routes } from '@/models/enums/Routes'
import { useActiveUserStore } from '@/store/activeUser'
import { ref } from 'vue'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const invitationCode: string = route.query.invitationCode as string
const animationVisible = ref(true)

const invitationStore = useInvitationStore()
const myOrganizationStore = useMyOrganizationStore()
const activeUserStore = useActiveUserStore()
const alertStore = useAlertStore()

/**
 *
 * assignUserToOrganization
 * @param {invitationCode} invitationCode
 */
async function assignUserToOrganization(invitationCode: string): Promise<void> {
  try {
    const { data } = await invitationStore.assignUserToOrganization(invitationCode)
    const organizationId = data.organizationId
    if (organizationId) {
      await Promise.all([activeUserStore.GET(), myOrganizationStore.setActiveOrganization(organizationId)])

      alertStore.add({
        text: t('assignments.success', { organizationName: myOrganizationStore.activeOrganization?.name }),
        type: AlertTypes.SUCCESS,
      })
    }
  } catch {
    Promise.resolve()
  } finally {
    animationVisible.value = false
    router.push({ name: Routes.HOME })
  }
}

if (invitationCode) {
  setTimeout(() => {
    assignUserToOrganization(invitationCode)
  }, 2000)
} else {
  router.push({ name: Routes.HOME })
}
</script>

<style lang="scss" scoped>
.assignments {
  width: 100%;

  .waiting-animation {
    width: 18.75rem;
    height: 12.5rem;
  }

  p {
    text-align: center;
    font-size: $font-size-m;
  }
}
</style>

<i18n lang="yaml">
de:
  assignments:
    wait: Bitte warten
    success: 'Du bist erfolgreich der Organisation: {organizationName} beigetreten.'
</i18n>
