<template>
  <div class="content-container">
    <div
      v-if="swaggerFile"
      id="swagger-ui-container"
    ></div>
    <p
      v-else
      class="mb-0"
    >
      {{ t('noOpenApiFileAvailable') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import { useFilesStore } from '@/store/files'
import { ProductDocumentationFileResponse } from '@/models/File'
import { FilesAndFolderNodeTypes } from '@/models/enums/FilesAndFolderTypes'
import { ProductDocumentationFolderResponse } from '@/models/Folder'

const { t } = useI18n()
const route = useRoute()
const filesStore = useFilesStore()

const { filesAndFolders } = storeToRefs(filesStore)

const swaggerFile = ref<ProductDocumentationFileResponse>()
const productId = route.params.productId as string

try {
  await filesStore.GET_PRODUCT_DOCUMENTATION_STRUCTURE(productId)

  const openApiFolder = filesAndFolders.value.find((folder) => {
    return folder.nodeType === FilesAndFolderNodeTypes.API
  })

  swaggerFile.value = (openApiFolder as ProductDocumentationFolderResponse)?.files.find((file) => file.active)

  onMounted(() => {
    if (swaggerFile.value) {
      SwaggerUI({
        url: `/api/products/documentations/files/${swaggerFile.value.id}/content`,
        dom_id: '#swagger-ui-container',
      })
    }
  })
} catch {
  Promise.resolve()
}
</script>
<i18n lang="yaml">
de:
  noOpenApiFileAvailable: Es wurde keine OpenAPI-Datei gefunden.
</i18n>
