export enum Icons {
  ADD_API = 'wi-add-api',
  ADD_ORGANIZATION = 'wi-add-organisation',
  ADD = 'wi-add',
  ALGORITHM = 'wi-algorithm',
  API_INFOS = 'wi-api-infos',
  API_OVERVIEW = 'wi-api-overview',
  APIS = 'wi-apis',
  APPS = 'wi-apps',
  ARCHIVE = 'wi-archive',
  ARROW_DOWN = 'wi-arrow-down',
  ARROW_LEFT_DOWN = 'wi-arrow-left-down',
  ARROW_LEFT_UP = 'wi-arrow-left-up',
  ARROW_LEFT = 'wi-arrow-left',
  ARROW_RIGHT_DOWN = 'wi-arrow-right-down',
  ARROW_RIGHT_UP = 'wi-arrow-right-up',
  ARROW_RIGHT = 'wi-arrow-right',
  ARROW_UP = 'wi-arrow-up',
  ATTACHMENT = 'wi-attachment',
  AUTHENTICATION = 'wi-authentication',
  BACKSPACE = 'wi-backspace',
  BAN = 'wi-ban',
  BANK_ACCOUNT = 'wi-bank-account',
  BAR_CHART_ALT = 'wi-bar-chart-alt',
  BAR_CHART = 'wi-bar-chart',
  BOARD = 'wi-board',
  BOLD = 'wi-bold',
  BOOK = 'wi-book',
  BOOKMARK = 'wi-bookmark',
  BOX_TILTED = 'wi-box-tilted',
  BROWSE_API = 'wi-browse-apis',
  CALENDAR = 'wi-calendar',
  CALL = 'wi-call',
  CAMERA = 'wi-camera',
  CARET_DOWN = 'wi-caret-down',
  CARET_LEFT = 'wi-caret-left',
  CARET_RIGHT = 'wi-caret-right',
  CARET_UP = 'wi-caret-up',
  CHECK_INDETERMINATE = 'wi-check-indeterminate',
  CHECKBOX_ON = 'wi-checkbox-on',
  CHECKBOX_OFF = 'wi-checkbox-off',
  CHECK = 'wi-check',
  CHEVRON_DOUBLE_DOWN = 'wi-chevron-double-down',
  CHEVRON_DOUBLE_LEFT = 'wi-chevron-double-left',
  CHEVRON_DOUBLE_RIGHT = 'wi-chevron-double-right',
  CHEVRON_DOUBLE_UP = 'wi-chevron-double-up',
  CHEVRON_DOWN = 'wi-chevron-down',
  CHEVRON_LEFT = 'wi-chevron-left',
  CHEVRON_RIGHT = 'wi-chevron-right',
  CHEVRON_UP = 'wi-chevron-up',
  CIRCLE_ADD = 'wi-circle-add',
  CIRCLE_ARROW_DOWN = 'wi-circle-arrow-down',
  CIRCLE_ARROW_LEFT = 'wi-circle-arrow-left',
  CIRCLE_ARROW_RIGHT = 'wi-circle-arrow-right',
  CIRCLE_ARROW_UP = 'wi-circle-arrow-up',
  CIRCLE_CHECK = 'wi-circle-check',
  CIRCLE_CLOSED = 'wi-circle-closed',
  CIRCLE_ERROR = 'wi-circle-error',
  CIRCLE_HELP = 'wi-circle-help',
  CIRCLE_INFORMATION = 'wi-circle-information',
  CIRCLE_REMOVE = 'wi-circle-remove',
  CIRCLE_WARNING = 'wi-circle-warning',
  CIRCLE = 'wi-circle',
  CIRCLE_FULL = 'wi-circle-full',
  CIRCLE_SMALL_FULL = 'wi-circle-small-full',
  CLIPBOARD_CHECK = 'wi-clipboard-check',
  CLIPBOARD_LIST = 'wi-clipboard-list',
  CLIPBOARD = 'wi-clipboard',
  CLOCK = 'wi-clock',
  CLOSE = 'wi-close',
  CLOUD_DOWNLOAD = 'wi-cloud-download',
  CLOUD_UPLOAD = 'wi-cloud-upload',
  CLOUD = 'wi-cloud',
  CODE_FILE_1 = 'wi-code-file-1',
  CODE_FILE = 'wi-code-file',
  CODE = 'wi-code',
  COLOR_PICKER_FILL_DESIGN = 'wi-color-picker-fill-design',
  COMPUTER = 'wi-computer',
  CONNECTED_APIS = 'wi-connected-apis',
  CONSUMER_OVERVIEW = 'wi-consumer-overview',
  COPY = 'wi-copy',
  CREDIT_CARD = 'wi-credit-card',
  CONSUMERS = 'wi-customers',
  DASHBOARD = 'wi-dashboard',
  DELETE_ALT = 'wi-delete-alt',
  DELETE = 'wi-delete',
  DOCUMENT_ADD = 'wi-document-add',
  DOCUMENT_CHECK = 'wi-document-check',
  DOCUMENT_DOWNLOAD = 'wi-document-download',
  DOCUMENT_EMPTY = 'wi-document-empty',
  DOCUMENT_WRITE = 'wi-document-move',
  DOCUMENT_REMOVE = 'wi-document-remove',
  DOCUMENT = 'wi-document',
  DOWNLOAD = 'wi-download',
  DRAG = 'wi-drag',
  EDIT_ALT = 'wi-edit-alt',
  EDIT = 'wi-edit',
  EMAIL = 'wi-email',
  ENTER = 'wi-enter',
  EURO = 'wi-euro',
  EXPAND = 'wi-expand',
  EXPORT = 'wi-export',
  EXTERNAL_LINK = 'wi-external-link',
  EYE_1 = 'wi-eye-1',
  EYE_OFF_1 = 'wi-eye-off-1',
  EYE_OFF = 'wi-eye-off',
  EYE = 'wi-eye',
  FAVORITE = 'wi-favorite',
  FAVORITE_HALF = 'wi-favorite-half',
  FAVORITE_FULL = 'wi-favorite-full',
  FILTER = 'wi-filter',
  FOLDER_ADD = 'wi-folder-add',
  FOLDER_CHECK = 'wi-folder-check',
  FOLDER_DOWNLOAD = 'wi-folder-download',
  FOLDER_REMOVE = 'wi-folder-remove',
  FOLDER = 'wi-folder',
  GLOBE = 'wi-globe',
  GRID = 'wi-grid',
  H1 = 'wi-h1',
  H2 = 'wi-h2',
  H3 = 'wi-h3',
  HEART = 'wi-heart',
  HOME = 'wi-home',
  IMAGE = 'wi-image',
  INBOX = 'wi-inbox',
  INVITATIONS = 'wi-invitations',
  INVITE = 'wi-invite',
  INVOICE = 'wi-invoice',
  ISSUE_REPORT = 'wi-issue-report',
  ITALIC = 'wi-italic',
  LAPTOP = 'wi-laptop',
  LINK_ALT = 'wi-link-alt',
  LINK = 'wi-link',
  LIST = 'wi-list',
  LOCATION = 'wi-location',
  LOCK = 'wi-lock',
  LOG_OUT = 'wi-log-out',
  MANAGE_APIS = 'wi-manage-apis',
  MANAGE_CONSUMERS = 'wi-manage-consumers',
  MAP = 'wi-map',
  MARKETPLACE = 'wi-marketplace',
  MEGAPHONE = 'wi-megaphone',
  MEMBER = 'wi-members',
  MENU = 'wi-menu',
  MESSAGE_ALT = 'wi-message-alt',
  MESSAGE = 'wi-message',
  MOBILE = 'wi-mobile',
  MOON = 'wi-moon',
  MOVE = 'wi-move',
  NEW_VERSION_API = 'wi-new-version-api',
  NEXT = 'wi-next',
  NUMERIC4 = 'wi-numeric-4',
  NUMERIC3 = 'wi-numeric-3',
  NUMERIC2 = 'wi-numeric-2',
  NUMERIC1 = 'wi-numeric-1',
  NUMERIC0 = 'wi-numeric-0',
  DYNAMIC_NUMERIC = 'wi-numeric-',
  NOTIFICATION_OFF = 'wi-notification-off',
  NOTIFICATION = 'wi-notification',
  OPTIONS_HORIZONTAL = 'wi-options-horizontal',
  OPTIONS_VERTICAL = 'wi-options-vertical',
  ORDERED_LIST = 'wi-ordered-list',
  ORGANIZATION_DOCS = 'wi-organisation-docs',
  ORGANIZATION_MEMBERS = 'wi-organisation-members',
  ORGANIZATION_SETTINGS = 'wi-organisation-settings',
  ORGANIZATIONS = 'wi-organisations',
  PAUSE = 'wi-pause',
  PDF = 'wi-pdf',
  PERCENTAGE = 'wi-percentage',
  PIN = 'wi-pin',
  PLATTFORM_MEMBERS = 'wi-platform-members',
  PLATFORM_ORGANIZATIONS = 'wi-platform-oragnisations',
  PLATFORM_ORGANIZATION = 'wi-platform-settings',
  PLATFORM_SUPPORT = 'wi-platform-support',
  PLAY = 'wi-play',
  PREVIOUS = 'wi-previous',
  PROGRAMM = 'wi-programm',
  PRIVATE = 'wi-private',
  PRODUCT = 'wi-product',
  PROVIDER = 'wi-provider',
  QUOTE = 'wi-quote',
  REFRESH = 'wi-refresh',
  REMOVE = 'wi-remove',
  REPEAT = 'wi-repeat',
  ROCKET = 'wi-rocket',
  SAVE = 'wi-save',
  SEARCH = 'wi-search',
  SECURE = 'wi-secure',
  SELECT = 'wi-select',
  SEND = 'wi-send',
  SETTINGS = 'wi-settings',
  SHARE = 'wi-share',
  SHOPPING_CART_ADD = 'wi-shopping-cart-add',
  SHOPPING_CART = 'wi-shopping-cart',
  SHUFFLE = 'wi-shuffle',
  SIDEBAR_1 = 'wi-sidebar-1',
  SIDEBAR = 'wi-sidebar',
  SORT = 'wi-sort',
  SPEAKERS = 'wi-speakers',
  STOP = 'wi-stop',
  SUN = 'wi-sun',
  SUNRISE = 'wi-sunrise',
  SUPPORT_QUESTION = 'wi-support-question',
  SWITCH = 'wi-switch',
  TABLE = 'wi-table',
  TABLET = 'wi-tablet',
  TAG = 'wi-tag',
  UNDO = 'wi-undo',
  UNLOCK = 'wi-unlock',
  UNORDERED_LIST = 'wi-unordered-list',
  UPDATE = 'wi-update',
  USER_ADD = 'wi-user-add',
  USER_CHECK = 'wi-user-check',
  USER_REMOVE = 'wi-user-remove',
  USER_SETTINGS = 'wi-user-settings',
  USER = 'wi-user',
  USERS = 'wi-users',
  VERSION = 'wi-version',
  VOLUMEN_OFF = 'wi-volume-off',
  VOLUMEN_UP = 'wi-volume-up',
  WARNING = 'wi-warning',
  WEBCAM = 'wi-webcam',
  ZOOM = 'wi-zoom-in',
  ZOOM_OUT = 'wi-zoom-out',
}
