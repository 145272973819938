<template>
  <FilesAndFolders
    v-if="filesAndFolders"
    ref="filesAndFoldersRef"
    :files-and-folders="filesAndFolders"
    @handle-file-download="downloadFile"
    @handle-create-folder="createFolder"
    @handle-delete-folder="deleteFolder"
    @handle-create-file="createFile"
    @handle-delete-file="deleteFile"
    @handle-file-activation="toggleActive"
  />
</template>

<script lang="ts" setup>
import FilesAndFolders from '@/components/filesAndFolders/FilesAndFolders.vue'
import { ProductDocumentationFileResponse } from '@/models/File'
import { Folder, ProductDocumentationFolderResponse } from '@/models/Folder'
import { useFilesStore } from '@/store/files'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const filesStore = useFilesStore()

const { filesAndFolders } = storeToRefs(filesStore)

const filesAndFoldersRef = ref<InstanceType<typeof FilesAndFolders>>()

/**
 * createFolder
 * @param {folderName} folderName
 * @param {parentFolder} parentFolder
 */
async function createFolder(folderName: string, parentFolder: Folder): Promise<void> {
  try {
    filesStore.CREATE_PRODUCT_DOCUMENTATION_FOLDER(folderName, parentFolder)
  } catch {
    return Promise.resolve()
  }
}

/**
 * deleteFolder
 * @param {folderId} folderId
 * @param {parentFolder} parentFolder
 */
async function deleteFolder(folderId: Folder['id'], parentFolder: Folder): Promise<void> {
  try {
    await filesStore.DELETE_PRODUCT_DOCUMENTATION_FOLDER(folderId, parentFolder)
  } catch {
    Promise.resolve()
  } finally {
    filesAndFoldersRef.value?.closeDialogs()
  }
}

/**
 * createFile
 * @param {files} files
 * @param {parentFolder} parentFolder
 */
async function createFile(files: Array<File>, parentFolder: Folder): Promise<void> {
  try {
    for (const file of files) {
      filesStore.UPLOAD_PRODUCT_DOCUMENTATION_FILE(parentFolder, file)
    }
  } catch {
    return Promise.resolve()
  }
}

/**
 * downloadFile
 * @param {fileObject} fileObject
 */
async function downloadFile(fileObject: ProductDocumentationFileResponse): Promise<void> {
  try {
    const response = await filesStore.GET_PRODUCT_DOCUMENTATION_FILE_BY_ID(fileObject.id)

    const blob = new Blob([response], { type: response.type })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = fileObject.name || ''
    link.click()
    URL.revokeObjectURL(link.href)
  } catch {
    Promise.resolve()
  }
}

/**
 * deleteFile
 * @param {fileId} fileId
 * @param {parentFolder} parentFolder
 */
async function deleteFile(fileId: ProductDocumentationFileResponse['id'], parentFolder: Folder): Promise<void> {
  try {
    await filesStore.DELETE_PRODUCT_DOCUMENTATION_FILE(fileId, parentFolder)
  } catch {
    Promise.resolve()
  } finally {
    filesAndFoldersRef.value?.closeDialogs()
  }
}

/**
 * toggleActive
 * @param {value} value
 * @param {fileId} fileId
 * @param {parentFolder} parentFolder
 */
async function toggleActive(value: boolean, fileId: ProductDocumentationFileResponse['id'], parentFolder: ProductDocumentationFolderResponse): Promise<void> {
  try {
    if (value) {
      parentFolder.files?.forEach((file) => {
        if (file.id !== fileId) file.active = false
      })
    }
    await filesStore.TOGGLE_PRODUCT_DOCUMENTATION_FILE_ACTIVE(value, fileId)
  } catch {
    Promise.resolve()
  }
}

try {
  await filesStore.GET_PRODUCT_DOCUMENTATION_STRUCTURE(route.params.productId as string)
} catch {
  Promise.resolve()
}
</script>

<style lang="scss" scoped>
.product-files {
  .v-checkbox {
    margin-right: 1rem;
  }

  .v-list-item__prepend > .v-icon {
    margin-inline-end: 1rem;
  }
}

.v-overlay-container {
  .v-list-item__prepend > .v-icon {
    margin-inline-end: 1rem;
  }
}
</style>
