<template>
  <div class="mb-6">
    <v-list
      v-for="(infoCard, index) in infoCards"
      :key="`infoCard-${index}`"
      class="info-card-list bg-transparent"
    >
      <v-list-group
        color="primary"
        class="bg-white"
      >
        <template #activator="{ isOpen, props }">
          <v-list-item
            v-bind="infoCard.notes?.length ? props : {}"
            :ripple="!!infoCard.notes?.length"
            class="pa-6"
          >
            <template #prepend>
              <DefaultAvatar
                size="48"
                :color="getStatusSetFromStatusKey(infoCard.type)?.color"
              >
                <v-icon>
                  {{ getStatusSetFromStatusKey(infoCard.type)?.icon }}
                </v-icon></DefaultAvatar
              >
            </template>
            <v-list-item-title
              v-if="infoCard.title"
              class="info-card-list__title"
            >
              {{ infoCard.title }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="infoCard.description"
              class="info-card-list__description"
            >
              <template v-if="Array.isArray(infoCard.description)">
                <span
                  v-for="(desc, i) in infoCard.description"
                  :key="`desc-${i}`"
                >
                  {{ desc }}
                </span>
              </template>
              <template v-else>
                {{ infoCard.description }}
              </template>
            </v-list-item-subtitle>
            <template #append>
              <template v-if="infoCard.type === StatusTypes.CHANGE">
                <DefaultButton
                  class="mr-4"
                  :color="PlattformColors.SECONDARY"
                  @click.stop="emit('discardChanges')"
                >
                  {{ t('buttons.discard') }}</DefaultButton
                >

                <DefaultButton
                  class="mr-4"
                  :color="PlattformColors.PRIMARY"
                  :loading="buttonLoadings[index]"
                  @click.stop="submitChanges(index)"
                  >{{ t('buttons.request') }}</DefaultButton
                >
              </template>
              <template v-else-if="activeOrganization?.provider && infoCard.type === StatusTypes.PENDING">
                <DefaultButton
                  class="mr-4"
                  :color="PlattformColors.PRIMARY"
                  :loading="buttonLoadings[index]"
                  @click.stop="handleRequest(infoCard)"
                  >{{ t('buttons.edit') }}</DefaultButton
                >
              </template>
              <IconButton
                v-if="infoCard.notes?.length"
                :icon="isOpen ? Icons.CHEVRON_UP : Icons.CHEVRON_DOWN"
              />
            </template>
          </v-list-item>
        </template>
        <div
          v-if="infoCard.notes?.length"
          class="px-4"
        >
          <template
            v-for="(note, i) in infoCard.notes"
            :key="`note-${i}`"
          >
            <v-divider />
            <v-list-item class="py-6">
              <v-list-item-title
                v-if="note.title"
                class="info-card-list__note-title pb-2"
              >
                {{ note.title }}
              </v-list-item-title>
              <p
                v-if="note.copy"
                class="sm mb-0"
              >
                {{ note.copy }}
              </p>
            </v-list-item>
          </template>
        </div>
      </v-list-group>
    </v-list>
  </div>
</template>

<script lang="ts" setup>
import { useStatusHelper } from '@/composables/useStatusHelper'
import { useBundleApprovalHelper } from '@/composables/useBundleApprovalHelper'
import { BundleApprovalResponse, BundleChanges, BundleResponse } from '@/models/Bundle'
import { InfoElement, InfoElementNote } from '@/models/InfoElement'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { format } from 'date-fns'
import { ComputedRef, PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import { ChangeTypes } from '@/models/enums/ChangeTypes'
import { useAccessHelper } from '@/composables/useAccessHelper'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import IconButton from '../baseComponents/buttons/IconButton.vue'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()

const { getStatusSetFromStatusKey } = useStatusHelper()
const { getMostRecentApproval } = useBundleApprovalHelper()
const { getMostRecentAccessStatus } = useAccessHelper()
const { activeOrganization } = storeToRefs(myOrganizationStore)

const componentProps = defineProps({
  bundle: {
    type: Object as PropType<BundleResponse>,
    default: undefined,
  },
  bundleChange: {
    type: Object as PropType<BundleChanges>,
    default: undefined,
  },
})

const emit = defineEmits(['submitChanges', 'discardChanges', 'handleRequest'])

const buttonLoadings = ref<Array<boolean>>([])

const bundleTerminationDate: ComputedRef<string> = computed(() => {
  const terminationDate: BundleApprovalResponse['createdAt'] | undefined = getMostRecentApproval(componentProps.bundle?.approvals)?.createdAt
  return componentProps.bundle?.contractStatus === StatusTypes.IN_TERMINATION && terminationDate ? format(new Date(terminationDate), 'dd.MM.yyyy') : ''
})

// Kündigungsdatum ist noch nicht eingebaut. Aktuell wird von allen Subscriptions von allen Produkten des Bundles
// das am weitesten in der Zukunft liegenden validUntil als Kündigungsdatum verwendet.
const bundleTerminationEffectiveDate: ComputedRef<string> = computed(() => {
  let latestSubscriptionValidUntil = new Date(0)
  componentProps.bundle?.bundleProducts?.forEach((product) => {
    product.subscriptions?.forEach((subscription) => {
      const subscriptionDate = new Date(subscription.validUntil || 0)
      if (subscriptionDate > latestSubscriptionValidUntil) latestSubscriptionValidUntil = subscriptionDate
    })
  })
  if (latestSubscriptionValidUntil) {
    return format(latestSubscriptionValidUntil, 'dd.MM.yyyy')
  }
  return ' '
})

const infoCards = computed(() => {
  const resultArray: Array<InfoElement> = []

  /* Bundle Access Status Infos
   *
   * - bundleStatus is REJECTED
   * - bundleAccessStatus is false
   */
  if (componentProps.bundle?.approvals?.length && componentProps.bundle.contractStatus === StatusTypes.REJECTED) {
    resultArray.push({
      type: StatusTypes.REJECTED,
      title: t('bundleStatus.REJECTED'),
      description: t('infoCard.note.description'),
      notes: [
        {
          title: t('infoCard.note.headline'),
          copy: getMostRecentApproval(componentProps.bundle.approvals)?.note,
        },
      ],
    })
  }

  if (!componentProps.bundle?.accessStatus?.access && componentProps.bundle?.contractStatus !== StatusTypes.DRAFT) {
    resultArray.push({
      type: StatusTypes.REJECTED,
      title: t('bundleAccessStatus.blocked'),
      notes: [
        {
          title: t('infoCard.note.headline'),
          copy: componentProps.bundle?.accessStatus?.accessNote,
        },
      ],
    })
  }

  /* Bundle Draft Changes Infos
   *
   * - new Product with plans added to bundle
   * - Business Case was edited
   */
  if (
    componentProps.bundleChange &&
    (componentProps.bundleChange.newProducts.length ||
      componentProps.bundleChange.changedProducts.length ||
      componentProps.bundleChange.changedBusinessCases?.length)
  ) {
    const infoElement = {
      type: StatusTypes.CHANGE,
      title: t('infoTypes.CHANGE'),
      description: t('lastEdited', { date: format(new Date(componentProps.bundleChange.lastEdited || 0), 'dd.MM.yyyy') }),
      notes: new Array<InfoElementNote>(),
    }
    componentProps.bundleChange.newProducts.forEach((newProduct) => {
      infoElement.notes.push({
        title: t('infoCard.addedProduct', { productName: newProduct.product.name }),
        copy: t('infoCard.plans', { count: newProduct.selectedPlans.length }, newProduct.selectedPlans.length),
      })
    })
    componentProps.bundleChange.changedProducts.forEach((changedProduct) => {
      infoElement.notes.push({
        title: t('infoCard.changedProduct', { productName: changedProduct.product.name }),
        copy: t('infoCard.plansAdded', { count: changedProduct.selectedPlans.length }, changedProduct.selectedPlans.length),
      })
    })
    if (componentProps.bundleChange.changedBusinessCases?.length) {
      infoElement.notes.push({
        title: t('infoCard.businessCases'),
        copy: t('infoCard.changeBusinessCases'),
      })
    }

    resultArray.push(infoElement)
  }

  /* Bundle Pending Changes that need to be approved or rejected by provider
   *
   * - Plans that need to be approved or rejected
   * - Business Case that needs to be approved or rejected
   */
  if (componentProps.bundle?.contractStatus === StatusTypes.APPROVED) {
    componentProps.bundle?.bundleProducts?.forEach((bundleProduct) => {
      bundleProduct.subscriptions?.forEach((subscription) => {
        if (getMostRecentApproval(subscription.approvals)?.approvalStatus === StatusTypes.REQUESTED) {
          resultArray.push({
            changeType: ChangeTypes.SUBSCRIPTION,
            type: StatusTypes.PENDING,
            title: t('infoCard.planAdded', { planName: subscription.planName }),
            description: [
              bundleProduct.name || '',
              t('infoCard.requestedAt', {
                createdDate: format(new Date(getMostRecentApproval(subscription.approvals)?.createdAt || 0), 'dd.MM.yyyy'),
              }),
            ],
            changedSubscription: {
              productName: bundleProduct.name,
              planName: subscription.planName,
              subscriptionId: subscription.id,
            },
          })
        }
      })
    })
  }

  if (
    componentProps.bundle?.businessCases?.find((businessCase) => businessCase.pendingText) &&
    getMostRecentApproval(componentProps.bundle.approvals)?.approvalStatus === StatusTypes.REQUESTED
  ) {
    const infoElement = {
      changeType: ChangeTypes.BUSINESS_CASE,
      type: StatusTypes.PENDING,
      title: t('infoCard.pendingBusinessCase'),
      description: t('infoCard.requestedAt', {
        createdDate: format(new Date(getMostRecentApproval(componentProps.bundle.approvals)?.createdAt || 0), 'dd.MM.yyyy'),
      }),
    }

    resultArray.push(infoElement)
  }

  /* Bundle Pending Access Changes
   *
   * - Accesses that need to be approved or rejected
   * - Accesses that are requested to be deactivated
   */
  if (componentProps.bundle?.contractStatus === StatusTypes.APPROVED) {
    componentProps.bundle?.accesses?.find((access) => {
      const accessStatus = getMostRecentAccessStatus(access.accessStatus)

      if (accessStatus?.statusValue === StatusTypes.ACTIVATION_REQUESTED && access.accessExchangeType !== AccessExchangeTypes.MANUAL_EXCHANGE) {
        const infoElement = {
          changeType: ChangeTypes.ACCESS_REQUESTED,
          type: StatusTypes.PENDING,
          title: t('infoCard.pendingRequestedAccess', { accessName: access.name }),
          description: t('infoCard.requestedAt', {
            createdDate: format(new Date(accessStatus?.createdAt || 0), 'dd.MM.yyyy'),
          }),
          requestedAccess: access,
        }

        resultArray.push(infoElement)
      } else if (accessStatus?.statusValue === StatusTypes.DEACTIVATION_REQUESTED && access.accessExchangeType !== AccessExchangeTypes.MANUAL_EXCHANGE) {
        const infoElement = {
          changeType: ChangeTypes.ACCESS_REQUESTED_DEACTIVATION,
          type: StatusTypes.PENDING,
          title: t('infoCard.pendingRequestedDeactivationAccess', { accessName: access.name }),
          description: t('infoCard.requestedAt', {
            createdDate: format(new Date(accessStatus?.createdAt || 0), 'dd.MM.yyyy'),
          }),
          requestedAccess: access,
        }

        resultArray.push(infoElement)
      }
    })
  }

  /* Bundle In Termination Infos
   *
   * - Bundlestatus is IN_TERMINATION
   */
  if (componentProps.bundle?.contractStatus === StatusTypes.IN_TERMINATION) {
    resultArray.push({
      type: StatusTypes.REPORT,
      title: t('infoCard.inTermination', { date: bundleTerminationEffectiveDate.value }),
      description: t('infoCard.terminatedAt', { date: bundleTerminationDate.value }),
    })
    // lock credentials Info for providers
    if (activeOrganization?.value?.provider) {
      resultArray.push({
        type: StatusTypes.WARNING,
        title: t('infoCard.lockCredentials.headline'),
        description: t('infoCard.lockCredentials.description'),
      })
    }
  }

  /* Bundle Terminated Infos
   *
   * - Bundlestatus is TERMINATED
   */
  if (componentProps.bundle?.contractStatus === StatusTypes.TERMINATED) {
    resultArray.push({
      type: StatusTypes.REPORT,
      title: t('infoCard.terminated.headline'),
      description: t('infoCard.terminated.description'),
    })
    // lock credentials Info for providers
    if (activeOrganization?.value?.provider) {
      resultArray.push({
        type: StatusTypes.WARNING,
        title: t('infoCard.lockCredentials.headline'),
        description: t('infoCard.lockCredentials.description'),
      })
    }
  }
  /* Bundle Plan in Termination Infos
   *
   * - Plan ist in Status IN_TERMINATION
   * - Bundlestatus is APPROVED
   */
  if (componentProps.bundle?.contractStatus === StatusTypes.APPROVED) {
    componentProps.bundle?.bundleProducts?.forEach((product) => {
      product.subscriptions?.forEach((subscription) => {
        const mostRecentApproval = getMostRecentApproval(subscription.approvals)
        if (mostRecentApproval?.approvalStatus === StatusTypes.IN_TERMINATION) {
          const subscriptionDate = format(new Date(subscription.validUntil || 0), 'dd.MM.yyyy')
          const subscritionTerminatedAt = format(new Date(mostRecentApproval.createdAt), 'dd.MM.yyyy')
          resultArray.push({
            type: StatusTypes.REPORT,
            title: t('infoCard.planTerminated', { name: subscription.planName, date: subscriptionDate }),
            description: [product.name || '', t('infoCard.terminatedAt', { date: subscritionTerminatedAt })],
          })
        }
      })
    })
    // lock credentials Info for providers if no active Subscription exists
    if (activeOrganization?.value?.provider) {
      // exists an active Subscription on any of the Products
      const hasBundleActiveSubscription = componentProps.bundle?.bundleProducts?.some((product) => {
        return product.subscriptions?.some((subscription) => {
          return getMostRecentApproval(subscription.approvals)?.approvalStatus === StatusTypes.APPROVED
        })
      })
      if (!hasBundleActiveSubscription) {
        resultArray.push({
          type: StatusTypes.WARNING,
          title: t('infoCard.lockCredentials.headline'),
          description: t('infoCard.lockCredentials.description'),
        })
      }
    }
  }

  // return all visible infoCards
  return resultArray
})

// remove loading states when bundle changes
watch(infoCards, () => {
  buttonLoadings.value = []
})

/**
 * submitChanges
 * @param {index} index
 */
function submitChanges(index: number): void {
  buttonLoadings.value[index] = true
  emit('submitChanges')
}

/**
 * handleRequest
 * @param {change?} change?
 */
function handleRequest(change?: InfoElement): void {
  emit('handleRequest', change)
}
</script>

<style lang="scss" scoped>
.info-card-list {
  &__title {
    font-weight: 700;
  }

  &__description {
    font-size: $font-size-xs;

    span {
      position: relative;
      padding: 0 0.5rem;

      &:first-child {
        padding-left: 0;
        font-weight: 700;
      }

      &:not(:last-child)::after {
        content: '';
        border-left: 1.5px solid rgb(var(--v-theme-black));
        height: 1.15rem;
        right: 0;
        position: absolute;
      }
    }
  }

  &__note-title {
    font-weight: 700;
  }
}
</style>

<i18n lang="yaml">
de:
  infoCard:
    addedProduct: '"{productName}" hinzugefügt'
    changedProduct: '"{productName}" bearbeitet'
    planAdded: Plan "{planName}" hinzugefügt
    requestedAt: Beantragt am {createdDate}
    note:
      headline: 'Ablehnungsgrund:'
      description: Die App wurde abgelehnt.
    plans: '{count} Plan ausgewählt | {count} Pläne ausgewählt'
    plansAdded: '{count} Plan hinzugefügt | {count} Pläne hinzugefügt'
    businessCases: Business Case.
    changeBusinessCases: Es gab Änderungen am Business Case.
    pendingBusinessCase: Änderung am Business Case
    pendingRequestedAccess: Aktivierung {accessName} beantragt
    pendingRequestedDeactivationAccess: Deaktivierung {accessName} beantragt.
    inTermination: Die App (inkl. aktiver Pläne) wird gekündigt zum {date}
    terminatedAt: Gekündigt am {date}
    terminated:
      headline: Der Vertrag für die App wurde aufgelöst
      description: Dein Funktionsumfang wurde eingeschränkt. Die letzte Konfiguration deiner App bleibt weiterhin sichtbar.
    lockCredentials:
      headline: Authentifizierungsmittel sperren!
      description: Stelle sicher, dass du aktive Authentifizierungsmittel zum Kündigungseintritt sperrst, um deinem Kunden den Zugriff zu entziehen.
    planTerminated: Plan "{name}" wird gekündigt zum {date}
</i18n>
