<template>
  <ProductPlans
    v-if="visibleProductPlans?.length"
    :available-product-plans="visibleProductPlans"
  />
  <div
    v-else
    class="content-container"
  >
    <p class="mb-0">
      {{ t('consumerProductPlans.noPlansAvailable') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import ProductPlans from '@/components/products/ProductPlans.vue'
import { useProductPlanHelper } from '@/composables/useProductPlanHelper'
import { usePlanStore } from '@/store/plans'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const route = useRoute()
const planStore = usePlanStore()

const { t } = useI18n()
const { plans } = storeToRefs(planStore)

const { isPlanVisible } = useProductPlanHelper()

const visibleProductPlans = computed(() => plans.value.filter((plan) => isPlanVisible(plan)))

await Promise.allSettled([planStore.GET_PRODUCT_PLANS(route.params.productId as string)])
</script>

<i18n lang="yaml">
de:
  consumerProductPlans:
    noPlansAvailable: Keine Pläne gefunden.
</i18n>
