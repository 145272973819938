<template>
  <div class="dialog-layout-wrapper bg-white">
    <div class="text-center">
      <div
        v-if="$slots.image"
        class="mb-10"
      >
        <slot name="image"></slot>
      </div>
      <div class="mb-10">
        <slot name="content"></slot>
      </div>
    </div>
    <div class="d-flex flex-column">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dialog-layout-wrapper {
  padding: 4rem 4rem 2rem;
}
</style>
