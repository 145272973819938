<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('consumerDashboard.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <OrganizationTeaser :active-organization="activeOrganization" />
    </v-col>
  </v-row>
  <v-row class="d-flex align-stretch">
    <v-col>
      <MarketplaceTeaser />
    </v-col>
    <v-col>
      <AppTeaser :bundles="bundles" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <TeamTeaser
        :team-count="teamsLength"
        :member-count="usersLength"
      />
    </v-col>
    <v-col>
      <DocsTeaser />
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import { useBundleStore } from '@/store/bundles'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useTeamStore } from '@/store/teams'
import { useUserStore } from '@/store/users'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import AppTeaser from '@/components/dashboards/consumer/AppTeaser.vue'
import DocsTeaser from '@/components/dashboards/general/DocsTeaser.vue'
import MarketplaceTeaser from '@/components/dashboards/user/MarketplaceTeaser.vue'
import OrganizationTeaser from '@/components/dashboards/general/OrganizationTeaser.vue'
import TeamTeaser from '@/components/dashboards/general/TeamTeaser.vue'
import { SortValues } from '@/models/enums/SortValues'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'

const { t } = useI18n()

const userStore = useUserStore()
const myOrganizationStore = useMyOrganizationStore()
const bundleStore = useBundleStore()
const teamStore = useTeamStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { bundles } = storeToRefs(bundleStore)
const { teamsLength } = storeToRefs(teamStore)
const { usersLength } = storeToRefs(userStore)

await Promise.allSettled([bundleStore.GET({ sort: SortValues.CREATED_AT }), teamStore.GET(), userStore.GET()])
</script>
<i18n lang="yaml">
de:
  consumerDashboard:
    headline: Kunden Dashboard
</i18n>
