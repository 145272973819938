<template>
  <div class="mt-8">
    <RouterView v-slot="{ Component }">
      <template v-if="Component">
        <Suspense timeout="0">
          <!-- main content -->
          <component :is="Component"></component>
          <!-- loading state -->
          <template #fallback>
            <div class="content-loading d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                size="50"
                color="primary"
              />
            </div>
          </template>
        </Suspense>
      </template>
    </RouterView>
  </div>
</template>

<style lang="scss" scoped>
.tab-loading-wrapper {
  margin-top: 1rem;
  height: 100%;
  width: 100%;
}
</style>
