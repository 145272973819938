<template>
  <v-text-field
    v-if="searchbarVariant === SearchbarVariants.LARGE"
    v-model="searchBarValues.searchQuery"
    class="mb-8"
    :label="label"
    hide-details
    clearable
    variant="outlined"
    :data-cy="SearchbarTest.SEARCHBAR_L"
  >
    <template #prepend-inner>
      <v-icon class="mx-1">{{ Icons.SEARCH }}</v-icon>
    </template>
  </v-text-field>

  <ListControls :data-cy="SearchbarTest.LIST_CONTROLS">
    <template #title>{{ resultLabel }}</template>
    <template #append>
      <v-progress-circular
        v-if="loading"
        class="mr-4"
        indeterminate
        :data-cy="SearchbarTest.LOADING"
      />
      <v-text-field
        v-if="searchbarVariant === SearchbarVariants.SMALL"
        v-model="searchBarValues.searchQuery"
        class="mb-0 mr-4"
        :label="label"
        hide-details
        clearable
        variant="outlined"
        density="comfortable"
        width="240"
        :data-cy="SearchbarTest.SEARCHBAR_S"
      >
        <template #prepend-inner>
          <v-icon class="mx-1">{{ Icons.SEARCH }}</v-icon>
        </template>
      </v-text-field>

      <DefaultFilter
        v-if="filterOptions"
        class="mb-0 mr-4"
        :filter-options="filterOptions"
        :preset-filter="searchBarValues.selectedFilterOptions"
        :data-cy="SearchbarTest.FILTER"
        @change="
          (selectedFilterValues: string[]) => {
            searchBarValues.selectedFilterOptions = selectedFilterValues
          }
        "
      />

      <v-select
        v-if="sortOptions"
        v-model="searchBarValues.selectedSortOption"
        class="mb-0"
        :items="sortOptions"
        item-title="key"
        item-value="value"
        :label="t('searchbar.sortbarLabel')"
        hide-details
        variant="outlined"
        density="comfortable"
        width="212"
        :data-cy="SearchbarTest.SORT"
      >
        <template #prepend-inner>
          <v-icon class="mx-1">{{ Icons.SORT }}</v-icon>
        </template>
      </v-select>
      <slot name="append"></slot>
    </template>
  </ListControls>
</template>
<script lang="ts" setup>
import ListControls from '@/components/ListControls.vue'
import { SortOption } from '@/models/SortOption'
import { FilterOption } from '@/models/FilterOption'
import { PropType, computed, ref, watch } from 'vue'
import { useSearchbarStore } from '@/store/searchbar'
import { useRoute } from 'vue-router'
import { SearchbarValues } from '@/models/SearchbarValues'
import { Icons } from '@/models/enums/IconTypes'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { SearchbarTest } from '@/models/enums/test/SearchbarTest'
import { SearchbarVariants } from '@/models/enums/SearchbarVariants'
import DefaultFilter from './baseComponents/filters/DefaultFilter.vue'

const componentProps = defineProps({
  presetOptions: { type: Object as PropType<SearchbarValues>, default: undefined },
  searchbarVariant: { type: String as PropType<SearchbarVariants>, default: SearchbarVariants.SMALL },
  searchbarLabel: { type: String, default: undefined },
  loading: { type: Boolean, default: false },
  resultLabel: { type: String, default: undefined },
  sortOptions: { type: Array<SortOption>, required: true },
  filterOptions: { type: Array<FilterOption>, default: undefined },
})

const emit = defineEmits(['updatedFilter'])

const { t } = useI18n()
const route = useRoute()
const searchbarStore = useSearchbarStore()

const { getMatchedSearchbarValues } = storeToRefs(searchbarStore)

const label = computed(() => componentProps.searchbarLabel || t('searchbar.searchbarLabel'))

const preset = componentProps.presetOptions
  ? {
      searchbarRoute: route.path,
      searchQuery: componentProps.presetOptions?.searchQuery,
      selectedFilterOptions: componentProps.presetOptions?.selectedFilterOptions,
      selectedSortOption: componentProps.presetOptions?.selectedSortOption || componentProps.sortOptions[0].value,
    }
  : getMatchedSearchbarValues.value(route.path)

const searchBarValues = ref<SearchbarValues>(preset || { searchbarRoute: route.path, selectedSortOption: componentProps.sortOptions[0].value })

emit('updatedFilter', searchBarValues.value)

let timer: ReturnType<typeof setTimeout>
watch(
  (): string | undefined => searchBarValues.value.searchQuery,
  () => {
    searchbarStore.setSearchbarValues(searchBarValues.value)
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      emit('updatedFilter', searchBarValues.value)
    }, 1000)
  }
)

watch([(): string[] | undefined => searchBarValues.value.selectedFilterOptions, (): string | undefined => searchBarValues.value.selectedSortOption], () => {
  searchbarStore.setSearchbarValues(searchBarValues.value)
  emit('updatedFilter', searchBarValues.value)
})
</script>
<i18n lang="yaml">
de:
  searchbar:
    searchbarLabel: Suche
    filterbarLabel: Filter
    sortbarLabel: Sortierung
</i18n>
