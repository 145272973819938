import { BundleBusinessCase } from '@/models/enums/BundleTypes'
import { i18n } from '@/plugins/i18n'

interface BundleBusinessCaseHelper {
  getBundleBusinessCaseName: (bundleBusinessCaseKey: BundleBusinessCase) => string | undefined
  getBundleBusinessCaseTooltip: (bundleBusinessCaseKey: BundleBusinessCase) => string | undefined
}

/**
 * useBundleBusinessCaseHelper
 * @return {BundleBusinessCaseHelper}
 */
export function useBundleBusinessCaseHelper(): BundleBusinessCaseHelper {
  const bundleBusinessCases = [
    {
      key: BundleBusinessCase.USE_CASE,
      value: i18n.t('bundleBusinessCase.USE_CASE.title'),
      tooltip: i18n.t('bundleBusinessCase.USE_CASE.tooltip'),
    },
    {
      key: BundleBusinessCase.ADDED_VALUE,
      value: i18n.t('bundleBusinessCase.ADDED_VALUE.title'),
      tooltip: i18n.t('bundleBusinessCase.ADDED_VALUE.tooltip'),
    },
    {
      key: BundleBusinessCase.TRAFFIC,
      value: i18n.t('bundleBusinessCase.TRAFFIC.title'),
      tooltip: i18n.t('bundleBusinessCase.TRAFFIC.tooltip'),
    },
  ]

  /**
   * getBundleBusinessCaseName
   * @param {bundleBusinessCaseKey} bundleBusinessCaseKey
   * @return {string}
   */
  function getBundleBusinessCaseName(bundleBusinessCaseKey: BundleBusinessCase): string | undefined {
    return bundleBusinessCases.find((bundleBusinessCase) => bundleBusinessCase.key === bundleBusinessCaseKey)?.value
  }

  /**
   * getBundleBusinessCaseTooltip
   * @param {bundleBusinessCaseKey} bundleBusinessCaseKey
   * @return {string}
   */
  function getBundleBusinessCaseTooltip(bundleBusinessCaseKey: BundleBusinessCase): string | undefined {
    return bundleBusinessCases.find((bundleBusinessCase) => bundleBusinessCase.key === bundleBusinessCaseKey)?.tooltip
  }

  return {
    getBundleBusinessCaseName,
    getBundleBusinessCaseTooltip,
  }
}
