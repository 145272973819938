import {
  OrganizationPublicResponse,
  RelationDependentOrganizationResponse,
  RelationDependentOrganizationResponseList,
  OrganizationRelationResponseList,
  OrganizationRelationResponse,
} from '@/models/Organization'
import { ProductResponse } from '@/models/Product'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface QueryParams {
  q?: string
  filter?: string
  sort?: string
}

interface OrganizationStateProperties {
  organizations: RelationDependentOrganizationResponse[]
  consumerOrganizations: OrganizationPublicResponse[]
  organization?: RelationDependentOrganizationResponse
  organizationRelations: OrganizationRelationResponse[]
}

export const useOrganizationStore = defineStore(StoreName.ORGANIZATIONS, {
  state: (): OrganizationStateProperties => ({
    organizations: [],
    consumerOrganizations: [],
    organization: undefined,
    organizationRelations: [],
  }),
  actions: {
    async GET_ORGANIZATION(organizationId: OrganizationPublicResponse['id']) {
      try {
        const { data }: { data: RelationDependentOrganizationResponse } = await useAxios.get(`/api/organizations/${organizationId}`)
        this.organization = data
      } catch {
        return Promise.reject()
      }
    },
    async GET_ORGANIZATIONS_IN_RELATION(organizationId: OrganizationPublicResponse['id'], params?: QueryParams) {
      try {
        const { data }: { data: OrganizationRelationResponseList } = await useAxios.get(`/api/organizations/${organizationId}/relations`, { params })
        this.organizationRelations = data.organizations
      } catch {
        return Promise.reject()
      }
    },
    async GET_ALL_PROVIDERS() {
      try {
        const { data }: { data: RelationDependentOrganizationResponseList } = await useAxios.get('/api/organizations?provider=true')
        this.organizations = data.organizations
      } catch {
        return Promise.reject()
      }
    },
    async GET_ALL_CONSUMERS() {
      try {
        const { data }: { data: RelationDependentOrganizationResponseList } = await useAxios.get('/api/organizations?provider=false')
        this.organizations = data.organizations
      } catch {
        return Promise.reject()
      }
    },
    async GET_CONSUMERS_BY_PRODUCT_ID(productId: ProductResponse['id']) {
      try {
        const { data }: { data: RelationDependentOrganizationResponseList } = await useAxios.get(`/api/products/${productId}/consumers`)
        this.consumerOrganizations = data.organizations
      } catch {
        return Promise.reject()
      }
    },
    set_by_id(organizationId: OrganizationPublicResponse['id']): void {
      this.organization = this.organizations.find((organization) => organization.id === organizationId)
    },
  },
  getters: {
    organizationById(state) {
      return (id: OrganizationPublicResponse['id']): OrganizationPublicResponse | undefined => state.organizations.find((org) => org.id === id)
    },
  },
})
