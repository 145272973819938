<template>
  <div class="create-teaser_wrapper d-flex bg-white">
    <div class="d-flex align--center">
      <DefaultAvatar
        size="48"
        class="mr-6"
        color="grey-lighten-4"
      >
        <v-icon size="24">{{ icon }}</v-icon>
      </DefaultAvatar>
      <div class="d-flex flex-column">
        <h3 class="create-teaser_headline">{{ headline }}</h3>
        <p
          v-if="copy"
          class="mb-0"
        >
          {{ copy }}
        </p>
      </div>
    </div>
    <DefaultButton
      :color="PlattformColors.PRIMARY"
      :loading="loading"
      @click="emit('submit')"
    >
      {{ btnText }}
    </DefaultButton>
  </div>
</template>

<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const emit = defineEmits(['submit'])

defineProps({
  icon: {
    type: String,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  copy: {
    type: String,
    default: '',
  },
  btnText: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
<style lang="scss" scoped>
.create-teaser {
  &_wrapper {
    width: 100%;
    padding: 2rem;
    border-radius: 0.5rem;
    align-items: center;
    justify-content: space-between;

    background-image: repeating-linear-gradient(
        -2deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 0.625rem
      ),
      repeating-linear-gradient(
        88deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 1.625rem
      ),
      repeating-linear-gradient(
        178deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 1.625rem
      ),
      repeating-linear-gradient(
        268deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 1.625rem
      );
    background-size:
      0.0625rem 100%,
      100% 0.0625rem,
      0.0625rem 100%,
      100% 0.0625rem;
    background-position:
      0 0,
      0 0,
      100% 0,
      0 100%;
    background-repeat: no-repeat;
  }

  &_box {
    margin: 0 auto;
    width: 50%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &_headline {
    margin-bottom: 0;
  }
}
</style>
