<template>
  <div>
    <h2>{{ t('wizardStep4.headline') }}</h2>
    <p>{{ t('wizardStep4.copy') }}</p>
    <v-list>
      <v-list-subheader>{{ title }}</v-list-subheader>
      <v-list-item
        v-for="(organization, i) in organizations"
        :key="i"
        >{{ organization.name }}</v-list-item
      >
    </v-list>
    <v-divider />
    <v-list>
      <v-list-subheader>{{ `${t('wizardStep4.folders')} : ${wizardContent.mode}` }}</v-list-subheader>
      <v-list-item
        v-for="(folder, j) in folders"
        :key="j"
        >{{ folder.name }}</v-list-item
      >
    </v-list>
  </div>
</template>

<script lang="ts" setup>
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { useSideWizardStore } from '@/store/sideWizard'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const sideWizardStore = useSideWizardStore()

const { wizardContent, wizardValidated } = storeToRefs(sideWizardStore)

wizardValidated.value[3] = computed(() => true)

const title = computed(() => {
  return wizardContent.value.type === OrganizationTypes.PROVIDER ? t('wizardStep4.consumer') : t('wizardStep4.provider')
})
const organizations = computed(() => (wizardContent.value.allSelected ? [{ name: t('wizardStep4.allSelected') }] : wizardContent.value.organizations))
const folders = computed(() => (wizardContent.value.allFolders ? [{ name: t('wizardStep4.allSelected') }] : wizardContent.value.folders))
</script>

<i18n lang="yaml">
de:
  wizardStep4:
    headline: Übersicht
    copy: Hier erhältst du eine Zusammenfassung der erteilten Berechtigungen.
    provider: Anbieter
    consumer: Kunde
    folders: Ordner
    allSelected: Alle und Zukünftige ausgewählt
</i18n>
