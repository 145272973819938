export enum SortValues {
  NAME_ASC = 'name ASC',
  NAME_DESC = 'name DESC',
  CREATED_AT = 'createdAt DESC',
}

export enum SortValuesOrganizationRelationProvider {
  NAME_ASC = 'provider.name ASC',
  NAME_DESC = 'provider.name DESC',
}

export enum SortValuesOrganizationRelationConsumer {
  NAME_ASC = 'consumer.name ASC',
  NAME_DESC = 'consumer.name DESC',
}