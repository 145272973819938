import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useAlertStore } from '@/store/alerts'
import axios from 'axios'
import { getKeycloakToken, isLoggedIn, updateKeycloakToken } from './keycloak'
import { AlertTypes } from '@/models/enums/AlertTypes'

const useAxios = axios.create()

useAxios.interceptors.request.use(async (config) => {
  if (isLoggedIn()) {
    await updateKeycloakToken()
    config.headers['Authorization'] = `Bearer ${getKeycloakToken()}`

    const myOrganizationStore = useMyOrganizationStore()

    // sometimes header ist set manually (e.g myOrganizationStore) so we only overwrite if it is not already set
    if (myOrganizationStore.activeOrganization && !config.headers['organization-id']) {
      config.headers['organization-id'] = myOrganizationStore.activeOrganization.id
    }
  }

  return config
})

useAxios.interceptors.response.use(
  async (response) => {
    return response
  },
  async ({ response }) => {
    const alertStore = useAlertStore()

    let text
    if (response.data) {
      text = response.data.message ? `${response.data.message} - TraceId: ${response.data.traceId}` : `${response.data.title} – ${response.data.detail}`
    } else {
      text = `${response.status}: ${response.statusText}`
    }
    alertStore.add({
      text,
      type: AlertTypes.ERROR,
    })
    return Promise.reject(response)
  }
)

export default useAxios
