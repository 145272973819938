<template>
  <v-row
    v-if="product?.detailedDescription"
    class="content-container product-details"
  >
    <v-col>
      <div class="d-flex justify-space-between">
        <h2>{{ t('providerProductDetail.headline') }}</h2>

        <IconButton
          :icon="Icons.EDIT"
          @click="detailedDescriptionDialogRef?.open()"
        />
      </div>
    </v-col>
    <v-col :cols="product.productLinks?.length ? 9 : 12"
      ><MdPreview
        v-model="product.detailedDescription"
        language="en-US"
        no-iconfont
    /></v-col>
    <v-col
      v-if="product.productLinks?.length"
      cols="3"
    >
      <p class="highlight">{{ t('providerProductDetail.links.subline') }}</p>
      <DefaultButton
        v-for="productLink in product.productLinks"
        :key="productLink.id"
        :color="PlattformColors.SECONDARY"
        :append-icon="Icons.EXTERNAL_LINK"
        :href="productLink.url"
        target="_blank"
        class="w-100 mb-2 justify-space-between text-left"
        >{{ productLink.name }}</DefaultButton
      >
    </v-col>
  </v-row>
  <div v-else>
    <CreateTeaser
      :icon="Icons.ORDERED_LIST"
      :headline="t('providerProductDetail.teaser.headline')"
      :copy="t('providerProductDetail.teaser.copy')"
      :btn-text="t('providerProductDetail.teaser.button')"
      @submit="detailedDescriptionDialogRef?.open()"
    />
  </div>
  <Dialog
    ref="detailedDescriptionDialogRef"
    @on-close="detailedDescriptionDialogRef?.close()"
  >
    <DialogProductFormDetail
      :product-object="product"
      :loading="loading"
      @cancel="detailedDescriptionDialogRef?.close()"
      @submit="(detailedDescription: string, productLinks?: ProductLink[]) => updateProduct(detailedDescription, productLinks)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { MdPreview } from 'md-editor-v3'
import { ref } from 'vue'
import { useProductStore } from '@/store/products'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { Icons } from '@/models/enums/IconTypes'
import { ProductLink } from '@/models/Product'
import { PlattformColors } from '@/models/enums/ColorSets'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogProductFormDetail from '@/components/dialogs/DialogProductFormDetail.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'

const { t } = useI18n()

const productStore = useProductStore()
const alertStore = useAlertStore()
const { product } = storeToRefs(productStore)

const loading = ref(false)
const detailedDescriptionDialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * updateProduct
 * @param {string} detailedDescription
 * @param {ProductLink[]} productLinks
 */
async function updateProduct(detailedDescription: string, productLinks?: ProductLink[]): Promise<void> {
  if (product?.value) {
    try {
      loading.value = true
      await productStore.UPDATE({ detailedDescription, productLinks }, product.value.id)
      alertStore.add({
        text: t('providerProductDetail.update.success'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      return Promise.resolve()
    } finally {
      loading.value = false
      detailedDescriptionDialogRef.value?.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.product-details {
  .highlight {
    font-size: $font-size-xs;
  }

  :deep(.v-btn__content) {
    grid-area: inherit;
    font-size: $font-size-s;
  }
}
</style>

<i18n lang="yaml">
de:
  providerProductDetail:
    headline: Produktbeschreibung
    update:
      success: Deine API wurde erfolgreich aktualisiert!
    teaser:
      headline: Was macht deine API aus?
      copy: Füge eine Beschreibung hinzu, um deine API zu spezifizieren.
      button: Beschreibung hinzufügen
    links:
      subline: Externe Links
</i18n>
