import { UserRoles } from '@/models/enums/UserRoles'
import { useActiveUserStore } from '@/store/activeUser'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useTenantStore } from '@/store/tenants'
import { storeToRefs } from 'pinia'

interface ActiveUserHelper {
  isUserAdmin: () => boolean
}

/**
 * useActiveUserHelper
 * @return {ActiveUserHelper}
 */
export function useActiveUserHelper(): ActiveUserHelper {
  /**
   * isUserAdmin
   * @return {boolean}
   */
  function isUserAdmin(): boolean {
    const myOrganizationStore = useMyOrganizationStore()
    const tenantStore = useTenantStore()
    const activeUserStore = useActiveUserStore()

    const { activeUser } = storeToRefs(activeUserStore)
    const { activeOrganization } = storeToRefs(myOrganizationStore)
    const { activeTenant } = storeToRefs(tenantStore)

    const activeOrganizationUserRole = activeUser?.value?.userOrganizations?.find(
      (userOrganization) => userOrganization.organizationId === activeOrganization?.value?.id
    )?.userOrganizationRole

    const activeTenantUserRole = activeUser?.value?.userOrganizations?.find(
      (userOrganization) => userOrganization.organizationId === activeTenant?.value?.id
    )?.userOrganizationRole

    return activeOrganizationUserRole === UserRoles.ADMIN || activeTenantUserRole === UserRoles.ADMIN
  }

  return {
    isUserAdmin,
  }
}
