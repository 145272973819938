import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { OrganizationPublicResponse } from '@/models/Organization'
import { AccessDefinitionRequest, AccessDefinitionResponse, AccessDefinitionResponseList } from '@/models/AccessDefinition'
import { StoreName } from '@/models/enums/StoreName'

interface AccessDefinitionStateType {
  accessDefinitions: AccessDefinitionResponse[]
}

export const useAccessDefinitionsStore = defineStore(StoreName.ACCESS_DEFINITIONS, {
  state: (): AccessDefinitionStateType => ({
    accessDefinitions: [],
  }),
  actions: {
    async GET_LIST(organizationId: OrganizationPublicResponse['id']): Promise<undefined> {
      try {
        const { data }: { data: AccessDefinitionResponseList } = await useAxios.get(`/api/organizations/${organizationId}/access-definitions`)
        this.accessDefinitions = data.accessDefinitions
      } catch {
        return Promise.reject()
      }
    },
    async GET_BY_ID(accessDefinitionId: AccessDefinitionResponse['id']): Promise<AccessDefinitionResponse> {
      try {
        const { data }: { data: AccessDefinitionResponse } = await useAxios.get(`/api/access-definitions/${accessDefinitionId}`)
        return data
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(accessDefinition: AccessDefinitionRequest): Promise<undefined> {
      try {
        const { data }: { data: AccessDefinitionResponse } = await useAxios.post(`/api/access-definitions`, { ...accessDefinition })
        this.accessDefinitions.push(data)
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(accessDefinition: Partial<AccessDefinitionRequest>, accessDefinitionId: AccessDefinitionResponse['id']): Promise<undefined> {
      try {
        const { data }: { data: AccessDefinitionResponse } = await useAxios.patch(`/api/access-definitions/${accessDefinitionId}`, accessDefinition)
        const index = this.accessDefinitions.findIndex((accessDefinitionItem: AccessDefinitionResponse) => accessDefinitionItem.id === accessDefinitionId)
        this.accessDefinitions[index] = data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(accessDefinitionId: AccessDefinitionResponse['id']): Promise<undefined> {
      try {
        await useAxios.delete(`/api/access-definitions/${accessDefinitionId}`)
        this.accessDefinitions = this.accessDefinitions.filter((accessDefinition: AccessDefinitionResponse) => accessDefinition.id !== accessDefinitionId)
      } catch {
        return Promise.reject()
      }
    },
  },
})
