<template>
  <div class="alert-wrapper">
    <div
      v-for="alert in alerts"
      :key="alert.id"
    >
      <v-alert
        :type="alert.type"
        :border="'start'"
        closable
        @input="alertStore.del(alert.id)"
      >
        {{ alert.text }}
      </v-alert>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAlertStore } from '@/store/alerts'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'

const alertStore = useAlertStore()

const { alerts } = storeToRefs(alertStore)

watch(
  () => alerts.value,
  (newVal, oldVal) => {
    if (newVal.length >= oldVal.length) {
      setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }, 200)
    }
  },
  { deep: true }
)
</script>
<style lang="scss" scoped>
.alert-wrapper {
  margin-bottom: 1rem;

  :deep(.v-alert__prepend) {
    margin-top: 0.15rem;
  }
}
</style>
