import { AccessResponse } from '@/models/Access'
import { BundleAccessStatusResponse, BundleBusinessCaseResponse, BundleCreateRequest, BundleGoLiveRequest } from './../models/Bundle'
import { BundleResponse, BundleResponseList } from '@/models/Bundle'
import { BundleChanges, BundleChangesProduct } from '@/models/Bundle'
import { ContactResponse } from '@/models/Contact'
import { PlanResponse } from '@/models/Plan'
import { StatusTypes } from '@/models/enums/StatusTypes'
import useAxios from '@/plugins/axios'
import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface filterParams {
  q?: string
  filter?: string
  sort?: string
}

interface BundlesStateProperties {
  bundles: BundleResponse[]
  bundle?: BundleResponse
  bundleChanges?: BundleChanges[]
}

export const useBundleStore = defineStore(StoreName.BUNDLES, {
  state: (): BundlesStateProperties => ({
    bundles: [],
    bundle: undefined,
    bundleChanges: useStorage('changesToSubmit', []).value,
  }),
  actions: {
    async GET(params?: filterParams) {
      try {
        const { data }: { data: BundleResponseList } = await useAxios.get('/api/bundles', { params })
        this.bundles = data.bundles
      } catch {
        return Promise.reject()
      }
    },
    async GET_BY_ID(bundleId: BundleResponse['id']) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.get(`/api/bundles/${bundleId}`)
        this.bundle = data
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(newBundle: BundleCreateRequest) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.post(`/api/bundles/`, newBundle)
        this.bundles.push(data)
        return data
      } catch {
        return Promise.reject()
      }
    },
    async PUT_ACCESS_STATUS(bundleId: BundleResponse['id'], bundleAccessStatus: BundleAccessStatusResponse) {
      try {
        const { data }: { data: BundleAccessStatusResponse } = await useAxios.put(`/api/bundles/${bundleId}/access-status`, bundleAccessStatus)
        this.bundle = { ...this.bundle!, accessStatus: data }
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(bundleId: BundleResponse['id'], bundleObject: Partial<BundleResponse>) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.patch(`/api/bundles/${bundleId}`, bundleObject)
        this.bundle = data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(bundleId: BundleResponse['id']) {
      try {
        await useAxios.delete(`/api/bundles/${bundleId}`)
      } catch {
        return Promise.reject()
      }
    },
    async TERMINATE(bundleId: BundleResponse['id'], note?: string) {
      try {
        if (note) {
          const { data }: { data: BundleResponse } = await useAxios.patch(`/api/bundles/${bundleId}/cancel`, { note: note })
          this.bundle = data
        } else {
          const { data }: { data: BundleResponse } = await useAxios.patch(`/api/bundles/${bundleId}/cancel`, {})
          this.bundle = data
        }
      } catch {
        return Promise.reject()
      }
    },
    async GO_LIVE_REQUEST(bundleId: BundleResponse['id'], bundleObject: BundleGoLiveRequest) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.post(`/api/bundles/${bundleId}/request`, bundleObject)
        this.bundle = data
      } catch {
        return Promise.reject()
      }
    },
    async ADD_APPROVAL(bundleId: BundleResponse['id'], approvalStatus: StatusTypes, note?: string, approvalAllPlans?: boolean) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.post(`/api/bundles/${bundleId}/approval`, {
          approvalStatus,
          note,
          approvalAllPlans,
        })
        this.bundle = data
      } catch {
        return Promise.reject()
      }
    },
    async ADD_SUBSCRIPTION(bundleId: BundleResponse['id'], planIds: Array<PlanResponse['id']>) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.post(`/api/bundles/${bundleId}/subscriptions`, {
          planIds,
        })
        this.bundle = data
      } catch {
        return Promise.reject()
      }
    },
    async ADD_CONTACT(bundleId: BundleResponse['id'], contactId: ContactResponse['id']) {
      try {
        const { data }: { data: BundleResponse } = await useAxios.patch(`/api/bundles/${bundleId}`, { contactId })
        this.bundle = data
      } catch {
        return Promise.reject()
      }
    },
    updateBundleAccesses(updatedAccess: AccessResponse) {
      if (this.bundle?.accesses) this.bundle.accesses = this.bundle.accesses.map((access) => (access.id !== updatedAccess.id ? access : updatedAccess))
    },
    addBundleAccess(newAccess: AccessResponse) {
      if (this.bundle?.accesses) this.bundle.accesses.push(newAccess)
    },
    pushProductToBundleChanges(bundleId: BundleResponse['id'], productWithPlans: BundleChangesProduct) {
      // save Date for lastEdited prop
      const lastEdited = new Date().toISOString()

      // check if entry for this bundleId already exists
      const bundleChange = this.bundleChanges?.find((change) => change.bundleId === bundleId)

      if (bundleChange) {
        // if the product is already in the changesToSubmit Array, replace the entry with the new one
        const indexToReplace = bundleChange.newProducts.findIndex((newProduct) => newProduct.product.id === productWithPlans.product.id)
        if (indexToReplace >= 0) {
          bundleChange.newProducts[indexToReplace] = productWithPlans
        } else {
          bundleChange.newProducts.push(productWithPlans)
        }
        bundleChange.lastEdited = lastEdited
      } else {
        this.bundleChanges?.push({ bundleId, lastEdited, newProducts: [productWithPlans], changedProducts: [], changedBusinessCases: [] })
      }
    },
    pushPlansToBundleChanges(bundleId: BundleResponse['id'], productWithPlans: BundleChangesProduct) {
      // save Date for lastEdited prop
      const lastEdited = new Date().toISOString()

      // check if entry for this bundleId already exists
      const bundleChange = this.bundleChanges?.find((change) => change.bundleId === bundleId)

      if (bundleChange) {
        // if the product is already in the changesToSubmit Array, replace the entry with the new one
        const indexToReplace = bundleChange.changedProducts.findIndex((changedProduct) => changedProduct.product.id === productWithPlans.product.id)
        if (indexToReplace >= 0) {
          bundleChange.changedProducts[indexToReplace] = productWithPlans
        } else {
          bundleChange.changedProducts.push(productWithPlans)
        }
        bundleChange.lastEdited = lastEdited
      } else {
        this.bundleChanges?.push({ bundleId, lastEdited, newProducts: [], changedProducts: [productWithPlans], changedBusinessCases: [] })
      }
    },
    pushBusinessCaseToBundleChanges(bundleId: BundleResponse['id'], businessCases: Array<BundleBusinessCaseResponse>) {
      // save Date for lastEdited prop
      const lastEdited = new Date().toISOString()

      // check if entry for this bundleId already exists
      const bundleChange = this.bundleChanges?.find((change) => change.bundleId === bundleId)

      if (bundleChange) {
        // if the product is already in the changesToSubmit Array, replace the entry with the new one
        bundleChange.changedBusinessCases = businessCases
        bundleChange.lastEdited = lastEdited
      } else {
        this.bundleChanges?.push({ bundleId, lastEdited, newProducts: [], changedProducts: [], changedBusinessCases: businessCases })
      }
    },
    removeBundleChangesById(bundleId: BundleResponse['id']) {
      this.bundleChanges?.splice(
        this.bundleChanges?.findIndex((change) => change.bundleId === bundleId),
        1
      )
    },
  },
  getters: {
    bundlesLength(state) {
      return state.bundles.length
    },
    getBundleChangeById(state) {
      return (bundleId: BundleResponse['id']): BundleChanges | undefined => state.bundleChanges?.find((bundleChange) => bundleChange.bundleId === bundleId)
    },
  },
})
