<template>
  <v-list-item
    class="mb-8 pa-0"
    min-height="80"
  >
    <template
      v-if="$slots.prepend"
      #prepend
    >
      <div class="mr-8">
        <slot name="prepend"></slot>
      </div>
    </template>
    <template #title>
      <h1 class="mb-0">
        <slot name="title"></slot>
      </h1>
    </template>
    <template #subtitle>
      <p class="mb-0 xs">
        <slot name="subtitle"></slot>
      </p>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-list-item>
</template>
