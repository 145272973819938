<template>
  <div
    class="dropzone"
    v-bind="getRootProps()"
    :class="{ drag: isDragActive }"
  ></div>
</template>
<script lang="ts" setup>
import { useDropzone } from 'vue3-dropzone'

const emit = defineEmits(['uploadFile'])

/**
 * onDrop
 * @param {acceptFiles} acceptFiles
 */
function onDrop(acceptFiles: File[]): void {
  emit('uploadFile', acceptFiles)
}

const { getRootProps, isDragActive } = useDropzone({ onDrop })
</script>
<style lang="scss" scoped>
.dropzone {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  &.drag {
    border: 3px dashed rgb(var(--v-theme-black));
  }
}
</style>
