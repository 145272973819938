<template>
  <v-form
    v-model="validateForm"
    @submit.prevent="emit('submit', deactivationNote)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1>{{ t('deactivateBundle.headline') }}</h1>
        <p>{{ t('deactivateBundle.copy') }}</p>

        <v-textarea
          v-model="deactivationNote"
          autofocus
          :label="t('deactivateBundle.label')"
          required
          :rules="maxLength"
          variant="outlined"
        >
          <template #append-inner>
            <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
          </template>
        </v-textarea>

        <InfoBox
          class="my-8"
          :copy="t('deactivateBundle.info')"
        />
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :disabled="!validateForm"
          >{{ t('buttons.send') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
          >{{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { Icons } from '@/models/enums/IconTypes'
import { isNotEmpty, isMaxLength } from '@/validators'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import InfoBox from '../InfoBox.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const deactivationNote = ref<string>()
const validateForm = ref(false)

const maxLength = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 1000)])

const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  deactivateBundle:
    headline: Zugriff sperren
    copy: Gib deinem Kunden eine kurze Begründung, warum der Zugriff auf die App gesperrt wurde.
    label: Sperrungsgrund
    info: Du kannst die Sperrung jederzeit aufheben.
</i18n>
