<template>
  <v-list-item class="pa-6 bg-white mb-4 contact-element">
    <template #prepend>
      <DefaultAvatar
        size="48"
        :color="PlattformColors.INFO"
      >
        <v-icon :icon="Icons.USERS" />
      </DefaultAvatar>
    </template>
    <v-list-item__content>
      <v-list-item-title>
        <h3 class="mb-1">{{ contact.name }}</h3>
      </v-list-item-title>
      <v-list-item-subtitle class="mb-6">
        <span class="contact-element__subline">{{
          activeOrganization?.provider ? t('contactElement.contactPerson') : t('createdAt', { d: format(new Date(contact.createdAt), 'dd.MM.yyyy') })
        }}</span>
      </v-list-item-subtitle>
    </v-list-item__content>
    <v-chip
      label
      size="small"
      class="mr-4"
      :href="`mailto:${contact.email}`"
    >
      <v-icon
        class="mr-2"
        :icon="Icons.EMAIL"
      />
      {{ contact.email }}
    </v-chip>
    <v-chip
      label
      size="small"
      class="mr-4"
      :href="`tel:${contact.phone}`"
    >
      <v-icon
        class="mr-2"
        :icon="Icons.CALL"
      />
      {{ contact.phone }}
    </v-chip>
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-list-item>
</template>
<script lang="ts" setup>
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { ContactResponse } from '@/models/Contact'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { format } from 'date-fns'
import { storeToRefs } from 'pinia'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

defineProps({
  contact: { type: Object as PropType<ContactResponse>, required: true },
})
</script>
<style lang="scss" scoped>
.contact-element {
  &__subline {
    font-size: $font-size-xs;
  }

  :deep(.v-list-item__prepend),
  :deep(.v-list-item__append) {
    align-self: flex-start;
  }
}
</style>
<i18n lang="yaml">
de:
  contactElement:
    contactPerson: Kontaktperson
</i18n>
