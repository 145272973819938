<template>
  <v-form
    v-model="submitBusinessCaseForm"
    @submit.prevent="emit('submit', businessCases)"
  >
    <DialogDefaultLayout>
      <template #content>
        <h1>{{ t('bundleBusinessCases.headline') }}</h1>
        <p>{{ t('bundleBusinessCases.copy') }}</p>

        <template
          v-for="businessCase in businessCases"
          :key="businessCase.businessCaseKey"
        >
          <p class="highlight">
            {{ getBundleBusinessCaseName(businessCase.businessCaseKey) }}
            <v-tooltip
              :text="getBundleBusinessCaseTooltip(businessCase.businessCaseKey)"
              location="bottom"
              max-width="400"
            >
              <template #activator="{ props }">
                <v-icon v-bind="props">{{ Icons.CIRCLE_INFORMATION }}</v-icon>
              </template>
            </v-tooltip>
          </p>
          <v-textarea
            v-model="businessCase.text"
            :autofocus="businessCase.businessCaseKey === BundleBusinessCase.USE_CASE"
            :label="getBundleBusinessCaseName(businessCase.businessCaseKey)"
            variant="outlined"
            :rules="maxLength"
            required
          >
            <template #append-inner>
              <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
            </template>
          </v-textarea>
        </template>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :disabled="!submitBusinessCaseForm"
          :loading="loading"
          >{{ t('buttons.save') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType, computed, ref } from 'vue'
import { BundleResponse, BundleBusinessCaseResponse, BundleChanges } from '@/models/Bundle'
import { BundleBusinessCase } from '@/models/enums/BundleTypes'
import { useBundleBusinessCaseHelper } from '@/composables/useBundleBusinessCaseHelper'
import { isMaxLength, isNotEmpty } from '@/validators'
import { Icons } from '@/models/enums/IconTypes'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()
const { getBundleBusinessCaseName, getBundleBusinessCaseTooltip } = useBundleBusinessCaseHelper()

const componentProps = defineProps({
  bundle: {
    type: Object as PropType<BundleResponse>,
    default: undefined,
  },
  bundleChange: {
    type: Object as PropType<BundleChanges>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])

const maxLength = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 4500)])

const submitBusinessCaseForm = ref(false)
const businessCases = ref<Array<BundleBusinessCaseResponse>>()

businessCases.value = [
  {
    businessCaseKey: BundleBusinessCase.USE_CASE,
    text: getCurrentBusinessCaseTextByKey(BundleBusinessCase.USE_CASE),
  },
  {
    businessCaseKey: BundleBusinessCase.ADDED_VALUE,
    text: getCurrentBusinessCaseTextByKey(BundleBusinessCase.ADDED_VALUE),
  },
  {
    businessCaseKey: BundleBusinessCase.TRAFFIC,
    text: getCurrentBusinessCaseTextByKey(BundleBusinessCase.TRAFFIC),
  },
]

/**
 * getCurrentBusinessCaseTextByKey
 * @param {businessCaseKey} businessCaseKey
 * @return {string}
 */
function getCurrentBusinessCaseTextByKey(businessCaseKey: BundleBusinessCase): string {
  const businessCase =
    componentProps.bundleChange?.changedBusinessCases?.find((businessCase) => businessCase.businessCaseKey === businessCaseKey) ||
    componentProps.bundle?.businessCases?.find((businessCase) => businessCase.businessCaseKey === businessCaseKey)
  if (businessCase) {
    return businessCase.pendingText || businessCase.text
  }
  return ''
}
</script>
<i18n lang="yaml">
de:
  bundleBusinessCases:
    headline: Business Case
    copy: 'Erzähle uns etwas über deine Anwendung:'
</i18n>
