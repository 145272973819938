<template>
  <div class="choose-bundle mb-4">
    <div class="choose-bundle-info d-flex justify-space-between align-center mb-6">
      <p class="highlight sm mb-0">
        {{ t('chooseBundle.info.text') }}
        <v-tooltip
          :text="t('chooseBundle.info.tooltip')"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon v-bind="props">{{ Icons.CIRCLE_INFORMATION }}</v-icon>
          </template>
        </v-tooltip>
      </p>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :disabled="showGhostBundle"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="handleShowGhostBundle()"
        >{{ t('chooseBundle.btn') }}</DefaultButton
      >
    </div>
    <v-list class="choose-bundle-list">
      <v-item-group
        v-model="selectedBundle"
        mandatory
      >
        <v-list-item
          v-if="showGhostBundle"
          class="ghost-bundle py-2 bg-secondary"
        >
          <template #prepend>
            <BundleAvatar :size="48" />
          </template>
          <template #title>
            <v-text-field
              ref="ghostBundleNameInputRef"
              v-model="ghostBundle.name"
              :label="t('chooseBundle.ghostBundle.input.label')"
              counter
              maxlength="25"
              required
              :rules="bundleNameRules"
              variant="plain"
            ></v-text-field>
          </template>
          <template #append>
            <DefaultButton
              class="mr-4"
              :color="PlattformColors.SECONDARY"
              @click="closeGhostBundle()"
              >{{ t('buttons.cancel') }}</DefaultButton
            >

            <DefaultButton
              :color="PlattformColors.PRIMARY"
              :disabled="!ghostBundle.name"
              @click="createBundle()"
              >{{ t('buttons.create') }}</DefaultButton
            >
          </template>
        </v-list-item>
        <v-item
          v-for="bundle in availableBundles"
          :key="bundle.id"
          v-slot="{ isSelected, toggle }"
          :value="bundle"
        >
          <v-list-item
            class="choose-bundle-list-item bg-secondary"
            color="secondary"
            :disabled="productInBundle(bundle)"
            @click="toggle"
          >
            <template #prepend>
              <BundleAvatar :size="48" />
            </template>
            <template #title> {{ bundle.name }} </template>
            <template #append>
              <p
                v-if="productInBundle(bundle)"
                class="sm highlight mb-0"
              >
                {{ t('chooseBundle.productDisabled') }}
              </p>
              <v-radio
                v-else
                :model-value="isSelected"
              />
            </template>
          </v-list-item>
        </v-item>
      </v-item-group>
      <p
        v-if="!(availableBundles.length || showGhostBundle)"
        class="highlight"
      >
        {{ t('chooseBundle.noBundles') }}
      </p>
    </v-list>
  </div>
</template>

<script lang="ts" setup>
import { ProductResponse } from '@/models/Product'
import { Icons } from '@/models/enums/IconTypes'
import { isMaxLength, isNotEmpty } from '@/validators'
import { useBundleStore } from '@/store/bundles'
import { nextTick, watch } from 'vue'
import { PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useAvatarHelper } from '@/composables/useAvatarHelper'
import { BundleCreateRequest, BundleResponse } from '@/models/Bundle'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'

const componentProps = defineProps({
  product: { type: Object as PropType<ProductResponse>, required: true },
  availableBundles: { type: Array<BundleResponse>, required: true },
})

const emit = defineEmits(['valid'])

const { t } = useI18n()
const router = useRouter()
const bundleStore = useBundleStore()

const { getDefaultAvatarKey } = useAvatarHelper()

const ghostBundleNameInputRef = ref<HTMLFormElement>()
const showGhostBundle = ref(false)

const ghostBundle = ref<BundleCreateRequest>({
  iconPath: getDefaultAvatarKey(),
  providerId: componentProps.product.organization.id,
  name: '',
})

const selectedBundle = ref<BundleResponse>()

watch(selectedBundle, (newValue) => {
  emit('valid', !!newValue)
})

const bundleNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 25)])

/**
 * createBundle
 */
async function createBundle(): Promise<void> {
  try {
    await bundleStore.CREATE(ghostBundle.value)
    closeGhostBundle()
    const newBundle = true
    setPreselectBundle(newBundle)
  } catch {
    Promise.resolve()
  }
}

/**
 * setPreselectBundle
 * @param {newBundle?} newBundle?
 */
function setPreselectBundle(newBundle?: boolean): void {
  const preSelectedBundle =
    router.currentRoute.value.query.selectedBundleId && !newBundle
      ? componentProps.availableBundles.find((bundle) => bundle.id === router.currentRoute.value.query.selectedBundleId)
      : undefined

  // select preselected bundle only if product is not in bundle
  if (preSelectedBundle && !productInBundle(preSelectedBundle)) {
    selectedBundle.value = preSelectedBundle
  } else if (!preSelectedBundle) {
    // select first bundle of list where the product is not in bundle when preselected bundle is not set
    const productNotInBundles = componentProps.availableBundles.filter((bundle) => !productInBundle(bundle))
    selectedBundle.value = productNotInBundles[0]
  }
}

setPreselectBundle()

/**
 * closeGhostBundle
 */
function closeGhostBundle(): void {
  ghostBundle.value.name = ''
  showGhostBundle.value = false
}

/**
 * productInBundle
 * @param {bundle} bundle
 * @return {boolean}
 */
function productInBundle(bundle: BundleResponse): boolean {
  return !!bundle.bundleProducts?.find((bundleProduct) => bundleProduct.id === componentProps.product.id)
}

/**
 * getSelectedBundle
 * @return {Bundle | undefined}
 */
function getSelectedBundle(): BundleResponse | undefined {
  return selectedBundle.value
}

/**
 * handleRequest
 */
async function handleShowGhostBundle(): Promise<void> {
  showGhostBundle.value = !showGhostBundle.value
  await nextTick()
  ghostBundleNameInputRef.value?.focus()
}

defineExpose({
  getSelectedBundle,
})
</script>

<style lang="scss" scoped>
.choose-bundle {
  :deep(.v-list-item) {
    padding: 1rem;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>

<i18n lang="yaml">
de:
  chooseBundle:
    info:
      text: Verfügbare Apps
      tooltip: Die Liste beinhaltet nur Apps dessen Anbieter mit dem Anbieter der API übereinstimmt.
    btn: Neue App erstellen
    ghostBundle:
      input:
        label: Name der App
    noBundles: Keine Apps gefunden!
    productDisabled: API bereits verwendet
</i18n>
