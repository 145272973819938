<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit', accessDefinitionObject)">
    <template #content>
      <h1>{{ t('deleteAccessDefinition.headline', { name: accessDefinitionObject.name }) }}</h1>
      <p>{{ t('deleteAccessDefinition.copy', { name: accessDefinitionObject.name }) }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit', accessDefinitionObject)"
        >{{ t('buttons.delete') }}</DefaultButton
      >

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  accessDefinitionObject: {
    type: Object as PropType<AccessDefinitionResponse>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  deleteAccessDefinition:
    headline: '{name} wirklich löschen?'
    copy: Möchtest du die Authentifizierungsmethode "{name}" wirklich löschen?
</i18n>
