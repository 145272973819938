<template>
  <BackButton />
  <GeneralPageTitle>
    <template #prepend>
      <DefaultAvatar
        size="80"
        :color="PlattformColors.WARNING"
      >
        <v-icon size="32">
          {{ Icons.AUTHENTICATION }}
        </v-icon>
      </DefaultAvatar>
    </template>
    <template #title>
      {{ t('accessDefinitions.headline') }}
    </template>
  </GeneralPageTitle>
  <p class="mb-6">{{ t('accessDefinitions.copy') }}</p>
  <ListControls>
    <template #title>
      {{ t('accessDefinitions.count', { count: accessDefinitions.length }) }}
    </template>
    <template #append>
      <DefaultButton
        :color="PlattformColors.LIGHT"
        :prepend-icon="Icons.CIRCLE_ADD"
        :disabled="moreOptionsAvailable()"
        @click="dialogAccessDefinitionRef?.open()"
      >
        {{ t('accessDefinitions.addBtn') }}
      </DefaultButton>
    </template>
  </ListControls>
  <AccessDefinitionList
    :access-definitions="accessDefinitions"
    :mapped-products="mappedProducts"
    @update="(accessDefinition: AccessDefinitionResponse) => showUpdateAccessDefinitionDialog(accessDefinition)"
    @set-default="(accessDefinition: AccessDefinitionResponse) => setAccessDefinitionForDefault(accessDefinition)"
    @toggle-activation="(accessDefinition: AccessDefinitionResponse) => updateAccessDefinition(accessDefinition, accessDefinition.id)"
    @delete="(accessDefinition: AccessDefinitionResponse) => showAccessDefinitionDeleteQuestionDialog(accessDefinition)"
  />
  <SmallCreateTeaser
    v-if="accessDefinitions.length <= 1"
    :icon="Icons.ALGORITHM"
    :headline="t('accessDefinitions.teaser.headline')"
    :copy="t('accessDefinitions.teaser.copy')"
    :btn-text="t('buttons.add')"
    @submit="dialogAccessDefinitionRef?.open()"
  />
  <Dialog
    ref="dialogAccessDefinitionRef"
    @on-close="dialogAccessDefinitionRef?.close()"
  >
    <DialogAccessDefinitionForm
      ref="accessDefinitionFormRef"
      :loading="loading"
      @cancel="dialogAccessDefinitionRef?.close()"
      @submit="(accessDefinition: AccessDefinitionFormEmit) => createAccessDefinition(accessDefinition)"
    />
  </Dialog>
  <Dialog
    ref="dialogUpdateAccessDefinitionRef"
    @on-close="dialogUpdateAccessDefinitionRef?.close()"
  >
    <DialogAccessDefinitionForm
      :access-definition-object="accessDefinitionForUpdate as AccessDefinitionTokenResponse"
      :loading="loading"
      @cancel="dialogUpdateAccessDefinitionRef?.close()"
      @submit="
        (accessDefinition: AccessDefinitionFormEmit) => {
          if (accessDefinitionForUpdate) updateAccessDefinition(accessDefinition, accessDefinitionForUpdate.id)
        }
      "
    />
  </Dialog>
  <Dialog
    ref="dialogAccessDefinitionDeleteQuestionRef"
    @on-close="dialogAccessDefinitionDeleteQuestionRef?.close()"
  >
    <DialogAccessDefinitionDelete
      :access-definition-object="accessDefinitionForDeletion"
      :loading="loading"
      @cancel="dialogAccessDefinitionDeleteQuestionRef?.close()"
      @submit="(accessDefinition: AccessDefinitionResponse) => deleteAccessDefinition(accessDefinition)"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { ref, computed, ComputedRef } from 'vue'
import { AccessDefinitionFormEmit, AccessDefinitionResponse, AccessDefinitionTokenResponse } from '@/models/AccessDefinition'
import { useAccessDefinitionsStore } from '@/store/accessDefinitions'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useProductStore } from '@/store/products'
import Dialog from '@/components/layout/Dialog.vue'
import BackButton from '@/components/layout/BackButton.vue'
import DialogAccessDefinitionForm from '@/components/dialogs/DialogAccessDefinitionForm.vue'
import AccessDefinitionList from '@/components/accessDefinitions/AccessDefinitionList.vue'
import DialogAccessDefinitionDelete from '@/components/dialogs/DialogAccessDefinitionDelete.vue'
import { useAlertStore } from '@/store/alerts'
import { Icons } from '@/models/enums/IconTypes'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import SmallCreateTeaser from '@/components/layout/SmallCreateTeaser.vue'
import { ProductResponse, ProductTeaser } from '@/models/Product'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import ListControls from '@/components/ListControls.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import { useAccessHelper } from '@/composables/useAccessHelper'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'

const { t } = useI18n()
const alertStore = useAlertStore()
const accessDefinitionsStore = useAccessDefinitionsStore()
const myOrganizationStore = useMyOrganizationStore()
const productStore = useProductStore()

const { accessDefinitions } = storeToRefs(accessDefinitionsStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)
const { products } = storeToRefs(productStore)

const { getNameFromAccessDefinition } = useAccessHelper()

const mappedProducts: ComputedRef<{ [key: string]: ProductTeaser[] }> = computed(() =>
  products.value.reduce((acc: { [key: string]: ProductTeaser[] }, product: ProductResponse) => {
    if (product.accessDefinitionId) {
      acc[product.accessDefinitionId] = acc[product.accessDefinitionId] || []

      acc[product.accessDefinitionId].push({
        name: product.name,
        colour: product.colour,
        id: product.id,
      })
    }

    return acc
  }, {})
)

const dialogAccessDefinitionRef = ref<InstanceType<typeof Dialog>>()
const dialogUpdateAccessDefinitionRef = ref<InstanceType<typeof Dialog>>()
const dialogAccessDefinitionDeleteQuestionRef = ref<InstanceType<typeof Dialog>>()
const accessDefinitionFormRef = ref()
const loading = ref(false)
const accessDefinitionForDeletion = ref<AccessDefinitionResponse>()
const accessDefinitionForUpdate = ref<AccessDefinitionResponse>()

/**
 * showUpdateAccessDefinitionDialog
 * @param {accessDefinition} accessDefinition
 */
function showUpdateAccessDefinitionDialog(accessDefinition: AccessDefinitionResponse): void {
  accessDefinitionForUpdate.value = accessDefinition
  dialogUpdateAccessDefinitionRef.value?.open()
}

/**
 * showAccessDefinitionDeleteQuestionDialog
 * @param {accessDefinition} accessDefinition
 */
function showAccessDefinitionDeleteQuestionDialog(accessDefinition: AccessDefinitionResponse): void {
  accessDefinitionForDeletion.value = accessDefinition
  dialogAccessDefinitionDeleteQuestionRef.value?.open()
}

/**
 * createAccessDefinition
 * @param {accessDefinition} accessDefinition
 */
async function createAccessDefinition(accessDefinition: AccessDefinitionFormEmit): Promise<void> {
  if (activeOrganization?.value?.id) {
    const lastDefaultAccessDefinition = accessDefinitions.value.find((accessDefinition) => accessDefinition.defaultDefinition)
    try {
      loading.value = true
      await accessDefinitionsStore.CREATE({
        ...accessDefinition,
        active: true,
        name: getNameFromAccessDefinition(accessDefinition),
        providerId: activeOrganization.value.id,
      })
      if (lastDefaultAccessDefinition && accessDefinition.defaultDefinition) {
        lastDefaultAccessDefinition.defaultDefinition = false
      }
      alertStore.add({
        text: t('accessDefinitions.success.created'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      if (lastDefaultAccessDefinition) {
        lastDefaultAccessDefinition.defaultDefinition = true
      }
      Promise.resolve()
    } finally {
      loading.value = false
      dialogAccessDefinitionRef.value?.close()
    }
  }
}

/**
 * setAccessDefinitionForDefault
 * @param {accessDefinition} accessDefinition
 */
async function setAccessDefinitionForDefault(accessDefinition: AccessDefinitionResponse): Promise<void> {
  if (activeOrganization?.value) {
    const lastDefaultAccessDefinition = accessDefinitions.value.find((accessDefinition) => accessDefinition.defaultDefinition)
    try {
      loading.value = true

      if (lastDefaultAccessDefinition) {
        lastDefaultAccessDefinition.defaultDefinition = false
      }

      accessDefinition.defaultDefinition = true
      await accessDefinitionsStore.UPDATE(accessDefinition, accessDefinition.id)
      await accessDefinitionsStore.GET_LIST(activeOrganization.value.id)
    } catch {
      if (lastDefaultAccessDefinition) {
        lastDefaultAccessDefinition.defaultDefinition = true
      }
      accessDefinition.defaultDefinition = false
      Promise.resolve()
    } finally {
      loading.value = false
    }
  }
}

/**
 * updateAccessDefinition
 * @param {AccessDefinitionFormEmit | AccessDefinitionResponse} accessDefinition
 * @param {AccessDefinitionResponse.id} accessDefinitionId
 */
async function updateAccessDefinition(
  accessDefinition: AccessDefinitionFormEmit | AccessDefinitionResponse,
  accessDefinitionId: AccessDefinitionResponse['id']
): Promise<void> {
  try {
    loading.value = true
    await accessDefinitionsStore.UPDATE({ ...accessDefinition, providerId: null }, accessDefinitionId)
    alertStore.add({
      text: t('accessDefinitions.success.updated'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    dialogUpdateAccessDefinitionRef.value?.close()
    loading.value = false
  }
}

/**
 * deleteAccessDefinition
 * @param {accessDefinition} accessDefinition
 */
async function deleteAccessDefinition(accessDefinition: AccessDefinitionResponse): Promise<void> {
  try {
    loading.value = true
    await accessDefinitionsStore.DELETE(accessDefinition.id)
    alertStore.add({
      text: t('accessDefinitions.success.deleted'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    loading.value = false
    dialogAccessDefinitionDeleteQuestionRef.value?.close()
  }
}

/**
 * moreOptionsAvailable
 * @return {boolean}
 */
function moreOptionsAvailable(): boolean {
  const manuelAccessExchangeType = accessDefinitions.value.find(
    (accessDefinition) => accessDefinition.accessExchangeType === AccessExchangeTypes.MANUAL_EXCHANGE
  )
  const tokenAccessExchangeType = accessDefinitions.value.find((accessDefinition) => accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN)
  return !!manuelAccessExchangeType && !!tokenAccessExchangeType
}

if (activeOrganization?.value) {
  await Promise.allSettled([
    accessDefinitionsStore.GET_LIST(activeOrganization.value.id),
    productStore.GET_PRODUCTS({ providerId: activeOrganization.value.id }),
  ])
}
</script>
<i18n lang="yaml">
de:
  accessDefinitions:
    headline: Authentifizierungsmethoden
    copy: Hier kannst du deine Authentifizierungsmethoden verwalten. Nutze die Manuelle Übergabe oder füge weitere Methoden hinzu, um die
      Authentifizierung zu automatisieren.
    count: '{count} Authentifizierungsmethode | {count} Authentifizierungsmethoden'
    addBtn: Methode hinzufügen
    success:
      created: Deine Authentifizierungsmethode wurde erstellt!
      updated: Deine Authentifizierungsmethode wurde aktualisiert!
      deleted: Deine Authentifizierungsmethode wurde gelöscht!
    teaser:
      headline: Access Token Generierung
      copy: Füge diese Methode hinzu, um die Verwaltung von Authentifizierungsmitteln zu erleichtern.
</i18n>
