<template>
  <div class="overlay-content">
    <v-form
      v-model="createTechnicalUserForm"
      @submit.prevent="emit('submit', technicalUserName)"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>
            {{ t('createTechnicalUser.form.headline') }}
          </h1>
          <p>{{ t('createTechnicalUser.form.subline') }}</p>
          <v-text-field
            v-model="technicalUserName"
            autofocus
            :label="t('createTechnicalUser.form.technicalUserNameLabel')"
            counter
            maxlength="50"
            variant="outlined"
            required
            :rules="technicalUserNameRules"
          >
            <template #append-inner>
              <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
            </template>
          </v-text-field>
        </template>
        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
            :disabled="!createTechnicalUserForm"
          >
            {{ t('buttons.create') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { Icons } from '@/models/enums/IconTypes'
import { isNotEmpty, isMaxLength } from '@/validators'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})

const createTechnicalUserForm = ref(false)
const technicalUserName = ref('')

const technicalUserNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 50)])
</script>

<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  createTechnicalUser:
    form:
      technicalUserNameLabel: Name
      headline: Technischen Nutzer erstellen
      subline: Gib deinem technischen Nutzer einen Namen. Anschließend wird der zugehörige Refresh Token und Client Secret einmalig angezeigt.
</i18n>
