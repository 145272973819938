<template>
  <DefaultList>
    <ListElement
      v-for="product in products"
      :key="product.id"
      :data-cy="ProductListWithPlansTest.ITEM"
      clickable
      @click="emit('handleProductClick', product.id)"
    >
      <template #prepend>
        <DefaultAvatar size="48">
          <v-icon
            color="primary"
            size="24"
            >{{ Icons.PRODUCT }}</v-icon
          >
        </DefaultAvatar>
      </template>
      <template #title>{{ product.name }}</template>
      <template #subtitle>
        <b>{{ product.organization.name }}</b>
        <v-divider
          vertical
          length="12"
          thickness="1"
          color="black"
          opacity=".5"
          class="mx-2"
        />
        {{
          t('createdAt', {
            d: format(new Date(product.createdAt), 'dd.MM.yyyy'),
          })
        }}
      </template>
      <template #append>
        <v-icon
          v-if="hasBundleProductAnyInfo(product)"
          size="24"
          class="mr-2"
          :data-cy="ProductListWithPlansTest.INFO"
          >{{ Icons.CIRCLE_INFORMATION }}</v-icon
        >
        <v-chip
          label
          class="mr-2"
          :class="{ 'cursor-default': !arePlansEditable(product) }"
          :ripple="arePlansEditable(product)"
          @click.stop="!arePlansEditable(product) || emit('editPlanClick', product)"
        >
          {{ t('productPlanList.plan', { count: product.subscriptions ? getVisibleSubscriptions(product.subscriptions).length : 0 }) }}
          <v-progress-circular
            v-if="productPlanLoading.loading && product.id === productPlanLoading.productId"
            indeterminate
            width="2"
            size="16"
            class="ml-2"
          />
          <v-icon
            v-else-if="arePlansEditable(product)"
            size="16"
            class="ml-2"
            >{{ Icons.EDIT }}</v-icon
          >
        </v-chip>
        <v-icon class="mx-2">{{ product.publiclyAvailable ? Icons.GLOBE : Icons.LOCK }}</v-icon>

        <IconButton
          v-if="isBundleDraft"
          :icon="Icons.DELETE"
          @click.stop="emit('handleProductRemoveClick', product)"
        />
      </template>
      <template
        v-if="product.subscriptions && getVisibleSubscriptions(product.subscriptions).length"
        #expand
      >
        <DefaultList :data-cy="ProductListWithPlansTest.SUBSCRIPTION_CONTAINER">
          <ListLineElement
            v-for="subscription in getVisibleSubscriptions(product.subscriptions)"
            :key="subscription.id"
            :data-cy="ProductListWithPlansTest.SUBSCRIPTION"
          >
            <template #prepend><div class="px-7"></div></template>
            <template #title>{{ subscription.planName }} </template>
            <template #subtitle>
              {{ t('productPlanList.valid') }}
              {{ subscription.validUntil ? format(new Date(subscription.validUntil), 'dd.MM.yyyy') : t('validityPeriodUnlimited') }}
            </template>
            <template #append>
              <div
                v-if="isSubscriptionInTermination(subscription)"
                :data-cy="ProductListWithPlansTest.INFO_TERMINATION"
              >
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  {{
                    t('productPlanList.terminateAt', {
                      date: subscription.validUntil ? format(new Date(subscription.validUntil), 'dd.MM.yyyy') : t('validityPeriodUnlimited'),
                    })
                  }}
                  <template #activator="{ props }">
                    <v-icon
                      size="24"
                      v-bind="props"
                    >
                      {{ Icons.CIRCLE_INFORMATION }}
                    </v-icon>
                  </template>
                </v-tooltip>
              </div>
              <div
                v-else-if="isSubscriptionRequested(subscription)"
                :data-cy="ProductListWithPlansTest.INFO_REQUESTED"
              >
                <v-tooltip
                  activator="parent"
                  location="bottom"
                >
                  {{ t('productPlanList.pending') }}
                  <template #activator="{ props }">
                    <DefaultAvatar
                      size="24"
                      :color="PlattformColors.CHANGE"
                    >
                      <v-icon
                        size="16"
                        v-bind="props"
                      >
                        {{ Icons.UPDATE }}
                      </v-icon>
                    </DefaultAvatar>
                  </template>
                </v-tooltip>
              </div>

              <DefaultButton
                v-else-if="bundleStatus === StatusTypes.APPROVED"
                :data-cy="ProductListWithPlansTest.TERMINATE"
                :color="PlattformColors.SECONDARY"
                @click.stop="emit('handleTerminateSubscription', subscription)"
                >{{ t('productPlanList.terminate') }}</DefaultButton
              >
            </template>
          </ListLineElement>
        </DefaultList>
      </template>
    </ListElement>
  </DefaultList>
</template>
<script lang="ts" setup>
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { useBundleApprovalHelper } from '@/composables/useBundleApprovalHelper'
import { ProductResponse } from '@/models/Product'
import { Icons } from '@/models/enums/IconTypes'
import { PropType } from 'vue'
import { SubscriptionResponse } from '@/models/Subscription'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { computed } from 'vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { ProductListWithPlansTest } from '@/models/enums/test/ProductListWithPlansTest'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { BundleProductExtended } from '@/models/Bundle'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import ListLineElement from '@/components/baseComponents/lists/ListLineElement.vue'

interface ProductPlanLoading {
  loading: boolean
  productId?: ProductResponse['id']
}

const { t } = useI18n()

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { getMostRecentApproval, isMostRecentApprovalNotRejectedOrTerminated } = useBundleApprovalHelper()

const emit = defineEmits(['handleProductClick', 'editPlanClick', 'handleProductRemoveClick', 'handleTerminateSubscription'])

const componentProps = defineProps({
  productPlanLoading: {
    type: Object as PropType<ProductPlanLoading>,
    default: () => ({
      loading: false,
      productId: undefined,
    }),
  },
  products: {
    type: Array<BundleProductExtended>,
    default: undefined,
  },
  bundleStatus: {
    type: String as PropType<StatusTypes>,
    default: undefined,
  },
})

const isBundleDraft = computed(() => !activeOrganization?.value?.provider && componentProps.bundleStatus === StatusTypes.DRAFT)

/**
 * arePlansEditable
 * @param {bundleProduct} bundleProduct
 * @return {boolean}
 */
function arePlansEditable(bundleProduct: BundleProductExtended): boolean {
  return (
    !activeOrganization?.value?.provider &&
    (componentProps.bundleStatus === StatusTypes.DRAFT ||
      (componentProps.bundleStatus === StatusTypes.APPROVED &&
        // if product does not support multiple plans and a plan is already selected, disable editing
        (bundleProduct.supportMultiplePlans || (!bundleProduct.supportMultiplePlans && getVisibleSubscriptions(bundleProduct.subscriptions).length < 1))))
  )
}

/**
 * hasBundleProductAnyInfo
 * @param {bundleProduct} bundleProduct
 * @return {boolean}
 */
function hasBundleProductAnyInfo(bundleProduct: BundleProductExtended): boolean {
  const info = bundleProduct.subscriptions?.filter(
    (subscription) =>
      getMostRecentApproval(subscription.approvals)?.approvalStatus === StatusTypes.IN_TERMINATION ||
      getMostRecentApproval(subscription.approvals)?.approvalStatus === StatusTypes.REQUESTED
  )

  return !!info?.length
}

/**
 * isSubscriptionInTermination
 * @param {subscription} subscription
 * @return {boolean}
 */
function isSubscriptionInTermination(subscription: SubscriptionResponse): boolean {
  return getMostRecentApproval(subscription.approvals)?.approvalStatus === StatusTypes.IN_TERMINATION
}

/**
 * isSubscriptionRequested
 * @param {subscription} subscription
 * @return {boolean}
 */
function isSubscriptionRequested(subscription: SubscriptionResponse): boolean {
  return getMostRecentApproval(subscription.approvals)?.approvalStatus === StatusTypes.REQUESTED
}

/**
 * getVisibleSubscriptions
 * @param {subscriptions} subscriptions
 * @return {Array<Subscription>}
 */
function getVisibleSubscriptions(subscriptions?: Array<SubscriptionResponse>): Array<SubscriptionResponse> {
  return subscriptions?.filter((subscription) => isMostRecentApprovalNotRejectedOrTerminated(subscription.approvals)) || []
}
</script>

<i18n lang="yaml">
de:
  productPlanList:
    valid: 'Vertragslaufzeit: '
    plan: '{count} Plan | {count} Pläne'
    remove: Entfernen
    terminate: Kündigen
    terminateAt: 'Plan gekündigt zum: {date}'
    pending: Genehmigung ausstehend
</i18n>
