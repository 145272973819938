<template>
  <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
    <template #content>
      <h1>{{ t('requestDeactivationCredential.headline') }}</h1>
      <p>{{ t('requestDeactivationCredential.copy') }}</p>

      <InfoBox
        class="my-8"
        :copy="t('requestDeactivationCredential.info')"
      />
    </template>

    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="handleSubmit()"
      >
        {{ t('buttons.request') }}</DefaultButton
      >
      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
      >
        {{ t('buttons.cancel') }}</DefaultButton
      >
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { AccessResponse } from '@/models/Access'
import { PropType, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import InfoBox from '../InfoBox.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const props = defineProps({
  selectedAccess: { type: Object as PropType<AccessResponse>, default: () => {} },
})
const loading = ref(false)

const { t } = useI18n()

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', props.selectedAccess)
  loading.value = true
}
const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  requestDeactivationCredential:
    headline: Authentifizierungsmittel deaktivieren
    copy: Dein Authentifizierungsmittel wird endgültig deaktiviert, sobald dein Anbieter deine Anfrage bearbeitet hat. Dies kann mehrere Tage dauern.
    info: Um deine API-Produkte weiterhin nutzen zu können, stelle sicher, dass weiterhin aktive Authentifizierungsmittel hinterlegt sind.
</i18n>
