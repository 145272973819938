import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface SnackbarStateProperties {
  snackbar: boolean
  snackbarText: string
}

export const useSnackbarStore = defineStore(StoreName.SNACKBAR, {
  state: (): SnackbarStateProperties => ({
    snackbar: false,
    snackbarText: '',
  }),
  actions: {
    setSnackbarText(text: string) {
      this.snackbarText = text
      this.snackbar = true
    },
  },
})
