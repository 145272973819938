<template>
  <DialogDefaultLayout>
    <template #content>
      <h1>{{ t('changeRequest.headline') }}</h1>
      <p>{{ t('changeRequest.copy', { bundleName }) }}</p>
      <p class="highlight">
        {{ t('changeRequest.copySecondary', { planName: changedSubscription?.planName, productName: changedSubscription?.productName }) }}
      </p>

      <v-radio-group v-model="radioSelect">
        <v-radio :value="StatusTypes.ACTIVE">
          <template #label>
            <DefaultAvatar
              size="12"
              class="mx-2"
              :color="PlattformColors.SUCCESS"
            />
            <b>
              {{ t('changeRequest.accept') }}
            </b>
          </template>
        </v-radio>
        <v-radio :value="StatusTypes.INACTIVE">
          <template #label>
            <DefaultAvatar
              size="12"
              class="mx-2"
              :color="PlattformColors.ERROR"
            />
            <b>
              {{ t('changeRequest.decline') }}
            </b>
          </template>
        </v-radio>
      </v-radio-group>

      <v-textarea
        v-if="radioSelect === StatusTypes.INACTIVE"
        v-model="declineNote"
        :label="t('changeRequest.label')"
        variant="outlined"
      >
        <template #append-inner>
          <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
        </template>
      </v-textarea>
      <p>{{ t('changeRequest.copyLower') }}</p>
    </template>

    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :disabled="isSubmitButtonDisabled"
        :loading="loading"
        @click="handleSubmitButton()"
        >{{ t('buttons.send') }}</DefaultButton
      >

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
        >{{ t('buttons.cancel') }}</DefaultButton
      >
    </template>
  </DialogDefaultLayout>
</template>

<script lang="ts" setup>
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { PropType } from 'vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { InfoElementChangedSubscription } from '@/models/InfoElement'
import { BundleResponse } from '@/models/Bundle'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const props = defineProps({
  changedSubscription: { type: Object as PropType<InfoElementChangedSubscription>, default: undefined },
  bundleName: { type: String as PropType<BundleResponse['name']>, default: undefined },
})

const { t } = useI18n()

const radioSelect = ref<string>()
const declineNote = ref<string>()
const loading = ref(false)

const isSubmitButtonDisabled = computed(() => !radioSelect.value || (radioSelect.value === StatusTypes.INACTIVE && !declineNote.value))

const emit = defineEmits(['approve', 'reject', 'cancel'])

/**
 * handleSubmit
 */
function handleSubmitButton(): void {
  loading.value = true
  if (radioSelect.value === StatusTypes.ACTIVE) {
    emit('approve', props.changedSubscription?.subscriptionId)
  } else if (radioSelect.value === StatusTypes.INACTIVE) {
    emit('reject', props.changedSubscription?.subscriptionId, declineNote.value)
  }
}
</script>
<style lang="scss" scoped>
:deep(.v-label) {
  opacity: 1;
}
</style>

<i18n lang="yaml">
de:
  changeRequest:
    headline: Änderungsanfrage
    copy: 'Dein Kunde hat folgende Anfrage zu der App {bundleName} gestellt:'
    copySecondary: Neuer Plan "{planName}" der API "{productName}" hinzugefügt
    copyLower: Der Kunde wird per E-Mail über die Statusänderung benachrichtigt. Sobald der Status festgelegt ist, kann dieser nicht wieder zurückgesetzt
      werden.
    label: Begründung
    accept: Freigeben
    decline: Ablehnen
</i18n>
