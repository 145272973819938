import { createI18n } from 'vue-i18n'
import de from './messages/de.json'

const instance = createI18n({
  locale: 'de',
  messages: {
    de,
  },
})

export default instance

export const i18n = instance.global
