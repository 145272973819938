<template>
  <DialogCenteredLayout>
    <template #content>
      <h1>{{ t('consumerWelcome.headline') }}</h1>
      <p>{{ t('consumerWelcome.copy') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="emit('submit')"
      >
        {{ t('consumerWelcome.button') }}
      </DefaultButton>
    </template>
  </DialogCenteredLayout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogCenteredLayout from '@/components/dialogs/layout/DialogCenteredLayout.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'

const { t } = useI18n()

const emit = defineEmits(['submit'])
</script>
<i18n lang="yaml">
de:
  consumerWelcome:
    headline: Wilkommen im Developer Portal
    copy: Nur noch wenige Schritte und es kann losgehen!
    button: Organisation erstellen
</i18n>
