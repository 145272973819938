<template>
  <div class="overlay-content">
    <DialogDefaultLayout>
      <template #content>
        <h1>
          {{ t('technicalUserInformation.headline') }}
        </h1>
        <p
          light
          class="overlay-content__copy"
        >
          {{ t('technicalUserInformation.copy') }}
        </p>
        <p class="highlight mb-0">{{ t('technicalUserInformation.clientSecret') }}</p>
        <p>{{ clientSecret }}</p>
        <div class="text-center">
          <CopyToClipBtn
            :text="t('technicalUserInformation.buttonConfirm')"
            :copy-value="clientSecret"
            class="mb-6"
          />
        </div>
        <p class="highlight mb-0">{{ t('technicalUserInformation.refreshToken') }}</p>
        <p>{{ shortenToken }}</p>
        <div class="text-center">
          <CopyToClipBtn
            :text="t('technicalUserInformation.buttonConfirm')"
            :copy-value="refreshToken"
            class="mb-6"
          />
        </div>
      </template>
      <template #buttons>
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          @click="emit('close')"
        >
          {{ t('buttons.done') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </div>
</template>
<script lang="ts" setup>
import { TechnicalUserCreateResponse } from '@/models/TechnicalUser'
import { PropType } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const props = defineProps({
  clientSecret: {
    type: String as PropType<TechnicalUserCreateResponse['clientSecret']>,
    default: undefined,
  },
  refreshToken: {
    type: String as PropType<TechnicalUserCreateResponse['refreshToken']>,
    default: undefined,
  },
})

const emit = defineEmits(['close'])

const shortenToken = computed(() => (props.refreshToken ? props.refreshToken?.replace(/(.{255})..+/, '$1…') : ''))
</script>
<style lang="scss" scoped>
.overlay-content {
  &__copy {
    max-height: 50vh;
    overflow: auto;
    white-space: pre-line;
  }
}
</style>

<i18n lang="yaml">
de:
  technicalUserInformation:
    headline: Dein neuer Refresh Token!
    copy: Bitte speichere das Client Secret und den Refresh Token an einem sicheren Ort, da diese nicht erneut angezeigt wird. Über die Buttons kannst du
      sie in die Zwischenablage kopieren. Nutze diesen Refresh Token, um dir per OpenID Connect Protocol einen temporären Access Token zu generieren.
    clientSecret: |
      Client Secret:
    refreshToken: |
      Refresh Token:
    buttonConfirm: In die Zwischenablage kopieren
</i18n>
