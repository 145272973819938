<template>
  <Transition name="fade">
    <div
      v-if="appLoading"
      class="d-flex justify-center align-center app-loading bg-white"
    ></div>

    <v-app
      v-else
      class="bg-secondary"
    >
      <NavDrawer />
      <NavBar />
      <v-main>
        <div
          v-if="showInactiveOverlay"
          class="inactive-provider"
        >
          <v-overlay
            v-model="overlay"
            contained
            persistent
          >
            <v-card>
              <v-img
                class="mx-auto"
                width="96"
                :src="alert"
              />
              <h1>{{ t('inactiveOverlay.headline') }}</h1>
              <p>{{ t('inactiveOverlay.copy') }}</p>
            </v-card>
          </v-overlay>
        </div>
        <v-container
          v-else
          class="main-container"
        >
          <AlertContainer />
          <Snackbar />
          <RouterView v-slot="{ Component }">
            <template v-if="Component">
              <Suspense timeout="0">
                <!-- main content -->
                <component :is="Component"></component>
                <!-- loading state -->
                <template #fallback>
                  <div class="content-loading d-flex justify-center align-center bg-secondary">
                    <v-progress-circular
                      indeterminate
                      size="100"
                      color="primary"
                    />
                  </div>
                </template>
              </Suspense>
            </template>
          </RouterView>
        </v-container>
      </v-main>
      <FooterBar />
    </v-app>
  </Transition>
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useLoadingStore } from '@/store/loading'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import AlertContainer from '@/components/layout/AlertContainer.vue'
import FooterBar from '@/components/layout/FooterBar.vue'
import NavBar from '@/components/layout/NavBar.vue'
import NavDrawer from '@/components/layout/NavDrawer.vue'

import alert from '@/assets/illustrations/alert.svg'
import Snackbar from '@/components/layout/Snackbar.vue'

const { t } = useI18n()
const route = useRoute()

const myOrganizationStore = useMyOrganizationStore()
const loadingStore = useLoadingStore()

const { appLoading } = storeToRefs(loadingStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)
const overlay = ref(true)

const showInactiveOverlay = computed(() => !route.meta.noActiveProvider && activeOrganization?.value?.provider && !activeOrganization.value.active)
</script>
<style lang="scss" scoped>
.app-loading {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
}

.fade-leave-active {
  transition: opacity 0.375s ease;
}

.fade-leave-to {
  opacity: 0;
}

.content-loading {
  position: absolute;
  height: 100vh;
  width: calc(100% - 16rem);
  z-index: 100;
  left: 16rem;
  top: 0;
}

.inactive-provider {
  position: relative;
  height: calc(100vh - 11rem);

  :deep(.v-overlay__content) {
    height: 100%;
    width: 100%;
  }

  :deep(.v-card) {
    margin: 10rem auto 0;
    max-width: 44rem;
    padding: 3rem 5rem 5rem;
    text-align: center;
  }

  .v-image {
    margin: 0 auto 1rem;
  }
}
</style>
<i18n lang="yaml">
de:
  inactiveOverlay:
    headline: Deine Organisation wurde noch nicht freigeschaltet.
    copy: Wir melden uns bei dir, sobald der Prozess erfolgreich abgeschlossen wurde. Bei Fragen kommen wir auf dich zu.
</i18n>
