import { BundleResponse } from '@/models/Bundle'
import { SubscriptionResponse } from '@/models/Subscription'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface SubscriptionStateProperties {
  subscriptions: SubscriptionResponse[]
  subscription?: SubscriptionResponse
}

export const useSubscriptionStore = defineStore(StoreName.SUBSCRIPTIONS, {
  state: (): SubscriptionStateProperties => ({
    // TODO, validate: are these state props in use?
    subscriptions: [],
    subscription: undefined,
  }),
  actions: {
    async APPROVE(subscriptionId: SubscriptionResponse['id'], note: string): Promise<BundleResponse> {
      try {
        const { data }: { data: BundleResponse } = await useAxios.patch(`/api/subscriptions/${subscriptionId}/approve`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async REJECT(subscriptionId: SubscriptionResponse['id'], note: string): Promise<BundleResponse> {
      try {
        const { data }: { data: BundleResponse } = await useAxios.patch(`/api/subscriptions/${subscriptionId}/reject`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
    async TERMINATE(subscriptionId: SubscriptionResponse['id'], note: string): Promise<BundleResponse> {
      try {
        const { data }: { data: BundleResponse } = await useAxios.patch(`/api/subscriptions/${subscriptionId}/cancel`, { note })
        return data
      } catch {
        return Promise.reject()
      }
    },
  },
})
