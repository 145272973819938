<template>
  <OrganizationCard :organization="organization" />
</template>

<script lang="ts" setup>
import OrganizationCard from '@/components/organizations/OrganizationCard.vue'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'

const organizationStore = useOrganizationStore()

const { organization } = storeToRefs(organizationStore)
</script>
