export enum PlattformColors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  LIGHT = 'white',
  SUCCESS = 'green',
  INFO = 'grey',
  WARNING = 'yellow',
  ERROR = 'red',
  CHANGE = 'blue',
}
