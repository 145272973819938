<template>
  <div>
    <h2>{{ t('wizardStep2.headline') }}</h2>
    <p>{{ t(`wizardStep2.copy`) }}</p>
    <v-select
      v-model="wizardContent.folders"
      :disabled="noPropertiesAvailable || wizardContent.allFolders"
      multiple
      :items="selectProperties"
      :label="t('wizardStep2.label')"
      item-title="name"
      variant="outlined"
      return-object
    >
      <template #selection="{ item, index }">
        <v-chip v-if="index < 3">
          <span>{{ item.title }}</span>
        </v-chip>
        <span
          v-if="index === 3"
          class="text-grey text-caption align-self-center"
        >
          (+{{ t('additional', { count: selectedLength - 3 }) }})
        </span>
      </template>
    </v-select>

    <DefaultCheckbox
      :model-value="wizardContent.allFolders"
      :label="t(`selectAll`)"
      @click="toggleAll"
    />
  </div>
</template>

<script lang="ts" setup>
import DefaultCheckbox from '@/components/baseComponents/checkboxes/DefaultCheckbox.vue'
import { useFilesStore } from '@/store/files'
import { useSideWizardStore } from '@/store/sideWizard'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const sideWizardStore = useSideWizardStore()
const filesStore = useFilesStore()

const { wizardContent, wizardValidated } = storeToRefs(sideWizardStore)
const { filesAndFolders } = storeToRefs(filesStore)

wizardValidated.value[1] = computed(() => !!wizardContent.value.folders?.length)

const selectProperties = computed(() => filesAndFolders.value)
const noPropertiesAvailable = computed(() => !selectProperties.value.length)
const selectedLength = computed(() => wizardContent.value.folders?.length || 0)

/**
 * toggleAll
 */
function toggleAll(): void {
  wizardContent.value.allFolders = !wizardContent.value.allFolders

  if (wizardContent.value.allFolders) {
    wizardContent.value.folders = selectProperties.value.slice()
  } else {
    wizardContent.value.folders = []
  }
}

if (wizardContent.value.organizations) {
  await filesStore.GET(wizardContent.value.organizations[0].id)
}
</script>

<style lang="scss" scoped>
.v-selection-control {
  margin-bottom: 2rem;
}
</style>

<i18n lang="yaml">
de:
  wizardStep2:
    label: Ordner auswählen
    headline: Ordner auswählen
    copy: Wähle die Ordner aus, die dem Team freigegeben werden.
</i18n>
