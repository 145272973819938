<template>
  <v-row
    v-if="product?.productLinks?.length || product?.detailedDescription"
    class="content-container product-details"
  >
    <v-col :cols="product?.productLinks?.length ? 9 : 12">
      <MdPreview
        v-if="product?.detailedDescription"
        v-model="product.detailedDescription"
        language="en-US"
        no-iconfont
      />
    </v-col>
    <v-col
      v-if="product?.productLinks?.length"
      cols="3"
    >
      <p class="highlight">{{ t('productDetail.links.subline') }}</p>
      <DefaultButton
        v-for="productLink in product.productLinks"
        :key="productLink.id"
        :color="PlattformColors.SECONDARY"
        :append-icon="Icons.EXTERNAL_LINK"
        :href="productLink.url"
        target="_blank"
        class="w-100 mb-2 justify-space-between text-left"
        >{{ productLink.name }}</DefaultButton
      >
    </v-col>
  </v-row>
  <v-row
    v-else
    class="content-container"
  >
    <v-col>
      <p class="mb-0">
        {{ t('productDetail.noDescription') }}
      </p>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { MdPreview } from 'md-editor-v3'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import 'md-editor-v3/lib/style.css'

import { useProductStore } from '@/store/products'

const { t } = useI18n()
const productStore = useProductStore()

const { product } = storeToRefs(productStore)
</script>

<style lang="scss" scoped>
.product-details {
  .highlight {
    font-size: $font-size-xs;
  }

  :deep(.v-btn__content) {
    grid-area: inherit;
    font-size: $font-size-s;
  }
}
</style>

<i18n lang="yaml">
de:
  productDetail:
    noDescription: Keine Beschreibung verfügbar.
    links:
      subline: Externe Links
</i18n>
