<template>
  <CenteredTeaser>
    <template #title>
      <span class="d-block px-12">{{ t('docsTeaser.headline') }}</span>
    </template>
    <template #text>
      <DefaultAvatar
        size="120"
        :color="PlattformColors.SECONDARY"
        class="mx-auto"
      >
        <v-icon
          color="primary"
          size="60"
          >{{ Icons.DOCUMENT }}</v-icon
        >
      </DefaultAvatar>
    </template>
    <template #actions>
      <DefaultButton
        :prepend-icon="Icons.SEARCH"
        :href="mapDocsURL()"
        :variant="ButtonVariants.PLAIN"
        >{{ t('docsTeaser.button') }}</DefaultButton
      >
    </template>
  </CenteredTeaser>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import CenteredTeaser from '@/components/baseComponents/teasers/CenteredTeaser.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()

/**
 * get dynamic URL environment specific at runtime
 * @return {string} URL
 */
function mapDocsURL(): string {
  const docsURL = window.location.origin.replace(window.location.host, 'docs.' + window.location.host)
  return docsURL
}
</script>
<i18n lang="yaml">
de:
  docsTeaser:
    headline: Du benötigst Hilfe bei der Einrichtung?
    button: Zur Dokumentation
</i18n>
