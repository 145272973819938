<template>
  <v-list-item
    class="mb-6 py-2 px-0"
    min-height="64"
  >
    <p class="highlight mb-0">
      <slot name="title"></slot>
    </p>
    <template #append>
      <slot name="append"></slot>
    </template>
  </v-list-item>
</template>
