<template>
  <v-form @submit.prevent="handleSubmit()">
    <DialogDefaultLayout>
      <template #content>
        <h1>{{ t('businessCaseChangeRequest.headline') }}</h1>
        <p>{{ t('businessCaseChangeRequest.copy', { bundleName: bundle?.name }) }}</p>
        <p class="highlight">{{ t('businessCaseChangeRequest.copySecondary') }}</p>

        <v-radio-group v-model="radioSelect">
          <v-radio :value="StatusTypes.ACTIVE">
            <template #label>
              <DefaultAvatar
                size="12"
                class="mx-2"
                :color="PlattformColors.SUCCESS"
              />
              <b>
                {{ t('businessCaseChangeRequest.accept') }}
              </b>
            </template>
          </v-radio>
          <v-radio :value="StatusTypes.INACTIVE">
            <template #label>
              <DefaultAvatar
                size="12"
                class="mx-2"
                :color="PlattformColors.ERROR"
              />
              <b>
                {{ t('businessCaseChangeRequest.decline') }}
              </b>
            </template>
          </v-radio>
        </v-radio-group>

        <v-textarea
          v-if="radioSelect === StatusTypes.INACTIVE"
          v-model="declineNote"
          :label="t('businessCaseChangeRequest.label')"
          variant="outlined"
        >
          <template #append-inner>
            <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
          </template>
        </v-textarea>
        <p>{{ t('businessCaseChangeRequest.copyLower') }}</p>
      </template>

      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :disabled="isSubmitButtonDisabled"
          :loading="loading"
        >
          {{ t('buttons.send') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayout>
  </v-form>
</template>

<script lang="ts" setup>
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { PropType } from 'vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { BundleResponse } from '@/models/Bundle'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const props = defineProps({
  bundle: { type: Object as PropType<BundleResponse>, default: undefined },
})

const { t } = useI18n()

const radioSelect = ref<string>()
const declineNote = ref<string>()
const loading = ref(false)

const isSubmitButtonDisabled = computed(() => !radioSelect.value || (radioSelect.value === StatusTypes.INACTIVE && !declineNote.value))

const emit = defineEmits(['approve', 'reject', 'cancel'])

/**
 * handleSubmit
 */
function handleSubmit(): void {
  loading.value = true
  if (radioSelect.value === StatusTypes.ACTIVE) {
    emit('approve', props.bundle?.id)
  } else if (radioSelect.value === StatusTypes.INACTIVE) {
    emit('reject', props.bundle?.id, declineNote.value)
  }
}
</script>
<style lang="scss" scoped>
:deep(.v-label) {
  opacity: 1;
}
</style>

<i18n lang="yaml">
de:
  businessCaseChangeRequest:
    headline: Änderungsanfrage
    copy: 'Dein Kunde hat folgende Anfrage zu der App {bundleName} gestellt:'
    copySecondary: Änderungen am Business Case.
    copyLower: Der Kunde wird per E-Mail über die Statusänderung benachrichtigt. Sobald der Status festgelegt ist, kann dieser nicht wieder zurückgesetzt
      werden.
    label: Begründung
    accept: Freigeben
    decline: Ablehnen
</i18n>
