<template>
  <v-app-bar
    flat
    height="96"
    class="bg-secondary"
  >
    <div class="nav-bar d-flex justify-end">
      <div class="d-flex">
        <DefaultButton
          v-if="resolvedTenant?.legalInformation.supportUrl"
          class="text-red"
          :color="PlattformColors.SECONDARY"
          :prepend-icon="Icons.SUPPORT_QUESTION"
          :variant="ButtonVariants.TEXT"
          :href="resolvedTenant.legalInformation.supportUrl"
          target="_blank"
          :data-cy="NavBarTest.SUPPORT_LINK"
        >
          {{ t('navbar.supportLink') }}
        </DefaultButton>

        <v-menu
          v-if="isLoggedIn()"
          transition="slide-y-transition"
        >
          <template #activator="{ props }">
            <DefaultButton
              v-bind="props"
              class="align-self-center mr-8"
              :variant="ButtonVariants.TEXT"
              :color="PlattformColors.PRIMARY"
              :prepend-icon="Icons.USER"
              :data-cy="NavBarTest.USER_MENU"
            >
              {{ fullName }}
            </DefaultButton>
          </template>
          <v-list density="compact">
            <v-list-item
              :to="{ name: Routes.MY_ORGANIZATIONS }"
              router
              exact
              flat
            >
              <template #prepend>
                <v-icon>{{ Icons.ORGANIZATIONS }}</v-icon>
              </template>
              <v-list-item-title>{{ t('navbar.profileMenu.organizations') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              @click="changePassword()"
            >
              <template #prepend>
                <v-icon>{{ Icons.LOCK }}</v-icon>
              </template>
              <v-list-item-title>{{ t('navbar.profileMenu.changePassword') }}</v-list-item-title>
            </v-list-item>
            <v-divider flat></v-divider>
            <v-list-item
              link
              @click="logoutFromKeycloak()"
            >
              <template #prepend>
                <v-icon>{{ Icons.LOG_OUT }}</v-icon>
              </template>
              <v-list-item-title>{{ t('navbar.profileMenu.logoutBtn') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <DefaultButton
          v-else
          class="align-self-center mr-8"
          :color="PlattformColors.LIGHT"
          :prepend-icon="Icons.USER"
          :data-cy="NavBarTest.LOG_IN"
          @click="loginToKeycloak()"
        >
          {{ t('navbar.login') }}
        </DefaultButton>

        <v-app-bar-nav-icon
          class="d-flex d-md-none nav-bar-button align-self-center mr-4"
          color="primary"
          @click.stop="navigationStore.toggleDrawer()"
        />
      </div>
    </div>
  </v-app-bar>
</template>

<script lang="ts" setup>
import { changePassword, isLoggedIn, loginToKeycloak, logoutFromKeycloak } from '@/plugins/keycloak'
import { useNavigationStore } from '@/store/navigations'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useActiveUserStore } from '@/store/activeUser'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import { useTenantStore } from '@/store/tenants'
import { NavBarTest } from '@/models/enums/test/NavBarTest'

const { t } = useI18n()

const activeUserStore = useActiveUserStore()
const navigationStore = useNavigationStore()
const tenantStore = useTenantStore()

const { fullName } = storeToRefs(activeUserStore)
const { resolvedTenant } = storeToRefs(tenantStore)
</script>

<style lang="scss" scoped>
@use 'sass:map';

.nav-bar {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media only screen and (max-width: #{map.get($container-max-widths, 'sm')}) {
    justify-content: space-between;
  }
}
</style>

<i18n lang="yaml">
de:
  navbar:
    supportLink: Support
    login: Login
    profileMenu:
      changePassword: Passwort ändern
      organizations: Organisationen
      logoutBtn: Abmelden
</i18n>
