import { defineStore } from 'pinia'

interface NavigationStateProperties {
  navDrawer: boolean
}

export const useNavigationStore = defineStore('navigations', {
  state: (): NavigationStateProperties => ({
    navDrawer: window.innerWidth > 959,
  }),
  actions: {
    toggleDrawer() {
      this.navDrawer = !this.navDrawer
    },
  },
})
