import { FileResponse, FileResponseWithNodeType } from '@/models/File'
import { FolderResponse } from '@/models/Folder'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { FilesAndFolderNodeTypes } from '@/models/enums/FilesAndFolderTypes'
import { Icons } from '@/models/enums/IconTypes'
import { i18n } from '@/plugins/i18n'
import { useAlertStore } from '@/store/alerts'

interface FileHelper {
  validateFile: (file: File, parentFolder: FolderResponse) => boolean
  validateProductFile: (file: File, parentFolder: FolderResponse) => boolean
  getFolderColor: (nodeType: FolderResponse['nodeType'] | FileResponseWithNodeType['nodeType']) => string
  getFolderSetIcon: (nodeType: FolderResponse['nodeType'] | FileResponseWithNodeType['nodeType']) => string
  getFilesInArray: (filesAndFolders: Array<FolderResponse>) => Array<FileResponseWithNodeType>
}

/**
 * useFileHelper
 * @return {FileHelper}
 */
export function useFileHelper(): FileHelper {
  const rootFolderSets = [
    {
      nodeType: FilesAndFolderNodeTypes.CONTRACT,
      icon: Icons.CLIPBOARD,
      color: PlattformColors.WARNING,
    },
    {
      nodeType: FilesAndFolderNodeTypes.INVOICE,
      icon: Icons.BANK_ACCOUNT,
      color: PlattformColors.SUCCESS,
    },
    {
      nodeType: FilesAndFolderNodeTypes.CREDENTIAL,
      icon: Icons.ROCKET,
      color: PlattformColors.ERROR,
    },
    {
      nodeType: FilesAndFolderNodeTypes.REPORT,
      icon: Icons.BAR_CHART_ALT,
      color: PlattformColors.CHANGE,
    },
    {
      nodeType: FilesAndFolderNodeTypes.MISC,
      icon: Icons.OPTIONS_HORIZONTAL,
      color: PlattformColors.INFO,
    },
    {
      nodeType: FilesAndFolderNodeTypes.README,
      icon: Icons.DOCUMENT,
      color: PlattformColors.CHANGE,
    },
    {
      nodeType: FilesAndFolderNodeTypes.API,
      icon: Icons.APIS,
      color: PlattformColors.SUCCESS,
    },
  ]

  const allowedFiles = {
    readme: ['md'],
    api: ['json', 'yaml', 'yml'],
    misc: ['pdf', 'jpeg', 'jpg', 'png', 'gif', 'json'],
  }

  /**
   * check if file is allowed (filename and filesize)
   * @param {File} file file to check
   * @param {FolderResponse} parentFolder folder that file is uploaded to
   * @return {boolean} true if file is allowed
   */
  function validateFile(file: File, parentFolder: FolderResponse): boolean {
    const alertStore = useAlertStore()

    if (parentFolder.files?.find((f) => f.name === file.name)) {
      alertStore.add({
        type: AlertTypes.ERROR,
        text: i18n.t('fileUpload.sameName'),
      })
      return false
    }
    if (file.size > Math.pow(2, 20) * 20) {
      alertStore.add({
        type: AlertTypes.ERROR,
        text: i18n.t('fileSizeError'),
      })
      return false
    }
    return true
  }

  /**
   * check if product file is allowed (filetype, filename and filesize)
   * @param {File} file file to check
   * @param {FolderResponse} parentFolder folder that file is uploaded to
   * @return {boolean} true if file is allowed
   */
  function validateProductFile(file: File, parentFolder: FolderResponse): boolean {
    const alertStore = useAlertStore()
    const fileType = file.name.split('.').pop()?.toLowerCase()

    if (parentFolder.nodeType === FilesAndFolderNodeTypes.README && fileType && !allowedFiles.readme.includes(fileType)) {
      alertStore.add({
        type: AlertTypes.ERROR,
        text: i18n.t('fileUpload.notMd'),
      })
      return false
    } else if (parentFolder.nodeType === FilesAndFolderNodeTypes.API && fileType && !allowedFiles.api.includes(fileType)) {
      alertStore.add({
        type: AlertTypes.ERROR,
        text: i18n.t('fileUpload.notJson'),
      })
      return false
    } else if (
      (parentFolder.nodeType === FilesAndFolderNodeTypes.MISC || parentFolder.nodeType === FilesAndFolderNodeTypes.CUSTOM) &&
      fileType &&
      !allowedFiles.misc.includes(fileType)
    ) {
      alertStore.add({
        type: AlertTypes.ERROR,
        text: i18n.t('fileUpload.notMisc'),
      })
      return false
    }
    return validateFile(file, parentFolder)
  }

  /**
   * return color for folder depending on nodeType
   * @param {FolderResponse.nodeType | FileResponseWithNodeType.nodeType} nodeType nodeType of file or folder
   * @return {ColorSets} color for nodeType
   */
  function getFolderColor(nodeType: FolderResponse['nodeType'] | FileResponseWithNodeType['nodeType']): string {
    return rootFolderSets.find((item) => item.nodeType === nodeType)?.color || PlattformColors.INFO
  }

  /**
   * return icon for folder depending on nodeType
   * @param {FolderResponse.nodeType | FileResponseWithNodeType.nodeType} nodeType nodeType of file or folder
   * @return {string} icon for nodeType
   */
  function getFolderSetIcon(nodeType: FolderResponse['nodeType'] | FileResponseWithNodeType['nodeType']): string {
    return rootFolderSets.find((item) => item.nodeType === nodeType)?.icon || Icons.SUPPORT_QUESTION
  }

  /**
   * return all files in filesAndFolders tree as an array
   * @param {Array<FolderResponse>} filesAndFolders filesAndFolders tree
   * @return {Array<FileResponseWithNodeType>} files in array
   */
  function getFilesInArray(filesAndFolders: Array<FolderResponse>): Array<FileResponseWithNodeType> {
    let filesResult: Array<FileResponseWithNodeType> = []
    // for each root folder and nodeType
    filesAndFolders.forEach((folder: FolderResponse) => getFilesWithNodeType(folder.nodeType, folder))

    /**
     * getFilesWithNodeType
     * @param {FolderResponse.nodeType} nodeType
     * @param {FolderResponse} folder
     */
    function getFilesWithNodeType(nodeType: FolderResponse['nodeType'], folder: FolderResponse): void {
      if (folder.files && folder.files.length) {
        // set root-Folder nodeType to file
        const filesWithNodeTypes: FileResponseWithNodeType[] = []
        folder.files.forEach((file: FileResponse) => {
          filesWithNodeTypes.push({ ...file, nodeType })
        })

        // append to files
        filesResult = [...filesResult, ...filesWithNodeTypes]
      }

      if (folder.subfolders && folder.subfolders.length) {
        // loop recursive over all subFolders with root nodeType and get the files
        folder.subfolders.forEach((subFolder: FolderResponse) => getFilesWithNodeType(nodeType, subFolder))
      }
    }

    return filesResult
  }

  return {
    validateFile,
    validateProductFile,
    getFolderColor,
    getFolderSetIcon,
    getFilesInArray,
  }
}
