interface LocalStorage {
  setItem: (key: string, value: string) => void
  getItem: (key: string) => string | null
  removeItem: (key: string) => void
}

/**
 * useLocalStorage
 * @return {LocalStorage}
 */
export function useLocalStorage(): LocalStorage {
  /**
   * setItem
   * @param {key} key
   * @param {value} value
   */
  function setItem(key: string, value: string): void {
    localStorage.setItem(key, value)
  }

  /**
   * removeItem
   * @param {key} key
   */
  function removeItem(key: string): void {
    localStorage.removeItem(key)
  }

  /**
   * getItem
   * @param {key} key
   * @return {string}
   */
  function getItem(key: string): string | null {
    return localStorage.getItem(key)
  }

  return {
    setItem,
    getItem,
    removeItem,
  }
}
