<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('organizationOverview.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.DETAILS }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.ORGANIZATIONS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('organizationOverview.organizationInfoTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('organizationOverview.organizationInfoTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col>
      <NavigationTeaser
        v-if="activeOrganization?.provider"
        :to="{ name: Routes.ACCESS_DEFINITIONS }"
      >
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.AUTHENTICATION }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('organizationOverview.accessTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('organizationOverview.accessTeaser.subtitle') }}
        </template>
      </NavigationTeaser>

      <NavigationTeaser
        v-else
        :to="{ name: Routes.CONTACT_PERSONS }"
      >
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.USER }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('organizationOverview.contactPersonTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('organizationOverview.contactPersonTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.USERS }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.USERS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('organizationOverview.memberTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('organizationOverview.memberTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
    <v-col>
      <NavigationTeaser :to="{ name: Routes.TECHNICAL_USERS }">
        <template #prepend>
          <DefaultAvatar
            size="48"
            color="grey-lighten-4"
          >
            <v-icon size="24">{{ Icons.USER_SETTINGS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>
          {{ t('organizationOverview.technicalUserTeaser.title') }}
        </template>
        <template #subtitle>
          {{ t('organizationOverview.technicalUserTeaser.subtitle') }}
        </template>
      </NavigationTeaser>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useMyOrganizationStore } from '@/store/myOrganizations'

import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import NavigationTeaser from '@/components/baseComponents/teasers/NavigationTeaser.vue'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()
const { activeOrganization } = storeToRefs(myOrganizationStore)
</script>
<style lang="scss" scoped></style>
<i18n lang="yaml">
de:
  organizationOverview:
    headline: Meine Organisation
    organizationInfoTeaser:
      title: Organisationsdaten
      subtitle: Verwaltung von Organisationsnname, Anschrift, etc.
    memberTeaser:
      title: Nutzerverwaltung
      subtitle: Verwaltung von Nutzern, Teams und Berechtigungen
    technicalUserTeaser:
      title: Technische Nutzer
      subtitle: Access Token für die Nutzung der Developer-Portal-API
    accessTeaser:
      title: Authentifizierungsmethoden
      subtitle: Authentifizierungsmethoden konfigurieren
    contactPersonTeaser:
      title: Kontaktpersonen
      subtitle: Anlegen und verwalten von Kontaktpersonen
</i18n>
