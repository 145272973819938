<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit')">
    <template #content>
      <h1>{{ t('team.delete.headline') }}</h1>
      <p>{{ t('team.delete.copy') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit')"
        >{{ t('buttons.delete') }}</DefaultButton
      >

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
        >{{ t('buttons.cancel') }}</DefaultButton
      >
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  team:
    delete:
      headline: Team Löschen?
      copy: Möchtest du das Team wirklich löschen? Dies kann nicht rückgängig gemacht werden!
</i18n>
