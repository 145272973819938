<!-- TODO rework team select -->
<template>
  <div class="team-select">
    <DefaultButton
      class="rounded-0 bg-white h-100 w-100"
      :color="PlattformColors.PRIMARY"
      :variant="ButtonVariants.OUTLINED"
      :append-icon="Icons.CHEVRON_DOWN"
      @click="showOverlay()"
      >{{ buttonText }}</DefaultButton
    >

    <v-overlay
      v-model="overlayShow"
      class="justify-center align-center"
      color="primary"
      @after-leave="resetOverlay"
    >
      <v-card
        :max-width="overlayWidth"
        variant="flat"
        rounded="0"
      >
        <div class="overlay-content bg-white">
          <v-row>
            <v-col
              :cols="headlineCols"
              class="overlay-content__headline"
            >
              <h1>
                {{ t('selectOverlay.headlineTeams') }}
              </h1>
              <p
                light
                class="overlay-content__copy"
              >
                {{ t('selectOverlay.copyTeams') }}
              </p>
            </v-col>
            <v-col
              :cols="imageCols"
              class="overlay-content__image"
            >
              <v-img :src="overlayTeams"></v-img>
            </v-col>
          </v-row>
          <div>
            <DefaultCheckbox
              v-model="tempSingleCheckbox"
              :label="t('selectOverlay.admin')"
            />
            <v-divider />
            <v-item-group
              v-model="tempSelect"
              multiple
              class="overlay-content__list"
            >
              <v-item
                v-for="item in props.teams"
                :key="item.id"
                v-slot="{ isSelected, selectedClass, toggle }"
                :value="item.id"
              >
                <v-list-item
                  :class="selectedClass"
                  :disabled="tempSingleCheckbox"
                  @click="toggle"
                >
                  <template #prepend>
                    <v-list-item-action start>
                      <DefaultCheckbox :model-value="isSelected" />
                    </v-list-item-action>
                  </template>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-item>
            </v-item-group>
          </div>
          <div class="d-flex justify-space-between">
            <DefaultButton
              :color="PlattformColors.PRIMARY"
              :variant="ButtonVariants.OUTLINED"
              @click="closeOverlay"
            >
              {{ t('buttons.cancel') }}
            </DefaultButton>

            <DefaultButton
              :color="PlattformColors.PRIMARY"
              @click="updateUser"
            >
              {{ t('buttons.save') }}
            </DefaultButton>
          </div>
        </div>
      </v-card>
    </v-overlay>
  </div>
</template>

<script lang="ts" setup>
import { Team } from '@/models/Team'
import { User } from '@/models/User'
import { computed, PropType, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import overlayTeams from '@/assets/illustrations/overlay_teams.svg'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultCheckbox from '../baseComponents/checkboxes/DefaultCheckbox.vue'

const props = defineProps({
  teams: { type: Array<Team>, required: true },
  user: { type: Object as PropType<Partial<User>>, required: true },
  e2eId: { type: String, default: '' },
})

const emit = defineEmits(['update'])

const { t } = useI18n()

const overlayShow = ref(false)
const tempSingleCheckbox = ref<User['admin']>()
const tempSelect = ref<string[]>()

const bigbox = computed(() => props.teams.length > 10)
const overlayWidth = computed(() => (bigbox.value ? 960 : 700))
const headlineCols = computed(() => (bigbox.value ? 10 : 9))
const imageCols = computed(() => (bigbox.value ? 2 : 3))

const buttonText = computed(() => {
  if (props.user.admin) {
    return t('selectOverlay.admin')
  }
  if (!props.user.teams || props.user.teams.length === 0) {
    return t('selectOverlay.empty')
  } else {
    return t('selectOverlay.selected', { count: props.user.teams.length })
  }
})

watch(tempSingleCheckbox, (value) => {
  if (value) tempSelect.value = []
})

/**
 * showOverlay
 */
function showOverlay(): void {
  tempSelect.value = props.user.teams || []
  tempSingleCheckbox.value = props.user.admin
  overlayShow.value = true
}

/**
 * closeOverlay
 */
function closeOverlay(): void {
  overlayShow.value = false
}

/**
 * resetOverlay
 */
function resetOverlay(): void {
  tempSelect.value = []
  tempSingleCheckbox.value = false
}

/**
 * updateUser
 */
function updateUser(): void {
  emit('update', { ...props.user, admin: tempSingleCheckbox.value, teams: tempSelect.value })
  closeOverlay()
}
</script>
<style lang="scss" scoped>
.team-select {
  width: 100%;
  height: 100%;
  font-weight: normal;
}

.overlay-content {
  padding: 2.5rem;

  &__list {
    display: flex;
    flex-flow: column wrap;
    max-height: 16rem;
    margin-bottom: 2rem;
    overflow-x: scroll;

    .v-list-item {
      flex: 0;
      padding-right: 0.5rem;
      width: 18.3rem;

      .v-list-item__action {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>

<i18n lang="yaml">
de:
  selectOverlay:
    headlineTeams: Teams oder Rolle zuweisen
    copyTeams: Du kannst einem Nutzer entweder die Rolle “Admin” zuweisen oder ihn zu Teams hinzufügen. Durch das Hinzufügen zu einem Team erbt der Nutzer
      alle Berechtigungen des Teams.
    all: Alle
    admin: Admin
    empty: Keine ausgewählt
    allElems: Alle Teams
    selected: 'Teams: {count}'
    lastAdmin: Du kannst den letzten Admin einer Organisation nicht entfernen!
</i18n>
