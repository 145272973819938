export enum AccessExchangeTypes {
  MANUAL_EXCHANGE = 'MANUAL_EXCHANGE',
  GENERATE_TOKEN = 'GENERATE_TOKEN',
}

export enum AccessTypes {
  ACCESS_TOKEN = 'accessToken',
  NO_RESSOURCE = 'noResource',
}

export enum AccessHashAlgorithms {
  SHA256 = 'SHA256',
}