import { UserSelfResponse } from '@/models/User'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useMyOrganizationStore } from './myOrganizations'
import { StoreName } from '@/models/enums/StoreName'

export interface ActiveUserStateProperties {
  activeUser?: UserSelfResponse
}

export const useActiveUserStore = defineStore(StoreName.ACTIVE_USER, {
  state: (): ActiveUserStateProperties => ({
    activeUser: undefined,
  }),
  actions: {
    async GET(): Promise<void | undefined> {
      try {
        const myOrganizationStore = useMyOrganizationStore()

        const { data }: { data: UserSelfResponse } = await useAxios.get(`/api/users/self`)
        this.activeUser = data
        myOrganizationStore.setOrganizations(data.userOrganizations)
      } catch {
        return Promise.reject()
      }
    },
  },
  getters: {
    fullName(state) {
      return state.activeUser ? `${state.activeUser.firstname} ${state.activeUser.lastname}` : ''
    },
  },
})
