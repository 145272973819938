<template>
  <LinkTeaser>
    <template #title>
      {{ t('consumerRequestsTeaser.headline') }}
    </template>
    <template #text>
      <v-list v-if="bundles.length">
        <v-list-item
          v-for="(bundle, i) in bundles.slice(0, 3)"
          :key="bundle.organizationId"
          :to="{
            name: Routes.PROVIDER_BUNDLES_PRODUCTS,
            params: { bundleId: bundle.id },
          }"
          class="pa-0"
          :class="{ 'mt-3': i > 0 }"
          :data-cy="ConsumerRequestsTeaserTest.REQUESTED_BUNDLE_ITEM"
        >
          <template #prepend>
            <BundleAvatar :size="48" />
          </template>
          <template #title>
            <p class="sm mb-0 highlight">{{ bundle.name }}</p>
          </template>
          <template #subtitle>
            <p class="xs mb-0">{{ bundle.organizationName }}</p>
          </template>
        </v-list-item>
      </v-list>
      <div
        v-else
        class="d-flex justify-center align-center flex-column h-100 border-grey bg-secondary rounded-lg pa-8"
      >
        <v-icon class="mb-4">{{ Icons.CIRCLE_INFORMATION }}</v-icon>
        <p class="mb-0">{{ t('consumerRequestsTeaser.placeholder') }}</p>
      </div>
    </template>
  </LinkTeaser>
</template>

<script lang="ts" setup>
import { ConsumerRequestsTeaserTest } from '@/models/enums/test/ConsumerRequestsTeaserTest'
import { useI18n } from 'vue-i18n'
import { BundleResponse } from '@/models/Bundle'
import { OrganizationPublicResponse } from '@/models/Organization'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'

interface ExtendedBundles extends BundleResponse {
  organizationName?: OrganizationPublicResponse['name']
}

const { t } = useI18n()

defineProps({
  bundles: { type: Array<ExtendedBundles>, required: true },
})
</script>
<i18n lang="yaml">
de:
  consumerRequestsTeaser:
    headline: Deine Kundenanfragen
    placeholder: Du hast noch keine Kundenanfragen
</i18n>
