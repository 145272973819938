<template>
  <div class="d-flex flex-column justify-center bg-white pa-16">
    <LottieAnimation
      class="waiting-animation"
      :loop="true"
      :autoplay="true"
      :animation-data="animationData"
    />
    <h3 class="text-center mt-8">{{ t('generateCredentials.loadingText') }}</h3>
  </div>
</template>

<script lang="ts" setup>
import { LottieAnimation } from 'lottie-web-vue'
import animationData from '@/assets/animation/wallis_logo-animation_lottie.json'
import { useI18n } from 'vue-i18n'

const emit = defineEmits(['loadingDone'])
const { t } = useI18n()

setTimeout(() => {
  emit('loadingDone')
}, 2000)
</script>

<style lang="scss" scoped>
.waiting-animation {
  height: 15rem;
}
</style>

<i18n lang="yaml">
de:
  generateCredentials:
    loadingText: Bitte habe einen Moment Geduld, wir generieren deinen Access Token...
</i18n>
