<template>
  <BackButton />
  <GeneralPageTitle>
    <template #title>
      {{ t('platformInformation.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <v-card
        class="h-100"
        variant="flat"
      >
        <template #prepend>
          <DefaultAvatar
            class="mr-4"
            color="grey-lighten-4"
          >
            <v-icon>{{ Icons.GLOBE }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title
          ><h4 class="mt-4">{{ t('platformInformation.domain.title') }}</h4></template
        >
        <template #text>
          <div class="h-100">
            <p class="sm mb-7">{{ t('platformInformation.domain.copy') }}</p>

            <v-text-field
              :label="t('platformInformation.domain.input.label')"
              readonly
              variant="outlined"
              hide-details
              :model-value="activeTenant?.subdomainName || t('platformInformation.domain.exampleValue')"
            >
              <template #append-inner>
                <CopyToClipBtn :copy-value="activeTenant?.subdomainName || t('platformInformation.domain.exampleValue')" />
              </template>
            </v-text-field>
            <p class="xs">{{ t('platformInformation.domain.example') }}</p>
          </div>
        </template>
      </v-card>
    </v-col>
    <v-col>
      <v-card
        class="h-100"
        variant="flat"
      >
        <template #prepend>
          <DefaultAvatar
            class="mr-4"
            color="grey-lighten-4"
          >
            <v-icon>{{ Icons.BOLD }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title
          ><h4 class="mt-4">{{ t('platformInformation.design.title') }}</h4></template
        >
        <template #text>
          <p class="sm mb-7">{{ t('platformInformation.design.copy') }}</p>

          <v-text-field
            class="mb-7"
            :label="t('platformInformation.design.input.label')"
            readonly
            variant="outlined"
            hide-details
            :model-value="activeTenant?.theming.primaryColor"
          >
            <template #append-inner>
              <v-avatar
                size="32"
                :color="activeTenant?.theming.primaryColor"
              >
              </v-avatar>
            </template>
          </v-text-field>

          <div class="d-flex justify-space-between">
            <PreviewImage
              class="mr-4"
              :title="t('platformInformation.design.previewLogo.title')"
              :image-src="activeTenant?.theming.logo"
              :background-color="PlattformColors.PRIMARY"
            />
            <PreviewImage
              :title="t('platformInformation.design.previewFavicon.title')"
              :image-src="activeTenant?.theming.favicon"
            />
          </div>
        </template>
      </v-card>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { Icons } from '@/models/enums/IconTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import BackButton from '@/components/layout/BackButton.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import PreviewImage from '@/components/baseComponents/images/PreviewImage.vue'
import { useTenantStore } from '@/store/tenants'

const tenantStore = useTenantStore()
const { t } = useI18n()
const { activeTenant } = storeToRefs(tenantStore)
</script>
<i18n lang="yaml">
de:
  platformInformation:
    headline: Plattforminformationen
    domain:
      title: Subdomain
      copy: Damit dein Dev Portal unter deiner eigenen Domain erreichbar ist, muss der Administrator deiner Domain ein sogenannter CNAME Record in den
        DNS-Einstellungen für die Domain eintragen
      input:
        label: Subdomain
      example: 'Beispiel CNAME Record: "[mandant-name-slug].console.wallis.de"'
      exampleValue: '[mandant-name-slug].console.wallis.de'
    design:
      title: Design
      copy: Das Aussehen deines Dev Portal wurde zusammen mit dir bei der Einrichtung festgelegt. Folgende Parameter können über den Support der Wallis GmbH
        geändert werden.
      input:
        label: Main Color
      previewLogo:
        title: Logo
      previewFavicon:
        title: Favicon
</i18n>
