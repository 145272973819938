<template>
  <div class="content-container">
    <div class="d-flex align-center mb-8">
      <h2 class="mb-0 flex-grow-1">{{ t('businessCaseList.headline') }}</h2>
      <div class="d-flex align-center">
        <v-tooltip
          v-if="isAnyBusinessCasePending"
          location="bottom"
        >
          {{ isProvider ? t('businessCaseList.pendingProvider') : t('businessCaseList.pendingConsumer') }}
          <template #activator="{ props }">
            <DefaultAvatar
              size="24"
              :color="PlattformColors.CHANGE"
              class="mr-2"
            >
              <v-icon
                size="16"
                v-bind="props"
              >
                {{ Icons.UPDATE }}
              </v-icon>
            </DefaultAvatar>
          </template>
        </v-tooltip>
        <iconButton
          v-if="bundle?.contractStatus === StatusTypes.DRAFT || (bundle?.contractStatus === StatusTypes.APPROVED && !isProvider)"
          :icon="Icons.EDIT"
          @click="emit('openEditDialog')"
        ></iconButton>
      </div>
    </div>
    <div class="w-75">
      <template
        v-for="businessCase in bundle?.businessCases"
        :key="businessCase.businessCaseKey"
      >
        <p class="highlight">{{ getBundleBusinessCaseName(businessCase.businessCaseKey) }}</p>
        <p class="mb-8 text-break">{{ businessCase.pendingText || businessCase.text }}</p>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useBundleApprovalHelper } from '@/composables/useBundleApprovalHelper'
import { useBundleBusinessCaseHelper } from '@/composables/useBundleBusinessCaseHelper'
import { BundleResponse } from '@/models/Bundle'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { PropType, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { BundleBusinessCaseResponse } from '@/models/Bundle'
import iconButton from '@/components/baseComponents/buttons/IconButton.vue'

const { t } = useI18n()

const { getBundleBusinessCaseName } = useBundleBusinessCaseHelper()
const { getMostRecentApproval } = useBundleApprovalHelper()

const emit = defineEmits(['openEditDialog'])

const componentProps = defineProps({
  bundle: { type: Object as PropType<BundleResponse>, default: undefined },
  isProvider: { type: Boolean, default: false },
})

const isAnyBusinessCasePending = computed(
  () =>
    !!componentProps.bundle?.businessCases.find((businessCase: BundleBusinessCaseResponse) => businessCase.pendingText) &&
    getMostRecentApproval(componentProps.bundle?.approvals)?.approvalStatus === StatusTypes.REQUESTED
)
</script>

<i18n lang="yaml">
de:
  businessCaseList:
    headline: Business Case
    pendingConsumer: Deine Business Case Änderung wurde eingereicht.
    pendingProvider: Eine Business Case Änderung wurde eingereicht.
</i18n>
