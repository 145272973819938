<template>
  <DefaultList>
    <ListElement
      v-for="bundle in bundles"
      :key="bundle.id"
      :to="bundleRoute(bundle.id)"
    >
      <template #prepend>
        <BundleAvatar />
      </template>
      <template #title>
        <h3 class="mb-1">{{ bundle.name }}</h3>
      </template>
      <template #subtitle>
        <span v-if="bundle.providerOrganization.name"> {{ bundle.providerOrganization.name }} </span>
        <v-divider
          vertical
          length="12"
          thickness="1"
          color="black"
          opacity=".5"
          class="mx-2"
        />
        <span v-if="bundle.createdAt">
          {{
            t('createdAt', {
              d: format(new Date(bundle.createdAt), 'dd.MM.yyyy'),
            })
          }}
        </span>
      </template>
      <template #append>
        <StatusChip
          class="mr-3"
          :color="getStatusSetFromStatusKey(bundle.contractStatus)?.color"
          :icon="getStatusSetFromStatusKey(bundle.contractStatus)?.icon"
          :outlined="getStatusSetFromStatusKey(bundle.contractStatus)?.outlined"
        >
          {{ t(`bundleStatus.${bundle.contractStatus}`) }}
        </StatusChip>
        <AccessStatusToggleButton
          v-if="bundle.contractStatus === StatusTypes.APPROVED"
          :toggle-btn="bundle.accessStatus?.access"
          :tooltips="{ active: t('bundlesOverview.toggleBtn.tooltips.active'), inactive: t('bundlesOverview.toggleBtn.tooltips.inactive') }"
        />
      </template>
      <template #expand>
        <div class="pl-4">
          <ProductList
            :products="bundle.bundleProducts"
            @handle-product-click="(productId: BundleProductResponse['id']) => handleProductClick(productId)"
          />
        </div>
      </template>
    </ListElement>
  </DefaultList>
</template>

<script lang="ts" setup>
import { BundleProductResponse, BundleResponse } from '@/models/Bundle'
import { useI18n } from 'vue-i18n'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { format } from 'date-fns'
import ProductList from '@/components/products/ProductList.vue'
import { Routes } from '@/models/enums/Routes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import AccessStatusToggleButton from './AccessStatusToggleButton.vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import StatusChip from '@/components/StatusChip.vue'
import BundleAvatar from '@/components/bundles/BundleAvatar.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'

const componentProps = defineProps({
  bundles: { type: Array<BundleResponse>, required: true },
  bundleLink: { type: String, required: true },
})

const { t } = useI18n()
const router = useRouter()

const { getStatusSetFromStatusKey } = useStatusHelper()

/**
 * @param {string} bundleId
 * @return {RouteLocationRaw}
 */
function bundleRoute(bundleId: BundleResponse['id']): RouteLocationRaw {
  return componentProps.bundleLink === OrganizationTypes.CONSUMER
    ? { name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId } }
    : { name: Routes.PROVIDER_BUNDLES_PRODUCTS, params: { bundleId } }
}

/**
 * handleProductClick
 * @param {productId} productId
 */
function handleProductClick(productId: BundleProductResponse['id']): void {
  if (componentProps.bundleLink === OrganizationTypes.CONSUMER) {
    router.push({ name: Routes.CONSUMER_PRODUCTS_INFOS, params: { productId } })
  } else if (componentProps.bundleLink === OrganizationTypes.PROVIDER) {
    router.push({ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId } })
  }
}
</script>

<i18n lang="yaml">
de:
  bundlesOverview:
    toggleBtn:
      tooltips:
        active: Zugriff auf die App ist freigegeben.
        inactive: Zugriff auf die App ist gesperrt.
</i18n>
