<template>
  <LinkTeaser :link="Routes.USERS">
    <template #title>{{ t('teamTeaser.headline') }}</template>
    <template #subtitle>
      <span>{{ t('teamTeaser.teamCount', { count: teamCount }) }}</span>
      <span>{{ t('teamTeaser.separator') }}</span>
      <span>{{ t('teamTeaser.memberCount', { count: memberCount }) }}</span>
    </template>
  </LinkTeaser>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { Routes } from '@/models/enums/Routes'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'

const { t } = useI18n()

defineProps({
  teamCount: { type: Number, required: true },
  memberCount: { type: Number, required: true },
})
</script>
<i18n lang="yaml">
de:
  teamTeaser:
    headline: Dein Team
    teamCount: '{count} Team | {count} Teams'
    separator: ' – '
    memberCount: '{count} Nutzer'
</i18n>
