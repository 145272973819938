<template>
  <LinkTeaser
    :link="mapDocsURL()"
    :link-type="LinkTypes.EXTERNAL"
  >
    <template #title>
      <p class="mb-0 highlight">{{ t('docsTeaser.headline') }}</p>
    </template>
  </LinkTeaser>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { LinkTypes } from '@/models/enums/LinkTypes'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'

const { t } = useI18n()

/**
 * get dynamic URL environment specific at runtime
 * @return {string} URL
 */
function mapDocsURL(): string {
  const docsURL = window.location.origin.replace(window.location.host, 'docs.' + window.location.host)
  return docsURL
}
</script>
<i18n lang="yaml">
de:
  docsTeaser:
    headline: Developer Portal Dokumentation
</i18n>
