export enum Permissions {
  ADMIN = 'ADMIN',
  UPDATE = 'UPDATE',
  READ = 'READ',
  READ_WRITE = 'READ_WRITE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  DOWNLOAD = 'DOWNLOAD',
  CREATE_FOLDER = 'CREATE_FOLDER',
  READ_FOLDER = 'READ_FOLDER',
  UPDATE_UPDATE = 'UPDATE_UPDATE',
  DELETE_FOLDER = 'DELETE_FOLDER',
  CREATE_FILE = 'CREATE_FILE',
  FILE_READ = 'READ_FILE',
  UPDATE_FILE = 'UPDATE_FILE',
  DELETE_FILE = 'DELETE_FILE',
}
