<template>
  <CenteredTeaser>
    <template #title>
      <DefaultAvatar
        size="80"
        :color="PlattformColors.SECONDARY"
      >
        <v-icon size="40">{{ Icons.ORGANIZATIONS }}</v-icon>
      </DefaultAvatar>
    </template>
    <template #text>
      <h3>{{ t('organizationCreateTeaser.headline') }}</h3>
      <p class="highlight mb-0">{{ t('organizationCreateTeaser.copy.bold') }}</p>
      <p>{{ t('organizationCreateTeaser.copy.standard') }}</p>
    </template>
    <template #actions>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :prepend-icon="Icons.CIRCLE_ADD"
        class="mb-6"
        @click="emit('openCreateOrganizationDialog')"
      >
        {{ t('organizationCreateTeaser.button') }}
      </DefaultButton>
    </template>
  </CenteredTeaser>
</template>
<script lang="ts" setup>
import CenteredTeaser from '@/components/baseComponents/teasers/CenteredTeaser.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { useI18n } from 'vue-i18n'
import { PlattformColors } from '@/models/enums/ColorSets'

const emit = defineEmits(['openCreateOrganizationDialog'])

const { t } = useI18n()
</script>
<i18n lang="yaml">
de:
  organizationCreateTeaser:
    headline: Organisation erstellen und alle Funktionen nutzen
    copy:
      bold: Deine Organisation ist bereits vorhanden?
      standard: Lass Dich von Deinem Admin zur Organisation hinzufügen.
    button: Organisation erstellen
</i18n>
