<template>
  <div class="create-teaser_wrapper bg-white d-flex pa-16 w-100">
    <div class="d-flex my-0 mx-auto w-50 align-center flex-column text-center">
      <DefaultAvatar
        size="64"
        class="mb-8"
        color="grey-lighten-4"
      >
        <v-icon size="32">{{ icon }}</v-icon>
      </DefaultAvatar>
      <h3 class="create-teaser_headline">{{ headline }}</h3>
      <p v-if="copy">{{ copy }}</p>

      <DefaultButton
        class="mt-4"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit')"
        >{{ btnText }}</DefaultButton
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'

const emit = defineEmits(['submit'])

defineProps({
  icon: {
    type: String,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  copy: {
    type: String,
    default: '',
  },
  btnText: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>
<style lang="scss" scoped>
.create-teaser {
  &_wrapper {
    border-radius: 0.5rem;
    background-image: repeating-linear-gradient(
        -2deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 0.625rem
      ),
      repeating-linear-gradient(
        88deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 1.625rem
      ),
      repeating-linear-gradient(
        178deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 1.625rem
      ),
      repeating-linear-gradient(
        268deg,
        rgb(var(--v-theme-primary)),
        rgb(var(--v-theme-primary)) 1rem,
        transparent 1rem,
        transparent 1.625rem,
        rgb(var(--v-theme-primary)) 1.625rem
      );
    background-size:
      0.0625rem 100%,
      100% 0.0625rem,
      0.0625rem 100%,
      100% 0.0625rem;
    background-position:
      0 0,
      0 0,
      100% 0,
      0 100%;
    background-repeat: no-repeat;
  }
}
</style>
