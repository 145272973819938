export enum FilesAndFolderTypes {
  ROOT_FOLDER = 'rootfolder',
  SUB_FOLDER = 'subfolder',
  LAST_FOLDER = 'lastfolder',
  NEW_FOLDER = 'newFolder',
  FILE = 'file',
}

export enum FilesAndFolderNodeTypes {
  CONTRACT = 'CONTRACT',
  INVOICE = 'INVOICE',
  CREDENTIAL = 'CREDENTIAL',
  REPORT = 'REPORT',
  MISC = 'MISC',
  README = 'README',
  API = 'API',
  CUSTOM = 'CUSTOM',
  NEW_FOLDER = 'NEW_FOLDER',
}
