<template>
  <v-menu
    transition="scroll-y-transition"
    :offset="[0]"
    width="255"
  >
    <template #activator="{ props, isActive }">
      <v-list>
        <v-list-item
          v-if="activeOrganization || activeTenant"
          class="my-4"
          v-bind="props"
        >
          <template #prepend>
            <DefaultAvatar
              size="32"
              :color="PlattformColors.LIGHT"
            >
              <v-icon size="20">{{ activeTenant ? Icons.ORGANIZATION_SETTINGS : Icons.ORGANIZATIONS }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #title>
            {{ activeTenant ? t('organizationType.tenant') : activeOrganization?.name }}
          </template>
          <template
            v-if="!activeTenant"
            #subtitle
          >
            <template v-if="activeOrganization?.provider">{{ t('organizationType.provider') }} </template>
            <template v-else>{{ t('organizationType.consumer') }} </template>
          </template>
          <template #append>
            <v-icon :class="[{ 'rotate-180': isActive }, 'ml-2']">{{ Icons.CHEVRON_DOWN }}</v-icon>
          </template>
        </v-list-item>
        <v-list-item
          v-else
          v-bind="props"
        >
          <template #title>
            {{ t('selectBox.choose') }}
          </template>
          <template #append>
            <v-icon size="24">{{ Icons.ARROW_RIGHT }}</v-icon>
          </template>
        </v-list-item>
      </v-list>
    </template>
    <v-list class="bg-primary-darken-1">
      <v-list-item
        v-if="groupedTenantOrganization"
        class="my-4"
        @click="switchOrganization(groupedTenantOrganization)"
      >
        <template #prepend>
          <DefaultAvatar
            size="32"
            :color="PlattformColors.LIGHT"
          >
            <v-icon size="20">{{ Icons.ORGANIZATION_SETTINGS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>{{ t('organizationType.tenant') }}</template>
        <template #append>
          <v-icon
            v-if="activeTenant"
            size="20"
            >{{ Icons.CHECK }}</v-icon
          >
        </template>
      </v-list-item>
      <v-divider
        v-if="groupedTenantOrganization"
        class="mb-2 ma-auto"
        color="white"
        opacity=".62"
        width="80%"
      />
      <v-list-subheader
        v-if="groupedProviderOrganizations.length"
        class="text-white"
        >API anbieten</v-list-subheader
      >
      <v-list-item
        v-for="(providerOrganization, i) in groupedProviderOrganizations"
        :key="i"
        variant="flat"
        class="bg-transparent"
        @click="switchOrganization(providerOrganization)"
      >
        <template #prepend>
          <DefaultAvatar
            size="32"
            :color="PlattformColors.LIGHT"
          >
            <v-icon size="20">{{ Icons.ORGANIZATIONS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>{{ providerOrganization.name }}</template>
        <template #append>
          <v-icon
            v-if="activeOrganization?.id === providerOrganization.organizationId"
            size="20"
          >
            {{ Icons.CHECK }}
          </v-icon>
        </template>
      </v-list-item>
      <v-list-subheader
        v-if="groupedConsumerOrganizations.length"
        class="mt-4 text-white"
        >API nutzen</v-list-subheader
      >
      <v-list-item
        v-for="(consumerOrganization, i) in groupedConsumerOrganizations"
        :key="i"
        variant="flat"
        class="bg-transparent"
        @click="switchOrganization(consumerOrganization)"
      >
        <template #prepend>
          <DefaultAvatar
            size="32"
            :color="PlattformColors.LIGHT"
          >
            <v-icon size="20">{{ Icons.ORGANIZATIONS }}</v-icon>
          </DefaultAvatar>
        </template>
        <template #title>{{ consumerOrganization.name }}</template>
        <template #append>
          <v-icon
            v-if="activeOrganization?.id === consumerOrganization?.organizationId"
            size="24"
          >
            {{ Icons.CHECK }}
          </v-icon>
        </template>
      </v-list-item>
      <v-list-item @click="createOrganizationDialogRef?.open()">
        <template #prepend>
          <DefaultAvatar
            size="32"
            color="transparent"
          >
            <v-icon size="20">{{ Icons.ADD }}</v-icon>
          </DefaultAvatar>
        </template>
        <v-list-item-title>
          {{ t('selectBox.create.btn') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <Dialog
    ref="createOrganizationDialogRef"
    @on-close="createOrganizationDialogRef?.close()"
  >
    <DialogOrganizationForm
      :loading="loading"
      @cancel="createOrganizationDialogRef?.close()"
      @submit="(organizationForm: OrganizationForm) => createOrganization(organizationForm)"
    />
  </Dialog>

  <Dialog
    ref="createdOrganizationDialogRef"
    :show-close-btn="false"
  >
    <DialogOrganizationCreated @submit="handleOverlayClick()" />
  </Dialog>
</template>

<script lang="ts" setup>
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useTenantStore } from '@/store/tenants'
import { ComputedRef, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useLoadingStore } from '@/store/loading'
import { storeToRefs } from 'pinia'

import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { AuthorizationsContexts } from '@/models/enums/AuthorizationsContextTypes'
import { UserOrganization } from '@/models/User'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import Dialog from '@/components/layout/Dialog.vue'
import DialogOrganizationForm from '@/components/dialogs/DialogOrganizationForm.vue'
import DialogOrganizationCreated from '@/components/dialogs/DialogOrganizationCreated.vue'
import { OrganizationForm } from '@/models/Organization'

const { t } = useI18n()
const router = useRouter()
const myOrganizationStore = useMyOrganizationStore()
const tenantStore = useTenantStore()
const loadingStore = useLoadingStore()

const { activeOrganization, myOrganizations } = storeToRefs(myOrganizationStore)
const { activeTenant } = storeToRefs(tenantStore)

const groupedTenantOrganization: ComputedRef<UserOrganization | undefined> = computed(() => {
  return myOrganizations.value.find((userOrganization) => {
    return userOrganization.authorizations.some((authorization) => {
      return authorization.contexts.find((context) => context.context === AuthorizationsContexts.TENANT)
    })
  })
})

const groupedProviderOrganizations: ComputedRef<UserOrganization[]> = computed(() => {
  return myOrganizations.value.filter((userOrganization) => {
    return userOrganization.authorizations.some((authorization) => {
      return authorization.contexts.find((context) => context.context === AuthorizationsContexts.PROVIDER)
    })
  })
})

const groupedConsumerOrganizations: ComputedRef<UserOrganization[]> = computed(() => {
  return myOrganizations.value.filter((userOrganization) => {
    return userOrganization.authorizations.some((authorization) => {
      return authorization.contexts.find((context) => context.context === AuthorizationsContexts.CONSUMER)
    })
  })
})

const createOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()
const createdOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()

const loading = ref(false)

/**
 * switchOrganization
 * @param {UserOrganization} userOrganization
 */
async function switchOrganization(userOrganization: UserOrganization): Promise<void> {
  try {
    if (activeOrganization?.value?.id !== userOrganization.organizationId) {
      loadingStore.setAppLoading(true)

      if (userOrganization.authorizations[0].contexts.some((context) => context.context === AuthorizationsContexts.TENANT)) {
        await tenantStore.setActiveTenant(userOrganization.organizationId)
      } else {
        await myOrganizationStore.setActiveOrganization(userOrganization.organizationId)
      }

      await router.push({ name: Routes.INDEX })
    }
  } catch {
    Promise.resolve()
  } finally {
    loadingStore.setAppLoading(false)
  }
}

/**
 * createOrganization
 * @param {OrganizationForm} organizationForm
 */
async function createOrganization(organizationForm: OrganizationForm): Promise<void> {
  try {
    await myOrganizationStore.CREATE_AND_SELECT({ ...organizationForm, provider: false })
    createOrganizationDialogRef.value?.close()
    createdOrganizationDialogRef.value?.open()
  } catch {
    Promise.resolve()
  } finally {
    loading.value = false
  }
}

/**
 * handleOverlayClick
 */
function handleOverlayClick(): void {
  createdOrganizationDialogRef.value?.close()
  router.push({ name: Routes.HOME })
}
</script>
<style lang="scss" scoped>
:deep(.v-list-item-title) {
  font-size: $font-size-s;
  font-weight: 700;
}

:deep(.v-list-item-subtitle),
:deep(.v-list-subheader__text) {
  font-size: $font-size-xs;
  font-weight: 700;
}
</style>
<i18n lang="yaml">
de:
  selectBox:
    choose: Organisation auswählen
    create:
      btn: Organisation erstellen
  organizationType:
    provider: API anbieten
    consumer: API nutzen
    tenant: Verwaltungskonsole
</i18n>
