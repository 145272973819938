<template>
  <div v-if="activeAccessDefinitions.length">
    <ListControls>
      <template #append>
        <IconButton
          :icon="Icons.SETTINGS"
          @click="router.push({ name: Routes.ACCESS_DEFINITIONS })"
        />
      </template>
    </ListControls>
    <AccessDefinitionList
      v-if="selectedAccessDefinition"
      :access-definitions="[selectedAccessDefinition]"
      :product-view="true"
    >
      <template #button>
        <DefaultButton
          class="ml-2"
          :color="PlattformColors.SECONDARY"
          @click="changeAccessDefinitionDialogRef?.open()"
        >
          {{ t('providedProductsAccesses.changeMethodBtn') }}
        </DefaultButton>
      </template>
    </AccessDefinitionList>
  </div>
  <div v-else>
    <h2 class="provided-detail__access-nothing">
      {{ t('providedProductsAccesses.nothing') }}
    </h2>
  </div>
  <Dialog
    ref="changeAccessDefinitionDialogRef"
    @on-close="changeAccessDefinitionDialogRef?.close()"
  >
    <DialogProductAccessDefinitionEdit
      :access-definitions="activeAccessDefinitions"
      :access-definition-selected="selectedAccessDefinition?.id"
      @cancel="changeAccessDefinitionDialogRef?.close()"
      @submit="updateProductAccess"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useAccessDefinitionsStore } from '@/store/accessDefinitions'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import { useProductStore } from '@/store/products'
import { useAlertStore } from '@/store/alerts'
import { computed, ref } from 'vue'
import { Icons } from '@/models/enums/IconTypes'
import { router } from '@/router'
import { Routes } from '@/models/enums/Routes'
import Dialog from '@/components/layout/Dialog.vue'
import DialogProductAccessDefinitionEdit from '@/components/dialogs/DialogProductAccessDefinitionEdit.vue'
import { AlertTypes } from '@/models/enums/AlertTypes'
import AccessDefinitionList from '@/components/accessDefinitions/AccessDefinitionList.vue'
import ListControls from '@/components/ListControls.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const productStore = useProductStore()
const accessDefinitionsStore = useAccessDefinitionsStore()
const myOrganizationStore = useMyOrganizationStore()
const alertStore = useAlertStore()

const { product } = storeToRefs(productStore)
const { accessDefinitions } = storeToRefs(accessDefinitionsStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)

const changeAccessDefinitionDialogRef = ref<InstanceType<typeof Dialog>>()

const selectedAccessDefinition = computed(() => accessDefinitions.value.find((accessDefinition) => accessDefinition.id === product?.value?.accessDefinitionId))

const activeAccessDefinitions = computed(() => accessDefinitions.value.filter((accessDefinition) => accessDefinition.active))

/**
 * updateProductAccess
 * @param {accessDefinitionId} accessDefinitionId
 */
async function updateProductAccess(accessDefinitionId: AccessDefinitionResponse['id']): Promise<void> {
  if (product?.value) {
    try {
      await productStore.UPDATE({ accessDefinitionId }, product.value.id)
      alertStore.add({
        text: t('providedProductsAccesses.success.update'),
        type: AlertTypes.SUCCESS,
      })
      changeAccessDefinitionDialogRef.value?.close()
    } catch {
      Promise.resolve()
    }
  }
}

if (activeOrganization?.value) await accessDefinitionsStore.GET_LIST(activeOrganization.value.id)
</script>

<i18n lang="yaml">
de:
  providedProductsAccesses:
    count: '{count} Authentifizierungsmethode | {count} Authentifizierungsmethoden'
    nothing: Keine aktiven Authentifizierungsmethoden gefunden.
    changeMethodBtn: Methode ändern
    success:
      update: Deine Authentifizierungsmethode wurde erfolgreich aktualisiert!
</i18n>
