import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface LoadingStateProperties {
  appLoading: boolean
}

export const useLoadingStore = defineStore(StoreName.LOADING, {
  state: (): LoadingStateProperties => ({
    appLoading: false,
  }),
  actions: {
    setAppLoading(value: boolean) {
      this.appLoading = value
    },
  },
})
