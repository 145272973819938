<template>
  <v-menu
    v-if="showMenu(access)"
    location="bottom"
    transition="slide-y-transition"
  >
    <template #activator="{ props }">
      <IconButton
        v-bind="props"
        :icon="Icons.OPTIONS_VERTICAL"
        class="ml-2"
      />
    </template>
    <v-list density="compact">
      <template v-if="activeOrganization?.provider">
        <v-list-item
          v-if="getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVE"
          flat
          @click="emit('deactivateCredential')"
        >
          <template #prepend>
            <v-icon :icon="Icons.CLOSE" />
          </template>
          <v-list-item-title>{{ t('credentialsContextMenu.menu.deactivate') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVATION_REQUESTED"
          flat
          @click="emit('handleRequestCredential')"
        >
          <template #prepend>
            <v-icon :icon="Icons.OPTIONS_VERTICAL" />
          </template>
          <v-list-item-title>{{ t('credentialsContextMenu.menu.request') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.INACTIVE"
          flat
          @click="emit('activateCredential')"
        >
          <template #prepend>
            <v-icon :icon="Icons.CIRCLE_CHECK" />
          </template>
          <v-list-item-title>{{ t('credentialsContextMenu.menu.activate') }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.DEACTIVATION_REQUESTED"
          flat
          @click="emit('deactivateRequestedCredential')"
        >
          <template #prepend>
            <v-icon :icon="Icons.CIRCLE_CHECK" />
          </template>
          <v-list-item-title>{{ t('credentialsContextMenu.menu.deactivateRequested') }}</v-list-item-title>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item
          v-if="getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVE"
          flat
          @click="emit('requestDeactivateCredential')"
        >
          <template #prepend>
            <v-icon :icon="Icons.CLOSE" />
          </template>
          <v-list-item-title>{{ t('credentialsContextMenu.menu.requestDeactivate') }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>
<script lang="ts" setup>
import { useAccessHelper } from '@/composables/useAccessHelper'
import { AccessResponse } from '@/models/Access'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import IconButton from '../baseComponents/buttons/IconButton.vue'

defineProps({
  access: { type: Object as PropType<AccessResponse>, required: true },
})

const emit = defineEmits([
  'deactivateCredential',
  'activateCredential',
  'deactivateRequestedCredential',
  'requestDeactivateCredential',
  'handleRequestCredential',
])

const { t } = useI18n()
const { getMostRecentAccessStatus } = useAccessHelper()

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

/**
 * showMenu
 * @param {access} access
 * @return {boolean}
 */
function showMenu(access: AccessResponse): boolean {
  return (
    (!!activeOrganization?.value?.provider &&
      (getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVE ||
        getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.DEACTIVATION_REQUESTED ||
        getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVATION_REQUESTED ||
        getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.INACTIVE)) ||
    (!activeOrganization?.value?.provider && getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVE)
  )
}
</script>
<i18n lang="yaml">
de:
  credentialsContextMenu:
    menu:
      deactivate: Deaktivieren
      request: Anfrage bearbeiten
      activate: Reaktivieren
      requestDeactivate: Deaktivierung beantragen
      deactivateRequested: Anfrage bearbeiten
</i18n>
