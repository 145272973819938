<template>
  <div>
    <v-form @submit.prevent="handleSubmit()">
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('activationRequest.headline') }}</h1>
          <p>{{ t('activationRequest.copy') }}</p>

          <v-radio-group v-model="radioSelect">
            <v-radio :value="StatusTypes.ACTIVE">
              <template #label>
                <DefaultAvatar
                  size="12"
                  class="mx-2"
                  :color="getStatusSetFromStatusKey(StatusTypes.ACTIVE)?.color"
                />
                <span>
                  <b>{{ t('activationRequest.accept.bold') }}</b>
                  {{ t('activationRequest.accept.normal') }}
                </span>
              </template>
            </v-radio>
            <v-radio :value="StatusTypes.INACTIVE">
              <template #label>
                <DefaultAvatar
                  size="12"
                  class="mx-2"
                  :color="getStatusSetFromStatusKey(StatusTypes.INACTIVE)?.color"
                />
                <span>
                  <b>{{ t('activationRequest.decline.bold') }}</b>
                  {{ t('activationRequest.decline.normal') }}
                </span>
              </template>
            </v-radio>
          </v-radio-group>

          <v-textarea
            v-if="radioSelect === StatusTypes.INACTIVE"
            v-model="declineNote"
            :label="t('activationRequest.label')"
            variant="outlined"
          >
            <template #append-inner>
              <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
            </template>
          </v-textarea>
          <InfoBox
            v-else-if="radioSelect === StatusTypes.ACTIVE"
            :headline="t('activationRequest.info.headline')"
            :copy="t('activationRequest.info.copy')"
          />
        </template>

        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
            :disabled="isSubmitButtonDisabled"
          >
            {{ t('buttons.send') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}</DefaultButton
          >
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { AccessResponse } from '@/models/Access'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { PropType } from 'vue'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import InfoBox from '../InfoBox.vue'
import { useStatusHelper } from '@/composables/useStatusHelper'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const props = defineProps({
  selectedAccess: { type: Object as PropType<AccessResponse>, default: () => {} },
})

const { t } = useI18n()

const { getStatusSetFromStatusKey } = useStatusHelper()

const radioSelect = ref<string>()
const declineNote = ref<string>()
const loading = ref(false)

const isSubmitButtonDisabled = computed(() => !radioSelect.value || (radioSelect.value === StatusTypes.INACTIVE && !declineNote.value))

const emit = defineEmits(['activate', 'decline', 'cancel'])

/**
 * handleSubmit
 */
function handleSubmit(): void {
  loading.value = true
  if (radioSelect.value === StatusTypes.ACTIVE) {
    emit('activate', props.selectedAccess)
  } else if (radioSelect.value === StatusTypes.INACTIVE) {
    emit('decline', props.selectedAccess, declineNote.value)
  }
}
</script>
<style lang="scss" scoped>
:deep(.v-radio .v-label) {
  opacity: 1;
}
</style>

<i18n lang="yaml">
de:
  activationRequest:
    headline: Aktivierungsanfrage
    copy: Dein Kunde möchte ein Authentifizierungsmittel aktivieren.
    label: Begründung
    accept:
      bold: Genehmigen
      normal: und aktivieren
    decline:
      bold: Ablehnen
      normal: und deaktivieren
    info:
      headline: Token-Hash gespeichert?
      copy: Kopiere dir den Token-Hash auf der Übersichtsseite, um ihn in deinem System zu hinterlegen
</i18n>
