<template>
  <LinkTeaser
    v-if="products.length"
    :link="Routes.PROVIDER_PRODUCTS"
  >
    <template #title>
      {{ t('productTeaser.list.headline') }}
    </template>
    <template #text>
      <v-list class="pa-0">
        <v-list-item
          v-for="(product, i) in products.slice(0, 5)"
          :key="product.id"
          :to="{ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId: product.id } }"
          class="pa-0"
          :class="{ 'mt-3': i > 0 }"
        >
          <template #prepend>
            <DefaultAvatar
              size="40"
              class="mr-2"
            >
              <v-icon
                color="primary"
                size="20"
                >{{ Icons.PRODUCT }}</v-icon
              >
            </DefaultAvatar>
          </template>
          <template #title>
            <p class="sm mb-0 highlight">{{ product.name }}</p>
          </template>
        </v-list-item>
      </v-list>
    </template>
    <template
      v-if="products.length < 5"
      #actions
    >
      <DefaultButton
        :variant="ButtonVariants.PLAIN"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="emit('showCreateProductOverlay')"
        >{{ t('productTeaser.list.button') }}</DefaultButton
      >
    </template>
  </LinkTeaser>
  <CenteredTeaser v-else>
    <template #title>{{ t('productTeaser.noApis.headline') }}</template>
    <template #text>
      <DefaultAvatar
        size="120"
        class="mx-auto"
      >
        <v-icon
          color="primary"
          size="60"
          >{{ Icons.PRODUCT }}</v-icon
        >
      </DefaultAvatar>
    </template>
    <template #actions>
      <DefaultButton
        :variant="ButtonVariants.PLAIN"
        :prepend-icon="Icons.CIRCLE_ADD"
        :color="PlattformColors.SECONDARY"
        @click="emit('showCreateProductOverlay')"
        >{{ t('productTeaser.noApis.button') }}</DefaultButton
      >
    </template>
  </CenteredTeaser>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ProductResponse } from '@/models/Product'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { ButtonVariants } from '@/models/enums/components/Buttons'
import LinkTeaser from '@/components/baseComponents/teasers/LinkTeaser.vue'
import CenteredTeaser from '@/components/baseComponents/teasers/CenteredTeaser.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()

const emit = defineEmits(['showCreateProductOverlay'])

defineProps({
  products: { type: Array<ProductResponse>, required: true },
})
</script>
<i18n lang="yaml">
de:
  productTeaser:
    list:
      headline: Deine API-Produkte
      button: Neue API erstellen
    noApis:
      headline: Füge deine erste API hinzu
      button: API hinzufügen
</i18n>
