import { ContactResponse, ContactResponseList, ContactRequest } from '@/models/Contact'
import { OrganizationPublicResponse } from '@/models/Organization'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface ContactsStateProperties {
  contacts: Array<ContactResponse>
}

export const useContactsStore = defineStore(StoreName.CONTACTS, {
  state: (): ContactsStateProperties => ({
    contacts: [],
  }),
  actions: {
    async GET_BY_ORGANIZATION_ID(organizationId: OrganizationPublicResponse['id']) {
      try {
        const { data }: { data: ContactResponseList } = await useAxios.get(`/api/organizations/${organizationId}/contacts`)
        this.contacts = data.contacts
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(organizationId: OrganizationPublicResponse['id'], newContact: ContactRequest) {
      try {
        const { data }: { data: ContactResponse } = await useAxios.post(`/api/organizations/${organizationId}/contacts`, newContact)
        this.contacts = [...this.contacts, data]
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(updatedContact: Partial<ContactResponse>) {
      try {
        const { data }: { data: ContactResponse } = await useAxios.patch(`/api/contacts/${updatedContact.id}`, updatedContact)
        const index = this.contacts.findIndex((contact) => contact.id === updatedContact.id)
        this.contacts[index] = data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(contact: ContactResponse) {
      try {
        await useAxios.delete(`/api/contacts/${contact.id}`)
        const index = this.contacts.findIndex((deletedContact) => deletedContact.id === contact.id)
        this.contacts.splice(index, 1)
      } catch {
        return Promise.reject()
      }
    },
  },
})
