<template>
  <BackButton />
  <GeneralPageTitle>
    <template #prepend>
      <DefaultAvatar
        color="white"
        size="80"
      >
        <v-icon size="32">{{ Icons.ORGANIZATIONS }}</v-icon>
      </DefaultAvatar>
    </template>
    <template #title>{{ organization?.name }}</template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <DefaultList>
        <ListElement>
          <template #prepend>
            <DefaultAvatar
              color="grey-lighten-4"
              size="48"
            >
              <v-icon>{{ Icons.ORGANIZATIONS }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #title>{{ t('tenantProviderDetail.organization') }}</template>
          <template #expand>
            <div class="d-flex flex-column align-start ml-16">
              <v-chip
                v-if="organization?.email"
                label
                size="small"
                class="mb-4"
                :href="`mailto:${organization?.email}`"
              >
                <template #prepend>
                  <v-icon class="mr-2">{{ Icons.EMAIL }}</v-icon>
                </template>
                {{ organization.email }}
              </v-chip>
              <v-chip
                v-if="fullAddress"
                label
                size="small"
                class="mb-4"
              >
                <template #prepend>
                  <v-icon class="mr-2">{{ Icons.LOCATION }}</v-icon>
                </template>
                {{ fullAddress }}
              </v-chip>
              <v-chip
                v-if="(organization as OrganizationFullResponse)?.registrationNumber"
                class="mb-4"
                label
                size="small"
                variant="outlined"
              >
                {{ (organization as OrganizationFullResponse)?.registrationNumber }}
              </v-chip>
            </div>
          </template>
        </ListElement>
      </DefaultList>
    </v-col>
    <v-col>
      <DefaultList v-if="defaultContact">
        <ListElement>
          <template #prepend>
            <DefaultAvatar
              color="grey-lighten-4"
              size="48"
            >
              <v-icon>{{ Icons.USER }}</v-icon>
            </DefaultAvatar>
          </template>
          <template #title>{{ t('tenantProviderDetail.contact') }}</template>
          <template #expand>
            <div class="d-flex flex-column align-start ml-16">
              <v-chip
                class="mb-4"
                label
                size="small"
                variant="outlined"
              >
                <template #prepend>
                  <v-icon class="mr-2">{{ Icons.USER }}</v-icon>
                </template>
                {{ defaultContact.name }}
              </v-chip>
              <v-chip
                label
                size="small"
                class="mb-4"
                :href="`mailto:${defaultContact.email}`"
              >
                <template #prepend>
                  <v-icon class="mr-2">{{ Icons.EMAIL }}</v-icon>
                </template>
                {{ defaultContact.email }}
              </v-chip>
              <v-chip
                class="mb-4"
                label
                size="small"
                :href="`tel:${defaultContact.phone}`"
              >
                {{ defaultContact.phone }}
              </v-chip>
            </div>
          </template>
        </ListElement>
      </DefaultList>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import BackButton from '@/components/layout/BackButton.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import { useI18n } from 'vue-i18n'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { Icons } from '@/models/enums/IconTypes'
import { OrganizationFullResponse } from '@/models/Organization'
import { computed } from 'vue'

const { t } = useI18n()
const route = useRoute()
const organizationStore = useOrganizationStore()

const { organization } = storeToRefs(organizationStore)

const fullAddress = computed(() => {
  const o = organization?.value as OrganizationFullResponse
  return o.address ? `${o.address?.street} ${o.address?.number}, ${o.address?.zipCode} ${o.address?.city}` : undefined
})

// TODO Default Contact
const defaultContact = computed(() => {
  const o = organization?.value as OrganizationFullResponse
  return o.contacts?.length ? o.contacts[0] : undefined
})

await organizationStore.GET_ORGANIZATION(route.params.providerId as string)
</script>
<i18n lang="yaml">
de:
  tenantProviderDetail:
    organization: Unternehmen
    contact: Kontaktperson
</i18n>
