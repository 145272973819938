<template>
  <v-list-group
    class="bg-white mb-5"
    :data-cy="dataCy"
  >
    <template #activator="{ props, isOpen }">
      <v-list-item
        class="pa-6"
        min-height="96"
        :to="to"
        v-on="clickable ? { click: () => emit('click') } : {}"
      >
        <template
          v-if="$slots.prepend"
          #prepend
        >
          <div class="mr-6">
            <slot name="prepend"></slot>
          </div>
        </template>
        <template
          v-if="$slots.title"
          #title
        >
          <h3 class="mb-0">
            <slot name="title"></slot>
          </h3>
        </template>
        <template
          v-if="$slots.subtitle"
          #subtitle
        >
          <p class="mb-0 xs">
            <slot name="subtitle"></slot>
          </p>
        </template>
        <template #append>
          <slot name="append"></slot>
          <DefaultButton
            v-if="$slots.expand"
            icon
            :variant="ButtonVariants.TEXT"
            :size="ButtonSizes.DEFAULT"
            v-bind="props"
            :data-cy="ListElementTest.CHEVRON"
            @click.prevent
          >
            <v-icon :class="{ 'rotate-180': isOpen }">{{ Icons.CHEVRON_DOWN }}</v-icon>
          </DefaultButton>
        </template>
      </v-list-item>
    </template>
    <template
      v-if="$slots.expand"
      #default
    >
      <div class="px-6 pb-6">
        <slot name="expand"></slot>
      </div>
    </template>
  </v-list-group>
</template>
<script setup lang="ts">
import { Icons } from '@/models/enums/IconTypes'
import { ButtonVariants, ButtonSizes } from '@/models/enums/components/Buttons'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PropType } from 'vue'
import { RouteLocationRaw } from 'vue-router'
import { ListElementTest } from '@/models/enums/test/ListElementTest'

defineProps({
  to: { type: Object as PropType<RouteLocationRaw>, default: undefined },
  dataCy: { type: String, default: undefined },
  clickable: { type: Boolean, default: false },
})

const emit = defineEmits(['click'])
</script>
<style lang="scss" scoped>
:deep(.v-list-item--active .v-list-item__overlay) {
  display: none;
}
</style>
