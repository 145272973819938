<template>
  <div class="bundle-credentials">
    <BundleCredentialsList
      :access-definitions="accessDefinitions"
      :bundle="bundle"
      @deactivate-credential="(access: AccessResponse) => openDeactivateCredentialDialog(access)"
      @deactivate-requested-credential="(access: AccessResponse) => openDeactivateRequestedCredentialDialog(access)"
      @activate-credential="(access: AccessResponse) => activateAccess(access)"
      @handle-request-credential="(access: AccessResponse) => openHandleActivationRequestDialog(access)"
    />
  </div>

  <Dialog
    ref="handleActivationRequestDialogRef"
    @on-close="handleActivationRequestDialogRef?.close()"
  >
    <DialogBundleCredentialHandleRequest
      :selected-access="selectedAccess"
      @cancel="handleActivationRequestDialogRef?.close()"
      @activate="(access: AccessResponse) => activateAccess(access)"
      @decline="(access: AccessResponse, note: AccessStatus['note']) => declineAccess(access, note)"
    />
  </Dialog>
  <Dialog
    ref="deactivateCredentialDialogRef"
    @on-close="deactivateCredentialDialogRef?.close()"
  >
    <DialogBundleCredentialDeactivateNote
      :selected-access="selectedAccess"
      @cancel="deactivateCredentialDialogRef?.close()"
      @submit="(access: AccessResponse, note: AccessStatus['note']) => deactivateAccess(access, note)"
    />
  </Dialog>
  <Dialog
    ref="deactivateRequestedCredentialDialogRef"
    @on-close="deactivateRequestedCredentialDialogRef?.close()"
  >
    <DialogBundleCredentialDeactivateRequested
      :selected-access="selectedAccess"
      @cancel="deactivateRequestedCredentialDialogRef?.close()"
      @submit="(access: AccessResponse) => deactivateAccess(access, '')"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { AccessResponse, AccessStatus } from '@/models/Access'
import { useAlertStore } from '@/store/alerts'
import { useAccessesStore } from '@/store/accesses'
import { AlertTypes } from '@/models/enums/AlertTypes'
import BundleCredentialsList from '@/components/bundles/BundleCredentialsList.vue'
import { useAccessDefinitionsStore } from '@/store/accessDefinitions'
import { ref } from 'vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleCredentialHandleRequest from '@/components/dialogs/DialogBundleCredentialHandleRequest.vue'
import DialogBundleCredentialDeactivateNote from '@/components/dialogs/DialogBundleCredentialDeactivateNote.vue'
import DialogBundleCredentialDeactivateRequested from '@/components/dialogs/DialogBundleCredentialDeactivateRequested.vue'

const { t } = useI18n()

const bundleStore = useBundleStore()
const accessStore = useAccessesStore()
const alertStore = useAlertStore()
const accessDefinitionsStore = useAccessDefinitionsStore()

const { bundle } = storeToRefs(bundleStore)
const { accessDefinitions } = storeToRefs(accessDefinitionsStore)

const handleActivationRequestDialogRef = ref<InstanceType<typeof Dialog>>()
const deactivateCredentialDialogRef = ref<InstanceType<typeof Dialog>>()
const deactivateRequestedCredentialDialogRef = ref<InstanceType<typeof Dialog>>()
const selectedAccess = ref<AccessResponse>()

/**
 * openHandleActivationRequestDialog
 * @param {access} access
 */
function openHandleActivationRequestDialog(access: AccessResponse): void {
  selectedAccess.value = access
  handleActivationRequestDialogRef.value?.open()
}

/**
 * openDeactivateCredentialDialog
 * @param {access} access
 */
function openDeactivateCredentialDialog(access: AccessResponse): void {
  selectedAccess.value = access
  deactivateCredentialDialogRef.value?.open()
}

/**
 * openDeactivateRequestedCredentialDialog
 * @param {access} access
 */
function openDeactivateRequestedCredentialDialog(access: AccessResponse): void {
  selectedAccess.value = access
  deactivateRequestedCredentialDialogRef.value?.open()
}

/**
 * activateAccess
 * @param {access} access
 */
async function activateAccess(access: AccessResponse): Promise<void> {
  try {
    const updatedAccess = await accessStore.ACTIVATE(access.id, 'access activated')
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleCredentials.banner.activate'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    handleActivationRequestDialogRef.value?.close()
  }
}

/**
 * declineAccess
 * @param {access} access
 * @param {declineNote} declineNote
 */
async function declineAccess(access: AccessResponse, declineNote: AccessStatus['note']): Promise<void> {
  try {
    const updatedAccess = await accessStore.DECLINE(access.id, declineNote)
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleCredentials.banner.decline'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    handleActivationRequestDialogRef.value?.close()
  }
}

/**
 * deactivateAccess
 * @param {access} access
 * @param {deactivateNote} deactivateNote
 */
async function deactivateAccess(access: AccessResponse, deactivateNote: AccessStatus['note']): Promise<void> {
  try {
    const updatedAccess = await accessStore.DEACTIVATE(access.id, deactivateNote)
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleCredentials.banner.deactivate'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    deactivateCredentialDialogRef.value?.close()
    deactivateRequestedCredentialDialogRef.value?.close()
  }
}

if (bundle?.value) await accessDefinitionsStore.GET_LIST(bundle.value.providerOrganization.id)
</script>

<i18n lang="yaml">
de:
  providerBundleCredentials:
    banner:
      activate: Das Authentifizierungsmittel wurde aktiviert!
      decline: Das Authentifizierungsmittel wurde abgelehnt!
      deactivate: Das Authentifizierungsmittel wurde deaktiviert!
</i18n>
