<template>
  <BackButton :back-url="{ name: Routes.PROVIDER_CONSUMERS }" />
  <div v-if="organization?.id">
    <GeneralPageTitle>
      <template #prepend>
        <DefaultAvatar
          color="grey"
          size="80"
          tile
          rounded
        >
          <h1 class="mb-0">{{ getFirstLetterUpperCase(organization.name) }}</h1>
        </DefaultAvatar>
      </template>
      <template #title>
        {{ organization.name }}
      </template>
      <template #append>
        <DefaultButton
          target="_blank"
          :color="PlattformColors.PRIMARY"
          :href="`mailto:${organization.email}`"
          :prepend-icon="Icons.EMAIL"
        >
          {{ t('consumerDetails.contact') }}
        </DefaultButton>
      </template>
    </GeneralPageTitle>
    <v-tabs>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_CONSUMERS_BUNDLES, params: { consumerId: organization.id } }"
      >
        {{ t('consumerDetails.appsTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_CONSUMERS_FILES, params: { consumerId: organization.id } }"
      >
        {{ t('consumerDetails.productFileTab') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.PROVIDER_CONSUMERS_ORGANIZATION, params: { consumerId: organization.id } }"
      >
        {{ t('consumerDetails.organizationDataTab') }}
      </v-tab>
    </v-tabs>
    <TabContainer />
  </div>
</template>

<script lang="ts" setup>
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import BackButton from '@/components/layout/BackButton.vue'
import TabContainer from '@/components/TabContainer.vue'
import { useFormatHelper } from '@/composables/useFormatHelper'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { Routes } from '@/models/enums/Routes'
import { useOrganizationStore } from '@/store/organizations'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const { t } = useI18n()
const route = useRoute()
const organizationStore = useOrganizationStore()

const { organization } = storeToRefs(organizationStore)
const { getFirstLetterUpperCase } = useFormatHelper()

await organizationStore.GET_ORGANIZATION(route.params.consumerId as string)
</script>

<i18n lang="yaml">
de:
  consumerDetails:
    contact: Kontakt
    appsTab: Apps
    productFileTab: Dokumente
    organizationDataTab: Organisationsdaten
</i18n>
