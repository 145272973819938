<template>
  <div>
    <h2>{{ t('wizardStep3.headline') }}</h2>
    <p>{{ t('wizardStep3.copy') }}</p>
    <v-select
      v-model="wizardContent.mode"
      :items="selectProperties"
      :label="t('wizardStep3.label')"
      :disabled="isConsumer"
      variant="outlined"
    >
    </v-select>
  </div>
</template>

<script lang="ts" setup>
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { Permissions } from '@/models/enums/Permissions'
import { useSideWizardStore } from '@/store/sideWizard'
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const sideWizardStore = useSideWizardStore()

const { wizardContent, wizardValidated } = storeToRefs(sideWizardStore)

wizardValidated.value[2] = computed(() => !!wizardContent.value.mode)

const isConsumer = computed(() => wizardContent.value.type === OrganizationTypes.CONSUMER)

const selectProperties = [Permissions.READ, Permissions.READ_WRITE]

onBeforeMount(() => {
  if (isConsumer.value) {
    wizardContent.value.mode = selectProperties[0]
  }
})
</script>

<i18n lang="yaml">
de:
  wizardStep3:
    label: Berechtigung auswählen
    headline: Berechtigung vergeben
    copy: Wähle die Art der Berechtigung des Teams auf die ausgewählten Ordner.
</i18n>
