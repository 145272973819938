<template>
  <div
    v-if="products?.length"
    class="product-list w-100"
  >
    <DefaultList
      v-if="view === ViewTypes.LIST || view === ViewTypes.LISTCOMFORTABLE"
      class="bg-transparent"
    >
      <template v-if="view === ViewTypes.LISTCOMFORTABLE">
        <ListElement
          v-for="product in products"
          :key="product.id"
          clickable
          :data-cy="ProductListTest.LIST_ITEM"
          @click="emit('handleProductClick', product.id)"
        >
          <template #prepend>
            <DefaultAvatar size="48">
              <v-icon
                color="primary"
                size="24"
                >{{ Icons.PRODUCT }}</v-icon
              >
            </DefaultAvatar>
          </template>
          <template #title>{{ product.name }}</template>
          <template #subtitle
            >{{
              t('createdAt', {
                d: format(new Date(product.createdAt), 'dd.MM.yyyy'),
              })
            }}
          </template>
          <template #append>
            <StatusChip
              v-if="(product as ProductResponse).productStatus === StatusTypes.DRAFT"
              class="mr-4"
              :color="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.color"
              :icon="getStatusSetFromStatusKey(StatusTypes.DRAFT)?.icon"
            >
              {{ t('productList.draftStatus') }}
            </StatusChip>
            <v-icon v-if="product.publiclyAvailable">{{ Icons.GLOBE }}</v-icon>
            <v-icon v-else>{{ Icons.LOCK }}</v-icon>
          </template>
        </ListElement>
      </template>
      <template v-if="view === ViewTypes.LIST">
        <template
          v-for="product in products"
          :key="product.id"
        >
          <ListLineElement
            clickable
            :data-cy="ProductListTest.LIST_ITEM"
            @click="emit('handleProductClick', product.id)"
          >
            <template #prepend>
              <DefaultAvatar
                size="40"
                class="mr-4"
              >
                <v-icon
                  color="primary"
                  :size="20"
                  >{{ Icons.PRODUCT }}</v-icon
                >
              </DefaultAvatar>
            </template>
            <template #title>{{ product.name }}</template>
            <template #subtitle
              >{{
                t('createdAt', {
                  d: format(new Date(product.createdAt), 'dd.MM.yyyy'),
                })
              }}
            </template>

            <template #append>
              <div class="pa-2">
                <v-icon v-if="product.publiclyAvailable">{{ Icons.GLOBE }}</v-icon>
                <v-icon v-else>{{ Icons.LOCK }}</v-icon>
              </div>
            </template>
          </ListLineElement>
        </template>
      </template>
    </DefaultList>

    <div
      v-else
      class="d-flex flex-wrap mx-n4"
    >
      <ProductTile
        v-for="product in products"
        :key="product.id"
        transition="scale-transition"
        :product="product as ProductResponse"
        :data-cy="ProductListTest.TILE_ITEM"
        @click="emit('handleProductClick', product.id)"
      />
    </div>
  </div>
  <div v-else>
    <v-divider inset />
    <p
      class="mt-6"
      :class="{ 'ml-16 pl-4': view === ViewTypes.LIST }"
      :data-cy="ProductListTest.NO_PRODUCTS"
    >
      {{ t('productList.noProducts') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'
import { useI18n } from 'vue-i18n'
import { ProductResponse } from '@/models/Product'
import { Icons } from '@/models/enums/IconTypes'
import { ViewTypes } from '@/models/enums/ViewTypes'
import ProductTile from '@/components/products/ProductTile.vue'
import { PropType } from 'vue'
import { ProductListTest } from '@/models/enums/test/ProductListTest'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useStatusHelper } from '@/composables/useStatusHelper'
import StatusChip from '../StatusChip.vue'
import { BundleProductResponse } from '@/models/Bundle'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import ListLineElement from '@/components/baseComponents/lists/ListLineElement.vue'

const { t } = useI18n()

const { getStatusSetFromStatusKey } = useStatusHelper()

const emit = defineEmits(['handleProductClick'])

defineProps({
  products: {
    type: Array<ProductResponse | BundleProductResponse>,
    default: undefined,
  },
  view: {
    type: String as PropType<ViewTypes>,
    default: ViewTypes.LIST,
  },
})
</script>

<i18n lang="yaml">
de:
  productList:
    noProducts: Keine API-Produkte gefunden.
    draftStatus: Entwurf
</i18n>
