export enum StoreName {
  ACCESS_DEFINITIONS = 'accessDefinitions',
  ACTIVE_USER = 'activeUser',
  ALERTS = 'alerts',
  LOADING = 'loading',
  MY_ORGANIZATIONS = 'myOrganizations',
  SIDE_WIZARD = 'sideWizard',
  SNACKBAR = 'snackbar',
  ACCESSES = 'accesses',
  BUNDLES = 'bundles',
  INVITATION = 'invitation',
  CATEGORIES = 'categories',
  CONTACTS = 'contacts',
  PRODUCT_OFFERS = 'productOffers',
  FILES = 'files',
  PLANS = 'plans',
  ORGANIZATIONS = 'organizations',
  SUBSCRIPTIONS = 'subscriptions',
  TECHNICAL_USERS = 'technicalUsers',
  USERS = 'users',
  TENANTS = 'tenants',
}
