<template>
  <div class="bundle-credential-card bg-white pa-6 mb-4">
    <div class="bundle-credential-card__header d-flex align-center">
      <DefaultAvatar
        size="48"
        class="mr-6"
        color="grey-lighten-4"
      >
        <v-icon :icon="getIconFromAccessDefinition(accessSet.accessDefinition)" />
      </DefaultAvatar>
      <h3 class="mb-0">{{ getNameFromAccessDefinition(accessSet.accessDefinition) }}</h3>
      <DefaultButton
        v-if="
          !activeOrganization?.provider &&
          accessSet.accessDefinition.accessExchangeType !== AccessExchangeTypes.MANUAL_EXCHANGE &&
          noAccessRequested(accessSet) &&
          requestEnabled
        "
        class="ml-auto"
        :color="PlattformColors.SECONDARY"
        :prepend-icon="Icons.CIRCLE_ADD"
        @click="emit('requestCredential', accessSet.accessDefinition)"
        >{{ t('credentialsElement.button') }}</DefaultButton
      >
    </div>
    <div class="bundle-credential-card__content">
      <v-divider
        color="primary"
        class="pb-6"
      />
      <div class="d-flex">
        <v-list class="w-33">
          <p class="sm highlight">{{ t('credentialsElement.products') }}</p>
          <v-list-item
            v-for="product in accessSet.products"
            :key="product.id"
            class="product-item px-0"
          >
            <template #prepend>
              <DefaultAvatar
                size="32"
                class="mr-2"
              >
                <v-icon
                  color="primary"
                  size="16"
                  >{{ Icons.PRODUCT }}</v-icon
                >
              </DefaultAvatar>
            </template>
            <v-list-item-title class="product-item__title">{{ product.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
        <div
          v-if="accessSet.accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN"
          class="w-66"
        >
          <v-list
            v-for="(accessGroup, i) in accessSet.accessGroups as AccessResponseAccessToken[][]"
            :key="`accessGroup-${accessSet.accessDefinition.id}${i}`"
            class="access-group px-0"
          >
            <div class="d-flex justify-space-between">
              <p class="sm highlight">{{ t('credentialsElement.access') }}</p>
              <div class="access-group__labels">
                <v-chip
                  class="mr-4"
                  label
                  size="small"
                  variant="outlined"
                >
                  {{ accessGroup[0].authenticator.hashAlgorithm }}
                </v-chip>
                <v-chip
                  class="mr-4"
                  label
                  size="small"
                  variant="outlined"
                >
                  {{ t('credentialsElement.chips.tokenLength', { length: accessGroup[0].authenticator.maxTokenLength }) }}
                </v-chip>
                <v-chip
                  class="mr-4"
                  label
                  size="small"
                  variant="outlined"
                >
                  {{ getRightPeriod(accessGroup[0].authenticator.validityPeriod) }}
                </v-chip>
              </div>
            </div>
            <v-list-item
              v-for="access in accessGroup"
              :key="access.id"
              class="access-item px-0"
            >
              <template #prepend>
                <DefaultAvatar
                  size="32"
                  :color="getStatusSetFromStatusKey(getMostRecentAccessStatus(access.accessStatus)?.statusValue)?.color"
                >
                  <v-icon
                    size="20"
                    :icon="getStatusSetFromStatusKey(getMostRecentAccessStatus(access.accessStatus)?.statusValue)?.icon"
                  />
                </DefaultAvatar>
              </template>
              <v-list-item-title class="access-item__title">{{ access.name }}</v-list-item-title>
              <template #append>
                <p
                  v-if="
                    access.authenticator.validUntil &&
                    (getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVE ||
                      getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.DEACTIVATION_REQUESTED)
                  "
                  class="access-item__valid-until mb-0"
                >
                  {{ t('validUntil', { date: format(new Date(access.authenticator.validUntil), 'dd.MM.yyyy') }) }}
                </p>
                <v-tooltip
                  v-if="
                    getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.INACTIVE && getMostRecentAccessStatus(access.accessStatus)?.note
                  "
                  location="bottom"
                  transition="fade-transition"
                  :max-width="320"
                  content-class="tooltip elevation-5 pa-4"
                >
                  <template #activator="{ props }">
                    <IconButton
                      v-bind="props"
                      class="text-red"
                      :icon="Icons.CIRCLE_INFORMATION"
                    />
                  </template>
                  <template #default>
                    <span class="d-flex">
                      <strong>{{ t('credentialsElement.reason') }}</strong>
                    </span>
                    <span class="d-flex">{{ getMostRecentAccessStatus(access.accessStatus)?.note }}</span>
                  </template>
                </v-tooltip>
                <CopyToClipBtn
                  v-if="activeOrganization?.provider"
                  :text="trimHash(access.authenticator.tokenHash)"
                  :copy-value="access.authenticator.tokenHash"
                  class="ml-4"
                />
                <BundleCredentialsContextMenu
                  :access="access"
                  @deactivate-credential="emit('deactivateCredential', access)"
                  @activate-credential="emit('activateCredential', access)"
                  @request-deactivate-credential="emit('requestDeactivateCredential', access)"
                  @deactivate-requested-credential="emit('deactivateRequestedCredential', access)"
                  @handle-request-credential="emit('handleRequestCredential', access)"
                />
              </template>
            </v-list-item>
          </v-list>
        </div>
        <div
          v-else
          class="w-66"
        >
          <v-list>
            <div class="d-flex justify-space-between">
              <p class="sm highlight">{{ t('credentialsElement.access') }}</p>
              <div class="access-group__labels">
                <v-chip
                  class="mr-4"
                  label
                  size="small"
                  variant="outlined"
                >
                  {{ t('credentialsElement.chips.noDefinition') }}
                </v-chip>
              </div>
            </div>
            <v-list-item class="product-item px-0">
              <template #prepend>
                <DefaultAvatar
                  size="32"
                  :color="PlattformColors.INFO"
                >
                  <v-icon
                    size="20"
                    :icon="Icons.CIRCLE_INFORMATION"
                /></DefaultAvatar>
              </template>
              <span class="product-item__title">{{ t('credentialsElement.manualInfo') }}</span>
            </v-list-item>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useAccessHelper } from '@/composables/useAccessHelper'
import { useStatusHelper } from '@/composables/useStatusHelper'
import { AccessResponseAccessToken, AccessSet } from '@/models/Access'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { Icons } from '@/models/enums/IconTypes'
import { format } from 'date-fns'
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import BundleCredentialsContextMenu from './BundleCredentialsContextMenu.vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { AccessTokenAuthenticatorResponse } from '@/models/Authenticator'
import { StatusTypes } from '@/models/enums/StatusTypes'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'

defineProps({
  accessSet: { type: Object as PropType<AccessSet>, required: true },
  requestEnabled: { type: Boolean, default: true },
})

const emit = defineEmits([
  'requestCredential',
  'deactivateCredential',
  'deactivateRequestedCredential',
  'activateCredential',
  'requestDeactivateCredential',
  'handleRequestCredential',
])

const { t } = useI18n()
const { getNameFromAccessDefinition, getIconFromAccessDefinition, getMostRecentAccessStatus, getRightPeriod } = useAccessHelper()
const { getStatusSetFromStatusKey } = useStatusHelper()

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

/**
 * noAccessRequested
 * @param {accessSet} accessSet
 * @return {boolean}
 */
function noAccessRequested(accessSet: AccessSet): boolean {
  let result = true
  accessSet.accessGroups.forEach((accessGroup) =>
    accessGroup.forEach((access) => {
      if (getMostRecentAccessStatus(access.accessStatus)?.statusValue === StatusTypes.ACTIVATION_REQUESTED) {
        result = false
        return
      }
    })
  )
  return result
}

/**
 * trimHash
 * @param {tokenHash} tokenHash
 * @return {string}
 */
function trimHash(tokenHash: AccessTokenAuthenticatorResponse['tokenHash']): string {
  return `...${tokenHash?.slice(-6)}`
}
</script>
<style lang="scss" scoped>
.bundle-credential-card {
  &__content {
    margin: 1.5rem 0 0 4.25rem;
  }
}

.product-item {
  &__title {
    font-size: $font-size-s;
    font-weight: 700;
  }
}

.access-item {
  &__title {
    font-weight: 700;
  }

  &__valid-until {
    font-size: $font-size-xs;
  }
}
</style>

<i18n lang="yaml">
de:
  credentialsElement:
    products: Verknüpfte API-Produkte
    access: Access Key
    chips:
      tokenLength: '{length} Zeichen'
      noDefinition: Keine Definition
    manualInfo: Manuelle Übergabe durch den Anbieter
    button: Neu beantragen
    reason: 'Begründung:'
</i18n>
