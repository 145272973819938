<template>
  <component :is="layout"></component>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const layout = computed(() => {
  return route.meta.layout || 'DefaultLayout'
})
</script>
<style lang="scss">
#app {
  .v-main {
    padding-bottom: 5rem;
  }
}
</style>
