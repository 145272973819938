<template>
  <ListControls>
    <template #title>
      {{ t('bundleDetailContact.headline') }}
    </template>
    <template #append>
      <IconButton
        :icon="Icons.SETTINGS"
        :to="{ name: Routes.CONTACT_PERSONS }"
      />
    </template>
  </ListControls>
  <ContactElement
    v-if="bundle?.contact"
    :contact="bundle.contact"
  >
    <template #append>
      <DefaultButton
        :color="PlattformColors.SECONDARY"
        :loading="contactLoading"
        @click="openChooseContactDialog()"
      >
        {{ t('bundleDetailContact.change') }}
      </DefaultButton>
    </template>
  </ContactElement>
  <CreateTeaser
    v-else
    :icon="Icons.USERS"
    :headline="t('bundleDetailContact.teaser.headline')"
    :btn-text="t('buttons.add')"
    :loading="contactLoading"
    @submit="openChooseContactDialog()"
  />
  <Dialog
    ref="bundleContactChooseDialogRef"
    @on-close="bundleContactChooseDialogRef?.close()"
  >
    <DialogBundleContactChoose
      :available-contacts="contacts"
      :pre-selected-contact="bundle?.contact"
      @submit="(contact: ContactResponse) => chooseContact(contact)"
      @cancel="bundleContactChooseDialogRef?.close()"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import { Routes } from '@/models/enums/Routes'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleContactChoose from '@/components/dialogs/DialogBundleContactChoose.vue'
import { ref } from 'vue'
import { useContactsStore } from '@/store/contacts'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { ContactResponse } from '@/models/Contact'
import ContactElement from '@/components/organizations/contacts/ContactElement.vue'
import ListControls from '@/components/ListControls.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()
const bundleStore = useBundleStore()
const contactStore = useContactsStore()
const myOrganizationStore = useMyOrganizationStore()

const { bundle } = storeToRefs(bundleStore)
const { contacts } = storeToRefs(contactStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)

const bundleContactChooseDialogRef = ref<InstanceType<typeof Dialog>>()
const contactLoading = ref(false)

/**
 * openChooseContactDialog
 */
async function openChooseContactDialog(): Promise<void> {
  if (activeOrganization?.value) {
    try {
      contactLoading.value = true
      await contactStore.GET_BY_ORGANIZATION_ID(activeOrganization.value.id)
      bundleContactChooseDialogRef.value?.open()
    } catch {
      Promise.resolve()
    } finally {
      contactLoading.value = false
    }
  }
}

/**
 * chooseContact
 * @param {contact} contact
 */
async function chooseContact(contact: ContactResponse): Promise<void> {
  if (bundle?.value) {
    try {
      await bundleStore.ADD_CONTACT(bundle?.value?.id, contact.id)
    } catch {
      Promise.resolve()
    } finally {
      bundleContactChooseDialogRef.value?.close()
    }
  }
}
</script>

<i18n lang="yaml">
de:
  bundleDetailContact:
    headline: Kontaktperson
    teaser:
      headline: Es wurde noch kein Kontakt hinterlegt.
    change: Kontaktperson ändern
</i18n>
