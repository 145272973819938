<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="3000"
  >
    {{ snackbarText }}
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useSnackbarStore } from '@/store/snackbar'
import { storeToRefs } from 'pinia'

const snackbarStore = useSnackbarStore()

const { snackbar, snackbarText } = storeToRefs(snackbarStore)
</script>
