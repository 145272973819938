import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { useActiveUserHelper } from './useActiveUserHelper'
import { Routes } from '@/models/enums/Routes'
import { isLoggedIn } from '@/plugins/keycloak'
import { useTenantStore } from '@/store/tenants'

interface RouterHelper {
  getUserStartRouteName: () => string
}

/**
 * useFileHelper
 * @return {RouterHelper}
 */
export function useRouterHelper(): RouterHelper {
  /**
   * get home route depending on role and organization type
   * @return {string} Route name
   */
  function getUserStartRouteName(): string {
    const myOrganizationStore = useMyOrganizationStore()
    const tenantStore = useTenantStore()
    const { activeOrganization } = storeToRefs(myOrganizationStore)
    const { activeTenant } = storeToRefs(tenantStore)

    const { isUserAdmin } = useActiveUserHelper()

    if (isLoggedIn()) {
      if (activeTenant?.value) {
        return Routes.TENANT_DASHBOARD
      }

      if (activeOrganization?.value && activeOrganization?.value?.provider) {
        if (isUserAdmin()) {
          return Routes.PROVIDER_DASHBOARD
        } else {
          return Routes.PROVIDER_CONSUMERS
        }
      } else if (activeOrganization?.value && !activeOrganization?.value?.provider) {
        if (isUserAdmin()) {
          return Routes.CONSUMER_DASHBOARD
        } else {
          return Routes.CONSUMER_PROVIDERS
        }
      } else {
        return Routes.HOME
      }
    } else {
      return Routes.CONSUMER_PRODUCTS
    }
  }
  return { getUserStartRouteName }
}
