import { ProductResponse } from '@/models/Product'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { PlanResponse, PlanRequest, PlanResponseList } from '@/models/Plan'
import { StoreName } from '@/models/enums/StoreName'

interface planStateProperties {
  plans: PlanResponse[]
}

export const usePlanStore = defineStore(StoreName.PLANS, {
  state: (): planStateProperties => ({
    plans: [],
  }),
  actions: {
    async CREATE(productId: ProductResponse['id'], newPlan: PlanRequest): Promise<void> {
      try {
        const { data }: { data: PlanResponse } = await useAxios.post(`/api/plans/${productId}`, newPlan)
        this.plans.push(data)
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(updatedPlan: PlanRequest, planId: PlanResponse['id']): Promise<void> {
      try {
        const { data }: { data: PlanResponse } = await useAxios.patch(`/api/plans/${planId}`, updatedPlan)
        const index = this.plans.findIndex((plan) => plan.id === planId)
        this.plans[index] = data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(deletedPlan: PlanResponse): Promise<void> {
      try {
        await useAxios.delete(`/api/plans/${deletedPlan.id}`)
        this.plans = this.plans.filter((plan) => plan.id !== deletedPlan.id)
      } catch {
        return Promise.reject()
      }
    },
    async GET_PRODUCT_PLANS(productId: ProductResponse['id']): Promise<void> {
      try {
        const { data }: { data: PlanResponseList } = await useAxios.get(`/api/products/${productId}/plans`)
        this.plans = data.plans
      } catch {
        return Promise.reject()
      }
    },
  },
})
