import { AccessStatus, AccessValidity } from '@/models/Access'
import { AccessDefinitionFormEmit, AccessDefinitionResponse } from '@/models/AccessDefinition'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { Icons } from '@/models/enums/IconTypes'
import { i18n } from '@/plugins/i18n'

interface AccessHelper {
  getAccessDefinitionTypes: () => AccessDefinitionType[]
  getNameFromAccessDefinition: (accessDefinition?: AccessDefinitionResponse | AccessDefinitionFormEmit) => string
  getIconFromAccessDefinition: (accessDefinition?: AccessDefinitionResponse) => string
  getMostRecentAccessStatus: (accessStatus?: Array<AccessStatus>) => AccessStatus | undefined
  getRightPeriod: (validityPeriod?: number) => string
  getAccessValidityPeriodListinYears: () => Array<AccessValidity>
  getAccessMaxTokenLengthList: () => Array<number>
}

export interface AccessDefinitionType {
  key: AccessExchangeTypes
  name: string
  icon: Icons
}

/**
 * useAccessHelper
 * @return {AccessHelper}
 */
export function useAccessHelper(): AccessHelper {
  const accessDefinitionTypes = [
    {
      key: AccessExchangeTypes.MANUAL_EXCHANGE,
      name: i18n.t(`accessTypes.${AccessExchangeTypes.MANUAL_EXCHANGE}`),
      icon: Icons.SWITCH,
    },
    {
      key: AccessExchangeTypes.GENERATE_TOKEN,
      name: i18n.t(`accessTypes.${AccessExchangeTypes.GENERATE_TOKEN}`),
      icon: Icons.ALGORITHM,
    },
  ]

  const accessValidityPeriods = [365, 730, 0]

  const accessMaxTokenLength = [32, 64, 128]

  /**
   * getAccessDefinitionTypes
   * @return {AccessDefinitionType[]}
   */
  function getAccessDefinitionTypes(): AccessDefinitionType[] {
    return accessDefinitionTypes
  }

  /**
   * getNameFromAccessDefinition
   * @param {AccessDefinitionResponse | AccessDefinitionFormEmit} accessDefinition
   * @return {string}
   */
  function getNameFromAccessDefinition(accessDefinition?: AccessDefinitionResponse | AccessDefinitionFormEmit): string {
    return (
      accessDefinitionTypes.find((accessDefinitionType) => accessDefinitionType.key === accessDefinition?.accessExchangeType)?.name ||
      i18n.t('accessTypes.unknownMethod')
    )
  }

  /**
   * getIconFromAccessDefinition
   * @param {AccessDefinitionResponse} accessDefinition
   * @return {string}
   */
  function getIconFromAccessDefinition(accessDefinition?: AccessDefinitionResponse): string {
    return (
      accessDefinitionTypes.find((accessDefinitionType) => accessDefinitionType.key === accessDefinition?.accessExchangeType)?.icon || Icons.SUPPORT_QUESTION
    )
  }

  /**
   * getMostRecentAccessStatus
   * @param {Array<AccessStatus> | undefined} accessStatus
   * @return {AccessStatus | undefined}
   */
  function getMostRecentAccessStatus(accessStatus?: Array<AccessStatus>): AccessStatus | undefined {
    return accessStatus?.sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime())[0]
  }

  /**
   * get year amount from days
   * @param {number | undefined} validityPeriod amount of days
   * @return {string} amount of years or unlimited if days is 0
   */
  function getRightPeriod(validityPeriod?: number): string {
    if (validityPeriod || (validityPeriod === 0 && validityPeriod >= 0)) {
      if (validityPeriod === 0) {
        return i18n.t('validityPeriodUnlimited')
      } else {
        const years = validityPeriod / 365
        return i18n.t('validityPeriodYears', { count: years })
      }
    } else {
      return i18n.t('validityPeriodNotSet')
    }
  }

  /**
   * get list for available validities in years
   * @return {Array<AccessValidity>} list of validities in years
   */
  function getAccessValidityPeriodListinYears(): Array<AccessValidity> {
    const items: Array<AccessValidity> = []

    accessValidityPeriods.forEach((value: number) => {
      const years = value > 0 ? value / 365 : 0
      items.push({
        key: years > 0 ? i18n.t('validityPeriodYears', { count: years }) : i18n.t('validityPeriodUnlimited'),
        value,
      })
    })

    return items
  }

  /**
   * get list for available max token lengths
   * @return {Array<number>} list of max token length
   */
  function getAccessMaxTokenLengthList(): Array<number> {
    return accessMaxTokenLength
  }

  return {
    getNameFromAccessDefinition,
    getIconFromAccessDefinition,
    getMostRecentAccessStatus,
    getAccessDefinitionTypes,
    getRightPeriod,
    getAccessValidityPeriodListinYears,
    getAccessMaxTokenLengthList,
  }
}
