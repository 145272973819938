<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit', technicalUser.id)">
    <template #content>
      <h1>{{ t('deleteTechnicalUser.headline') }}</h1>
      <p>{{ t('deleteTechnicalUser.copy', { name: technicalUser.name }) }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit', technicalUser.id)"
      >
        {{ t('buttons.delete') }}
      </DefaultButton>

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import { TechnicalUserResponse } from '@/models/TechnicalUser'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  technicalUser: {
    type: Object as PropType<TechnicalUserResponse>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  deleteTechnicalUser:
    headline: Technischen Nutzer wirklich löschen?
    copy: Möchtest du den technischen Nutzer {name} wirklich löschen? Dies kann nicht rückgängig gemacht werden.
</i18n>
