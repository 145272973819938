import { BundleApprovalResponse } from '@/models/Bundle'
import { StatusTypes } from '@/models/enums/StatusTypes'

interface BundleApprovalHelper {
  getMostRecentApproval: (approvals: Array<BundleApprovalResponse> | undefined) => BundleApprovalResponse | undefined
  isMostRecentApprovalNotRejectedOrTerminated: (approvals: Array<BundleApprovalResponse>) => boolean
}

/**
 * useBundleApprovalHelper
 * @return {BundleApprovalHelper}
 */
export function useBundleApprovalHelper(): BundleApprovalHelper {
  /**
   * getMostRecentApproval
   * @param {approvals} approvals
   * @return {Approval}
   */
  function getMostRecentApproval(approvals?: Array<BundleApprovalResponse>): BundleApprovalResponse | undefined {
    return approvals?.sort((a, b) => new Date(b.createdAt || 0).getTime() - new Date(a.createdAt || 0).getTime())[0]
  }

  /**
   * isMostRecentApprovalNotRejectedOrTerminated
   * @param {approvals} approvals
   * @return {boolean}
   */
  function isMostRecentApprovalNotRejectedOrTerminated(approvals: Array<BundleApprovalResponse>): boolean {
    const mostRecentApproval = getMostRecentApproval(approvals)
    return (
      !mostRecentApproval ||
      (mostRecentApproval.approvalStatus !== StatusTypes.REJECTED && mostRecentApproval.approvalStatus !== StatusTypes.TERMINATED)
    )
  }

  return {
    getMostRecentApproval,
    isMostRecentApprovalNotRejectedOrTerminated,
  }
}
