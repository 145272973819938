<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('products.overview.headline') }}
    </template>
  </GeneralPageTitle>

  <Searchbar
    :preset-options="presetSearchbarValues"
    :searchbar-variant="SearchbarVariants.LARGE"
    :searchbar-label="t('products.searchbarLabel')"
    :loading="searchbarLoading && !initLoading"
    :result-label="t('products.results', { count: products.length })"
    :sort-options="getDefaultSortOptions()"
    :filter-options="activeOrganization ? getFilterOptionsProduct() : undefined"
    @updated-filter="(updatedFilterValues: SearchbarValues) => handleFilter(updatedFilterValues)"
  />

  <div
    v-if="initLoading"
    class="d-flex justify-center"
  >
    <v-progress-circular
      indeterminate
      size="100"
    />
  </div>
  <div
    v-else
    class="search-apis d-flex"
  >
    <ProductList
      v-if="products.length > 0"
      :products="products"
      :view="view"
      @handle-product-click="goToProduct"
    />
    <p
      v-else
      class="search-apis__no-products"
    >
      {{ t('products.overview.noProducts') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useDisplay } from 'vuetify'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useProductStore } from '@/store/products'
import ProductList from '@/components/products/ProductList.vue'
import Searchbar from '@/components/Searchbar.vue'
import { ProductResponse } from '@/models/Product'
import { SearchbarValues } from '@/models/SearchbarValues'
import { Routes } from '@/models/enums/Routes'
import { ViewTypes } from '@/models/enums/ViewTypes'
import { useSearchHelper } from '@/composables/useSearchHelper'
import { SearchbarVariants } from '@/models/enums/SearchbarVariants'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { FilterValuesProduct } from '@/models/enums/FilterValues'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const productStore = useProductStore()
const myOrganizationStore = useMyOrganizationStore()
const { mdAndUp } = useDisplay()

const { products } = storeToRefs(productStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)

const { getDefaultSortOptions, getFilterOptionsProduct } = useSearchHelper()

const searchbarLoading = ref(false)
const initLoading = ref(true)

const view = computed(() => {
  return mdAndUp.value ? ViewTypes.CARDS : ViewTypes.LIST
})

/**
 * goToProduct
 * @param {productId} productId
 */
function goToProduct(productId: ProductResponse['id']): void {
  const query = router.currentRoute.value.query.selectedBundleId ? { selectedBundleId: router.currentRoute.value.query.selectedBundleId } : undefined
  router.push({ name: Routes.CONSUMER_PRODUCTS_INFOS, params: { productId }, query })
}

/**
 * handleFilter
 * @param {searchbarValues} searchbarValues
 */
async function handleFilter(searchbarValues: SearchbarValues): Promise<void> {
  searchbarLoading.value = true
  try {
    await productStore.GET_PRODUCTS({
      ...(searchbarValues.searchQuery ? { q: searchbarValues.searchQuery } : {}),
      includePrivate: searchbarValues.selectedFilterOptions?.includes(FilterValuesProduct.PRIVATE),
      includePublic: searchbarValues.selectedFilterOptions?.includes(FilterValuesProduct.PUBLIC),
      sort: searchbarValues.selectedSortOption,
    })
  } finally {
    initLoading.value = false
    searchbarLoading.value = false
  }
}

const presetSearchbarValues = route.query.providerName ? { searchbarRoute: route.path, searchQuery: route.query.providerName.toString() } : undefined
</script>

<style lang="scss" scoped>
.search-apis {
  margin-bottom: 2rem;

  &__no-products {
    font-size: $font-size-s;
    margin-top: 2rem;
  }
}
</style>

<i18n lang="yaml">
de:
  products:
    searchbarLabel: API-Produkte durchsuchen
    results: '{count} API | {count} API-Produkte'
    overview:
      headline: Marktplatz
      noProducts: Keine API-Produkte gefunden!
</i18n>
