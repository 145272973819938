<template>
  <div class="credential-container">
    <DialogSplitLayout>
      <template #content-left>
        <div class="d-flex flex-column step-wrapper">
          <div class="step-item text-center">
            <DefaultAvatar
              size="64"
              color="white"
              class="mb-2"
            >
              <v-icon
                size="32"
                :color="PlattformColors.PRIMARY"
                >{{ !isOnlyManuelAccessDefinition ? Icons.SAVE : Icons.CIRCLE_INFORMATION }}</v-icon
              >
            </DefaultAvatar>
            <h2>{{ t('reNewCredentials.step1.headline') }}</h2>
            <p v-if="!isOnlyManuelAccessDefinition">{{ t('reNewCredentials.step1.copy') }}</p>
            <p v-else>{{ t('reNewCredentials.step1.copyManuel') }}</p>
          </div>

          <div class="step-item text-center">
            <DefaultAvatar
              size="64"
              color="white"
              class="mb-2"
            >
              <v-icon
                size="32"
                :color="PlattformColors.PRIMARY"
                >{{ Icons.MESSAGE }}</v-icon
              >
            </DefaultAvatar>
            <h2>{{ t('reNewCredentials.step2.headline') }}</h2>
            <p v-if="!isOnlyManuelAccessDefinition">{{ t('reNewCredentials.step2.copy') }}</p>
            <p v-else>{{ t('reNewCredentials.step2.copyManuel') }}</p>
          </div>

          <div class="step-item text-center">
            <DefaultAvatar
              size="64"
              color="white"
              class="mb-2"
            >
              <v-icon
                size="32"
                :color="PlattformColors.PRIMARY"
                >{{ Icons.ROCKET }}</v-icon
              >
            </DefaultAvatar>
            <h2>{{ t('reNewCredentials.step3.headline') }}</h2>
            <p>{{ t('reNewCredentials.step3.copy') }}</p>
          </div>
        </div>
      </template>
      <template #content-right>
        <div class="access-container">
          <h1>{{ t('reNewCredentials.headline') }}</h1>
          <p v-if="!isOnlyManuelAccessDefinition">{{ t('reNewCredentials.copy') }}</p>
          <template v-if="!isOnlyManuelAccessDefinition">
            <v-list>
              <v-list-item class="access-info px-0 mb-8">
                <template #prepend>
                  <DefaultAvatar
                    size="32"
                    :color="PlattformColors.CHANGE"
                  >
                    <v-icon size="20">{{ Icons.CLOCK }}</v-icon>
                  </DefaultAvatar>
                </template>
                <template #title>{{ authenticatorView?.name }}</template>
                <template #append>
                  <v-chip
                    label
                    size="small"
                    variant="outlined"
                    >{{ t('reNewCredentials.tokenGen') }}</v-chip
                  >
                </template>
              </v-list-item>
            </v-list>
            <div
              v-if="authenticatorView"
              class="credential-container"
            >
              <v-text-field
                :label="t('reNewCredentials.label')"
                readonly
                variant="outlined"
                :model-value="authenticatorView.accessKey"
              >
                <template #append-inner>
                  <CopyToClipBtn :copy-value="authenticatorView?.accessKey" />
                </template>
              </v-text-field>
            </div>
          </template>

          <v-list>
            <v-list-item
              class="manual-info px-0"
              lines="two"
            >
              <template #prepend>
                <DefaultAvatar
                  size="32"
                  :color="PlattformColors.INFO"
                >
                  <v-icon size="20">{{ Icons.CIRCLE_INFORMATION }}</v-icon>
                </DefaultAvatar>
              </template>
              {{ t('reNewCredentials.manualInfo') }}
            </v-list-item>
          </v-list>
          <template v-if="!isOnlyManuelAccessDefinition">
            <v-divider
              class="my-8"
              color="black"
            />

            <div class="d-flex">
              <DefaultCheckbox
                v-model="checkBox"
                class="mr-6"
                :label="t('reNewCredentials.checkBoxLabel')"
              />
            </div>
          </template>
        </div>
      </template>
      <template #buttons>
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          :disabled="!isOnlyManuelAccessDefinition && !checkBox"
          :loading="loading"
          @click="emit('createNewAccessKey', authenticators)"
          >{{ t('reNewCredentials.submitBtn') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
          >{{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogSplitLayout>
  </div>
</template>

<script lang="ts" setup>
import sha256 from 'crypto-js/sha256'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { AccessTokenAuthenticatorView, AuthenticatorRequest } from '@/models/Authenticator'
import { Icons } from '@/models/enums/IconTypes'
import { AccessDefinitionResponse, AccessDefinitionTokenResponse } from '@/models/AccessDefinition'
import DialogSplitLayout from './layout/DialogSplitLayout.vue'
import { AccessExchangeTypes, AccessTypes } from '@/models/enums/AccessDefinitionTypes'
import { onMounted } from 'vue'
import { computed } from 'vue'
import CopyToClipBtn from '@/components/CopyToClipBtn.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import DefaultCheckbox from '../baseComponents/checkboxes/DefaultCheckbox.vue'

const props = defineProps({
  accessDefinitions: { type: Array<AccessDefinitionResponse>, default: [] },
  requestInProgress: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
})

const emit = defineEmits(['createNewAccessKey', 'cancel'])

const { t } = useI18n()

const authenticatorView = ref<AccessTokenAuthenticatorView>()
const authenticators: AuthenticatorRequest[] = []
const checkBox = ref(false)

const isOnlyManuelAccessDefinition = computed(() => {
  return (
    props.accessDefinitions.length < 2 &&
    props.accessDefinitions.find((accessDefinition) => accessDefinition.accessExchangeType === AccessExchangeTypes.MANUAL_EXCHANGE)
  )
})

/**
 * dec2hex
 * @param {dec} dec
 * @return {string}
 */
function dec2hex(dec: number): string {
  return dec.toString(16).padStart(2, '0')
}

/**
 * createAuthenticators
 */
function createAuthenticators(): void {
  props.accessDefinitions.forEach((accessDefinition) => {
    if (accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN) {
      // create random accesskey and hash
      const accessKey = Array.from(
        window.crypto.getRandomValues(new Uint8Array((accessDefinition as AccessDefinitionTokenResponse).accessType.maxTokenLength / 2)),
        dec2hex
      ).join('')
      const tokenHash = sha256(accessKey).toString()

      authenticatorView.value = {
        name: t('hiddenAccess', {
          accessKey: accessKey.slice(-4),
        }),
        accessKey,
      }

      authenticators.push({
        accessDefinitionId: (accessDefinition as AccessDefinitionTokenResponse).id,
        name: t('hiddenAccess', {
          accessKey: accessKey.slice(-4),
        }),
        type: (accessDefinition as AccessDefinitionTokenResponse).accessType.type,
        tokenHash,
        validFrom: new Date().toISOString(),
      })
    } else {
      authenticators.push({
        accessDefinitionId: accessDefinition.id,
        name: AccessExchangeTypes.MANUAL_EXCHANGE,
        type: AccessTypes.NO_RESSOURCE,
      })
    }
  })
}

onMounted(() => {
  createAuthenticators()
})
</script>

<style lang="scss" scoped>
.credential-container {
  .step-item {
    margin-bottom: 3.5rem;

    h2 {
      margin-bottom: 0;
    }
  }

  .access-container {
    h1 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 2rem;
    }

    .v-checkbox {
      :deep(.v-label) {
        opacity: 1;
      }
    }
  }
}
</style>

<i18n lang="yaml">
de:
  reNewCredentials:
    headline: Aktivierung beantragen
    copy: Beantrage die Aktivierung deines Access Keys, um diesen zur Authentifizierung deiner API-Produkte nutzen zu können.
    label: Access Key
    tokenGen: Access Token Generierung
    manualInfo: Für die »Manuelle Übergabe« wird sich dein Anbieter gesondert mit dir in Verbindung setzen.
    checkBoxLabel: Ich bin mir darüber im klaren, dass ich den Access Key zu einem späteren Zeitpunkt nicht mehr einsehen kann.
    submitBtn: Antrag senden
    step1:
      headline: Schritt 1
      copy: Speichere deinen Access Key.
      copyManuel: Setze dich mit dem Anbieter in Verbindung.
    step2:
      headline: Schritt 2
      copy: Beantrage die Aktivierung deines Access Keys.
      copyManuel: Der Anbieter übergibt dir den Access Key.
    step3:
      headline: Schritt 3
      copy: Sobald dein Anbieter deinen Access Key aktiviert hat, kannst du diesen zur Authentifizierung deiner API-Produkte nutzen.
</i18n>
