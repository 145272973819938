<template>
  <div class="dialog-layout-wrapper bg-white">
    <slot name="content"></slot>
    <div class="dialog-divider py-8">
      <v-divider
        color="primary"
        class="dialog-divider__line"
      />
    </div>
    <div class="d-flex justify-space-between flex-row-reverse">
      <slot name="buttons"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dialog-layout-wrapper {
  padding: 4rem 4rem 2rem;
}

.dialog-divider {
  height: 100%;

  &__line {
    position: absolute;
    width: 100%;
    left: 0;
  }
}
</style>
