<template>
  <v-navigation-drawer
    v-model="navDrawer"
    :mini-variant="false"
    fixed
    color="primary"
    class="v-navigation-drawler"
    :width="navWidth"
  >
    <IconButton
      class="v-navigation-drawler-close d-sm-none"
      :icon="Icons.CLOSE"
      @click.stop="navigationStore.toggleDrawer()"
    />
    <div class="wrapper">
      <router-link
        :to="{ name: Routes.INDEX }"
        class="v-navigation-drawler-header d-flex mt-4 ml-4"
      >
        <v-img
          contain
          class="v-navigation-drawler-icon"
          :src="resolvedTenant?.theming.logo"
          max-height="100"
        />
      </router-link>
      <div class="wrapper">
        <div>
          <v-list class="navigation-groups">
            <v-list-item
              v-for="(group, index) in navigationGroups"
              :key="index"
              no-action
              class="navigation__group"
            >
              <v-list>
                <v-list-item
                  v-for="(item, i) in filteredNavigationGroup(group.items)"
                  :key="i"
                  :to="item.to"
                  :exact="item.exact"
                  class="bg-primary"
                  :data-cy="NavDrawerTest.ITEM"
                >
                  <template
                    v-if="item.icon"
                    #prepend
                  >
                    <v-icon :icon="item.icon" />
                  </template>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-list-item>
          </v-list>
        </div>
        <Selectbox v-if="isLoggedIn()" />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { useDisplay } from 'vuetify'
import { useNavigationStore } from '@/store/navigations'
import { storeToRefs } from 'pinia'
import Selectbox from './Selectbox.vue'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { useActiveUserHelper } from '@/composables/useActiveUserHelper'
import { useNavigationHelper } from '@/composables/useNavigationHelper'
import IconButton from '../baseComponents/buttons/IconButton.vue'
import { isLoggedIn } from '@/plugins/keycloak'
import { useTenantStore } from '@/store/tenants'
import { NavDrawerTest } from '@/models/enums/test/NavDrawerTest'
import { NavigationItem } from '@/models/NavigationGroup'

const breakpoints = useDisplay()
const myOrganizationStore = useMyOrganizationStore()
const navigationStore = useNavigationStore()
const tenantStore = useTenantStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { navDrawer } = storeToRefs(navigationStore)
const { resolvedTenant, activeTenant } = storeToRefs(tenantStore)

const { isUserAdmin } = useActiveUserHelper()
const { getProviderGroups, getConsumerGroups, getNoOrganizationGroups, getPublicGroups, getTenantGroups } = useNavigationHelper()

const navWidth = computed(() => {
  return breakpoints.smAndUp.value ? 256 : window.innerWidth
})
const navigationGroups = computed(() => {
  if (isLoggedIn() && activeTenant?.value) {
    return getTenantGroups()
  }

  if (isLoggedIn() && activeOrganization?.value) {
    return activeOrganization.value.provider ? getProviderGroups() : getConsumerGroups()
  }

  if (isLoggedIn()) {
    return getNoOrganizationGroups()
  }

  return getPublicGroups()
})

/**
 * filteredNavigationGroup
 * @param {NavigationItem[]} groupItems
 * @return {NavigationItem[]}
 */
function filteredNavigationGroup(groupItems: NavigationItem[]): NavigationItem[] {
  return groupItems.filter((item) => !item.admin || (item.admin && isUserAdmin()))
}
</script>

<style lang="scss" scoped>
.v-list {
  width: 100%;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.v-navigation-drawler {
  width: 100%;

  &-header {
    width: 100%;
    margin-bottom: 1rem;
    text-decoration: none;
  }

  &-icon {
    max-width: 9.5rem;
  }

  &-close {
    background-color: transparent;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }

  .navigation__group {
    padding: 0;

    &--img {
      margin-right: 2rem;
    }
  }
}
</style>
