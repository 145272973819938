import { RouteLocationNormalized, RouteLocationRaw, createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/user/Home.vue'
import Invitations from '@/views/Invitations.vue'
import MyOrganizations from '@/views/MyOrganizations.vue'
import OrganizationOverview from '@/views/organization/Index.vue'
import ProviderDashboard from '@/views/provider/Dashboard.vue'
import ConsumerDashboard from '@/views/consumer/Dashboard.vue'
import ProviderProductsOverview from '@/views/provider/products/Overview.vue'
import ProviderProductDetail from '@/views/provider/products/Detail.vue'
import ProviderProductDetails from '@/views/provider/products/detail/Details.vue'
import ProviderProductFiles from '@/views/provider/products/detail/Files.vue'
import ProviderProductAccesses from '@/views/provider/products/detail/Accesses.vue'
import ProviderProductPlans from '@/views/provider/products/detail/Plans.vue'
import UserManagement from '@/views/organization/UserManagement.vue'
import AccessDefinitions from '@/views/organization/AccessDefinitions.vue'
import UserManagementUsers from '@/views/organization/user-management/Users.vue'
import UserManagementTeams from '@/views/organization/user-management/Teams.vue'
import OrganizationDetails from '@/views/organization/Details.vue'
import SettingsTechnicalUser from '@/views/organization/TechnicalUser.vue'
import ContactPersons from '@/views/organization/ContactPersons.vue'
import ConsumerProviderOverview from '@/views/consumer/providers/Overview.vue'
import ConsumerProviderDetail from '@/views/consumer/providers/Detail.vue'
import ConsumerProviderDetailFiles from '@/views/consumer/providers/details/Files.vue'
import ConsumerBundlesOverview from '@/views/consumer/bundles/Overview.vue'
import ConsumerBundlesDetail from '@/views/consumer/bundles/Detail.vue'
import ConsumerBundlesDetailProducts from '@/views/consumer/bundles/detail/Products.vue'
import ConsumerBundlesCredentials from '@/views/consumer/bundles/detail/Credentials.vue'
import ConsumerBundlesDetailBusinessCase from '@/views/consumer/bundles/detail/BusinessCase.vue'
import ConsumerBundlesContact from '@/views/consumer/bundles/detail/Contact.vue'
import ConsumerProductsOverview from '@/views/public/products/Overview.vue'
import ConsumerProductsDetail from '@/views/public/products/Detail.vue'
import ConsumerProductsDetailsInfos from '@/views/public/products/details/Infos.vue'
import ConsumerProductsDetailDocs from '@/views/public/products/details/Docs.vue'
import ConsumerProductsDetailOpenAPI from '@/views/public/products/details/OpenAPI.vue'
import ConsumerProductsDetailPlans from '@/views/public/products/details/Plans.vue'
import ConsumerProductsDetailDownloads from '@/views/public/products/details/Downloads.vue'
// TODO DEVPO-3290
// import ConsumerProductsDetailContact from '@/views/public/products/details/Contact.vue'
import ProviderBundlesDetail from '@/views/provider/bundles/Detail.vue'
import ProviderBundlesDetailProducts from '@/views/provider/bundles/detail/Products.vue'
import ProviderBundlesCredentials from '@/views/provider/bundles/detail/Credentials.vue'
import ProviderBundlesDetailBusinessCase from '@/views/provider/bundles/detail/BusinessCase.vue'
import ProviderBundlesDetailContact from '@/views/provider/bundles/detail/Contact.vue'
import ProviderConsumersOverview from '@/views/provider/consumers/Overview.vue'
import ProviderConsumersDetail from '@/views/provider/consumers/Detail.vue'
import ProviderConsumersDetailBundles from '@/views/provider/consumers/details/Bundles.vue'
import ProviderConsumersDetailFiles from '@/views/provider/consumers/details/Files.vue'
import ProviderConsumersDetailOrganizationData from '@/views/provider/consumers/details/OrganizationData.vue'
// Tenant Views
import TenantDashboard from '@/views/tenant/Dashboard.vue'
import TenantSettings from '@/views/tenant/settings/Index.vue'
import TenantData from '@/views/tenant/settings/TenantData.vue'
import TenantContactPersons from '@/views/tenant/settings/ContactPersons.vue'
import TenantUserManagement from '@/views/tenant/settings/UserManagement.vue'
import TenantLegalContent from '@/views/tenant/settings/LegalContent.vue'
import TenantPlatformInformation from '@/views/tenant/settings/PlatformInformation.vue'
import TenantProviderOverview from '@/views/tenant/providers/Overview.vue'
import TenantProviderDetail from '@/views/tenant/providers/Detail.vue'

import { Routes } from './models/enums/Routes'
import { useRouterHelper } from './composables/useRouterHelper'
import { useMyOrganizationStore } from './store/myOrganizations'
import { storeToRefs } from 'pinia'
import { isLoggedIn } from './plugins/keycloak'
import { NavigationContexts } from './models/enums/NavigationContexts'
import { useTenantStore } from './store/tenants'

declare module 'vue-router' {
  interface RouteMeta {
    layout?: string
    navigationContext: Array<NavigationContexts>
    noActiveProvider?: boolean
  }
}

export const routes = [
  {
    path: '/',
    name: Routes.INDEX,
    component: Home,
    meta: {
      navigationContext: [],
    },
    beforeEnter: async (to: RouteLocationNormalized): Promise<RouteLocationRaw | undefined> => {
      const { getUserStartRouteName } = useRouterHelper()
      const startRoute = getUserStartRouteName()
      if (startRoute !== to.name) {
        return { name: startRoute }
      }
    },
  },
  {
    path: '/home',
    name: Routes.HOME,
    component: Home,
    meta: {
      navigationContext: [NavigationContexts.USER],
    },
  },
  {
    path: '/invitations',
    name: Routes.INVITATIONS,
    meta: {
      layout: 'CleanLayout',
      noActiveOrganization: true,
      navigationContext: [NavigationContexts.USER, NavigationContexts.CONSUMER, NavigationContexts.PROVIDER, NavigationContexts.TENANT],
    },
    component: Invitations,
  },
  {
    path: '/my-organizations',
    name: Routes.MY_ORGANIZATIONS,
    component: MyOrganizations,
    meta: {
      navigationContext: [NavigationContexts.USER, NavigationContexts.CONSUMER, NavigationContexts.PROVIDER, NavigationContexts.TENANT],
      noActiveProvider: true,
    },
  },
  {
    path: '/provider/dashboard',
    name: Routes.PROVIDER_DASHBOARD,
    component: ProviderDashboard,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
  },
  {
    path: '/consumer/dashboard',
    name: Routes.CONSUMER_DASHBOARD,
    component: ConsumerDashboard,
    meta: {
      navigationContext: [NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/organization',
    name: Routes.ORGANIZATION,
    component: OrganizationOverview,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER, NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/organization/details',
    name: Routes.DETAILS,
    component: OrganizationDetails,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER, NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/organization/contact-persons',
    name: Routes.CONTACT_PERSONS,
    component: ContactPersons,
    meta: {
      navigationContext: [NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/organization/technical-user',
    name: Routes.TECHNICAL_USERS,
    component: SettingsTechnicalUser,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER, NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/organization/user-management',
    component: UserManagement,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER, NavigationContexts.CONSUMER],
    },
    children: [
      {
        path: 'users',
        name: Routes.USERS,
        component: UserManagementUsers,
      },
      {
        path: 'teams',
        name: Routes.TEAMS,
        component: UserManagementTeams,
      },
    ],
  },
  {
    path: '/organization/access-definitions',
    name: Routes.ACCESS_DEFINITIONS,
    component: AccessDefinitions,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
  },
  {
    path: '/provider/products',
    name: Routes.PROVIDER_PRODUCTS,
    component: ProviderProductsOverview,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
  },
  {
    path: '/provider/products/:productId',
    component: ProviderProductDetail,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
    children: [
      {
        path: 'details',
        name: Routes.PROVIDER_PRODUCTS_DETAILS,
        component: ProviderProductDetails,
      },
      {
        path: 'files',
        name: Routes.PROVIDER_PRODUCTS_FILES,
        component: ProviderProductFiles,
      },
      {
        path: 'accesses',
        name: Routes.PROVIDER_PRODUCTS_ACCESSES,
        component: ProviderProductAccesses,
      },
      {
        path: 'plans',
        name: Routes.PROVIDER_PRODUCTS_PLANS,
        component: ProviderProductPlans,
      },
    ],
  },
  {
    path: '/provider/bundles/:bundleId',
    component: ProviderBundlesDetail,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
    children: [
      {
        path: 'products',
        name: Routes.PROVIDER_BUNDLES_PRODUCTS,
        component: ProviderBundlesDetailProducts,
      },
      {
        path: 'business-case',
        name: Routes.PROVIDER_BUNDLES_BUSINESSCASE,
        component: ProviderBundlesDetailBusinessCase,
      },
      {
        path: 'credentials',
        name: Routes.PROVIDER_BUNDLES_CREDENTIALS,
        component: ProviderBundlesCredentials,
      },
      {
        path: 'contact',
        name: Routes.PROVIDER_BUNDLES_CONTACT,
        component: ProviderBundlesDetailContact,
      },
    ],
  },
  {
    path: '/provider/consumers',
    name: Routes.PROVIDER_CONSUMERS,
    component: ProviderConsumersOverview,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
  },
  {
    path: '/provider/consumers/:consumerId',
    component: ProviderConsumersDetail,
    meta: {
      navigationContext: [NavigationContexts.PROVIDER],
    },
    children: [
      {
        path: 'bundles',
        name: Routes.PROVIDER_CONSUMERS_BUNDLES,
        component: ProviderConsumersDetailBundles,
      },
      {
        path: 'files',
        name: Routes.PROVIDER_CONSUMERS_FILES,
        component: ProviderConsumersDetailFiles,
      },
      {
        path: 'organization',
        name: Routes.PROVIDER_CONSUMERS_ORGANIZATION,
        component: ProviderConsumersDetailOrganizationData,
      },
    ],
  },
  {
    path: '/consumer/providers',
    name: Routes.CONSUMER_PROVIDERS,
    component: ConsumerProviderOverview,
    meta: {
      navigationContext: [NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/consumer/providers/:providerId',
    component: ConsumerProviderDetail,
    meta: {
      navigationContext: [NavigationContexts.CONSUMER],
    },
    children: [
      {
        path: 'files',
        name: Routes.CONSUMER_PROVIDERS_FILES,
        component: ConsumerProviderDetailFiles,
      },
    ],
  },
  {
    path: '/consumer/bundles',
    name: Routes.CONSUMER_BUNDLES,
    component: ConsumerBundlesOverview,
    meta: {
      navigationContext: [NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/consumer/bundles/:bundleId',
    component: ConsumerBundlesDetail,
    meta: {
      navigationContext: [NavigationContexts.CONSUMER],
    },
    children: [
      {
        path: 'products',
        name: Routes.CONSUMER_BUNDLES_PRODUCTS,
        component: ConsumerBundlesDetailProducts,
      },
      {
        path: 'credentials',
        name: Routes.CONSUMER_BUNDLES_CREDENTIALS,
        component: ConsumerBundlesCredentials,
      },
      {
        path: 'business-case',
        name: Routes.CONSUMER_BUNDLES_BUSINESSCASE,
        component: ConsumerBundlesDetailBusinessCase,
      },
      {
        path: 'contact',
        name: Routes.CONSUMER_BUNDLES_CONTACT,
        component: ConsumerBundlesContact,
      },
    ],
  },
  {
    path: '/marketplace',
    name: Routes.CONSUMER_PRODUCTS,
    component: ConsumerProductsOverview,
    meta: {
      navigationContext: [NavigationContexts.UNREGISTERED, NavigationContexts.USER, NavigationContexts.CONSUMER],
    },
  },
  {
    path: '/marketplace/:productId',
    component: ConsumerProductsDetail,
    meta: {
      navigationContext: [NavigationContexts.UNREGISTERED, NavigationContexts.USER, NavigationContexts.CONSUMER],
    },
    children: [
      {
        path: 'infos',
        name: Routes.CONSUMER_PRODUCTS_INFOS,
        component: ConsumerProductsDetailsInfos,
      },
      {
        path: 'docs',
        name: Routes.CONSUMER_PRODUCTS_DOCS,
        component: ConsumerProductsDetailDocs,
      },
      {
        path: 'open-api',
        name: Routes.CONSUMER_PRODUCTS_OPENAPI,
        component: ConsumerProductsDetailOpenAPI,
      },
      {
        path: 'plans',
        name: Routes.CONSUMER_PRODUCTS_PLANS,
        component: ConsumerProductsDetailPlans,
      },
      {
        path: 'downloads',
        name: Routes.CONSUMER_PRODUCTS_DOWNLOADS,
        component: ConsumerProductsDetailDownloads,
        meta: {
          navigationContext: [NavigationContexts.USER, NavigationContexts.CONSUMER],
        },
      },
      // TODO DEVPO-3290
      // {
      //   path: 'contact',
      //   name: Routes.CONSUMER_PRODUCTS_CONTACT,
      //   component: ConsumerProductsDetailContact,
      // },
    ],
  },
  // TENANT ROUTES
  {
    path: '/tenant/dashboard',
    name: Routes.TENANT_DASHBOARD,
    component: TenantDashboard,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/settings',
    name: Routes.TENANT_SETTINGS,
    component: TenantSettings,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/settings/data',
    name: Routes.TENANT_SETTINGS_TENANT_DATA,
    component: TenantData,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/settings/contact-persons',
    name: Routes.TENANT_SETTINGS_CONTACT_PERSONS,
    component: TenantContactPersons,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/settings/user-management',
    name: Routes.TENANT_SETTINGS_USER_MANAGEMENT,
    component: TenantUserManagement,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/settings/legal-content',
    name: Routes.TENANT_SETTINGS_LEGAL_CONTENT,
    component: TenantLegalContent,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/settings/platform-information',
    name: Routes.TENANT_SETTINGS_PLATFORM_INFORMATION,
    component: TenantPlatformInformation,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/provider',
    name: Routes.TENANT_PROVIDER,
    component: TenantProviderOverview,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  {
    path: '/tenant/provider/:providerId',
    name: Routes.TENANT_PROVIDER_DETAIL,
    component: TenantProviderDetail,
    meta: {
      navigationContext: [NavigationContexts.TENANT],
    },
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

export const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to) => {
  const myOrganizationStore = useMyOrganizationStore()
  const tenantStore = useTenantStore()
  const { activeOrganization } = storeToRefs(myOrganizationStore)
  const { activeTenant } = storeToRefs(tenantStore)

  let context

  if (isLoggedIn() && activeTenant?.value) {
    context = NavigationContexts.TENANT
  } else if (isLoggedIn() && activeOrganization?.value) {
    context = activeOrganization.value.provider ? NavigationContexts.PROVIDER : NavigationContexts.CONSUMER
  } else if (isLoggedIn()) {
    context = NavigationContexts.USER
  } else {
    context = NavigationContexts.UNREGISTERED
  }

  if (!to.meta.navigationContext.length || to.meta.navigationContext.includes(context)) {
    return
  } else {
    return { name: Routes.INDEX }
  }
})
