<template>
  <div class="create-bundle">
    <v-form
      ref="bundleForm"
      v-model="createBundleForm"
      @submit.prevent="emit('submit', bundle)"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ isEditForm ? t('editBundle.headline') : t('createBundle.headline') }}</h1>
          <p class="mb-8">{{ t('createBundle.copy') }}</p>
          <v-item-group
            v-model="bundle.iconPath"
            class="d-flex mb-6"
          >
            <v-item
              v-for="img in images"
              :key="img.key"
              v-slot="{ isSelected, toggle }"
              :value="img.key"
            >
              <div
                class="cursor-pointer pa-2 mr-4 rounded-xl"
                :class="{ 'border-primary': isSelected, 'border-transparent': !isSelected }"
                @click="toggle"
              >
                <v-img
                  :width="56"
                  :src="img.value"
                />
              </div>
            </v-item>
          </v-item-group>

          <v-autocomplete
            v-model="bundle.providerId"
            :items="availableOrganizations"
            :label="t('createBundle.form.select.label')"
            variant="outlined"
            item-title="name"
            item-value="id"
            :disabled="!!bundleObject"
          />

          <v-text-field
            v-model="bundle.name"
            :label="t('createBundle.form.name.label')"
            counter
            maxlength="25"
            variant="outlined"
            required
            :rules="bundleNameRules"
          >
            <template #append-inner>
              <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
            </template>
          </v-text-field>

          <v-textarea
            ref="bundleFormDesc"
            v-model="bundle.description"
            :autofocus="focusElementRef === 'bundleFormDesc'"
            :label="t('createBundle.form.description.label')"
            variant="outlined"
          ></v-textarea>
        </template>

        <template #buttons>
          <DefaultButton
            :type="ButtonTypes.SUBMIT"
            :color="PlattformColors.PRIMARY"
            :disabled="!createBundleForm"
            :loading="loading"
          >
            {{ t('buttons.save') }}</DefaultButton
          >

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { OrganizationPublicResponse } from '@/models/Organization'
import { Icons } from '@/models/enums/IconTypes'
import { isNotEmpty, isMaxLength } from '@/validators'
import { PropType, Ref, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { BundleResponse, BundleForm } from '@/models/Bundle'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import { useAvatarHelper } from '@/composables/useAvatarHelper'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { ButtonTypes } from '@/models/enums/components/Buttons'

const { t } = useI18n()

const { getAvatarList } = useAvatarHelper()

const emit = defineEmits(['submit', 'cancel'])
const bundleFormDesc = ref<HTMLFormElement>()

const componentProps = defineProps({
  availableOrganizations: {
    type: Array<OrganizationPublicResponse>,
    default: () => [],
  },
  bundleObject: {
    type: Object as PropType<BundleResponse>,
    default: undefined,
  },
  focusElementRef: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const bundleNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 25)])

const images = getAvatarList()
const createBundleForm = ref(false)
const bundleForm = ref<HTMLFormElement>()
const bundle: Ref<BundleForm> = ref({ ...componentProps.bundleObject })

if (!bundle.value.iconPath) bundle.value.iconPath = images[0].key

const isEditForm = computed(() => !!componentProps.bundleObject)
</script>

<i18n lang="yaml">
de:
  createBundle:
    headline: Neue App erstellen
    copy: Gib deiner App einen Namen und beschreibe kurz die Funktionalität. Beachte dabei, dass der Name eindeutig sein muss.
    form:
      select:
        label: Anbieter auswählen
      name:
        label: Name
      description:
        label: Kurzbeschreibung
  editBundle:
    headline: App bearbeiten
</i18n>
