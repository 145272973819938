<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('profileOrganizations.headline') }}
    </template>
  </GeneralPageTitle>
  <div class="content-container">
    <p class="mb-6">
      {{ t('profileOrganizations.copy') }}
    </p>
    <v-list>
      <template
        v-for="organization in myOrganizations"
        :key="organization.id"
      >
        <v-list-item class="py-3">
          <v-list-item-title>{{ organization.name }}</v-list-item-title>
          <template #append>
            <DefaultButton
              :color="PlattformColors.PRIMARY"
              @click="showOverlay(organization)"
            >
              {{ t('buttons.leave') }}
            </DefaultButton>
          </template>
        </v-list-item>
        <v-divider color="grey" />
      </template>
    </v-list>
  </div>
  <Dialog
    ref="leaveOrganizationDialogRef"
    @on-close="leaveOrganizationDialogRef?.close()"
  >
    <DialogOrganizationLeaveQuestion
      :organization="selectedOrganization"
      :loading="leaveOrganizationLoading"
      @submit="leaveOrganization()"
      @cancel="leaveOrganizationDialogRef?.close()"
    />
  </Dialog>
</template>
<script lang="ts" setup>
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DialogOrganizationLeaveQuestion from '@/components/dialogs/DialogOrganizationLeaveQuestion.vue'
import Dialog from '@/components/layout/Dialog.vue'
import { UserOrganization } from '@/models/User'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { PlattformColors } from '@/models/enums/ColorSets'
import { useAlertStore } from '@/store/alerts'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const myOrganizationStore = useMyOrganizationStore()
const alertStore = useAlertStore()

const { myOrganizations } = storeToRefs(myOrganizationStore)

const selectedOrganization = ref<UserOrganization>()
const leaveOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()
const leaveOrganizationLoading = ref(false)

/**
 *
 * showOverlay
 * @param {organization} organization
 */
function showOverlay(organization: UserOrganization): void {
  selectedOrganization.value = organization
  leaveOrganizationDialogRef.value?.open()
}

/**
 *
 * leaveOrganization
 */
async function leaveOrganization(): Promise<void> {
  try {
    leaveOrganizationLoading.value = true
    await myOrganizationStore.DELETE(selectedOrganization.value)
    alertStore.add({
      text: t('profileOrganizations.success.deleted'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    leaveOrganizationLoading.value = false
    leaveOrganizationDialogRef.value?.close()
  }
}
</script>

<i18n lang="yaml">
de:
  profileOrganizations:
    headline: Meine Organisationen
    copy: Hier erhältst du einen Überblick über deine beigetrenen Organisationen und kannst diese verlassen.
    success:
      deleted: Du hast die Organisation verlassen!
</i18n>
