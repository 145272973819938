<template>
  <DefaultButton
    :color="PlattformColors.PRIMARY"
    :variant="ButtonVariants.PLAIN"
    class="mb-4 pl-0"
    :prepend-icon="Icons.ARROW_LEFT"
    @click="handleBack"
  >
    {{ t('back') }}</DefaultButton
  >
</template>

<script lang="ts" setup>
import { Icons } from '@/models/enums/IconTypes'
import { PropType } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router'
import { ButtonVariants } from '@/models/enums/components/Buttons'

const props = defineProps({
  backUrl: {
    type: Object as PropType<RouteLocationRaw>,
    default: undefined,
  },
})

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const previousRoute = computed(() => router.options.history.state.back)

/**
 * handleBack
 */
function handleBack(): void {
  if (previousRoute.value) {
    router.back()
    return
  }
  if (props.backUrl) {
    router.push(props.backUrl)
  } else {
    // this.$router.push('./') works for going down one level but will not work, if clicked twice to traverse down two levels.
    // Instead we have to use a more complicated approach:
    const routePath = route.path
    const splitRoute =
      routePath.charAt(routePath.length - 1) === '/' // check if last character in route is a '/'
        ? route.path.split('/').slice(0, -2) // if true: remove the last two elements instead of just one
        : route.path.split('/').slice(0, -1)
    router.push(splitRoute.join('/'))
  }
}
</script>

<i18n lang="yaml">
de:
  back: Zurück
</i18n>
