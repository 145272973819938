<template>
  <BackButton />
  <GeneralPageTitle>
    <template #title>
      {{ t('tenantData.headline') }}
    </template>
  </GeneralPageTitle>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import BackButton from '@/components/layout/BackButton.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'

const { t } = useI18n()
</script>
<i18n lang="yaml">
de:
  tenantData:
    headline: Kontaktpersonen
</i18n>
