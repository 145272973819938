import { OrganizationWizard } from '@/models/sideWizard/Organization'
import { ResponsibilityWizard } from '@/models/sideWizard/Responsibility'
import { defineStore } from 'pinia'
import { ComputedRef } from 'vue'
import { StoreName } from '@/models/enums/StoreName'

interface WizardContent extends ResponsibilityWizard, OrganizationWizard {}

interface SideWizardStateProperties {
  wizardContent: WizardContent
  wizardValidated: ComputedRef<boolean>[]
}

export const useSideWizardStore = defineStore(StoreName.SIDE_WIZARD, {
  state: (): SideWizardStateProperties => ({
    wizardContent: {},
    wizardValidated: [],
  }),
  actions: {
    setType(type: string) {
      this.wizardContent.type = type
    },
  },
})
