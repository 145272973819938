<template>
  <div class="bundles-overview">
    <GeneralPageTitle>
      <template #title>
        {{ t('consumerBundlesOverview.headline') }}
      </template>
      <template #append>
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          :prepend-icon="Icons.CIRCLE_ADD"
          @click="openCreateBundleDialog()"
        >
          {{ t('consumerBundlesOverview.createBtn') }}
        </DefaultButton>
      </template>
    </GeneralPageTitle>
    <Searchbar
      :filter-options="getFilterOptionsBundleStatusExtended()"
      :sort-options="getDefaultSortOptions()"
      :searchbar-label="t('consumerBundlesOverview.searchbarLabel')"
      :result-label="t('consumerBundlesOverview.results', { count: bundles.length })"
      :loading="searchbarLoading && !initLoading"
      @updated-filter="handleFilter"
    />

    <div
      v-if="initLoading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        indeterminate
        size="100"
      />
    </div>
    <Bundles
      v-else
      :bundles="bundles"
      :bundle-link="OrganizationTypes.CONSUMER"
    />
    <Dialog
      ref="createBundleDialog"
      @on-close="closeCreateBundleDialog()"
    >
      <DialogBundleForm
        :available-organizations="organizations"
        :loading="createBundleLoading"
        @cancel="closeCreateBundleDialog()"
        @submit="(bundle: BundleCreateRequest) => createBundle(bundle)"
      />
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { BundleCreateRequest } from '@/models/Bundle'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { useOrganizationStore } from '@/store/organizations'
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleForm from '@/components/dialogs/DialogBundleForm.vue'
import Bundles from '@/components/bundles/Bundles.vue'
import Searchbar from '@/components/Searchbar.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import { SearchbarValues } from '@/models/SearchbarValues'
import { Icons } from '@/models/enums/IconTypes'
import { OrganizationTypes } from '@/models/enums/OrganizationTypes'
import { router } from '@/router'
import { Routes } from '@/models/enums/Routes'
import { useSearchHelper } from '@/composables/useSearchHelper'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()
const organizationStore = useOrganizationStore()
const bundleStore = useBundleStore()

const { organizations } = storeToRefs(organizationStore)
const { bundles } = storeToRefs(bundleStore)

const { getDefaultSortOptions, getFilterOptionsBundleStatusExtended } = useSearchHelper()

const createBundleDialog = ref()
const createBundleLoading = ref(false)
const searchbarLoading = ref(false)
const initLoading = ref(true)

/**
 * closeCreateBundleDialog
 */
function closeCreateBundleDialog(): void {
  createBundleDialog.value.close()
}

/**
 * openCreateBundleDialog
 */
function openCreateBundleDialog(): void {
  createBundleDialog.value.open()
}

/**
 * handleFilter
 * @param {searchbarValues} searchbarValues
 */
async function handleFilter(searchbarValues: SearchbarValues): Promise<void> {
  searchbarLoading.value = true
  try {
    await bundleStore.GET({
      ...(searchbarValues.searchQuery ? { q: searchbarValues.searchQuery } : {}),
      filter: searchbarValues?.selectedFilterOptions?.join(' OR '),
      sort: searchbarValues.selectedSortOption,
    })
  } finally {
    searchbarLoading.value = false
    initLoading.value = false
  }
}

/**
 *
 * createBundle
 * @param {bundle} bundle
 */
async function createBundle(bundle: BundleCreateRequest): Promise<void> {
  try {
    createBundleLoading.value = true
    const bundleResponse = await bundleStore.CREATE(bundle)
    await router.push({ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId: bundleResponse.id } })
  } catch {
    closeCreateBundleDialog()
  } finally {
    createBundleLoading.value = false
  }
}

await Promise.allSettled([organizationStore.GET_ALL_PROVIDERS()])
</script>

<i18n lang="yaml">
de:
  consumerBundlesOverview:
    results: '{count} App | {count} Apps'
    headline: Deine Apps
    createBtn: Neue App erstellen
    searchbarLabel: Apps durchsuchen
</i18n>
