import { Team } from '@/models/Team'
import { OrganizationUserResponseList, User } from '@/models/User'
import { StoreName } from '@/models/enums/StoreName'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'

interface UserStateProperties {
  users: User[]
  filter?: string
}

export const useUserStore = defineStore(StoreName.USERS, {
  state: (): UserStateProperties => ({
    users: [],
    filter: undefined,
  }),
  actions: {
    async GET() {
      try {
        const { data }: { data: OrganizationUserResponseList } = await useAxios.get('/api/users')
        this.users = data.users.map((user) => {
          if (user.id) {
            return {
              ...user,
              name: `${user.firstname} ${user.lastname}`,
              searchId: user.id,
            }
          } else {
            return {
              ...user,
              name: user.email,
              searchId: user.email,
            }
          }
        })
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(deletedUser: User) {
      try {
        await useAxios.delete(`/api/users/${deletedUser.id}`)
        this.users = this.users.filter((user) => user.id !== deletedUser.id)
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(userObject: User) {
      try {
        const { data }: { data: User } = await useAxios.patch(`/api/users/${userObject.id}`, { teams: userObject.teams, admin: userObject.admin })
        this.users = [{ ...data, name: `${data.firstname} ${data.lastname}`, searchId: data.id }, ...this.users.filter((user) => user.id !== data.id)]
      } catch {
        return Promise.reject()
      }
    },
    setFilter(team: Team) {
      this.filter = team.id
    },
  },
  getters: {
    filteredUsers(state) {
      if (state.filter) {
        return state.users.filter((user) => (user.teams && user.teams.includes(state.filter!)) || user.searchId === state.filter)
      } else {
        return state.users
      }
    },
    usersLength(state) {
      return state.users.length
    },
  },
})
