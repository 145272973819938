<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="emit('submit', subscription)"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('subscription.terminate.headline') }}</h1>
          <p>{{ t('subscription.terminate.copy', { subscriptionName: subscription.planName }) }}</p>

          <InfoBox
            v-if="isLastSubscription"
            class="mb-8"
            :headline="t('subscription.terminate.lastSubscriptionInfoBox.headline')"
            :copy="t('subscription.terminate.lastSubscriptionInfoBox.copy')"
            :info-type="StatusTypes.WARNING"
          />
          <v-text-field
            :label="t('subscription.terminate.form.terminateDate.label')"
            variant="outlined"
            :model-value="format(terminateDate, 'dd.MM.yyyy')"
            disabled
            required
          >
          </v-text-field>
        </template>
        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
          >
            {{ t('buttons.terminate') }}</DefaultButton
          >

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { SubscriptionResponse } from '@/models/Subscription'
import { format, addDays } from 'date-fns'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import InfoBox from '../InfoBox.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()
const dateNow = new Date()
const terminateDate = addDays(dateNow, 1)

defineProps({
  subscription: {
    type: Object as PropType<SubscriptionResponse>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isLastSubscription: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  subscription:
    terminate:
      headline: Plan unwiderruflich kündigen
      copy: Bist du dir sicher, dass du den Plan "{subscriptionName}" unwiderruflich kündigen möchtest?
      form:
        terminateDate:
          label: Kündigung zum
      lastSubscriptionInfoBox:
        headline: Letzter aktiver Plan
        copy: Durch das Kündigen des letzten Plans wird der Zugriff auf die App entfernt.
</i18n>
