<template>
  <GeneralPageTitle>
    <template #title>
      {{ t('userDashboard.headline') }}
    </template>
  </GeneralPageTitle>
  <v-row>
    <v-col>
      <OrganizationCreateTeaser @open-create-organization-dialog="organizationCreateDialogRef?.open()" />
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <MarketplaceTeaser />
    </v-col>
    <v-col>
      <DocsTeaser />
    </v-col>
  </v-row>
  <Dialog
    ref="consumerWelcomeDialogRef"
    @on-close="consumerWelcomeDialogRef?.close()"
  >
    <DialogConsumerWelcome @submit="consumerWelcomeDialogRef?.close(), organizationCreateDialogRef?.open()" />
  </Dialog>
  <Dialog
    ref="organizationCreateDialogRef"
    @on-close="organizationCreateDialogRef?.close()"
  >
    <DialogOrganizationForm
      :loading="loading"
      @cancel="organizationCreateDialogRef?.close()"
      @submit="(organization: OrganizationForm) => createOrganization(organization)"
    />
  </Dialog>
  <Dialog
    ref="createdOrganizationDialogRef"
    :show-close-btn="false"
  >
    <DialogOrganizationCreated @submit="handleOverlayClick()" />
  </Dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, ref } from 'vue'
import { OrganizationForm } from '@/models/Organization'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useRouter } from 'vue-router'
import { Routes } from '@/models/enums/Routes'
import { isLoggedIn } from '@/plugins/keycloak'
import { storeToRefs } from 'pinia'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import OrganizationCreateTeaser from '@/components/dashboards/user/OrganizationCreateTeaser.vue'
import MarketplaceTeaser from '@/components/dashboards/user/MarketplaceTeaser.vue'
import DocsTeaser from '@/components/dashboards/user/DocsTeaser.vue'
import Dialog from '@/components/layout/Dialog.vue'
import DialogOrganizationForm from '@/components/dialogs/DialogOrganizationForm.vue'
import DialogOrganizationCreated from '@/components/dialogs/DialogOrganizationCreated.vue'
import DialogConsumerWelcome from '@/components/dialogs/DialogConsumerWelcome.vue'

const { t } = useI18n()
const router = useRouter()

const myOrganizationStore = useMyOrganizationStore()

const { myOrganizations } = storeToRefs(myOrganizationStore)

const consumerWelcomeDialogRef = ref<InstanceType<typeof Dialog>>()
const organizationCreateDialogRef = ref<InstanceType<typeof Dialog>>()
const createdOrganizationDialogRef = ref<InstanceType<typeof Dialog>>()
const loading = ref(false)

/**
 * createOrganization
 * @param {OrganizationForm} organizationForm
 */
async function createOrganization(organizationForm: OrganizationForm): Promise<void> {
  try {
    await myOrganizationStore.CREATE_AND_SELECT({ ...organizationForm, provider: false })
    organizationCreateDialogRef.value?.close()
    createdOrganizationDialogRef.value?.open()
  } catch {
    Promise.resolve()
  } finally {
    loading.value = false
  }
}

/**
 * handleOverlayClick
 */
function handleOverlayClick(): void {
  createdOrganizationDialogRef.value?.close()
  router.push({ name: Routes.HOME })
}

onMounted(() => {
  if (isLoggedIn() && !myOrganizations.value.length) {
    consumerWelcomeDialogRef.value?.open()
  }
})
</script>
<i18n lang="yaml">
de:
  userDashboard:
    headline: Kunden Dashboard
</i18n>
