import { FilterOption } from '@/models/FilterOption'
import { SortOption } from '@/models/SortOption'
import { FilterValuesBundle, FilterValuesOrganization, FilterValuesProduct } from '@/models/enums/FilterValues'
import { SortValues, SortValuesOrganizationRelationConsumer, SortValuesOrganizationRelationProvider } from '@/models/enums/SortValues'
import { i18n } from '@/plugins/i18n'

interface DateSortType {
  createdAt: string
}
interface NameSortType {
  name: string
}

interface SearchHelper {
  getDefaultSortOptions: () => Array<SortOption>
  getSortOptionsOrganizationRelationConsumer: () => Array<SortOption>
  getSortOptionsOrganizationRelationProvider: () => Array<SortOption>
  getFilterOptionsBundleStatus: () => Array<FilterOption>
  getFilterOptionsBundleStatusExtended: () => Array<FilterOption>
  getFilterOptionsProduct: () => Array<FilterOption>
  getSortFunction: (sortValue: string) => (a: object, b: object) => number
  getFilterOptionsOrganizationStatus: () => Array<FilterOption>
}

/**
 * useSearchHelper
 * @return {SearchHelper}
 */
export function useSearchHelper(): SearchHelper {
  const defaultSortOptions = [
    {
      key: i18n.t(`sortOptions.nameASC`),
      value: SortValues.NAME_ASC,
    },
    {
      key: i18n.t(`sortOptions.nameDESC`),
      value: SortValues.NAME_DESC,
    },
    {
      key: i18n.t(`sortOptions.createdAt`),
      value: SortValues.CREATED_AT,
    },
  ]

  const sortOptionsOrganizationRelationConsumer = [
    {
      key: i18n.t(`sortOptions.nameASC`),
      value: SortValuesOrganizationRelationConsumer.NAME_ASC,
    },
    {
      key: i18n.t(`sortOptions.nameDESC`),
      value: SortValuesOrganizationRelationConsumer.NAME_DESC,
    },
  ]

  const sortOptionsOrganizationRelationProvider = [
    {
      key: i18n.t(`sortOptions.nameASC`),
      value: SortValuesOrganizationRelationProvider.NAME_ASC,
    },
    {
      key: i18n.t(`sortOptions.nameDESC`),
      value: SortValuesOrganizationRelationProvider.NAME_DESC,
    },
  ]

  const bundleStatusFilterOptions = [
    {
      key: i18n.t(`bundleStatus.${FilterValuesBundle.REQUESTED}`),
      value: FilterValuesBundle.REQUESTED,
    },
    {
      key: i18n.t(`bundleStatus.${FilterValuesBundle.IN_PROGRESS}`),
      value: FilterValuesBundle.IN_PROGRESS,
    },
    {
      key: i18n.t(`bundleStatus.${FilterValuesBundle.APPROVED}`),
      value: FilterValuesBundle.APPROVED,
    },
    {
      key: i18n.t(`bundleStatus.${FilterValuesBundle.REJECTED}`),
      value: FilterValuesBundle.REJECTED,
    },
  ]

  const bundleStatusFilterOptionsExtended = [
    {
      key: i18n.t(`bundleStatus.${FilterValuesBundle.DRAFT}`),
      value: FilterValuesBundle.DRAFT,
    },
  ]

  const productFilterOptions = [
    {
      key: i18n.t(`productStatus.${FilterValuesProduct.PUBLIC}`),
      value: FilterValuesProduct.PUBLIC,
    },
    {
      key: i18n.t(`productStatus.${FilterValuesProduct.PRIVATE}`),
      value: FilterValuesProduct.PRIVATE,
    },
  ]

  const organizationStatusFilterOptions = [
    {
      key: i18n.t(`organizationStatus.${FilterValuesOrganization.ACTIVE}`),
      value: FilterValuesOrganization.ACTIVE,
    },
    {
      key: i18n.t(`organizationStatus.${FilterValuesOrganization.INACTIVE}`),
      value: FilterValuesOrganization.INACTIVE,
    },
  ]

  /**
   * get all sort options
   * @return {Array<SortOption>} sort options
   */
  function getDefaultSortOptions(): Array<SortOption> {
    return defaultSortOptions
  }

  /**
   * get all sort options for consumer organizations
   * @return {Array<SortValuesOrganizationRelationConsumer>} sort options
   */
  function getSortOptionsOrganizationRelationConsumer(): Array<SortOption> {
    return sortOptionsOrganizationRelationConsumer
  }

  /**
   * get all sort options for provider organizations
   * @return {Array<SortValuesOrganizationRelationProvider>} sort options
   */
  function getSortOptionsOrganizationRelationProvider(): Array<SortOption> {
    return sortOptionsOrganizationRelationProvider
  }

  /**
   * get default filter options for bundle
   * @return {Array<FilterOption>} default filter options for bundle
   */
  function getFilterOptionsBundleStatus(): Array<FilterOption> {
    return bundleStatusFilterOptions
  }

  /**
   * get default filter options for consumer provider searchbar
   * @return {Array<FilterOption>} default filter options for consumer provider searchbar
   */
  function getFilterOptionsOrganizationStatus(): Array<FilterOption> {
    return organizationStatusFilterOptions
  }

  /**
   * get all filter options for bundle
   * @return {Array<FilterOption>} all filter options for bundle
   */
  function getFilterOptionsBundleStatusExtended(): Array<FilterOption> {
    return [...bundleStatusFilterOptions, ...bundleStatusFilterOptionsExtended]
  }

  /**
   * get filter options for product
   * @return {Array<FilterOption>} filter options for product
   */
  function getFilterOptionsProduct(): Array<FilterOption> {
    return productFilterOptions
  }

  /**
   * get sort function depending on selected sort option
   * @param {SortValues} sortValue selected sort option
   * @return {Function} sort function (nameSort or dateSort)
   */
  function getSortFunction(sortValue: string): (a: object, b: object) => number {
    /**
     * dateSort
     *
     * @param {object} a
     * @param {object} b
     * @return {number}
     */
    function dateSort(a: object, b: object): number {
      if (Object.hasOwn(a, 'createdAt') && Object.hasOwn(b, 'createdAt')) {
        return new Date((b as DateSortType).createdAt || 0).getTime() - new Date((a as DateSortType).createdAt || 0).getTime()
      }
      return 0
    }

    /**
     * nameSort
     * @param {object} a
     * @param {object} b
     * @return {number}
     */
    function nameSort(a: object, b: object): number {
      if (Object.hasOwn(a, 'name') && Object.hasOwn(b, 'name')) {
        // use collator instead of localeCompare because of performance
        const collator = new Intl.Collator('de', { caseFirst: 'upper', sensitivity: 'base' })

        if (sortValue === SortValues.NAME_ASC) {
          return collator.compare((a as NameSortType).name, (b as NameSortType).name)
        } else {
          return collator.compare((b as NameSortType).name, (a as NameSortType).name)
        }
      }
      return 0
    }

    return sortValue === SortValues.CREATED_AT ? dateSort : nameSort
  }

  return {
    getDefaultSortOptions,
    getSortOptionsOrganizationRelationConsumer,
    getSortOptionsOrganizationRelationProvider,
    getFilterOptionsBundleStatus,
    getFilterOptionsBundleStatusExtended,
    getFilterOptionsProduct,
    getSortFunction,
    getFilterOptionsOrganizationStatus,
  }
}
