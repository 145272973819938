<template>
  <DialogDefaultLayoutEnter :on-submit="() => emit('submit')">
    <template #content>
      <h1>{{ t('goLiveBundle.headline') }}</h1>
      <p>
        <strong>{{ t('goLiveBundle.copy.one') }}</strong>
      </p>
      <p>{{ t('goLiveBundle.copy.two') }}</p>
      <p>{{ t('goLiveBundle.copy.three') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="emit('submit')"
      >
        {{ t('buttons.start') }}
      </DefaultButton>

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click.prevent="emit('cancel')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  loading: { type: Boolean, default: false },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  goLiveBundle:
    headline: Aktivierungsanfrage
    copy:
      one: Bist du bereit, deine App zur Prüfung einzureichen?
      two: Wir versuchen dir so schnell wie möglich den Zugriff auf deine ausgewählten API-Produkte zu ermöglichen. Sobald du die Aktivierungsanfrage
        abgeschickt hast, wird dein Anbieter benachrichtigt.
      three: Du erhältst von uns umgehend eine Nachricht, sobald dein Anbieter deine Aktivierungsanfrage bearbeitet.
</i18n>
