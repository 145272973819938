import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { i18n } from '@/plugins/i18n'

interface StatusTypeSet {
  key: string
  color?: string
  icon?: Icons
  tooltip?: string
  outlined?: boolean
}

interface StatusHelper {
  getStatusSetFromStatusKey: (statusKey?: string) => StatusTypeSet | undefined
}

/**
 * useStatusHelper1
 * @return {StatusHelper}
 */
export function useStatusHelper(): StatusHelper {
  const statusTypeSets: StatusTypeSet[] = [
    {
      key: StatusTypes.ACTIVE,
      color: PlattformColors.SUCCESS,
      icon: Icons.CIRCLE_CHECK,
      tooltip: i18n.t('tooltips.active'),
    },
    {
      key: StatusTypes.INACTIVE,
      color: PlattformColors.ERROR,
      icon: Icons.CIRCLE_ERROR,
      tooltip: i18n.t('tooltips.inactive'),
    },
    {
      key: StatusTypes.ACTIVATION_REQUESTED,
      color: PlattformColors.CHANGE,
      icon: Icons.CLOCK,
      tooltip: i18n.t('tooltips.activationRequested'),
    },
    {
      key: StatusTypes.DEACTIVATION_REQUESTED,
      color: PlattformColors.CHANGE,
      icon: Icons.CLOCK,
      tooltip: i18n.t('tooltips.deactivationRequested'),
    },
    {
      key: StatusTypes.DRAFT,
      icon: Icons.EDIT,
    },
    {
      key: StatusTypes.PUBLISHED,
      color: PlattformColors.SUCCESS,
    },
    {
      key: StatusTypes.REQUESTED,
      color: PlattformColors.CHANGE,
      icon: Icons.OPTIONS_HORIZONTAL,
      outlined: false,
    },
    {
      key: StatusTypes.IN_PROGRESS,
      color: PlattformColors.CHANGE,
      icon: Icons.OPTIONS_HORIZONTAL,
    },
    {
      key: StatusTypes.APPROVED,
      color: PlattformColors.SUCCESS,
      icon: Icons.CHECK,
    },
    {
      key: StatusTypes.REJECTED,
      color: PlattformColors.ERROR,
      icon: Icons.CIRCLE_CLOSED,
    },
    {
      key: StatusTypes.IN_TERMINATION,
      color: PlattformColors.SUCCESS,
    },
    {
      key: StatusTypes.TERMINATED,
      icon: Icons.CIRCLE_CLOSED,
    },
    {
      key: StatusTypes.ACTIVE,
      color: PlattformColors.SUCCESS,
    },
    {
      key: StatusTypes.INACTIVE,
      icon: Icons.CIRCLE_CLOSED,
    },
    {
      key: StatusTypes.INITIAL,
      icon: Icons.CIRCLE_CLOSED,
    },
    {
      key: StatusTypes.IN_TERMINATION,
      color: PlattformColors.INFO,
    },
    {
      key: StatusTypes.INFO,
      icon: Icons.CIRCLE_INFORMATION,
      color: PlattformColors.INFO,
    },
    {
      key: StatusTypes.REPORT,
      icon: Icons.ISSUE_REPORT,
      color: PlattformColors.INFO,
    },
    {
      key: StatusTypes.WARNING,
      icon: Icons.WARNING,
      color: PlattformColors.WARNING,
    },
    {
      key: StatusTypes.ERROR,
      icon: Icons.CIRCLE_ERROR,
      color: PlattformColors.ERROR,
    },
    {
      key: StatusTypes.REJECTED,
      icon: Icons.CIRCLE_CLOSED,
      color: PlattformColors.ERROR,
    },
    {
      key: StatusTypes.CHANGE,
      icon: Icons.UPDATE,
      color: PlattformColors.CHANGE,
    },
    {
      key: StatusTypes.PENDING,
      icon: Icons.CLOCK,
      color: PlattformColors.CHANGE,
    },
  ]

  /**
   * get status type object depending on status type
   * @param { StatusTypes } statusKey status type
   * @return {StatusType} color set
   */
  function getStatusSetFromStatusKey(statusKey?: string): StatusTypeSet | undefined {
    return statusTypeSets.find((statusType) => statusType.key === statusKey)
  }

  return {
    getStatusSetFromStatusKey,
  }
}
