import { Team, TeamResponse, TeamResponseList } from '@/models/Team'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'

interface TeamsStateProperties {
  teams: Team[]
  team?: Team
}

export const useTeamStore = defineStore('teams', {
  state: (): TeamsStateProperties => ({
    teams: [],
    team: undefined,
  }),
  actions: {
    async GET() {
      try {
        const { data }: { data: TeamResponseList } = await useAxios.get('/api/teams')
        this.teams = data.teams.map((team) => ({
          ...team,
          searchId: team.id,
        }))
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(name: string) {
      try {
        const { data }: { data: TeamResponse } = await useAxios.post('/api/teams', { name })
        this.teams.push({ ...data, searchId: data.id })
        this.team = { ...data, searchId: data.id }
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(teamObject: Team) {
      try {
        const { data }: { data: Team } = await useAxios.patch(`/api/teams/${teamObject.id}`, {
          name: teamObject.name,
          responsibilities: teamObject.responsibilities,
        })
        this.teams = [{ ...data, searchId: data.id }, ...this.teams.filter((team) => team.id !== data.id)]
        this.team = { ...data, searchId: data.id }
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(teamId: Team['id']) {
      try {
        await useAxios.delete(`/api/teams/${teamId}`)
        this.teams = this.teams.filter((team) => team.id !== teamId)
        this.team = undefined
      } catch {
        return Promise.reject()
      }
    },
    setSelected(team: Team) {
      this.team = team
    },
  },
  getters: {
    teamById(state) {
      return (id: Team['id']): Team | undefined => state.teams.find((team) => team.id === id)
    },
    teamsLength(state) {
      return state.teams.length
    },
  },
})
