<template>
  <v-card
    variant="flat"
    class="bg-white pa-2"
  >
    <template #prepend>
      <DefaultAvatar
        size="48"
        class="mr-2"
        :color="PlattformColors.INFO"
      >
        <OrganizationIcon :organization='activeOrganization'/>
      </DefaultAvatar>
    </template>
    <template #title>
      <h3 class="mb-0">{{ organization?.name }}</h3>
    </template>
    <!-- <template #subtitle>
        {{
          t('organizationCard.lastUpdated', {
            date: format(new Date(activeOrganization?.updatedAt || 0), 'dd.MM.yyyy'),
          })
        }}
      </template> -->
    <template #append>
      <slot name="append"></slot>
    </template>
    <template #text>
      <div class="ml-16">
        <v-chip
          v-if="organization?.email"
          class="mr-4 mb-2"
          label
          size="small"
          :href="`mailto:${organization?.email}`"
        >
          <template #prepend
            ><v-icon class="mr-2">{{ Icons.EMAIL }}</v-icon>
          </template>
          {{ organization?.email }}
        </v-chip>
        <v-chip
          v-if="isOrganizationAddressComplete(organization)"
          class="mr-4 mb-2"
          label
          size="small"
          variant="outlined"
        >
          <template #prepend>
            <v-icon class="mr-2">{{ Icons.LOCATION }}</v-icon>
          </template>
          {{
            t('organizationCard.completeAddress', {
              street: organization?.address?.street,
              number: organization?.address?.number,
              zipCode: organization?.address?.zipCode,
              city: organization?.address?.city,
            })
          }}
        </v-chip>
        <v-chip
          v-else-if="activeOrganization?.id === organization?.id"
          label
          size="small"
          variant="text"
          class="not-completed mr-4 mb-2"
          >{{ t('organizationCard.notCompletedAddress') }}</v-chip
        >
        <v-chip
          v-if="organization?.registrationNumber"
          class="mr-4 mb-2"
          label
          size="small"
          variant="outlined"
        >
          {{ organization?.registrationNumber }}
        </v-chip>
        <v-chip
          v-else-if="activeOrganization?.id === organization?.id"
          label
          size="small"
          variant="text"
          class="not-completed mr-4 mb-2"
          >{{ t('organizationCard.notCompletedRegistrationNumber') }}</v-chip
        >
      </div>
    </template>
  </v-card>
</template>
<script lang="ts" setup>
// import { format } from 'date-fns'
import { Icons } from '@/models/enums/IconTypes'
import { useI18n } from 'vue-i18n'
import { OrganizationExtendedResponse } from '@/models/Organization'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { PropType } from 'vue'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { storeToRefs } from 'pinia'
import OrganizationIcon from '@/components/baseComponents/images/OrganizationIcon.vue'

const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)

defineProps({
  organization: { type: Object as PropType<OrganizationExtendedResponse>, default: undefined },
})

const { t } = useI18n()

/**
 * isOrganizationAddressComplete
 * @param {organization} organization
 * @return {boolean} true or false
 */
function isOrganizationAddressComplete(organization?: OrganizationExtendedResponse): boolean {
  return (
    !!organization?.name &&
    !!organization?.email &&
    !!organization?.address?.street &&
    !!organization?.address?.number &&
    !!organization?.address?.zipCode &&
    !!organization?.address?.city
  )
}
</script>
<style lang="scss">
.not-completed {
  border-radius: 0.5rem;
  background-image: repeating-linear-gradient(
      -2deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.5rem,
      transparent 0.5rem,
      transparent 1rem,
      rgb(var(--v-theme-light-grey)) 1rem
    ),
    repeating-linear-gradient(
      88deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.5rem,
      transparent 0.5rem,
      transparent 1rem,
      rgb(var(--v-theme-light-grey)) 1rem
    ),
    repeating-linear-gradient(
      178deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.5rem,
      transparent 0.5rem,
      transparent 1rem,
      rgb(var(--v-theme-light-grey)) 1rem
    ),
    repeating-linear-gradient(
      268deg,
      rgb(var(--v-theme-light-grey)),
      rgb(var(--v-theme-light-grey)) 0.5rem,
      transparent 0.5rem,
      transparent 1rem,
      rgb(var(--v-theme-light-grey)) 1rem
    );
  background-size:
    0.0625rem 100%,
    100% 0.0625rem,
    0.0625rem 100%,
    100% 0.0625rem;
  background-position:
    0 0,
    0 0,
    100% 0,
    0 100%;
  background-repeat: no-repeat;
}
</style>
<i18n lang="yaml">
de:
  organizationCard:
    lastUpdated: 'Zuletzt bearbeitet am: {date}'
    completeAddress: '{street} {number}, {zipCode} {city}'
    notCompletedAddress: Organisationsadresse
    notCompletedRegistrationNumber: Handelsregisternummer
</i18n>
