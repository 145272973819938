<template>
  <BundleCredentialsList
    :access-definitions="accessDefinitions"
    :bundle="bundle"
    @request-credential="credentialGenerateLoaderDialogRef?.open()"
    @request-deactivate-credential="(access: AccessResponse) => openRequestDeactivationDialog(access)"
  />
  <Dialog
    ref="credentialGenerateLoaderDialogRef"
    :show-close-btn="false"
  >
    <DialogBundleCredentialGenerateLoader @loading-done="() => openCreateCredentialsDialog()" />
  </Dialog>
  <Dialog
    ref="createNewCredentialDialogRef"
    :size="DialogSizes.EXTRA_LARGE"
    :show-close-btn="false"
    @on-close="createNewCredentialDialogRef?.close()"
  >
    <DialogBundleCredentialCreate
      ref="createCredentialsRef"
      :loading="createCredentialsLoading"
      :access-definitions="accessDefinitions.filter((accessDefinition) => accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN)"
      @create-new-access-key="(authenticators: AuthenticatorRequest[]) => requestNewCredential(authenticators)"
      @cancel="closeCreateCredentialsDialog()"
    />
  </Dialog>
  <Dialog
    ref="requestDeactivationDialogRef"
    @on-close="requestDeactivationDialogRef?.close()"
  >
    <DialogBundleCredentialRequestDeactivation
      :selected-access="selectedAccessToDeactivate"
      @submit="(access: AccessResponse) => requestDeactivation(access)"
      @cancel="requestDeactivationDialogRef?.close()"
    />
  </Dialog>
  <Dialog
    ref="requestDeactivationDialogRef"
    @on-close="requestDeactivationDialogRef?.close()"
  >
    <DialogBundleCredentialRequestDeactivation
      :selected-access="selectedAccessToDeactivate"
      @submit="(access: AccessResponse) => requestDeactivation(access)"
      @cancel="requestDeactivationDialogRef?.close()"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useBundleStore } from '@/store/bundles'
import Dialog from '@/components/layout/Dialog.vue'
import DialogBundleCredentialGenerateLoader from '@/components/dialogs/DialogBundleCredentialGenerateLoader.vue'
import DialogBundleCredentialCreate from '@/components/dialogs/DialogBundleCredentialCreate.vue'
import { ref } from 'vue'
import { AccessResponse } from '@/models/Access'
import { AuthenticatorRequest } from '@/models/Authenticator'
import { useAccessesStore } from '@/store/accesses'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { useAccessDefinitionsStore } from '@/store/accessDefinitions'
import { DialogSizes } from '@/models/enums/DialogSizes'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import BundleCredentialsList from '@/components/bundles/BundleCredentialsList.vue'
import DialogBundleCredentialRequestDeactivation from '@/components/dialogs/DialogBundleCredentialRequestDeactivation.vue'

const bundleStore = useBundleStore()
const accessStore = useAccessesStore()
const accessDefinitionsStore = useAccessDefinitionsStore()
const alertStore = useAlertStore()

const credentialGenerateLoaderDialogRef = ref<InstanceType<typeof Dialog>>()
const createNewCredentialDialogRef = ref<InstanceType<typeof Dialog>>()
const requestDeactivationDialogRef = ref<InstanceType<typeof Dialog>>()
const createCredentialsRef = ref()
const createCredentialsLoading = ref(false)
const selectedAccessToDeactivate = ref<AccessResponse>()

const { t } = useI18n()

const { accessDefinitions } = storeToRefs(accessDefinitionsStore)
const { bundle } = storeToRefs(bundleStore)

/**
 * openRequestDeactivationDialog
 * @param {access} access
 */
function openRequestDeactivationDialog(access: AccessResponse): void {
  selectedAccessToDeactivate.value = access
  requestDeactivationDialogRef.value?.open()
}

/**
 * openCreateCredentialsDialog
 */
async function openCreateCredentialsDialog(): Promise<void> {
  if (bundle?.value) {
    try {
      // refetch accessDefinitions to ensure token is created with up-to-date settings
      await accessDefinitionsStore.GET_LIST(bundle?.value?.providerOrganization.id)
      createNewCredentialDialogRef.value?.open()
    } catch {
      Promise.resolve()
    } finally {
      credentialGenerateLoaderDialogRef.value?.close()
    }
  }
}

/**
 * closeCreateCredentialsDialog
 */
function closeCreateCredentialsDialog(): void {
  createNewCredentialDialogRef.value?.close()
}

/**
 * requestDeactivation
 * @param {access} access
 */
async function requestDeactivation(access: AccessResponse): Promise<void> {
  try {
    const updatedAccess = await accessStore.LOCK_REQUEST(access.id, 'lock-request for access')
    bundleStore.updateBundleAccesses(updatedAccess)
    alertStore.add({
      text: t('providerBundleCredentials.banner.lockRequest'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    requestDeactivationDialogRef.value?.close()
  }
}

/**
 * requestNewCredential
 * @param {authenticators} authenticators
 */
async function requestNewCredential(authenticators: AuthenticatorRequest[]): Promise<void> {
  if (bundle?.value) {
    try {
      createCredentialsLoading.value = true
      const newAccess = await accessStore.CREATE(authenticators[0], bundle?.value?.id)
      bundleStore.addBundleAccess(newAccess)

      alertStore.add({
        text: t('providerBundleCredentials.success.requested'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      createCredentialsLoading.value = false
      closeCreateCredentialsDialog()
    }
  }
}

if (bundle?.value) await accessDefinitionsStore.GET_LIST(bundle?.value?.providerOrganization?.id)
</script>

<i18n lang="yaml">
de:
  consumerBundleCredentials:
    banner:
      lockRequest: Die Anfrage wurde abgeschickt!
    success:
      requested: Der neue Access wurde erfolgreich beantragt.
</i18n>
