import { OrganizationPublicResponse } from '@/models/Organization'
import { ProductResponse } from '@/models/Product'
import { ProductOfferResponse, ProductOfferResponseList } from '@/models/ProductOffer'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface ProductOfferStateProperties {
  productOffers: ProductOfferResponse[]
}

export const useProductOfferStore = defineStore(StoreName.PRODUCT_OFFERS, {
  state: (): ProductOfferStateProperties => ({
    productOffers: [],
  }),
  actions: {
    async GET(productId: ProductResponse['id']) {
      try {
        const { data }: { data: ProductOfferResponseList } = await useAxios.get(`/api/products/${productId}/offers`)
        this.productOffers = data.productOffers
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(productId: ProductResponse['id'], consumerOrganizationId: OrganizationPublicResponse['id']) {
      try {
        const { data }: { data: ProductOfferResponse } = await useAxios.post(`/api/products/${productId}/offers`, { consumerOrganizationId })
        this.productOffers = [data, ...this.productOffers]
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(productId: ProductResponse['id'], productOfferId: ProductOfferResponse['productOfferId']) {
      try {
        await useAxios.delete(`/api/products/${productId}/offers/${productOfferId}`)
        this.productOffers = this.productOffers.filter((productOffer: ProductOfferResponse) => productOffer.productOfferId !== productOfferId)
      } catch {
        return Promise.reject()
      }
    },
  },
})
