export enum ProductPlanItemTest {
  ITEM = 'product-plan-item',
  CHECKBOX_ICON = 'product-plan-item-checkbox-icon',
  MENU_BUTTON = 'product-plan-item-menu-button',
  MENU_UPDATE = 'product-plan-item-menu-update',
  MENU_DELETE = 'product-plan-item-menu-delete',
  NAME = 'product-plan-item-name',
  DESCRIPTION = 'product-plan-item-description',
  VALIDITY = 'product-plan-item-validity',
  VALID_FROM = 'product-plan-item-valid-from',
  VALID_UNTIL = 'product-plan-item-valid-until',
}
