<template>
  <BackButton />
  <GeneralPageTitle>
    <template #prepend>
      <DefaultAvatar
        size="80"
        :color="PlattformColors.WARNING"
      >
        <v-icon size="32">
          {{ Icons.USER_SETTINGS }}
        </v-icon>
      </DefaultAvatar>
    </template>
    <template #title>
      {{ t('technicalUser.create.headline') }}
    </template>
  </GeneralPageTitle>
  <p class="mb-6">{{ t('technicalUser.create.copy') }}</p>

  <CreateTeaser
    v-if="!technicalUsersLength"
    :icon="Icons.USER_SETTINGS"
    :headline="t('technicalUser.teaser.headline')"
    :copy="t('technicalUser.teaser.copy')"
    :btn-text="t('buttons.create')"
    @submit="createTechnicalUserDialogRef?.open()"
  />
  <template v-else>
    <ListControls>
      <template #title>
        {{ t('technicalUser.show.count', { count: technicalUsersLength }) }}
      </template>
      <template #append>
        <DefaultButton
          :color="PlattformColors.LIGHT"
          :prepend-icon="Icons.CIRCLE_ADD"
          @click="createTechnicalUserDialogRef?.open()"
        >
          {{ t('buttons.create') }}
        </DefaultButton>
      </template>
    </ListControls>
    <DefaultList
      v-for="technicalUserItem in technicalUsers"
      :key="technicalUserItem.id"
    >
      <ListElement>
        <template #prepend>
          <DefaultAvatar
            :color="PlattformColors.INFO"
            size="48"
          >
            <v-icon>
              {{ Icons.USER_SETTINGS }}
            </v-icon>
          </DefaultAvatar>
        </template>
        <template #title>{{ technicalUserItem.name }}</template>
        <template #subtitle>{{ t('technicalUser.items.id') }} {{ technicalUserItem.id }} </template>
        <template #append>
          <IconButton
            :icon="Icons.DELETE"
            @click.stop="showDeletionOverlay(technicalUserItem)"
          />
        </template>
        <template #expand>
          <DefaultList v-if="technicalUserItem.credentialType === TechnicalUserTokenTypes.REFRESH">
            <ListLineElement>
              <template #prepend><div class="px-7"></div></template>
              <template #title>{{ t('technicalUser.items.clientId') }}</template>
              <template #subtitle>{{ technicalUserItem.clientId }}</template>
            </ListLineElement>
            <ListLineElement>
              <template #prepend><div class="px-7"></div></template>
              <template #title>{{ t('technicalUser.items.tokenEndpoint') }}</template>
              <template #subtitle>{{ technicalUserItem.tokenEndpoint }}</template>
            </ListLineElement>
          </DefaultList>
        </template>
      </ListElement>
    </DefaultList>
  </template>

  <Dialog
    ref="createTechnicalUserDialogRef"
    @on-close="createTechnicalUserDialogRef?.close()"
  >
    <DialogTechnicalUserCreate
      :loading="createTechnicalUserLoading"
      @submit="createTechnicalUser"
      @cancel="createTechnicalUserDialogRef?.close()"
    />
  </Dialog>
  <Dialog
    ref="createdTechnicalUserDialogRef"
    @on-close="createdTechnicalUserDialogRef?.close()"
  >
    <DialogTechnicalUserCreated
      :client-secret="createdTechnicalUser?.clientSecret"
      :refresh-token="createdTechnicalUser?.refreshToken"
      @close="createdTechnicalUserDialogRef?.close()"
  /></Dialog>
  <Dialog
    ref="deleteTechnicalUserDialogRef"
    @on-close="deleteTechnicalUserDialogRef?.close()"
  >
    <DialogTechnicalUserDelete
      :technical-user="selectedToDelete"
      :loading="deleteTechnicalUserLoading"
      @submit="deleteTechnicalUser()"
      @cancel="deleteTechnicalUserDialogRef?.close()"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { TechnicalUserCreateResponse, TechnicalUserResponse } from '@/models/TechnicalUser'
import { useAlertStore } from '@/store/alerts'
import { useTechnicalUserStore } from '@/store/technicalUsers'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import Dialog from '@/components/layout/Dialog.vue'
import DialogTechnicalUserCreate from '@/components/dialogs/DialogTechnicalUserCreate.vue'
import DialogTechnicalUserCreated from '@/components/dialogs/DialogTechnicalUserCreated.vue'
import DialogTechnicalUserDelete from '@/components/dialogs/DialogTechnicalUserDelete.vue'
import { Icons } from '@/models/enums/IconTypes'
import { TechnicalUserTokenTypes } from '@/models/enums/TechnicalUserTypes'
import { AlertTypes } from '@/models/enums/AlertTypes'
import BackButton from '@/components/layout/BackButton.vue'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import ListControls from '@/components/ListControls.vue'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DefaultList from '@/components/baseComponents/lists/DefaultList.vue'
import ListElement from '@/components/baseComponents/lists/ListElement.vue'
import ListLineElement from '@/components/baseComponents/lists/ListLineElement.vue'

const { t } = useI18n()
const technicalUserStore = useTechnicalUserStore()
const alertStore = useAlertStore()

const { technicalUsers, technicalUsersLength } = storeToRefs(technicalUserStore)

const technicalUserForm = ref<HTMLFormElement>()
const selectedToDelete = ref<TechnicalUserResponse>()
const createdTechnicalUser = ref<TechnicalUserCreateResponse>()
const deleteTechnicalUserLoading = ref(false)
const createTechnicalUserLoading = ref(false)
const createTechnicalUserDialogRef = ref<InstanceType<typeof Dialog>>()
const createdTechnicalUserDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteTechnicalUserDialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * showDeletionOverlay
 * @param {TechnicalUserResponse} technicalUser
 */
function showDeletionOverlay(technicalUser: TechnicalUserResponse): void {
  selectedToDelete.value = technicalUser
  deleteTechnicalUserDialogRef.value?.open()
}

/**
 * deleteTechnicalUser
 */
async function deleteTechnicalUser(): Promise<void> {
  if (selectedToDelete.value) {
    try {
      deleteTechnicalUserLoading.value = true
      await technicalUserStore.DELETE(selectedToDelete.value?.id)
      alertStore.add({
        text: t('technicalUser.success.deleted'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      deleteTechnicalUserLoading.value = false
      deleteTechnicalUserDialogRef.value?.close()
    }
  }
}

/**
 * createTechnicalUser
 * @param {string} technicalUserName
 */
async function createTechnicalUser(technicalUserName: string): Promise<void> {
  if (technicalUserName) {
    createTechnicalUserLoading.value = true
    try {
      createdTechnicalUser.value = await technicalUserStore.CREATE(technicalUserName)

      createdTechnicalUserDialogRef.value?.open()
      createTechnicalUserDialogRef.value?.close()
      technicalUserForm.value?.reset()
    } catch {
      Promise.resolve()
    } finally {
      createTechnicalUserLoading.value = false
    }
  }
}

await Promise.allSettled([technicalUserStore.GET()])
</script>

<i18n lang="yaml">
de:
  technicalUser:
    create:
      headline: Technische Nutzer
      copy: Beim Erstellen eines technischen Nutzers wird der zugehörige Refresh Token einmalig angezeigt. Zur Nutzung der Developer Portal API kannst du
        dir über die unten stehende Schnittstelle zusammen mit deiner Client ID und deinem Client Secret einen kurzzeitig gültigen Access Token
        generieren.
    teaser:
      headline: Technischen Nutzer erstellen
      copy: Erstelle einen technischen Nutzer, um die Developer Portal API zu nutzen.
    show:
      count: '{count} technischer Nutzer | {count} technische Nutzer'
    items:
      id: 'ID:'
      clientId: Client ID
      clientSecret: Client Secret
      tokenEndpoint: Schnittstelle
    success:
      deleted: Der technische Nutzer wurde gelöscht!
</i18n>
