import { i18n } from '@/plugins/i18n'

/**
 * returns true if val is an email string
 * @param {string} val email address to check
 * @return {boolean | string} true or error text
 */
export function isEmail(val: string): boolean | string {
  return (!!val && /^[a-z0-9_+&-]+(?:\.[a-z0-9_+&-]+)*@(?:[a-z0-9-]+\.)+[a-z]{2,7}$/.test(val.toLowerCase())) || i18n.t('formFieldValidations.error.isEmail')
}

/**
 * returns true if val is at least minLength characters long
 * @param {string} val text to check for minimum length
 * @param {number} minLength minimum length for text
 * @return {boolean | string} true or error text
 */
export function isMinLength(val: string, minLength: number): boolean | string {
  return (!!val && val.length >= minLength) || i18n.t('formFieldValidations.error.isMinLength', { value: minLength })
}

/**
 * returns true if val is shorter than maxLength
 * @param {string} val text to check for maximum length
 * @param {number} maxLength maximum length for text
 * @return {boolean | string} true or error text
 */
export function isMaxLength(val: string, maxLength: number): boolean | string {
  return !val || val.length <= maxLength || i18n.t('formFieldValidations.error.isMaxLength', { value: maxLength })
}

/**
 * returns true if val is not empty string, null or undefined
 * @param {string | number} val text to check
 * @return {boolean | string} true or error text
 */
export function isNotEmpty(val: string | number): boolean | string {
  if (typeof val === 'string') {
    return !!val || i18n.t('formFieldValidations.error.isNotEmpty')
  } else {
    return (val !== undefined && val !== null) || i18n.t('formFieldValidations.error.isNotEmpty')
  }
}

/**
 * returns true if val is null or later than compareDate
 * @param {string} val date to check
 * @param {string} compareDate date to compare against
 * @return {boolean | string} true or error text
 */
export function isDateLaterOrNull(val: string, compareDate: string): boolean | string {
  return !val || !compareDate || new Date(val) > new Date(compareDate) || i18n.t('formFieldValidations.error.isDateLaterOrNull')
}
