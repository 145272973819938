<template>
  <div v-if="products">
    <GeneralPageTitle>
      <template #title>
        {{ t('providedProductsOverview.headline') }}
      </template>
      <template #append>
        <DefaultButton
          v-if="isUserAdmin()"
          :color="PlattformColors.PRIMARY"
          :prepend-icon="Icons.CIRCLE_ADD"
          @click="showCreateProductDialog()"
        >
          {{ t('providedProductsOverview.addBtn') }}
        </DefaultButton>
      </template>
    </GeneralPageTitle>
    <Searchbar
      :sort-options="getDefaultSortOptions()"
      :filter-options="getFilterOptionsProduct()"
      :result-label="t('providedProductsOverview.results', { count: products.length })"
      :loading="searchbarLoading && !initLoading"
      @updated-filter="(updatedFilterValues: SearchbarValues) => handleFilter(updatedFilterValues)"
    />
    <div
      v-if="initLoading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        indeterminate
        size="100"
      />
    </div>
    <ProductList
      v-else
      :products="products"
      :view="ViewTypes.LISTCOMFORTABLE"
      @handle-product-click="goToProduct"
    />
    <Dialog
      ref="dialogRef"
      @on-close="closeCreateProductDialog()"
    >
      <DialogProductFormBasic
        :loading="loading"
        @cancel="closeCreateProductDialog()"
        @submit="(product: ProductForm) => createProduct(product)"
      />
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useProductStore } from '@/store/products'
import Dialog from '@/components/layout/Dialog.vue'
import ProductList from '@/components/products/ProductList.vue'
import DialogProductFormBasic from '@/components/dialogs/DialogProductFormBasic.vue'
import { ProductForm, ProductResponse } from '@/models/Product'
import { ref } from 'vue'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { useActiveUserHelper } from '@/composables/useActiveUserHelper'
import { ViewTypes } from '@/models/enums/ViewTypes'
import Searchbar from '@/components/Searchbar.vue'
import { SearchbarValues } from '@/models/SearchbarValues'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { useSearchHelper } from '@/composables/useSearchHelper'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { FilterValuesProduct } from '@/models/enums/FilterValues'

const { t } = useI18n()
const router = useRouter()

const productStore = useProductStore()
const myOrganizationStore = useMyOrganizationStore()

const { activeOrganization } = storeToRefs(myOrganizationStore)
const { products } = storeToRefs(productStore)

const { isUserAdmin } = useActiveUserHelper()
const { getDefaultSortOptions, getFilterOptionsProduct } = useSearchHelper()

const loading = ref(false)
const searchbarLoading = ref(false)
const initLoading = ref(true)
const dialogRef = ref<InstanceType<typeof Dialog>>()

/**
 * goToProduct
 * @param {productId} productId
 */
function goToProduct(productId: ProductResponse['id']): void {
  router.push({ name: Routes.PROVIDER_PRODUCTS_DETAILS, params: { productId } })
}

/**
 * closeCreateProductDialog
 */
function closeCreateProductDialog(): void {
  dialogRef.value?.close()
}

/**
 * showCreateProductDialog
 */
function showCreateProductDialog(): void {
  dialogRef.value?.open()
}

/**
 * createProduct
 * @param {product} product
 */
async function createProduct(product: ProductForm): Promise<void> {
  try {
    loading.value = true
    const resp = await productStore.CREATE(product)
    goToProduct(resp.id)
  } catch {
    Promise.resolve()
  } finally {
    loading.value = false
    closeCreateProductDialog()
  }
}

/**
 * handleFilter
 * @param {searchbarValues} searchbarValues
 */
async function handleFilter(searchbarValues: SearchbarValues): Promise<void> {
  searchbarLoading.value = true
  try {
    await productStore.GET_PRODUCTS({
      ...(searchbarValues.searchQuery ? { q: searchbarValues.searchQuery } : {}),
      sort: searchbarValues.selectedSortOption,
      includePrivate: searchbarValues.selectedFilterOptions?.includes(FilterValuesProduct.PRIVATE),
      includePublic: searchbarValues.selectedFilterOptions?.includes(FilterValuesProduct.PUBLIC),
      providerId: activeOrganization?.value?.id,
    })
  } finally {
    searchbarLoading.value = false
    initLoading.value = false
  }
}
</script>

<i18n lang="yaml">
de:
  providedProductsOverview:
    results: '{count} API | {count} API-Produkte'
    headline: API-Produkte
    addBtn: Neue API erstellen
</i18n>
