<template>
  <DefaultButton
    v-if="text"
    :append-icon="copied ? Icons.CHECK : Icons.COPY"
    :color="PlattformColors.SECONDARY"
    @click="handleCopyToClip(copyValue)"
  >
    {{ text }}
  </DefaultButton>

  <IconButton
    v-else
    :icon="copied ? Icons.CHECK : Icons.COPY"
    @click="handleCopyToClip(copyValue)"
  />
</template>
<script lang="ts" setup>
import { Icons } from '@/models/enums/IconTypes'
import { useSnackbarStore } from '@/store/snackbar'
import { i18n } from '@/plugins/i18n'
import { ref } from 'vue'
import DefaultButton from './baseComponents/buttons/DefaultButton.vue'
import IconButton from './baseComponents/buttons/IconButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const snackbarStore = useSnackbarStore()
const copied = ref(false)

defineProps({
  text: { type: String, default: undefined },
  copyValue: { type: String, default: undefined },
})

/**
 * handleCopyToClip
 * @param {copyValue} copyValue
 */
async function handleCopyToClip(copyValue?: string): Promise<void> {
  await navigator.clipboard.writeText(copyValue || '')
  copied.value = true
  snackbarStore.setSnackbarText(i18n.t('snackbarText'))
}
</script>
