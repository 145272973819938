<template>
  <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
    <template #content>
      <h1>{{ t('folder.delete.headline', { name: folder.name }) }}</h1>
      <p>{{ t('folder.delete.copy', { name: folder.name }) }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        :loading="loading"
        @click="handleSubmit()"
      >
        {{ t('buttons.delete') }}
      </DefaultButton>

      <DefaultButton
        :color="PlattformColors.SECONDARY"
        @click="emit('cancel')"
        >{{ t('buttons.cancel') }}</DefaultButton
      >
    </template>
  </DialogDefaultLayoutEnter>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType, ref } from 'vue'
import { Folder } from '@/models/Folder'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const loading = ref(false)

const props = defineProps({
  folder: {
    type: Object as PropType<Folder>,
    default: () => {},
  },
})

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', props.folder.id)
  loading.value = true
}

const emit = defineEmits(['submit', 'cancel'])
</script>
<style lang="scss" scoped></style>

<i18n lang="yaml">
de:
  folder:
    delete:
      headline: '"{name}" wirklich löschen?'
      copy: Du kannst den Ordner erst löschen, wenn sich keine Dateien mehr in ihm befinden!
</i18n>
