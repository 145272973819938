<template>
  <template v-if="bundle?.bundleProducts?.length">
    <Searchbar
      :result-label="t('consumerBundleDetailProducts.results', { count: bundle.bundleProducts.length })"
      :sort-options="getDefaultSortOptions()"
      :searchbar-variant="SearchbarVariants.NONE"
      @updated-filter="(searchbarValues: SearchbarValues) => (selectedSortOption = searchbarValues.selectedSortOption)"
    >
      <template #append>
        <DefaultButton
          v-if="bundle.contractStatus === StatusTypes.DRAFT || bundle.contractStatus === StatusTypes.APPROVED"
          class="ml-6"
          :color="PlattformColors.LIGHT"
          :prepend-icon="Icons.CIRCLE_ADD"
          @click="openMarketplace()"
        >
          {{ t('consumerBundleDetailProducts.addProduct.btn') }}
        </DefaultButton>
      </template>
    </Searchbar>
    <div class="bundle-details__products-list">
      <ProductListWithPlans
        :product-plan-loading="productPlanLoading"
        :products="combinedBundleProducts"
        :bundle-status="bundle.contractStatus"
        @handle-product-click="(productId: ProductResponse['id']) => handleProductClick(productId)"
        @handle-product-remove-click="(product: BundleProductExtended) => handleRemoveProduct(product)"
        @edit-plan-click="(product: BundleProductExtended) => editPlan(product)"
        @handle-terminate-subscription="(subscription: SubscriptionResponse) => handleTerminateSubscription(subscription)"
      />
    </div>
    <Dialog
      ref="productPlansDialogRef"
      :size="DialogSizes.LARGE"
      @on-close="closeProductPlansDialog()"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('consumerBundleDetailProducts.editPlan.headline') }}</h1>
          <ProductList
            v-if="productForPlanUpdate"
            :products="[productForPlanUpdate]"
          />
          <ProductPlans
            ref="productPlansRef"
            selectable
            :multi-selectable="productForPlanUpdate?.supportMultiplePlans"
            :available-product-plans="plans"
            :bundle-product-subscriptions="productForPlanUpdate?.subscriptions"
            :bundle-state="bundle.contractStatus"
            @valid="(value: boolean) => validateSubmitBtn(value)"
          />
        </template>
        <template #buttons>
          <DefaultButton
            :color="PlattformColors.PRIMARY"
            :disabled="!validSubmitBtn"
            :loading="submitButtonLoading"
            @click="submitPlansToBundle(productForPlanUpdate)"
          >
            {{ t('buttons.submit') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="closeProductPlansDialog()"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </Dialog>
    <Dialog
      ref="bundleProductRemoveQuestionDialogRef"
      @on-close="bundleProductRemoveQuestionDialogRef?.close()"
    >
      <DialogBundleProductRemove
        :bundle="bundle"
        :bundle-product="productForRemove"
        :loading="bundleProductRemoveLoading"
        @cancel="bundleProductRemoveQuestionDialogRef?.close()"
        @submit="(bundleProduct: BundleProductExtended) => removeProduct(bundleProduct)"
      />
    </Dialog>
    <Dialog
      ref="bundleTerminateSubscriptionQuestionDialogRef"
      @on-close="closeBundleTerminateSubscriptionQuestionDialog()"
    >
      <DialogBundleSubscriptionTerminate
        :subscription="subscriptionForTerminate"
        :loading="subscriptionTerminateLoading"
        :is-last-subscription="hasBundleOnlyOneSubscription"
        @cancel="closeBundleTerminateSubscriptionQuestionDialog()"
        @submit="(subscription: SubscriptionResponse) => terminateSubscription(subscription)"
      />
    </Dialog>
  </template>

  <CreateTeaser
    v-else
    :icon="Icons.CONNECTED_APIS"
    :headline="t('consumerBundleDetailProducts.createContent.headline')"
    :btn-text="t('consumerBundleDetailProducts.createContent.btn')"
    @submit="openMarketplace()"
  />
</template>

<script lang="ts" setup>
import { ProductResponse } from '@/models/Product'
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { ComputedRef, ref } from 'vue'
import CreateTeaser from '@/components/layout/CreateTeaser.vue'
import { Routes } from '@/models/enums/Routes'
import Dialog from '@/components/layout/Dialog.vue'
import ProductListWithPlans from '@/components/products/ProductListWithPlans.vue'
import DialogBundleProductRemove from '@/components/dialogs/DialogBundleProductRemove.vue'
import DialogBundleSubscriptionTerminate from '@/components/dialogs/DialogBundleSubscriptionTerminate.vue'
import ProductList from '@/components/products/ProductList.vue'
import ProductPlans from '@/components/products/ProductPlans.vue'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import { useProductStore } from '@/store/products'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import { SubscriptionResponse } from '@/models/Subscription'
import { useSubscriptionStore } from '@/store/subscriptions'
import { PlanResponse } from '@/models/Plan'
import { computed } from 'vue'
import DialogDefaultLayout from '@/components/dialogs/layout/DialogDefaultLayout.vue'
import { useBundleApprovalHelper } from '@/composables/useBundleApprovalHelper'
import { DialogSizes } from '@/models/enums/DialogSizes'
import Searchbar from '@/components/Searchbar.vue'
import { useSearchHelper } from '@/composables/useSearchHelper'
import { SearchbarVariants } from '@/models/enums/SearchbarVariants'
import { SearchbarValues } from '@/models/SearchbarValues'
import { BundleProductExtended } from '@/models/Bundle'
import { usePlanStore } from '@/store/plans'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

interface ProductPlanLoading {
  loading: boolean
  productId?: ProductResponse['id']
}

const { t } = useI18n()
const router = useRouter()
const bundleStore = useBundleStore()
const productStore = useProductStore()
const subscriptionStore = useSubscriptionStore()
const alertStore = useAlertStore()
const planStore = usePlanStore()

const { bundle } = storeToRefs(bundleStore)
const { bundleProducts } = storeToRefs(productStore)
const { plans } = storeToRefs(planStore)
const productPlansDialogRef = ref<InstanceType<typeof Dialog>>()
const productPlansRef = ref()
const bundleProductRemoveQuestionDialogRef = ref<InstanceType<typeof Dialog>>()
const bundleTerminateSubscriptionQuestionDialogRef = ref<InstanceType<typeof Dialog>>()
const productForPlanUpdate = ref<BundleProductExtended>()
const productPlanLoading = ref<ProductPlanLoading>({ loading: false, productId: undefined })
const bundleProductRemoveLoading = ref(false)
const validSubmitBtn = ref(false)
const submitButtonLoading = ref(false)
const productForRemove = ref<BundleProductExtended>()
const subscriptionForTerminate = ref<SubscriptionResponse>()
const subscriptionTerminateLoading = ref(false)

const { isMostRecentApprovalNotRejectedOrTerminated, getMostRecentApproval } = useBundleApprovalHelper()
const { getDefaultSortOptions, getSortFunction } = useSearchHelper()

const selectedSortOption = ref<string>()

/**
 * handleProductClick
 * @param {productId} productId
 */
function handleProductClick(productId: ProductResponse['id']): void {
  router.push({ name: Routes.CONSUMER_PRODUCTS_INFOS, params: { productId } })
}

const combinedBundleProducts: ComputedRef<BundleProductExtended[]> = computed(() => {
  const result: Array<BundleProductExtended> = []
  bundle?.value?.bundleProducts?.forEach((bundleProduct) => {
    if (bundleProducts.value.find((product) => product.id === bundleProduct.id)) {
      result.push({ ...bundleProduct, ...bundleProducts.value.find((product) => product.id === bundleProduct.id)! })
    }
  })
  return selectedSortOption.value ? result.sort(getSortFunction(selectedSortOption.value)) : result
})

const hasBundleOnlyOneSubscription: ComputedRef<boolean> = computed(() => {
  let activeSubscriptionCount: number = 0
  bundle?.value?.bundleProducts?.forEach((product) => {
    if (product.subscriptions) {
      product.subscriptions.forEach((subscription) => {
        const subscriptionApprovalStatus: StatusTypes | undefined = getMostRecentApproval(subscription.approvals)?.approvalStatus
        if (
          // is Subscription active
          subscriptionApprovalStatus === StatusTypes.APPROVED ||
          subscriptionApprovalStatus === StatusTypes.IN_TERMINATION
        ) {
          activeSubscriptionCount++
        }
      })
    }
  })
  return activeSubscriptionCount == 1
})

/**
 * editPlan
 * @param {selectedProduct} selectedProduct
 */
async function editPlan(selectedProduct: BundleProductExtended): Promise<void> {
  try {
    productPlanLoading.value.loading = true
    productPlanLoading.value.productId = selectedProduct.id

    await planStore.GET_PRODUCT_PLANS(selectedProduct.id)

    productForPlanUpdate.value = selectedProduct
    productPlansDialogRef.value?.open()
  } catch {
    Promise.resolve()
  } finally {
    productPlanLoading.value.loading = false
    productPlanLoading.value.productId = undefined
  }
}

/**
 * closeProductPlansDialog
 */
function closeProductPlansDialog(): void {
  productPlansDialogRef.value?.close()
  validateSubmitBtn(false)
}

/**
 * showBundleProductRemoveQuestionDialog
 */
function showBundleProductRemoveQuestionDialog(): void {
  bundleProductRemoveQuestionDialogRef.value?.open()
}

/**
 * openMarketplace
 */
function openMarketplace(): void {
  router.push({ name: Routes.CONSUMER_PRODUCTS, query: { providerName: bundle?.value?.providerOrganization?.name, selectedBundleId: bundle?.value?.id } })
}

/**
 * validateSubmitBtn
 * @param {value} value
 */
function validateSubmitBtn(value: boolean): void {
  validSubmitBtn.value = value
}

/**
 * submitPlansToBundle
 * @param {product} product
 */
async function submitPlansToBundle(product?: BundleProductExtended): Promise<void> {
  if (product) {
    const selectedPlanIds = productPlansRef.value.getSelectedPlans()

    if (bundle?.value?.contractStatus === StatusTypes.DRAFT) {
      const bundleProductsPlanIds: Array<SubscriptionResponse['planId']> = []

      // flat planIds and push to bundleProductsPlanIds without affected product
      bundle?.value?.bundleProducts?.forEach((bundleProduct) => {
        if (bundleProduct.id !== product?.id) {
          bundleProduct.subscriptions?.forEach((subscription) => bundleProductsPlanIds.push(subscription.planId))
        }
      })

      const updatedBundle = { ...bundle?.value, planIds: bundleProductsPlanIds.concat(selectedPlanIds) }

      try {
        submitButtonLoading.value = true
        await bundleStore.UPDATE(bundle?.value?.id, updatedBundle)
        alertStore.add({
          text: t('consumerBundleDetailProducts.planUpdated'),
          type: AlertTypes.SUCCESS,
        })
      } catch {
        Promise.resolve()
      } finally {
        submitButtonLoading.value = false
        closeProductPlansDialog()
      }
    } else {
      if (bundle?.value) {
        const changedProduct = {
          product: {
            name: product.name,
            id: product.id,
          },
          // only add new selected plans, but ignore REJECTED and TERMINATED
          selectedPlans: selectedPlanIds.filter(
            (planId: PlanResponse['id']) =>
              !product?.subscriptions?.find(
                (subscription) => isMostRecentApprovalNotRejectedOrTerminated(subscription.approvals) && planId === subscription.planId
              )
          ),
        }
        bundleStore.pushPlansToBundleChanges(bundle.value.id, changedProduct)
        closeProductPlansDialog()
      }
    }
  }
}

/**
 * handleRemoveProduct
 * @param {bundleProduct} bundleProduct
 */
function handleRemoveProduct(bundleProduct: BundleProductExtended): void {
  productForRemove.value = bundleProduct
  showBundleProductRemoveQuestionDialog()
}

/**
 * removeProduct
 * @param {bundleProduct} bundleProduct
 */
async function removeProduct(bundleProduct: BundleProductExtended): Promise<void> {
  if (bundle?.value) {
    bundleProductRemoveLoading.value = true
    const bundlePlanIds: Array<SubscriptionResponse['planId']> = []

    bundle?.value?.bundleProducts?.forEach((bProduct) => {
      if (bProduct.id !== bundleProduct.id) {
        bProduct.subscriptions?.forEach((subscription) => bundlePlanIds.push(subscription.planId))
      }
    })

    const updatedBundle = {
      ...bundle?.value,
      planIds: bundlePlanIds,
    }

    try {
      await bundleStore.UPDATE(bundle.value.id, updatedBundle)
      alertStore.add({
        text: t('consumerBundleDetailProducts.removeProduct'),
        type: AlertTypes.SUCCESS,
      })
    } catch {
      Promise.resolve()
    } finally {
      bundleProductRemoveLoading.value = false
      bundleProductRemoveQuestionDialogRef.value?.close()
    }
  }
}

/**
 * showBundleTerminateSubscriptionQuestionDialog
 */
function showBundleTerminateSubscriptionQuestionDialog(): void {
  bundleTerminateSubscriptionQuestionDialogRef.value?.open()
}

/**
 * closeBundleTerminateSubscriptionQuestionDialog
 */
function closeBundleTerminateSubscriptionQuestionDialog(): void {
  bundleTerminateSubscriptionQuestionDialogRef.value?.close()
}

/**
 * handleTerminateSubscription
 * @param {subscription} subscription
 */
function handleTerminateSubscription(subscription: SubscriptionResponse): void {
  subscriptionForTerminate.value = subscription
  showBundleTerminateSubscriptionQuestionDialog()
}

/**
 * terminateSubscription
 * @param {subscription} subscription
 */
async function terminateSubscription(subscription: SubscriptionResponse): Promise<void> {
  try {
    subscriptionTerminateLoading.value = true
    const bundleResponse = await subscriptionStore.TERMINATE(subscription.id, 'note')
    if (bundle?.value) bundle.value = bundleResponse
    alertStore.add({
      text: t('consumerBundleDetailProducts.terminatePlan'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    subscriptionTerminateLoading.value = false
    closeBundleTerminateSubscriptionQuestionDialog()
  }
}

if (bundle?.value) await productStore.GET_BUNDLE_PRODUCTS(bundle.value.bundleProducts)
</script>

<i18n lang="yaml">
de:
  consumerBundleDetailProducts:
    results: '{count} API | {count} API-Produkte'
    createContent:
      headline: Füge API-Produkte hinzu, um deine App mit Leben zu füllen
      btn: Zum Marktplatz
    addProduct:
      btn: API hinzufügen
    editPlan:
      headline: Pläne zu API hinzufügen
    planUpdated: Die API wurde erfolgreich angepasst.
    removeProduct: Die API wurde erfolgreich entfernt.
    terminatePlan: Der Plan wurde erfolgreich gekündigt.
</i18n>
