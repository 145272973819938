<template>
  <v-list
    v-if="accessDefinitions?.length"
    class="access-definition-list"
  >
    <v-list-item
      v-for="accessDefinition in accessDefinitions"
      :key="accessDefinition.id"
      class="bg-white mb-6"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="d-flex">
          <DefaultAvatar
            size="48"
            class="mr-6"
            color="grey-lighten-4"
          >
            <v-icon
              v-if="!accessDefinition.active"
              size="24"
              >{{ Icons.CIRCLE_ERROR }}</v-icon
            >
            <v-icon
              v-else
              size="24"
              >{{ getIconFromAccessDefinition(accessDefinition) }}</v-icon
            >
          </DefaultAvatar>
          <v-list-item-title
            ><h3 class="mb-0">{{ getNameFromAccessDefinition(accessDefinition) }}</h3></v-list-item-title
          >

          <!-- wenn verfügbar -->
          <!-- <v-list-item-subtitle> {{t('accessDefinitionList.lastEdited')}} </v-list-item-subtitle> -->
        </div>
        <div class="d-flex align-center">
          <v-chip
            v-if="!accessDefinition.active"
            label
            size="small"
            variant="outlined"
          >
            {{ t('accessDefinitionList.disabled') }}
          </v-chip>
          <v-chip
            v-if="accessDefinition.defaultDefinition"
            label
            size="small"
            variant="outlined"
          >
            {{ t('accessDefinitionList.default') }}
          </v-chip>
          <template v-if="productView">
            <slot name="button"></slot>
          </template>
          <template v-else>
            <v-menu
              v-if="mappedProducts && accessDefinition.id && accessDefinition.id in mappedProducts"
              open-on-click
              class="access-definition-list-menu"
              transition="slide-y-transition"
              :offset="5"
              :max-height="325"
            >
              <template #activator="{ isActive, props }">
                <div v-bind="props">
                  <v-chip
                    label
                    size="small"
                  >
                    {{ t('accessDefinitionList.products', mappedProducts[accessDefinition.id].length) }}
                    <template #prepend>
                      <v-icon>{{ Icons.LINK_ALT }}</v-icon>
                    </template>
                    <template #append>
                      <v-icon class="ml-1">{{ isActive ? Icons.CHEVRON_UP : Icons.CHEVRON_DOWN }}</v-icon>
                    </template>
                  </v-chip>
                </div>
              </template>

              <v-list
                density="compact"
                class="pa-2"
              >
                <div
                  v-for="(item, index) in mappedProducts[accessDefinition.id]"
                  :key="index"
                >
                  <v-list-item
                    class="pa-2"
                    @click="handleClick(item.id)"
                  >
                    <template #prepend>
                      <DefaultAvatar
                        size="40"
                        class="mr-2"
                      >
                        <v-icon
                          color="primary"
                          size="20"
                          >{{ Icons.PRODUCT }}</v-icon
                        >
                      </DefaultAvatar>
                    </template>
                    <p class="mb-0">{{ item.name }}</p>
                  </v-list-item>
                  <v-divider
                    v-if="index < mappedProducts[accessDefinition.id].length - 1"
                    class="my-1"
                  ></v-divider>
                </div>
              </v-list>
            </v-menu>

            <v-chip
              v-else
              label
              size="small"
              variant="outlined"
            >
              {{ t('accessDefinitionList.noProducts') }}

              <template #prepend>
                <v-icon>{{ Icons.LINK_ALT }}</v-icon>
              </template>
            </v-chip>
            <div class="d-flex align-center">
              <v-menu
                location="bottom"
                transition="slide-y-transition"
              >
                <template #activator="{ props }">
                  <IconButton
                    v-bind="props"
                    :icon="Icons.OPTIONS_VERTICAL"
                  ></IconButton>
                </template>
                <v-list density="compact">
                  <v-list-item
                    v-if="isGenerateToken(accessDefinition.accessExchangeType)"
                    class="context-menu-list-item"
                    flat
                    @click="toggleActivation(accessDefinition)"
                  >
                    <template #prepend>
                      <v-icon :icon="accessDefinition.active ? Icons.CIRCLE_ERROR : Icons.CIRCLE_CHECK" />
                    </template>
                    <v-list-item-title v-if="accessDefinition.active">{{ t('accessDefinitionList.menu.deactivate') }}</v-list-item-title>
                    <v-list-item-title v-else>{{ t('accessDefinitionList.menu.activate') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    class="context-menu-list-item"
                    flat
                    @click="emit('setDefault', accessDefinition)"
                  >
                    <template #prepend>
                      <v-icon>{{ Icons.AUTHENTICATION }}</v-icon>
                    </template>
                    <v-list-item-title>{{ t('accessDefinitionList.menu.default') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="isGenerateToken(accessDefinition.accessExchangeType)"
                    class="context-menu-list-item"
                    flat
                    @click="emit('update', accessDefinition)"
                  >
                    <template #prepend>
                      <v-icon>{{ Icons.EDIT }}</v-icon>
                    </template>
                    <v-list-item-title>{{ t('accessDefinitionList.menu.edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="isGenerateToken(accessDefinition.accessExchangeType)"
                    flat
                    @click="emit('delete', accessDefinition)"
                  >
                    <template #prepend>
                      <v-icon>{{ Icons.DELETE }}</v-icon>
                    </template>
                    <v-list-item-title>{{ t('accessDefinitionList.menu.delete') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </div>
      </div>

      <div
        v-if="isGenerateToken(accessDefinition.accessExchangeType)"
        class="access-definition--chips"
      >
        <v-chip
          label
          size="small"
          variant="outlined"
          >{{ accessDefinition.accessType?.hashAlgorithm }}</v-chip
        >
        <v-chip
          label
          size="small"
          variant="outlined"
          >{{ accessDefinition.accessType?.maxTokenLength }} {{ t('accessDefinitionList.maxTokenLength') }}</v-chip
        >
        <v-chip
          label
          size="small"
          variant="outlined"
          >{{ t('validityPeriod', { validity: getRightPeriod(accessDefinition.accessType?.validityPeriod) }) }}</v-chip
        >
      </div>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { AccessDefinitionResponse } from '@/models/AccessDefinition'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { Icons } from '@/models/enums/IconTypes'
import { ProductResponse, ProductTeaser } from '@/models/Product'
import { PropType } from 'vue'
import { router } from '@/router'
import { Routes } from '@/models/enums/Routes'
import { useAccessHelper } from '@/composables/useAccessHelper'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'

const { t } = useI18n()
const emit = defineEmits(['toggleActivation', 'setDefault', 'update', 'delete'])

const { getIconFromAccessDefinition, getNameFromAccessDefinition, getRightPeriod } = useAccessHelper()

defineProps({
  accessDefinitions: {
    type: Array<AccessDefinitionResponse>,
    default: undefined,
  },
  mappedProducts: {
    type: Object as PropType<{ [key: string]: ProductTeaser[] }>,
    default: undefined,
  },
  productView: { type: Boolean, default: false },
})

/**
 * handleClick
 * @param {productId} productId
 */
function handleClick(productId: ProductResponse['id']): void {
  router.push({ name: Routes.PROVIDER_PRODUCTS_ACCESSES, params: { productId } })
}

/**
 * isGenerateToken
 * @param {accessExchangeType} accessExchangeType
 * @return {boolean}
 */
function isGenerateToken(accessExchangeType: AccessDefinitionResponse['accessExchangeType']): boolean {
  return accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN
}

/**
 * toggleActivation
 * @param {accessDefinition} accessDefinition
 */
function toggleActivation(accessDefinition: AccessDefinitionResponse): void {
  emit('toggleActivation', { ...accessDefinition, active: !accessDefinition.active })
}
</script>
<style lang="scss" scoped>
.access-definition-list {
  width: 100%;
  background-color: transparent;

  .v-list-item {
    padding: 1.5rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .access-definition--chips {
    margin-left: 4.5rem;
    padding: 0.25rem 0;
  }

  .v-chip {
    cursor: pointer;
    margin: 0.25rem 0.5rem 0.25rem 0;

    &__content {
      b {
        padding-left: 0.5rem;
      }
    }

    &__prepend {
      i {
        margin-right: 0.5rem;
      }
    }
  }

  :deep(.v-switch) {
    margin-bottom: 0;
  }
}
</style>

<i18n lang="yaml">
de:
  accessDefinitionList:
    maxTokenLength: Zeichen
    manualChip: Manuelle Übergabe des Authentifizierungsmittels
    default: Standard
    disabled: Inaktiv
    lastEdited: Zuletzt bearbeitet am
    products: '{count} API | {count} API-Produkte'
    noProducts: Keine API-Produkte
    menu:
      activate: Aktivieren
      deactivate: Deaktivieren
      default: Als Standard festlegen
      edit: Bearbeiten
      delete: Löschen
</i18n>
