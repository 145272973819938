<template>
  <div class="product-details">
    <v-form @submit.prevent="emit('submit', detailedDescription, linkGroups)">
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('productDetail.form.headline') }}</h1>
          <div class="product-details__detailed-description">
            <p class="highlight">{{ t('productDetail.form.productDetailDescriptionLabel') }}</p>
            <MdEditor
              v-model="detailedDescription"
              language="en-US"
              :toolbars="mdToolbars"
              :footers="mdFooter"
              :custom-icon="customIcon"
              :data-cy="DialogProductFormDetailTest.DESCRIPTION"
              no-iconfont
              :preview="false"
              :style="{ height: '300px' }"
            />
          </div>

          <div lass="product-details__links">
            <p class="highlight">{{ t('productDetail.links.subline') }}</p>

            <div
              v-for="(linkGroupItem, index) in linkGroups"
              :key="index"
            >
              <div class="d-flex align-center justify-space-between mb-4">
                <p class="product-details__link-group mb-0">{{ t('productDetail.links.link') }} {{ index + 1 }}</p>
                <IconButton
                  :icon="Icons.DELETE"
                  @click="removeLinkGroup(index)"
                ></IconButton>
              </div>

              <v-text-field
                v-model="linkGroupItem.name"
                :label="t('productDetail.links.name.label')"
                :rules="linkNameRules"
                required
                variant="outlined"
              >
                <template #append-inner>
                  <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
                </template>
              </v-text-field>
              <v-text-field
                v-model="linkGroupItem.url"
                :label="t('productDetail.links.url.label')"
                :rules="urlRules"
                required
                variant="outlined"
              >
                <template #append-inner>
                  <v-icon size="10">{{ Icons.FAVORITE }}</v-icon>
                </template>
              </v-text-field>
            </div>

            <DefaultButton
              :color="PlattformColors.SECONDARY"
              :disabled="linkGroups.length > 2"
              :prepend-icon="Icons.ADD"
              @click="addLinkGroup()"
            >
              {{ t('productDetail.buttons.add') }}
            </DefaultButton>
          </div>
        </template>
        <template #buttons>
          <DefaultButton
            type="submit"
            :color="PlattformColors.PRIMARY"
            :loading="loading"
            :data-cy="DialogProductFormDetailTest.SUBMIT"
          >
            {{ t('buttons.save') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('buttons.cancel') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </v-form>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ref, PropType, computed } from 'vue'
import { ProductLink, ProductResponse } from '@/models/Product'
import { MdEditor, Footers, ToolbarNames, CustomIcon } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'
import { VIcon } from 'vuetify/lib/components/index.mjs'
import { Icons } from '@/models/enums/IconTypes'
import { isNotEmpty, isMaxLength } from '@/validators'
import { h } from 'vue'
import { DialogProductFormDetailTest } from '@/models/enums/test/DialogProductFormDetailTest'
import DialogDefaultLayout from './layout/DialogDefaultLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'

const mdToolbars: Array<ToolbarNames | number> = ['bold', 'italic', 'title', 'quote', 'orderedList', 'unorderedList', 'code', 'link', '=', 'preview']
const mdFooter: Array<Footers | number> = []

const customIcon: CustomIcon = {
  bold: { component: h(VIcon, { icon: Icons.BOLD, class: 'mx-1' }) },
  italic: { component: h(VIcon, { icon: Icons.ITALIC, class: 'mx-1' }) },
  title: { component: h(VIcon, { icon: Icons.H1, class: 'mx-1' }) },
  quote: { component: h(VIcon, { icon: Icons.QUOTE, class: 'mx-1' }) },
  'unordered-list': { component: h(VIcon, { icon: Icons.UNORDERED_LIST, class: 'mx-1' }) },
  'ordered-list': { component: h(VIcon, { icon: Icons.ORDERED_LIST, class: 'mx-1' }) },
  code: { component: h(VIcon, { icon: Icons.CODE, class: 'mx-1' }) },
  link: { component: h(VIcon, { icon: Icons.LINK_ALT, class: 'mx-1' }) },
  preview: { component: h(VIcon, { icon: Icons.EYE, class: 'mx-1' }) },
}

const { t } = useI18n()

const componentProps = defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const linkGroups = ref<ProductLink[]>([])

const linkNameRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 25)])
const urlRules = computed(() => [(v: string): boolean | string => isNotEmpty(v), (v: string): boolean | string => isMaxLength(v, 2048)])

const emit = defineEmits(['submit', 'cancel'])

const detailedDescription = ref(componentProps.productObject?.detailedDescription || '')

if (componentProps.productObject?.productLinks?.length) {
  componentProps.productObject?.productLinks.forEach((productLink) => {
    addLinkGroup(productLink)
  })
}

/**
 * addLinkGroup
 * @param {productLink} productLink
 */
function addLinkGroup(productLink?: ProductLink): void {
  if (productLink) {
    linkGroups.value?.push(productLink)
  } else {
    linkGroups.value?.push({
      name: '',
      url: '',
    })
  }
}

/**
 * removeLinkGroup
 * @param {index} index
 */
function removeLinkGroup(index: number): void {
  linkGroups.value?.splice(index, 1)
}
</script>
<style lang="scss" scoped>
.product-details {
  &__detailed-description {
    margin-bottom: 2rem;
  }

  &__link-group {
    font-size: $font-size-s;
    font-weight: 700;
  }
}
</style>

<i18n lang="yaml">
de:
  productDetail:
    form:
      headline: Produktbeschreibung
      productDetailDescriptionLabel: Produktbeschreibung
      identity:
        headline: Visuelle Identität
    links:
      link: Link
      subline: Externe Links
      name:
        label: Linkname
      url:
        label: Url
    buttons:
      add: Link hinzufügen
</i18n>
