<template>
  <v-menu
    location="end"
    offset="16"
  >
    <template #activator="{ props }">
      <IconButton
        v-bind="props"
        :icon="Icons.OPTIONS_HORIZONTAL"
      />
    </template>
    <v-list>
      <template
        v-for="(item, index) in menuItems"
        :key="index"
      >
        <v-list-item
          :value="index"
          :disabled="item.type === Permissions.CREATE_FOLDER && newGhostFolder"
          @click="handleMenuClick(item)"
        >
          <template #prepend>
            <v-icon
              color="primary"
              :icon="item?.icon"
            ></v-icon>
          </template>
          <v-list-item-title>{{ item?.title }}</v-list-item-title>
        </v-list-item>
        <v-divider v-if="menuItems && index < menuItems?.length - 1"></v-divider>
      </template>
    </v-list>
    <input
      ref="fileUpload"
      type="file"
      hidden
      @change="handleFileUpload"
    />
  </v-menu>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { useFilesStore } from '@/store/files'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import { Permissions } from '@/models/enums/Permissions'
import { FilesAndFolderTypes } from '@/models/enums/FilesAndFolderTypes'
import IconButton from '../baseComponents/buttons/IconButton.vue'

const { t } = useI18n()
const filesStore = useFilesStore()

const { newGhostFolder } = storeToRefs(filesStore)

interface MenuItemList {
  title: string
  icon: string
  permission: string
  type: string
}

interface MenuItem {
  type: string
  items: Array<MenuItemList>
}

const prop = defineProps({
  type: {
    type: String,
    default: '',
  },
  permissions: {
    type: Array<string>,
    default: () => [],
  },
})

const fileCreate = {
  title: t('contextMenu.fileUpload'),
  icon: Icons.CLOUD_UPLOAD,
  permission: Permissions.CREATE_FILE,
  type: Permissions.CREATE_FILE,
}

const folderCreate = {
  title: t('contextMenu.folderCreate'),
  icon: Icons.FOLDER_ADD,
  permission: Permissions.CREATE_FOLDER,
  type: Permissions.CREATE_FOLDER,
}

const folderDelete = {
  title: t('contextMenu.delete'),
  icon: Icons.DELETE,
  permission: Permissions.DELETE,
  type: Permissions.DELETE_FOLDER,
}

const fileDelete = {
  title: t('contextMenu.delete'),
  icon: Icons.DELETE,
  permission: Permissions.DELETE,
  type: Permissions.DELETE_FILE,
}

const fileDownload = {
  title: t('contextMenu.download'),
  icon: Icons.DOWNLOAD,
  permission: Permissions.READ,
  type: Permissions.FILE_READ,
}

const menuSets: Array<MenuItem> = [
  {
    type: FilesAndFolderTypes.ROOT_FOLDER,
    items: [fileCreate, folderCreate],
  },
  {
    type: FilesAndFolderTypes.SUB_FOLDER,
    items: [fileCreate, folderCreate, folderDelete],
  },
  {
    type: FilesAndFolderTypes.LAST_FOLDER,
    items: [fileCreate, folderDelete],
  },
  {
    type: FilesAndFolderTypes.FILE,
    items: [fileDownload, fileDelete],
  },
]

const emit = defineEmits(['handleMenuClick'])

const fileUpload = ref<HTMLInputElement>()

const menuItems = computed(() => {
  const menuItems = menuSets.find((menu) => menu.type === prop.type)?.items
  const filteredMenuItems = menuItems?.filter((menu) => menu.permission === prop.permissions.find((permission: string) => permission === menu.permission))
  return filteredMenuItems
})

/**
 * handleMenuClick
 * @param {item} item
 */
function handleMenuClick(item: MenuItemList): void {
  if (item.permission === Permissions.CREATE_FILE) {
    fileUpload.value?.click()
  } else {
    emit('handleMenuClick', item.type)
  }
}

/**
 * handleFileUpload
 * @param {event} event
 */
function handleFileUpload(event: Event): void {
  const target = event.target as HTMLInputElement
  if (target.files?.length) {
    emit('handleMenuClick', Permissions.CREATE_FILE, target.files)
  }
}
</script>

<style lang="scss" scoped>
.v-list-item__prepend > .v-icon {
  margin-inline-end: 1rem;
}

:deep(.v-list-item__prepend > .v-icon ~ .v-list-item__spacer) {
  display: none;
}
</style>

<i18n lang="yaml">
de:
  contextMenu:
    fileUpload: Datei hochladen
    folderCreate: Ordner erstellen
    download: Herunterladen
    delete: Löschen
</i18n>
