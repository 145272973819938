<template>
  <div>
    <DialogDefaultLayoutEnter :on-submit="() => handleSubmit()">
      <template #content>
        <h1>{{ t('contacts.delete.headline', { name: contact.name }) }}</h1>
        <p>{{ t('contacts.delete.copy', { name: contact.name }) }}</p>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="handleSubmit()"
          >{{ t('buttons.delete') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
          >{{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType, ref } from 'vue'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import { ContactResponse } from '@/models/Contact'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

const loading = ref(false)

const props = defineProps({
  contact: {
    type: Object as PropType<ContactResponse>,
    default: () => {},
  },
})

/**
 * handleSubmit
 */
function handleSubmit(): void {
  emit('submit', props.contact)
  loading.value = true
}

const emit = defineEmits(['submit', 'cancel'])
</script>

<i18n lang="yaml">
de:
  contacts:
    delete:
      headline: '{name} wirklich löschen?'
      copy: Möchtest du die Kontaktperson "{name}" wirklich löschen?
</i18n>
