interface FormatHelper {
  getFirstLetterUpperCase: (text?: string) => string
}

/**
 * @return {FormatHelper}
 */
export function useFormatHelper(): FormatHelper {
  /**
   * return the first letter of the text in uppercase
   * @param {string | undefined} text
   * @return {string} first letter of text in uppercase
   */
  function getFirstLetterUpperCase(text?: string): string {
    return text ? text.slice(0, 1).toUpperCase() : '?'
  }

  return {
    getFirstLetterUpperCase,
  }
}
