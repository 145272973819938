<template>
  <BackButton :back-url="{ name: Routes.CONSUMER_PRODUCTS }" />
  <template v-if="product">
    <GeneralPageTitle>
      <template #prepend>
        <DefaultAvatar size="80">
          <v-icon
            color="primary"
            size="40"
            >{{ Icons.PRODUCT }}</v-icon
          >
        </DefaultAvatar>
      </template>
      <template #title>
        {{ product.name }}
        <v-icon>{{ product.publiclyAvailable ? Icons.GLOBE : Icons.LOCK }}</v-icon>
      </template>
      <template #subtitle>
        <b>{{ product.organization?.name }}</b>
        <v-divider
          vertical
          length="12"
          thickness="1"
          color="black"
          opacity=".5"
          class="mx-2"
        />
        {{
          t('createdAt', {
            d: format(new Date(`${product.createdAt}`), 'dd.MM.yyyy'),
          })
        }}
      </template>
      <template #append>
        <DefaultButton
          v-if="isUserAdmin()"
          :color="PlattformColors.PRIMARY"
          :loading="providerLoading"
          :prepend-icon="Icons.CIRCLE_ADD"
          @click="showChooseOrCreateBundleDialog()"
        >
          {{ t('consumerProductDetail.btnActivateApi') }}
        </DefaultButton>
      </template>
    </GeneralPageTitle>
    <p class="mb-6">{{ productDescription }}</p>
    <v-tabs>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_PRODUCTS_INFOS, params: { productId: product.id } }"
      >
        {{ t('consumerProductDetail.descriptionTabName') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_PRODUCTS_DOCS, params: { productId: product.id } }"
      >
        {{ t('consumerProductDetail.docsTabName') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_PRODUCTS_OPENAPI, params: { productId: product.id } }"
      >
        {{ t('consumerProductDetail.apidocsTabName') }}
      </v-tab>
      <v-tab
        v-if="isLoggedIn()"
        replace
        exact
        :to="{ name: Routes.CONSUMER_PRODUCTS_DOWNLOADS, params: { productId: product.id } }"
      >
        {{ t('consumerProductDetail.downloadsTabName') }}
      </v-tab>
      <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_PRODUCTS_PLANS, params: { productId: product.id } }"
      >
        {{ t('consumerProductDetail.plansTabName') }}
      </v-tab>
      <!-- TODO DEVPO-3290 -->
      <!-- <v-tab
        replace
        exact
        :to="{ name: Routes.CONSUMER_PRODUCTS_CONTACT, params: { productId: product.id } }"
      >
        {{ t('consumerProductDetail.contactTabName') }}
      </v-tab> -->
    </v-tabs>
    <TabContainer />
    <Dialog
      ref="WizardRef"
      :size="DialogSizes.LARGE"
      @on-close="closeChooseOrCreateBundleDialog()"
    >
      <DialogDefaultLayout>
        <template #content>
          <h1>{{ t('addProductWizard.headline') }}</h1>
          <ProductList :products="[product]" />
          <v-window v-model="dialogStep">
            <v-window-item :value="0">
              <ChooseOrCreateBundle
                ref="chooseOrCreateBundleRef"
                :product="product"
                :available-bundles="availableBundles"
                @valid="(value: boolean) => updateValidstate(0, value)"
              />
            </v-window-item>
            <v-window-item :value="1">
              <ProductPlans
                ref="productPlansRef"
                selectable
                :multi-selectable="product.supportMultiplePlans"
                :available-product-plans="visibleProductPlans"
                @valid="(value: boolean) => updateValidstate(1, value)"
              />
            </v-window-item>
          </v-window>
        </template>
        <template #buttons>
          <DefaultButton
            :color="PlattformColors.PRIMARY"
            :disabled="!validStates[dialogStep]"
            :loading="submitButtonLoading"
            @click="handleRightButton()"
          >
            {{ isNotLastStep ? t('buttons.next') : t('buttons.create') }}
          </DefaultButton>

          <DefaultButton
            :color="PlattformColors.SECONDARY"
            @click="handleLeftButton()"
          >
            {{ isFirstStep ? t('buttons.cancel') : t('buttons.back') }}
          </DefaultButton>
        </template>
      </DialogDefaultLayout>
    </Dialog>
  </template>
</template>

<script lang="ts" setup>
import { useProductStore } from '@/store/products'
import { format } from 'date-fns'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useBundleStore } from '@/store/bundles'
import Dialog from '@/components/layout/Dialog.vue'
import ChooseOrCreateBundle from '@/components/bundles/ChooseOrCreateBundle.vue'
import ProductPlans from '@/components/products/ProductPlans.vue'
import TabContainer from '@/components/TabContainer.vue'
import BackButton from '@/components/layout/BackButton.vue'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { StatusTypes } from '@/models/enums/StatusTypes'
import ProductList from '@/components/products/ProductList.vue'
import { BundleChangesProduct } from '@/models/Bundle'
import { useAlertStore } from '@/store/alerts'
import { AlertTypes } from '@/models/enums/AlertTypes'
import DialogDefaultLayout from '@/components/dialogs/layout/DialogDefaultLayout.vue'
import { useAccessDefinitionsStore } from '@/store/accessDefinitions'
import { AccessExchangeTypes } from '@/models/enums/AccessDefinitionTypes'
import { useProductPlanHelper } from '@/composables/useProductPlanHelper'
import { DialogSizes } from '@/models/enums/DialogSizes'
import { useActiveUserHelper } from '@/composables/useActiveUserHelper'
import GeneralPageTitle from '@/components/GeneralPageTitle.vue'
import { usePlanStore } from '@/store/plans'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { isLoggedIn } from '@/plugins/keycloak'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const productStore = useProductStore()
const bundleStore = useBundleStore()
const alertStore = useAlertStore()
const accessDefinitionStore = useAccessDefinitionsStore()
const planStore = usePlanStore()

const { product } = storeToRefs(productStore)
const { bundles } = storeToRefs(bundleStore)
const { plans } = storeToRefs(planStore)

const { isPlanVisible } = useProductPlanHelper()
const { isUserAdmin } = useActiveUserHelper()

const WizardRef = ref<InstanceType<typeof Dialog>>()
const chooseOrCreateBundleRef = ref<InstanceType<typeof ChooseOrCreateBundle>>()
const productPlansRef = ref<InstanceType<typeof ProductPlans>>()
const providerLoading = ref(false)
const dialogStep = ref(0)
const validStates = ref<Array<boolean>>([])
const submitButtonLoading = ref(false)

const productDescription = computed(() => {
  return product?.value?.description || t('consumerProductDetail.noDescription')
})

const isFirstStep = computed(() => {
  return dialogStep.value === 0
})
const isNotLastStep = computed(() => {
  return dialogStep.value < 1
})

const availableBundles = computed(() => {
  return bundles.value
    .filter(
      (bundle) =>
        bundle.providerOrganization?.id === product?.value?.organization.id &&
        (bundle.contractStatus === StatusTypes.DRAFT || bundle.contractStatus === StatusTypes.APPROVED)
    )
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
})

const visibleProductPlans = computed(() => plans.value.filter((plan) => isPlanVisible(plan)))

/**
 * updateValidstate
 * @param {index} index
 * @param {value} value
 */
function updateValidstate(index: number, value: boolean): void {
  validStates.value[index] = value
}

/**
 * handleLeftButton
 */
function handleLeftButton(): void {
  if (isFirstStep.value) {
    closeChooseOrCreateBundleDialog()
  } else {
    dialogStep.value -= 1
  }
}

/**
 * handleRightButton
 */
function handleRightButton(): void {
  if (isNotLastStep.value) {
    dialogStep.value += 1
  } else {
    submitButtonLoading.value = true
    submitPlansToBundle()
  }
}

/**
 * closeChooseOrCreateBundleDialog
 */
function closeChooseOrCreateBundleDialog(): void {
  WizardRef.value?.close()
  dialogStep.value = 0
  validStates.value = []
}

/**
 * showChooseOrCreateBundleDialog
 */
async function showChooseOrCreateBundleDialog(): Promise<void> {
  if (product?.value) {
    try {
      providerLoading.value = true
      await Promise.all([bundleStore.GET(), planStore.GET_PRODUCT_PLANS(product.value.id)])
      WizardRef.value?.open()
    } catch {
      Promise.resolve()
    } finally {
      providerLoading.value = false
    }
  }
}

/**
 * submitPlansToBundle
 */
async function submitPlansToBundle(): Promise<void> {
  const selectedBundle = chooseOrCreateBundleRef.value?.getSelectedBundle()
  const selectedPlans = productPlansRef.value?.getSelectedPlans()

  if (selectedBundle && selectedPlans) {
    if (selectedBundle.contractStatus === StatusTypes.DRAFT) {
      try {
        await bundleStore.ADD_SUBSCRIPTION(selectedBundle.id, selectedPlans)
        router.push({ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId: selectedBundle.id } })
        alertStore.add({
          text: t('consumerProductDetail.addPlan'),
          type: AlertTypes.SUCCESS,
        })
      } catch {
        Promise.resolve()
      } finally {
        submitButtonLoading.value = false
        closeChooseOrCreateBundleDialog()
      }
    } else {
      if (product?.value) {
        try {
          const newProduct: BundleChangesProduct = {
            product: {
              name: product.value.name,
              id: product.value.id,
            },
            selectedPlans,
          }
          if (!selectedBundle.bundleProducts?.find((bundleProduct) => bundleProduct.accessDefinitionId === product?.value?.accessDefinitionId)) {
            if (product?.value?.accessDefinitionId) {
              const accessDefinition = await accessDefinitionStore.GET_BY_ID(product.value.accessDefinitionId)
              if (accessDefinition.accessExchangeType === AccessExchangeTypes.GENERATE_TOKEN) {
                newProduct.product.accessDefinition = accessDefinition
              }
            }
          }
          bundleStore.pushProductToBundleChanges(selectedBundle.id, newProduct)
          router.push({ name: Routes.CONSUMER_BUNDLES_PRODUCTS, params: { bundleId: selectedBundle.id } })
        } catch {
          Promise.resolve()
        } finally {
          submitButtonLoading.value = false
          closeChooseOrCreateBundleDialog()
        }
      }
    }
  }
}

await Promise.allSettled([productStore.GET_BY_ID(route.params.productId as string)])
</script>
<i18n lang="yaml">
de:
  consumerProductDetail:
    btnActivateApi: Zu App hinzufügen
    descriptionTabName: Produktbeschreibung
    docsTabName: Readme
    apidocsTabName: API Explorer
    downloadsTabName: Downloads
    contactTabName: Kontakt
    plansTabName: Pläne
    noDescription: Keine Beschreibung verfügbar.
    addPlan: Der Plan wurde zur App hinzugefügt.
  addProductWizard:
    headline: API zu App hinzufügen
</i18n>
