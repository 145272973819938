import { ProductRequest, ProductResponse, ProductResponseList, ProductSearchQuery, ProductUpdateRequest } from '@/models/Product'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { OrganizationPublicResponse } from '@/models/Organization'
import { BundleProductResponse } from '@/models/Bundle'
import { StatusTypes } from '@/models/enums/StatusTypes'

interface productStateProperties {
  products: ProductResponse[]
  product?: ProductResponse
  bundleProducts: ProductResponse[]
}

export const useProductStore = defineStore('products', {
  state: (): productStateProperties => ({
    products: [],
    product: undefined,
    bundleProducts: [],
  }),
  actions: {
    async GET_PRODUCTS(params: ProductSearchQuery) {
      try {
        const { data }: { data: ProductResponseList } = await useAxios.get('/api/products', {
          params,
        })
        this.products = data.products
      } catch {
        return Promise.reject()
      }
    },
    async GET_BY_ID(productId: ProductResponse['id']) {
      try {
        const { data }: { data: ProductResponse } = await useAxios.get(`/api/products/${productId}`)
        this.product = data
      } catch {
        return Promise.reject()
      }
    },
    async GET_BUNDLE_PRODUCTS(bundleProducts: BundleProductResponse[]) {
      try {
        this.bundleProducts = []
        if (bundleProducts) {
          await Promise.all(
            bundleProducts.map(async (product) => {
              const { data }: { data: ProductResponse } = await useAxios.get(`/api/products/${product.id}`)
              this.bundleProducts.push(data)
            })
          )
        }
      } catch {
        return Promise.reject()
      }
    },
    async GET_BY_PROVIDER(providerId: OrganizationPublicResponse['id']) {
      try {
        const { data }: { data: ProductResponseList } = await useAxios.get(`/api/products/provider/${providerId}`)
        this.products = data.products
      } catch {
        return Promise.reject()
      }
    },
    async CREATE(productObject: ProductRequest) {
      try {
        const { data }: { data: ProductResponse } = await useAxios.post(`/api/products`, { ...productObject, productStatus: StatusTypes.DRAFT })
        this.products.push(data)
        return data
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(productObject: ProductUpdateRequest, productId: ProductResponse['id']) {
      try {
        const { data }: { data: ProductResponse } = await useAxios.patch(`/api/products/${productId}`, productObject)
        this.product = data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(productId: ProductResponse['id']) {
      try {
        await useAxios.delete(`/api/products/${productId}`)
      } catch {
        return Promise.reject()
      }
    },
    setProductById(productId: ProductResponse['id']) {
      this.product = this.products.find((prod) => prod.id === productId)
      if (this.product?.detailedDescription === null) {
        this.product.detailedDescription = ''
      }
    },
  },
})
