<template>
  <DialogCenteredLayout>
    <template #image>
      <DefaultAvatar
        size="96"
        :color="PlattformColors.SECONDARY"
      >
        <v-icon
          size="60"
          :color="PlattformColors.SUCCESS"
          >{{ Icons.CIRCLE_CHECK }}</v-icon
        >
      </DefaultAvatar>
    </template>
    <template #content>
      <h1>{{ t('createdOrganization.headline') }}</h1>
      <p>{{ t('createdOrganization.copy') }}</p>
    </template>
    <template #buttons>
      <DefaultButton
        type="submit"
        :color="PlattformColors.PRIMARY"
        @click="emit('submit')"
      >
        {{ t('buttons.done') }}
      </DefaultButton>
    </template>
  </DialogCenteredLayout>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import DialogCenteredLayout from './layout/DialogCenteredLayout.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import { Icons } from '@/models/enums/IconTypes'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'

const { t } = useI18n()

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  createdOrganization:
    headline: Deine Organisation wurde erfolgreich erstellt!
    copy: Nun kannst du unser Developer Portal in vollem Umfang nutzen.
</i18n>
