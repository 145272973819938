import { Avatar } from '@/models/Avatar'

interface AvatarHelper {
  getAvatarIcon: (iconPath: string | undefined) => string
  getAvatarList: () => Array<Avatar>
  getDefaultAvatarKey: () => string
}

/**
 * @return {AvatarHelper}
 */
export function useAvatarHelper(): AvatarHelper {
  const bundleAvatars = [
    {
      key: 'BLUE_LINES',
      value: '/images/avatars/blue-lines.svg',
    },
    {
      key: 'RED_LINES',
      value: '/images/avatars/red-lines.svg',
    },
    {
      key: 'GREEN_LINES',
      value: '/images/avatars/green-lines.svg',
    },
    {
      key: 'LIGHT_BLUE_LINES',
      value: '/images/avatars/light-blue-lines.svg',
    },
    {
      key: 'YELLOW_LINES',
      value: '/images/avatars/yellow-lines.svg',
    },
  ]

  /**
   * Get file path from avatar key
   * @param {string | undefined} iconPath avatar key
   * @return {string} file path to avatar icon
   */
  function getAvatarIcon(iconPath: string | undefined): string {
    return bundleAvatars.find((avatar) => avatar.key === iconPath)?.value || bundleAvatars[0].value
  }

  /**
   * Get list of all avatars
   * @return {Array<Avatar>} avatar list
   */
  function getAvatarList(): Array<Avatar> {
    return bundleAvatars
  }

  /**
   * Get default avatar key
   * @return {string} default avatar key
   */
  function getDefaultAvatarKey(): string {
    return bundleAvatars[0].key
  }

  return {
    getAvatarIcon,
    getAvatarList,
    getDefaultAvatarKey,
  }
}
