import { User } from '@/models/User'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'
import { Invitation } from '@/models/Invitation'

interface InvitationProperties {
  invitationCode: string
}

export const useInvitationStore = defineStore(StoreName.INVITATION, {
  state: (): InvitationProperties => ({
    invitationCode: '',
  }),
  actions: {
    async INVITE(invitedUser: Invitation) {
      try {
        await useAxios.post(`/api/invitations`, invitedUser)
      } catch {
        return Promise.reject()
      }
    },
    async UPDATE(invitedUser: Invitation) {
      try {
        const { data }: { data: User } = await useAxios.post(`/api/invitations`, invitedUser)

        return data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(user?: User) {
      try {
        await useAxios.delete(`/api/invitations?email=${user?.email}`)
      } catch {
        return Promise.reject()
      }
    },
    async assignUserToOrganization(invitationCode: InvitationProperties['invitationCode']) {
      try {
        return await useAxios.post(`/api/invitations/${invitationCode}/accept`).then((resp) => resp)
      } catch {
        return Promise.reject()
      }
    },
  },
})
