import { Icons } from '@/models/enums/IconTypes'
import { h } from 'vue'
import type { IconSet, IconAliases, IconProps } from 'vuetify'

const aliases: IconAliases = {
  calendar: Icons.CALENDAR,
  collapse: Icons.CHEVRON_UP,
  complete: Icons.CHECK,
  cancel: Icons.CIRCLE_CLOSED,
  close: Icons.CLOSE,
  delete: Icons.CIRCLE_CLOSED,
  clear: Icons.CLOSE,
  success: Icons.CIRCLE_CHECK,
  info: Icons.CIRCLE_INFORMATION,
  warning: Icons.CIRCLE_WARNING,
  error: Icons.CIRCLE_ERROR,
  prev: Icons.CHEVRON_LEFT,
  next: Icons.CHEVRON_RIGHT,
  checkboxOn: Icons.CHECKBOX_ON,
  checkboxOff: Icons.CHECKBOX_OFF,
  checkboxIndeterminate: Icons.CHECK_INDETERMINATE,
  delimiter: Icons.CIRCLE,
  expand: Icons.CHEVRON_DOWN,
  menu: Icons.MENU,
  subgroup: Icons.CARET_DOWN,
  dropdown: Icons.CHEVRON_DOWN,
  radioOn: Icons.CIRCLE_CHECK,
  radioOff: Icons.CIRCLE,
  edit: Icons.EDIT,
  ratingEmpty: Icons.FAVORITE,
  ratingFull: Icons.FAVORITE_FULL,
  ratingHalf: Icons.FAVORITE_HALF,
  loading: Icons.UPDATE,
  first: 'first',
  last: 'last',
  unfold: Icons.SORT,
  file: Icons.ATTACHMENT,
  plus: Icons.ADD,
  minus: Icons.REMOVE,
  sortAsc: Icons.ARROW_UP,
  sortDesc: Icons.ARROW_DOWN,
}

const wallisIcons: IconSet = {
  component: (props: IconProps) => {
    const extendedProps = { ...props, class: [props.icon] }
    return h(props.tag, extendedProps)
  },
}

export { aliases, wallisIcons }
