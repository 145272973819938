export enum ButtonVariants {
  ELEVATED = 'elevated',
  FLAT = 'flat',
  OUTLINED = 'outlined',
  TONAL = 'tonal',
  TEXT = 'text',
  PLAIN = 'plain',
}

export enum ButtonSizes {
  XSMALL = 'x-small',
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
  XLARGE = 'x-large',
}

export enum ButtonTypes {
  DEFAULT = 'button',
  SUBMIT = 'submit',
}
