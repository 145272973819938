<template>
  <div>
    <DialogDefaultLayoutEnter :on-submit="() => emit('submit', bundleProduct)">
      <template #content>
        <h1>{{ t('bundleProduct.remove.headline', { productName: bundleProduct.name }) }}</h1>
        <p>{{ t('bundleProduct.remove.copy', { productName: bundleProduct.name, bundleName: bundle.name }) }}</p>
      </template>
      <template #buttons>
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="emit('submit', bundleProduct)"
          >{{ t('buttons.remove') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}</DefaultButton
        >
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import { BundleProductExtended, BundleResponse } from '@/models/Bundle'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  bundle: {
    type: Object as PropType<BundleResponse>,
    default: () => {},
  },
  bundleProduct: {
    type: Object as PropType<BundleProductExtended>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>

<i18n lang="yaml">
de:
  bundleProduct:
    remove:
      headline: '{productName} wirklich entfernen?'
      copy: Möchtest du die API "{productName}"" aus der App "{bundleName}" wirklich entfernen?
</i18n>
