export enum StatusTypes {
  ACTIVATION_REQUESTED = 'ACTIVATION_REQUESTED',
  ACTIVE = 'ACTIVE',
  DEACTIVATION_REQUESTED = 'DEACTIVATION_REQUESTED',
  INACTIVE = 'INACTIVE',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  IN_TERMINATION = 'IN_TERMINATION',
  TERMINATED = 'TERMINATED',
  INITIAL = 'INITIAL',
  CHANGE = 'CHANGE',
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
  PENDING = 'PENDING',
  REPORT = 'REPORT',
}
