import { TechnicalUserResponse, TechnicalUserCreateResponse } from '@/models/TechnicalUser'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { StoreName } from '@/models/enums/StoreName'

interface TechnicalUserStateProperties {
  technicalUsers: TechnicalUserResponse[]
}

export const useTechnicalUserStore = defineStore(StoreName.TECHNICAL_USERS, {
  state: (): TechnicalUserStateProperties => ({
    technicalUsers: [],
  }),
  actions: {
    async GET() {
      try {
        const { data }: { data: TechnicalUserResponse[] } = await useAxios.get('/api/technicalUsers')
        this.technicalUsers = data
      } catch {
        return Promise.reject()
      }
    },
    async DELETE(id: TechnicalUserResponse['id']) {
      try {
        await useAxios.delete(`/api/technicalUsers/${id}`)
        this.technicalUsers = [...this.technicalUsers.filter((technicalUser) => technicalUser.id !== id)]
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async CREATE(name: TechnicalUserResponse['name']): Promise<TechnicalUserCreateResponse> {
      try {
        const { data }: { data: TechnicalUserCreateResponse } = await useAxios.post(
          '/api/technicalUsers',
          { name },
          { headers: { 'X-API-RESOURCE-VERSION': 2 } }
        )
        this.technicalUsers.push(data)
        return data
      } catch {
        return Promise.reject()
      }
    },
  },
  getters: {
    technicalUsersLength(state) {
      return state.technicalUsers.length
    },
  },
})
