<template>
  <div class="side-wizard">
    <v-row>
      <v-col
        cols="4"
        class="side-wizard__sidebar"
      >
        <div class="side-wizard__sidebar-container">
          <div class="side-wizard__overview">
            <div class="side-wizard__overview-img">
              <v-img :src="overviewImage" />
            </div>
            <div class="side-wizard__overview-text">
              <h2>{{ props.overview.headline }}</h2>
            </div>
          </div>
          <v-item-group
            v-model="wizard"
            selected-class="side-wizard__menu-active"
            disabled
            class="side-wizard__menu"
          >
            <v-item
              v-for="i in components.length"
              :key="i"
              v-slot="{ selectedClass }"
            >
              <v-list-item :class="[selectedClass, wizard >= i - 1 ? 'side-wizard__menu-selected' : '']">
                <template #prepend>
                  <v-avatar
                    size="small"
                    :icon="wizard > i - 1 ? Icons.CHECK : `${Icons.DYNAMIC_NUMERIC}${i}`"
                    :color="wizard > i - 1 ? 'primary' : 'secondary'"
                  />
                </template>
                <v-list-item-title>{{ props.components[i - 1].headline }}</v-list-item-title>
              </v-list-item>
            </v-item>
          </v-item-group>
        </div>
        <div
          v-if="isDeletable"
          class="side-wizard__sidebar-delete"
        >
          <IconButton
            :icon="Icons.DELETE"
            @click="clickDelete"
          />
        </div>
      </v-col>
      <v-col
        cols="8"
        class="d-flex flex-column justify-space-between"
      >
        <v-window v-model="wizard">
          <v-window-item
            v-for="(component, i) in props.components"
            :key="i"
            :value="i"
          >
            <component
              :is="component.componentName"
              :key="i"
            ></component
          ></v-window-item>
        </v-window>
        <div class="d-flex justify-space-between">
          <DefaultButton
            v-if="isNotFirst"
            :color="PlattformColors.SECONDARY"
            :disabled="!isNotFirst"
            @click="prevStep"
          >
            {{ buttonPrevText }}
          </DefaultButton>

          <DefaultButton
            v-else
            :color="PlattformColors.SECONDARY"
            @click="emit('cancel')"
          >
            {{ t('sideWizard.cancel') }}
          </DefaultButton>

          <DefaultButton
            v-if="isNotLast"
            :color="PlattformColors.PRIMARY"
            :disabled="!checkCompleted"
            :loading="buttonLoading"
            @click="nextStep"
          >
            {{ buttonNextText }}
          </DefaultButton>

          <DefaultButton
            v-else
            :color="PlattformColors.PRIMARY"
            :disabled="!checkCompleted"
            :loading="buttonLoading"
            @click="finish"
          >
            {{ buttonFinishText }}
          </DefaultButton>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts" setup>
import { useSideWizardStore } from '@/store/sideWizard'
import { storeToRefs } from 'pinia'
import { computed, onBeforeUnmount, PropType, ref } from 'vue'
import type { Component } from 'vue'
import { useI18n } from 'vue-i18n'

import blueLines from '/images/avatars/blue-lines.svg'
import { Icons } from '@/models/enums/IconTypes'
import IconButton from './baseComponents/buttons/IconButton.vue'
import DefaultButton from './baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

interface WizardOverview {
  image?: string
  headline: string
}

interface Step {
  headline: string
  componentName: Component
}

const { t } = useI18n()

const props = defineProps({
  overview: {
    type: Object as PropType<WizardOverview>,
    required: true,
  },
  components: {
    type: Array<Step>,
    required: true,
  },
  buttonPrev: {
    type: String,
    default: '',
  },
  buttonNext: {
    type: String,
    default: '',
  },
  buttonFinish: {
    type: String,
    default: '',
  },
  buttonLoading: {
    type: Boolean,
    default: false,
  },
  isDeletable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['finish', 'delete', 'cancel'])

const sideWizardStore = useSideWizardStore()
const { wizardValidated, wizardContent } = storeToRefs(sideWizardStore)

const wizard = ref(0)

const isNotFirst = computed(() => {
  return wizard.value > 0
})
const isNotLast = computed(() => {
  return wizard.value < props.components.length - 1
})
const checkCompleted = computed(() => {
  return wizardValidated.value[wizard.value]?.value
})
const overviewImage = computed(() => {
  return props.overview.image || blueLines
})
const buttonPrevText = computed(() => {
  return props.buttonPrev || t('sideWizard.back')
})
const buttonNextText = computed(() => {
  return props.buttonNext || t('sideWizard.next')
})
const buttonFinishText = computed(() => {
  return props.buttonFinish || t('sideWizard.finish')
})

/**
 * prevStep
 */
function prevStep(): void {
  wizard.value -= 1
}

/**
 * nextStep
 */
function nextStep(): void {
  wizard.value += 1
}

/**
 * finish
 */
function finish(): void {
  emit('finish', wizardContent.value)
}

/**
 * clickDelete
 */
function clickDelete(): void {
  emit('delete')
}

onBeforeUnmount(() => {
  sideWizardStore.$reset()
})
</script>
<style lang="scss" scoped>
.side-wizard {
  margin: 0 -0.8rem; // align stepper-header with headline of parent component

  &__sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-container {
      position: relative;
    }

    &-delete {
      padding-left: 0.3rem;
      margin-bottom: -0.35rem;
    }
  }

  &__overview {
    display: flex;
    margin-bottom: 1rem;

    &::before {
      position: absolute;
      content: '';
      left: 1.6rem;
      top: 1.5rem;
      bottom: 1.5rem;
      border: 2px dashed rgb(var(--v-theme-light-grey));
    }

    &-img {
      width: 3.5rem;
      flex-shrink: 0;
    }

    &-text {
      padding-left: 0.7rem;
      align-self: center;
      display: flex;

      h2 {
        margin: 0;
        overflow-wrap: anywhere;
      }
    }
  }

  &__menu {
    margin-top: 2rem;

    .v-list-item.v-theme--light:not(.v-list-item--active):not(.v-list-item--disabled) {
      padding: 0.75rem;

      .v-list-item-title {
        font-weight: 700;
      }
    }
  }
}
</style>

<i18n lang="yaml">
de:
  sideWizard:
    cancel: Abbrechen
    back: Zurück
    next: Weiter
    finish: Fertig
</i18n>
