<template>
  <div class="my-organization-delete-question">
    <DialogDefaultLayoutEnter :on-submit="() => emit('submit', organization.id)">
      <template #content>
        <h1>{{ t('myOrganization.delete.headline', { name: organization.name }) }}</h1>
        <p>{{ t('myOrganization.delete.copy') }}</p>
      </template>
      <template #buttons>
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="emit('submit', organization.id)"
        >
          {{ t('buttons.leave') }}
        </DefaultButton>

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('cancel')"
        >
          {{ t('buttons.cancel') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { PropType } from 'vue'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import { UserOrganization } from '@/models/User'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

const { t } = useI18n()

defineProps({
  organization: {
    type: Object as PropType<UserOrganization>,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['submit', 'cancel'])
</script>
<i18n lang="yaml">
de:
  myOrganization:
    delete:
      headline: '{name} wirklich verlassen?'
      copy: Wenn du die Organisation verlässt, kannst du die Inhalte dieser Organisation nicht mehr einsehen und nur durch eine erneute Einladung wieder
        beitreten.
</i18n>
