import { Category } from '@/models/Category'
import useAxios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
import { StoreName } from '@/models/enums/StoreName'

interface CategoryStateProperties {
  allCategories: Category[]
  productCategories: Category[]
  organizationCategories: Category[]
}

export const useCategoryStore = defineStore(StoreName.CATEGORIES, {
  state: (): CategoryStateProperties => ({
    allCategories: [],
    productCategories: [],
    organizationCategories: [],
  }),
  actions: {
    async GET_PRODUCTS() {
      const { t } = useI18n()
      try {
        const { data }: { data: Category[] } = await useAxios.get('/api/categories/products')
        this.productCategories = data.map((category: Category) => ({ ...category, name: t(`categories.${category.name}`) }))
        this.allCategories = [...this.allCategories, ...this.productCategories]
      } catch {
        return Promise.reject()
      }
    },
    async GET_ORGANIZATIONS() {
      const { t } = useI18n()
      try {
        const { data }: { data: Category[] } = await useAxios.get('/api/categories/organizations')
        this.organizationCategories = data.map((category: Category) => ({ ...category, name: t(`categories.${category.name}`) }))
        this.allCategories = [...this.allCategories, ...this.organizationCategories]
      } catch {
        return Promise.reject()
      }
    },
  },
  getters: {
    categoryById(state) {
      return (id: Category['id']): Category | undefined => state.allCategories.find((category) => category.id === id)
    },
  },
})
