<template>
  <div
    v-if="bundle?.contact"
    class="bundle-contacts"
  >
    <ContactElement :contact="bundle.contact" />
  </div>
  <InfoBox
    v-else
    :headline="t('providerBundleDetailNoContact.headline')"
    :copy="t('providerBundleDetailNoContact.copy')"
  />
</template>

<script lang="ts" setup>
import { useBundleStore } from '@/store/bundles'
import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import ContactElement from '@/components/organizations/contacts/ContactElement.vue'
import InfoBox from '@/components/InfoBox.vue'

const { t } = useI18n()
const bundleStore = useBundleStore()

const { bundle } = storeToRefs(bundleStore)
</script>

<i18n lang="yaml">
de:
  providerBundleDetailNoContact:
    headline: Kein Kontakt gefunden!
    copy: Es wurde noch keine Kontaktperson hinterlegt.
</i18n>
