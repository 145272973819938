<template>
  <v-dialog
    v-model="isOpen"
    class="component-dialog"
    :max-width="size"
    :persistent="!showCloseBtn"
  >
    <v-card class="pa-0">
      <template #default>
        <div v-if="showCloseBtn">
          <IconButton
            :icon="Icons.CLOSE"
            class="component-dialog__close-btn"
            @click="emit('onClose')"
          />
        </div>
        <slot></slot>
      </template>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { DialogSizes } from '@/models/enums/DialogSizes'
import { Icons } from '@/models/enums/IconTypes'
import { PropType, ref } from 'vue'
import IconButton from '../baseComponents/buttons/IconButton.vue'

const emit = defineEmits(['onClose'])

const isOpen = ref(false)

defineProps({
  showCloseBtn: {
    type: Boolean,
    default: true,
  },
  size: {
    type: Number as PropType<DialogSizes>,
    default: DialogSizes.MEDIUM,
  },
})

/**
 * open
 */
function open(): void {
  isOpen.value = true
}

/**
 * close
 */
function close(): void {
  isOpen.value = false
}

defineExpose({
  open,
  close,
})
</script>

<style lang="scss" scoped>
.component-dialog {
  &__close-btn {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
</style>
