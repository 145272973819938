<template>
  <div class="user-tab bg-white">
    <p>{{ t('organizationUsersMemberTab.desc') }}</p>
    <h2>{{ t('organizationUsersMemberTab.headline') }}</h2>
    <p>{{ t('organizationUsersMemberTab.copy') }}</p>
    <v-form
      ref="invite"
      v-model="inviteUserForm"
      @submit.prevent="inviteUser"
    >
      <div class="d-flex user-tab__invite">
        <v-col class="pa-0">
          <v-text-field
            v-model="newUser.email"
            class="rounded-0"
            :label="t('organizationUsersMemberTab.invite.email')"
            :rules="userEmailRules"
            variant="outlined"
        /></v-col>
        <v-col class="pa-0">
          <TeamSelect
            :teams="teams"
            :user="newUser"
            @update="updateNewUser"
          />
        </v-col>
        <v-col class="pa-0">
          <DefaultButton
            class="rounded-0 h-100"
            block
            :type="ButtonTypes.SUBMIT"
            :color="PlattformColors.PRIMARY"
            :disabled="!isEmail(newUser.email || '')"
            :loading="inviteUserButtonLoading"
            :variant="ButtonVariants.FLAT"
          >
            {{ t('organizationUsersMemberTab.invite.button') }}
          </DefaultButton>
        </v-col>
      </div>
    </v-form>
    <h2>{{ t('organizationUsersMemberTab.searchHeadline') }}</h2>
    <v-autocomplete
      v-model="filter"
      clearable
      :items="searchItems"
      :label="t('organizationUsersMemberTab.searchLabel')"
      item-title="name"
      item-value="searchId"
      variant="outlined"
    />
    <v-table
      v-if="filteredUsers.length"
      class="user-tab__users row-colored"
    >
      <template #default>
        <thead>
          <tr>
            <th class="text-left">
              {{ t('organizationUsersMemberTab.table.user') }}
            </th>
            <th class="text-left">
              {{ t('organizationUsersMemberTab.table.role') }}
            </th>
            <th class="text-left">
              {{ t('organizationUsersMemberTab.table.status') }}
            </th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(user, index) in filteredUsers"
            :key="index"
          >
            <td class="td-name">
              <DefaultAvatar
                size="24"
                class="mr-2"
                :color="PlattformColors.SUCCESS"
              >
                <v-icon size="18">{{ Icons.USER }}</v-icon>
              </DefaultAvatar>
              <span class="td-name-wrapper">
                <span class="td-name-value">{{ user.name }}</span>
                <span
                  v-if="user.id"
                  class="td-name-email"
                >
                  {{ user.email }}
                </span>
              </span>
            </td>
            <td class="td-role">
              <TeamSelect
                :teams="teams"
                :user="user"
                @update="updateUser"
              />
            </td>
            <td>
              <span
                v-if="user.id"
                class="td-status bg-green"
                >{{ t('organizationUsersMemberTab.table.active') }}</span
              >
              <span
                v-else
                class="td-status bg-blue-200"
                >{{ t('organizationUsersMemberTab.table.pending') }}</span
              >
            </td>
            <td class="d-flex justify-end">
              <IconButton
                :icon="Icons.DELETE"
                @click="showDeletionOverlay(user)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <v-row
      v-if="isTeamSelected"
      class="user-tab__links"
    >
      <v-col class="user-tab__links-team">
        <DefaultButton
          :color="PlattformColors.PRIMARY"
          :prepend-icon="Icons.USERS"
          :to="{ name: Routes.TEAMS, query: queryParam }"
          :variant="ButtonVariants.TEXT"
        >
          {{ t('organizationUsersMemberTab.teamsAndProperties') }}
        </DefaultButton>
      </v-col>
    </v-row>
  </div>
  <Dialog
    ref="invitedUserDialogRef"
    @on-close="invitedUserDialogRef?.close()"
  >
    <DialogUserInvited @submit="invitedUserDialogRef?.close()" />
  </Dialog>
  <Dialog
    ref="deleteUserDialogRef"
    @on-close="deleteUserDialogRef?.close()"
  >
    <DialogUserDelete
      :user="selectedToDelete"
      :loading="deleteUserLoading"
      @cancel="deleteUserDialogRef?.close()"
      @submit="(userToDelete: User) => deleteUser(userToDelete)"
    />
  </Dialog>
</template>

<script lang="ts" setup>
import { useTeamStore } from '@/store/teams'
import { useUserStore } from '@/store/users'
import { useAlertStore } from '@/store/alerts'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { User } from '@/models/User'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { isEmail, isMinLength, isMaxLength } from '@/validators'
import TeamSelect from '@/components/teams/TeamSelect.vue'
import { useInvitationStore } from '@/store/invitations'
import { InvitationForm } from '@/models/Invitation'
import { useMyOrganizationStore } from '@/store/myOrganizations'
import { Routes } from '@/models/enums/Routes'
import { Icons } from '@/models/enums/IconTypes'
import { AlertTypes } from '@/models/enums/AlertTypes'
import Dialog from '@/components/layout/Dialog.vue'
import DialogUserInvited from '@/components/dialogs/DialogUserInvited.vue'
import DialogUserDelete from '@/components/dialogs/DialogUserDelete.vue'
import DefaultAvatar from '@/components/baseComponents/avatars/DefaultAvatar.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
import DefaultButton from '@/components/baseComponents/buttons/DefaultButton.vue'
import { ButtonTypes, ButtonVariants } from '@/models/enums/components/Buttons'
import IconButton from '@/components/baseComponents/buttons/IconButton.vue'

const { t } = useI18n()
const route = useRoute()
const teamStore = useTeamStore()
const myOrganizationStore = useMyOrganizationStore()
const invitationStore = useInvitationStore()
const userStore = useUserStore()
const alertStore = useAlertStore()

const { teams, teamById } = storeToRefs(teamStore)
const { users, filteredUsers, filter } = storeToRefs(userStore)
const { activeOrganization } = storeToRefs(myOrganizationStore)

const inviteUserButtonLoading = ref(false)
const inviteUserForm = ref(false)
const invite = ref<HTMLFormElement>()
const selectedToDelete = ref<User>()
const newUser = ref<InvitationForm>({
  email: undefined,
  teams: [],
  admin: false,
})
const invitedUserDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteUserDialogRef = ref<InstanceType<typeof Dialog>>()
const deleteUserLoading = ref(false)

const userEmailRules = computed(() => {
  return [(v: string): boolean | string => isEmail(v), (v: string): boolean | string => isMinLength(v, 3), (v: string): boolean | string => isMaxLength(v, 64)]
})
const searchItems = computed(() => {
  return [...teams.value, ...users.value]
})
const queryParam = computed(() => {
  if (filter?.value && isTeamSelected) {
    return { teamId: filter.value }
  }
  return undefined
})
const isTeamSelected = computed(() => {
  return filter?.value ? !!teamById.value(filter.value) : false
})

/**
 * updateNewUser
 * @param {userObject} userObject
 */
function updateNewUser(userObject: Partial<User>): void {
  if (userObject.teams) newUser.value.teams = userObject.teams
  if (userObject.admin) newUser.value.admin = userObject.admin
}

/**
 * inviteUser
 */
async function inviteUser(): Promise<void> {
  if (inviteUserForm.value && activeOrganization?.value && newUser.value.email) {
    inviteUserButtonLoading.value = true
    newUser.value.email = newUser.value.email.toLocaleLowerCase()
    try {
      const invitationRequest = { ...newUser.value, email: newUser.value.email, organizationId: activeOrganization.value.id! } // TODO interface organization
      await invitationStore.INVITE(invitationRequest)
      await userStore.GET()
      invitedUserDialogRef.value?.open()
      invite.value?.reset()
    } catch {
      Promise.resolve()
    } finally {
      inviteUserButtonLoading.value = false
    }
  }
}

/**
 * updateUser
 * @param {User} userObject
 */
async function updateUser(userObject: User): Promise<void> {
  try {
    if (activeOrganization?.value) {
      if (userObject?.id) {
        await userStore.UPDATE(userObject)
      } else {
        // TODO interface organization
        const userAssignment = { organizationId: activeOrganization.value.id!, email: userObject.email, teams: userObject.teams, admin: userObject.admin }
        await invitationStore.UPDATE(userAssignment)
        await userStore.GET()
      }
      alertStore.add({
        text: t('organizationUsersMemberTab.success.updated'),
        type: AlertTypes.SUCCESS,
      })
    }
  } catch {
    return Promise.resolve()
  }
}

/**
 * showDeletionOverlay
 * @param {user} user
 */
function showDeletionOverlay(user: User): void {
  selectedToDelete.value = user
  deleteUserDialogRef.value?.open()
}

/**
 * deleteUser
 * @param {userToDelete} userToDelete
 */
async function deleteUser(userToDelete?: User): Promise<void> {
  try {
    deleteUserLoading.value = true
    if (userToDelete?.id) {
      await userStore.DELETE(userToDelete)
    } else {
      await invitationStore.DELETE(userToDelete)
      users.value = users.value.filter((user) => user.email !== selectedToDelete.value?.email)
    }
    alertStore.add({
      text: t('organizationUsersMemberTab.success.deleted'),
      type: AlertTypes.SUCCESS,
    })
  } catch {
    Promise.resolve()
  } finally {
    deleteUserLoading.value = false
    deleteUserDialogRef.value?.close()
  }
}

await Promise.allSettled([teamStore.GET(), userStore.GET()])
// TODO use store instead of query?
if (route.query.teamId) {
  const team = teamById.value(route.query.teamId as string)
  if (team) userStore.setFilter(team)
}
</script>

<style lang="scss" scoped>
.v-menu__content {
  text-transform: capitalize;
}

.user-tab {
  &__links {
    margin-top: 1rem;

    &-team {
      font-weight: bold;

      .v-icon {
        font-size: 2rem;
        margin: -0.25rem 0.75rem 0 0;
      }
    }
  }

  &__invite {
    display: flex;
    height: 3.5rem;
    margin-bottom: 2rem;

    > div {
      display: flex;

      &:first-child {
        flex-grow: 2;
      }

      :deep(.v-field--variant-outlined .v-field__outline__start),
      :deep(.v-field--variant-outlined .v-field__outline__end) {
        border-radius: 0;
      }
    }
  }

  &__users {
    tbody {
      font-weight: 700;

      tr {
        .td-name {
          display: flex;
          align-items: center;

          &-wrapper {
            display: inline-block;
          }

          &-value {
            display: block;
          }

          &-email {
            display: block;
            font-size: 0.7rem;
            line-height: 0.7rem;
            font-weight: normal;
          }
        }

        .td-role {
          padding: 0.375rem 1rem;
        }

        td {
          font-size: $font-size-xs;

          &:first-child {
            font-size: $font-size-m;
          }
        }

        .td-status {
          display: inline-block;
          padding: 0.25rem 0.5rem;
          text-align: center;
          width: 5.5rem;
        }
      }
    }
  }
}
</style>

<i18n lang="yaml">
de:
  organizationUsersMemberTab:
    desc: Verwalte die Rollen und Teams deiner Nutzer oder lade neue Nutzer zu deiner Organisation ein.
    info:
      lastAdmin: Du kannst den letzten Admin einer Organisation nicht entfernen!
    headline: Nutzer einladen
    copy: Die E-Mail-Adresse wird beim Absenden automatisch in Kleinbuchstaben konvertiert.
    invite:
      email: E-Mail-Adresse
      button: Einladen
    searchHeadline: Übersicht deiner Nutzer
    searchLabel: Nutzer durchsuchen
    table:
      user: Nutzer
      status: Status
      role: Rolle / Teams
      active: Aktiv
      pending: Eingeladen
    teamsAndProperties: Teams und Eigenschaften anzeigen
    success:
      deleted: Der Nutzer wurde aus der Organisation entfernt!
      deletedAssignment: Die Einladung wurde entfernt!
      updated: Die Nutzerberechtigungen wurden aktualisiert!
</i18n>
