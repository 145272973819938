<template>
  <v-list-item class="rounded-lg bg-white new-folder border-grey">
    <template #prepend>
      <v-icon
        color="primary"
        :icon="Icons.FOLDER"
      ></v-icon>
    </template>

    <template #title>
      <v-form
        v-model="folderForm"
        @submit.prevent="validate"
      >
        <v-text-field
          v-model="folderName"
          autofocus
          :label="t('newFolder.defaultValue')"
          density="compact"
          :rules="folderNameRules"
          variant="solo"
          single-line
          flat
        ></v-text-field>
      </v-form>
    </template>

    <template #append>
      <DefaultButton
        class="mr-4"
        :color="PlattformColors.SECONDARY"
        @click="emit('handleCancelNewFolder')"
      >
        {{ t('buttons.cancel') }}
      </DefaultButton>

      <DefaultButton
        type="submit"
        class="mr-4"
        :color="PlattformColors.PRIMARY"
        :loading="buttonLoading"
        @click="validate"
      >
        {{ t('buttons.create') }}
      </DefaultButton>
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { isNotEmpty } from '@/validators'
import { Folder } from '@/models/Folder'
import { PropType, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Icons } from '@/models/enums/IconTypes'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'
const { t } = useI18n()

const folderForm = ref(false)
const folderName = ref('')
const buttonLoading = ref(false)

const prop = defineProps({
  parentFolder: {
    type: Object as PropType<Folder>,
    default: undefined,
    required: true,
  },
})

const folderNameRules = computed(() => [
  (v: string): boolean | string => !prop.parentFolder?.subfolders?.find((folder: Folder) => folder.name === v) || t('newFolder.textfield.notUnique'),
  (v: string): boolean | string => isNotEmpty(v),
])

const emit = defineEmits(['handleSaveNewFolder', 'handleCancelNewFolder'])

/**
 * validate
 */
async function validate(): Promise<void> {
  if (folderForm.value) {
    buttonLoading.value = true
    emit('handleSaveNewFolder', folderName.value)
  }
}
</script>

<style lang="scss" scoped>
.v-list-group__items {
  .v-list-item {
    &__prepend > .v-icon {
      margin-inline-end: 1rem;
    }
    
    .v-input {
      margin-bottom: 0;

      :deep(.v-label.v-field-label) {
        margin-inline-start: 0;
        font-size: $font-size-s;
      }

      :deep(.v-field__input) {
        padding-inline-start: 0;
        font-size: $font-size-s;
      }

      :deep(.v-messages__message) {
        margin-top: 0.8rem;
      }

      :deep(.v-input__details) {
        position: absolute;
        top: 2rem;
        padding-top: 0;
        padding-inline-start: 0;
      }
    }
  }

  .v-list-item.new-folder {
    padding: 0.7rem 0.5rem;
  }
}
</style>

<i18n lang="yaml">
de:
  newFolder:
    defaultValue: Ordnername
    textfield:
      notUnique: Der Ordnername muss eindeutig sein! Es existiert bereits ein Ordner mit diesem Namen.
</i18n>
