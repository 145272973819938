<template>
  <div>
    <DialogDefaultLayoutEnter :on-submit="() => (productObject?.publiclyAvailable ? emit('done') : emit('enable'))">
      <template #content>
        <h1>{{ t('publishProductSuccess.headline') }}</h1>

        <template v-if="productObject?.publiclyAvailable">
          <p>{{ t('publishProductSuccess.copy.public') }}</p>
        </template>

        <template v-else>
          <p>{{ t('publishProductSuccess.copy.private') }}</p>

          <InfoBox
            :headline="t('publishProductSuccess.info.headline')"
            :copy="t('publishProductSuccess.info.copy')"
          />
        </template>
      </template>
      <template
        v-if="productObject?.publiclyAvailable"
        #buttons
      >
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          @click="emit('done')"
          >{{ t('buttons.done') }}</DefaultButton
        >
      </template>
      <template
        v-else
        #buttons
      >
        <DefaultButton
          type="submit"
          :color="PlattformColors.PRIMARY"
          :loading="loading"
          @click="emit('enable')"
          >{{ t('buttons.enable') }}</DefaultButton
        >

        <DefaultButton
          :color="PlattformColors.SECONDARY"
          @click="emit('done')"
        >
          {{ t('buttons.later') }}
        </DefaultButton>
      </template>
    </DialogDefaultLayoutEnter>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { ProductResponse } from '@/models/Product'
import DialogDefaultLayoutEnter from './layout/DialogDefaultLayoutEnter.vue'
import { PropType } from 'vue'
import InfoBox from '../InfoBox.vue'
import DefaultButton from '../baseComponents/buttons/DefaultButton.vue'
import { PlattformColors } from '@/models/enums/ColorSets'

defineProps({
  productObject: {
    type: Object as PropType<ProductResponse>,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const emit = defineEmits(['done', 'enable'])
</script>

<i18n lang="yaml">
de:
  publishProductSuccess:
    headline: API veröffentlicht!
    copy:
      public: Deine API wurde erfolgreich veröffentlicht und ist jetzt auf dem Marktplatz verfügbar.
      private: Deine API wurde erfolgreich veröffentlicht. Gebe deine API jetzt für Organisationen frei, um sie auf dem Marktplatz zugänglich zu machen.
    info:
      headline: Verwalte deine Freigaben über das Kontextmenü
      copy: Private API-Produkte müssen für Organisationen freigegeben werden, um auf dem Marktplatz sichtbar zu sein.
</i18n>
