import { PlanResponse } from '@/models/Plan'

interface ProductPlanHelper {
  isPlanVisible: (plan: PlanResponse) => boolean
}
/**
 * @return {ProductPlanHelper}
 */
export function useProductPlanHelper(): ProductPlanHelper {
  /**
   * Check visibility of plan
   * @param {Plan} plan
   * @return {boolean} true if plan is visible
   */
  function isPlanVisible(plan: PlanResponse): boolean {
    if (!plan.validity) {
      return true // if validity is null, visibility is unlimited
    } else {
      const today = new Date()
      return (
        (!plan.validity.validUntil || new Date(plan.validity.validUntil) > today) && (!plan.validity.validFrom || new Date(plan.validity.validFrom) < today)
      )
    }
  }

  return {
    isPlanVisible,
  }
}
