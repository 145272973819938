export enum FilterValuesBundle {
  DRAFT = 'status:DRAFT',
  REQUESTED = 'status:REQUESTED',
  IN_PROGRESS = 'status:IN_PROGRESS',
  APPROVED = 'status:APPROVED',
  REJECTED = 'status:REJECTED',
}

export enum FilterValuesProduct {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum FilterValuesOrganization {
  ACTIVE = 'status:ACTIVE',
  INACTIVE = 'status:INACTIVE',
  INITIAL = 'status:INITIAL',
  IN_TERMINATION = 'status:IN_TERMINATION',
}
